import MockAdapter from "axios-mock-adapter-path-params";

import { NODE_ENV } from "src/config";
import {
    randomInteger
} from "src/utils";
import captchaItems from "src/data/captcha-item.json";
// import videos from "src/data/videos.json";


const mockLog = (config, status, response) => {
    return [status, response];
};

const httpMockFactory = (axios) => {
    const mock = new MockAdapter(axios, {
        onNoMatch: "passthrough",
        delayResponse: 150
    }, {
        ":id": "[^/]+"
    });

    let lastCaptchaId = null;

    mock.onGet("/api/captcha").reply(async (config) => {
        const captchaIds = captchaItems.map((captcha) => {
            return captcha.id;
        }).filter((id) => {
            return id !== lastCaptchaId;
        });

        lastCaptchaId = captchaIds[randomInteger(0, captchaIds.length - 1)];

        const captcha = captchaItems.find((captcha) => {
            return captcha.id === lastCaptchaId;
        });

        if (captcha) {
            const {
                successPosition,
                ...item
            } = captcha;

            return mockLog(config, 200, {
                item
            });
        }

        return mockLog(config, 500, {});
    });

    mock.onPost("/api/captcha/:id/validate").reply(async (config) => {
        const {
            routeParams: {
                id
            }
        } = config;

        const { value } = JSON.parse(config.data);

        const captcha = captchaItems.find((captcha) => captcha.id === parseInt(id));

        if (captcha && Math.abs(value - captcha.successPosition) <= 0.03) {
            return mockLog(config, 200, {
                status: "OK"
            });
        }

        return mockLog(config, 500, {
            status: "ERROR"
        });
    });

    if (NODE_ENV === "development") {
        // mock.onGet("/api/playlist").reply((config) => {
        //     return mockLog(config, 200, videos);
        // });
    }

    mock.onPost("/api/support").reply(async (config) => {
        await new Promise((resolve) => setTimeout(resolve, 1000));

        return mockLog(
            config,
            randomInteger(0, 100) >= 50 ? 200 : 500,
            {}
        );
    });

    mock.onGet("/mock/company/:id").reply(async (config) => {


        return mockLog(config, 200, {});
    });
};


export { httpMockFactory };