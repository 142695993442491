import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
    useFormContext,
    Controller
} from "react-hook-form";


const FormControl = (props) => {
    const {
        component: Component = "input",
        componentProps = {},
        validate,
        setvalue,
        value,
        required,
        clicked,
        maxLength,
        checked,
        generate,
        setValidate,
        minLength,
        label,
        name,
        defaultValue
    } = props;

    const { t } = useTranslation();
    const { control } = useFormContext();

    const handleError = useCallback((error) => {
        if (error) {
            switch (error.type) {
                case "required":
                    return t("Field is mandatory");

                case "minLength":
                    return `${t("Field must be at least")} ${minLength} ${t("characters long.")}`;

                case "maxLength":
                    return `${t("Field can be max")} ${maxLength} ${t("characters long.")}`;

                default:
                    return t(error.message || "");
            }
        }

        return undefined;
    }, [t, minLength, maxLength]);
    return (
        <Controller
            control={control}
            name={name}
            rules={{
                required,
                validate,
                minLength,
                maxLength
            }}
            defaultValue={defaultValue}
            render={({ field, fieldState }) => {
                const { ref, ...inputProps } = field;
                const { error } = fieldState;

                const errorMessage = handleError(error);

                return (
                    <Component
                        {...componentProps}
                        {...inputProps}
                        label={label}
                        setValidate={setValidate}
                        generate={generate}
                        checked={checked}
                        setvalue={setvalue}
                        {...name === 'confirmPassword' && value}
                        name={name}
                        clicked={clicked}
                        {...typeof errorMessage !== "undefined" ? {
                            error: true,
                            helperText: errorMessage
                        } : {}} />
                );
            }} />
    );
};


export { FormControl };