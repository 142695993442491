import {axiosInstance} from "src/store/helpers";


export const getCaptcha = () => {
    return async () => {
        const res = await axiosInstance.get("/api/captcha");

        if(res.status === 200) {
            return res.data.item;
        }

        return null;
    };
};

export const validateCaptcha = (id, value) => {
    return async () => {
        try {
            const res = await axiosInstance.post(`/api/captcha/${id}/validate`, {
                value
            });

            return res.status === 200;
        }
        catch(err) {
            return false;
        }
    };
};
