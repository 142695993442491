import React, { memo } from 'react';
import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  usePDF,
  View,
} from '@react-pdf/renderer';

import Header from 'assets/images/INFOCOIN-Blank-A4-2022-Cyprus.png';

import moment from 'moment';

const styles = StyleSheet.create({
  page: {
    width: '100%',
  },
  content: {
    padding: '20px',
  },
  logo: {
    width: '100%',
  },
  table: {
    width: '100%',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '10px 0',
    borderBottom: '1px solid gray',
    alignItems: 'center',
    flexDirection: 'row',
  },
  column: {
    // width: 'calc(50% - 20px)',
  },
  type: {
    fontSize: '16px',
    fontWeight: 'normal',
  },
  created_at: {
    marginTop: '5px',
    fontSize: '12px',
    color: 'gray',
  },
  sum: {
    fontSize: '20px',
    textAlign: 'right',
  },
});

const TransactionInvoice = ({ transactions, children }) => {
  const currentType = {
    transfer: 'Video viewing',
    outcome: 'Withdrawal of funds',
    in_verifying: 'Unconfirmed withdrawal',
    income: 'Return',
  };
  const MyDocument = ({ tr }) => {
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <Image style={styles.logo} source={Header} />
          <View style={styles.content}>
            <View style={styles.table}>
              {tr.map(trans => (
                <View style={styles.row}>
                  <View style={styles.column}>
                    <Text style={styles.type}>
                      {currentType[trans?.type] || trans?.type}
                    </Text>
                    <Text style={styles.created_at}>
                      {moment(trans?.created_at).format('DD MMMM YYYY')}
                    </Text>
                  </View>
                  <View style={styles.column}>
                    <Text style={styles.sum}>{trans?.sum}</Text>
                  </View>
                </View>
              ))}
            </View>
          </View>
        </Page>
      </Document>
    );
  };

  const [instance, update] = usePDF({
    document: <MyDocument tr={transactions} />,
  });

  return (
    <a download href={instance.url}>
      {children}
    </a>
  );
};

export default memo(TransactionInvoice);
