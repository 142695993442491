import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogActions, DialogTitle, Grid } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { termsOfUse } from 'src/data/terms-of-use.js';
import { privacyPolicy } from 'src/data/privacy-policy.js';
import { Button, Dialog, IconButton } from 'src/views/blocks';
import { CloseIcon } from 'src/views/icons';

import { ButtonGroup, Button as ButtonMui } from '@mui/material';

import './index.scss';

const TermsModal = props => {
  const { open = false, onAccept, onCancel, lightTheme = false } = props;

  const { t, i18n } = useTranslation();

  const [language, setLanguage] = useState(
    privacyPolicy[i18n.language] ? i18n.language : 'en',
  );

  const onChangeLanguage = value => {
    setLanguage(value);
  };

  useEffect(() => {
    if (i18n.language !== language) {
      if (privacyPolicy[i18n.language]) {
        setLanguage(i18n.language);
      } else {
        setLanguage('en');
      }
    }
  }, [i18n.language]);

  const [tab, setTab] = useState(0);

  const handleAccept = useCallback(() => {
    if (onAccept) {
      onAccept();
    } else {
      onCancel();
    }
  }, [onAccept, onCancel]);

  const handleCancel = useCallback(() => {
    if (onCancel) {
      onCancel();
    }
  }, [onCancel]);

  const style = {
    buttonText: {
      border: '0 !important',
      color: lightTheme ? 'black !important' : 'var(--color) !important',
    },
  };

  return (
    <Dialog lightTheme={lightTheme} className={`terms-modal`} open={open}>
      <DialogTitle>
        <Grid container justifyContent="space-between" flexWrap="wrap-reverse">
          <Grid display="flex" alignItems="center" className="document-buttons">
            <Button
              className="mr-2"
              variant="primary"
              disabled={tab === 0}
              onClick={() => setTab(0)}
            >
              {t('Terms of use')}
            </Button>

            <Button
              variant="primary"
              disabled={tab === 1}
              onClick={() => setTab(1)}
            >
              {t('Privacy policy on page help', {
                context: 'Privacy policy',
              })}
            </Button>
          </Grid>

          <Grid
            item
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            className="header-right-menu"
          >
            <div className="header-right-menu__button">
              <Button
                color="primary"
                variant="text"
                onClick={() => onChangeLanguage('uk')}
                disabled={language === 'uk'}
              >
                UKR
              </Button>
              |
              <Button
                color="primary"
                variant="text"
                onClick={() => onChangeLanguage('en')}
                disabled={language === 'en'}
              >
                ENG
              </Button>
              |
              <Button
                color="primary"
                variant="text"
                onClick={() => onChangeLanguage('de')}
                disabled={language === 'de'}
              >
                GER
              </Button>
            </div>
            <IconButton
              color={lightTheme ? 'black' : 'var(--color)'}
              icon={CloseIcon}
              onClick={handleCancel}
            />
          </Grid>
        </Grid>
      </DialogTitle>

      {tab === 0 && (
        <PerfectScrollbar theme="light">
          <div
            className="terms-modal__content"
            dangerouslySetInnerHTML={{
              __html: termsOfUse[language] || termsOfUse.en,
            }}
          />
        </PerfectScrollbar>
      )}

      {tab === 1 && (
        <PerfectScrollbar theme="light">
          <div
            className="terms-modal__content"
            dangerouslySetInnerHTML={{
              __html: privacyPolicy[language] || privacyPolicy.en,
            }}
          />
        </PerfectScrollbar>
      )}

      <DialogActions>
        <Grid container className="mb-2" justifyContent="center">
          <Grid item>
            <Button variant="primary" onClick={handleAccept}>
              {t('Close')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export { TermsModal };
