import React, {
  useState,
  useMemo,
  useRef,
  useEffect,
  useCallback,
} from 'react';
import { generatePath, Link } from 'react-router-dom';
import videoLogo from 'src/assets/images/default_video_logo.jpg';
import { ROUTES } from 'src/routing';
import { useTranslation } from 'react-i18next';
import { Grid, Avatar } from '@mui/material';
import { buildQuery } from 'src/utils';

import {
  getPlaylist,
  setVideoLotWatched,
  getLikedInVideo,
  videoLike,
  videoDislike,
  addBtnObjectClick,
  getDemoPlaylist,
} from '@services/api/video/video-api';

import {
  PageHeader,
  Player,
  Typography,
  PageLoader,
  Button,
} from 'src/views/blocks';
import { DashboardLayout } from 'src/views/layouts';

import { NoItems } from '..';

import Verification from '../Verification';
import { toast } from 'react-toastify';
import { getUserVerificationMethodAction } from '../../../../../store/modules/auth/actions';
import { useDispatch, useSelector } from 'react-redux';
import { userVerificationMethodsSelector } from '../../../../../store/modules/auth/selectors';
import * as faceapi from 'face-api.js';
import { getUserCategories } from '@services/api/user';
import { setFollowsAction } from '../../../../../store/modules/categories/actions';

const AuthHomePage = () => {
  const { t } = useTranslation();

  const [videos, setVideos] = useState([]);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [videoIsLike, setVideoIsLike] = useState(false);
  const [dataVideoLike, setDataVideoLike] = useState({});
  const [isEmailClicked, setEmailClicked] = useState(false);
  const [videosToVerification, setVideosToVerification] = useState(1);

  const { videos_count: videosToVerificationCount } = useSelector(
    userVerificationMethodsSelector,
  );

  const { follows } = useSelector(state => state.categories);

  const category_demo_id = 10149;

  const isShowDemoVideos = useMemo(() => {
    return follows.some(follow => follow.id === category_demo_id);
  }, [category_demo_id, follows]);

  const dispatch = useDispatch();

  const getByIndex = useCallback(
    activeIndex => {
      return videos.find((v, index) => {
        return index === activeIndex;
      });
    },
    [videos],
  );

  const video = useMemo(() => {
    return getByIndex(activeIndex);
  }, [activeIndex, getByIndex]);

  const updateVideosLike = useCallback(data => {
    if (data.lot_id !== 0 && data.auction_id !== 0) {
      getLikedInVideo(data.lot_id, data.auction_id)
        .then(res => {
          if (res.data) {
            setVideoIsLike(true);
            setDataVideoLike(res.data);
          } else {
            setVideoIsLike(false);
          }
        })
        .catch(() => setVideoIsLike(false));
    }
  }, []);

  useEffect(() => {
    if (video) {
      updateVideosLike(video);
    }
  }, [video]);

  const { company, category } = video || {};

  const isLast = useMemo(() => {
    return activeIndex === videos.length - 1;
  }, [activeIndex, videos]);

  const playerRef = useRef(null);

  const actionGetVideos = useCallback(
    async is_show_demo_videos => {
      const responseVideos = await getPlaylist({
        type: 'user',
        limit: 2,
        offset: 0,
      });

      if (responseVideos.length) {
        const stateVideos = [...videos.slice(2), ...responseVideos];
        setVideos(stateVideos);
      } else {
        if (is_show_demo_videos) {
          const responseDemoVideos = await getDemoPlaylist();
          if (responseDemoVideos.length) {
            const stateDemoVideos = [...videos.slice(2), ...responseDemoVideos];
            setVideos(stateDemoVideos);
          } else {
            setVideos([]);
          }
        } else {
          setVideos([]);
        }
      }
    },
    [videos, setVideos, follows],
  );

  const actionNext = useCallback(async () => {
    const { current: player } = playerRef;

    if (player) {
      if (isLast) {
        await actionGetVideos(isShowDemoVideos).then(() => {
          player.next();
        });
      } else {
        player.next();
      }
    }
  }, [actionGetVideos, isLast, isShowDemoVideos]);

  const handleCaptcha = useCallback(async () => {
    setShowCaptcha(false);
    if (video) {
      const { lot_id } = video;

      if (playerRef.current) {
        playerRef.current.pause();
      }

      if (lot_id) {
        try {
          await setVideoLotWatched(lot_id);
        } catch (e) {
          if (e.response.status === 400) {
            toast.error(t(e.response?.data?.error) || t('Error'));
          }
        }
      }
      setShowCaptcha(false);
      await actionNext();
    }
  }, [video, actionNext, t]);

  const handleEnded = useCallback(async () => {
    if (video?.id) {
      if (video?.categories) {
        const {
          categories: [{ id: categoryId }],
        } = video;

        if (categoryId === 18) {
          setTimeout(actionNext, 300);
        } else {
          if (videosToVerification - 1 === 0) {
            setShowCaptcha(true);
            setVideosToVerification(videosToVerificationCount);
          } else {
            setVideosToVerification(videosToVerification - 1);
            await handleCaptcha();
          }
        }
      } else {
        if (videosToVerification - 1 === 0) {
          setShowCaptcha(true);
          setVideosToVerification(videosToVerificationCount);
        } else {
          setVideosToVerification(videosToVerification - 1);
          await handleCaptcha();
        }
      }
    }
  }, [
    video,
    actionNext,
    videosToVerification,
    videosToVerificationCount,
    handleCaptcha,
  ]);

  const handlePlay = useCallback(() => {
    setShowCaptcha(false);
  }, []);

  const handleSlideChange = useCallback(activeIndex => {
    setActiveIndex(activeIndex);
    setEmailClicked(false);

    if (playerRef.current) {
      playerRef.current.play();
    }
  }, []);

  const handleToggleLike = useCallback(
    async (video, isLiked) => {
      setVideoIsLike(isLiked);
      if (isLiked) {
        await videoLike(video);
        await updateVideosLike(video);
      } else {
        await videoDislike(dataVideoLike.id).then(() =>
          updateVideosLike(video),
        );
      }
    },
    [dataVideoLike.id, updateVideosLike],
  );

  useEffect(() => {
    const initial = async () => {
      setLoading(true);
      try {
        await Promise.all([
          faceapi.nets.tinyFaceDetector.loadFromUri('/models'),
          faceapi.nets.faceLandmark68Net.loadFromUri('/models'),
          faceapi.nets.faceRecognitionNet.loadFromUri('/models'),
        ]);

        await dispatch(getUserVerificationMethodAction()).then(res => {
          if (res?.videos_count) {
            setVideosToVerification(res?.videos_count);
          }
        });

        if (!follows.length) {
          const resUserCategories = await getUserCategories();

          dispatch(setFollowsAction(resUserCategories.data));

          const initialIsShowDemoVideos = resUserCategories.data.some(
            follows => follows.id === category_demo_id,
          );
          await actionGetVideos(initialIsShowDemoVideos);
        } else {
          await actionGetVideos(isShowDemoVideos);
        }
        setLoading(false);
      } catch (e) {
        console.error(e);
        setLoading(false);
      }
    };

    initial();
  }, []);

  const onClickBtnEmail = () => {
    addBtnObjectClick({
      video_id: video.id,
      type: 'email',
    });
  };

  const onClickBtnUrl = () => {
    addBtnObjectClick({
      video_id: video.id,
      type: 'url',
    });
  };

  return (
    <DashboardLayout className="home-page">
      {isLoading && <PageLoader />}

      <div
        style={{ display: isLoading ? 'none' : undefined }}
        className="player__header"
      >
        <PageHeader
          title={company ? t(company.title) : ''}
          titleProps={{
            className: 'pl-4',
            variant: 'h5',
            ...(company
              ? {
                  component: Link,
                  to:
                    generatePath(ROUTES.company, {
                      id: company.id,
                    }) +
                    '?' +
                    buildQuery({
                      title: company.title,
                      avatar: company.avatar,
                      description: company.description,
                      header: company.header_filepath,
                    }),
                }
              : {}),
          }}
          subtitle={category ? t(category.title) : ''}
          subtitleProps={{
            className: 'pl-4',
          }}
          leftAddons={
            <React.Fragment>
              {company && (
                <Avatar
                  sx={{
                    width: 45,
                    height: 45,
                  }}
                  component={Link}
                  src={company.avatar || videoLogo}
                  to={
                    generatePath(ROUTES.company, {
                      id: company.id,
                    }) +
                    '?' +
                    buildQuery({
                      title: company.title,
                      avatar: company.avatar,
                      description: company.description,
                      header: company.header_filepath,
                    })
                  }
                />
              )}
            </React.Fragment>
          }
        />
      </div>
      {!isLoading && videos.length > 0 && (
        <React.Fragment>
          <Player
            ref={playerRef}
            videos={videos}
            onSlideChange={handleSlideChange}
            onPlay={handlePlay}
            videoIsLike={videoIsLike}
            onEnded={handleEnded}
            onLike={handleToggleLike}
            showCaptcha={showCaptcha}
          />
          {showCaptcha && (
            <Verification onSuccess={handleCaptcha} open={showCaptcha} />
          )}
          {video && (
            <Grid container sx={{ flexShrink: 0 }} direction="column">
              <Grid item container justifyContent="center">
                <Grid item className="mt-2">
                  {video.url && (
                    <Button
                      component="a"
                      variant="primary"
                      size="small"
                      className="video__bottom_button"
                      target="_blank"
                      rel="nofollow"
                      href={video.url}
                      onClick={onClickBtnUrl}
                    >
                      {t('Go to the site')}
                    </Button>
                  )}

                  {video.email && (
                    <Button
                      component="a"
                      variant={!isEmailClicked ? 'primary' : ''}
                      size="small"
                      rel="nofollow"
                      href={`mailto:${video.email}?subject=Infocoin: ${t(
                        video.title,
                      )}`}
                      onClick={() => {
                        setEmailClicked(true);
                        onClickBtnEmail();
                      }}
                    >
                      {!isEmailClicked && t('Send a message')}

                      {isEmailClicked && video.email}
                    </Button>
                  )}
                </Grid>
              </Grid>

              <Grid item>
                <Typography className="my-4 video--title" variant="h3">
                  {video.title}
                </Typography>
              </Grid>

              <Grid item>
                <Typography className="video--description">
                  {video.description}
                </Typography>
              </Grid>
            </Grid>
          )}
        </React.Fragment>
      )}

      {!isLoading && videos.length === 0 && (
        <>
          <div className="mt-4">
            <React.Fragment>
              <NoItems />
            </React.Fragment>
          </div>
        </>
      )}
    </DashboardLayout>
  );
};

export { AuthHomePage };
