import {getStorageValue} from "src/utils";


const initialState = {
    session_id: null,
    isAuthenticated: false,
    token: getStorageValue("token", ""),
    refreshToken: getStorageValue("refresh_token", ""),
    userData: {},
    isProfileLoaded: false,
    attachments: [],
    document: [],
    userVerificationMethod:{},
    verificationMethods:[]
};


export {initialState};