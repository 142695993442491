import React, { memo, useEffect, useState } from 'react';
import { Puzzle, Typography } from '../../../../blocks';
import FaceRecognition from '@components/FaceRecognition';
import Modal from 'src/components/Modal';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { userVerificationMethodsSelector } from '../../../../../store/modules/auth/selectors';

const Verification = ({ onSuccess, open }) => {
  const userVerificationMethod = useSelector(userVerificationMethodsSelector);

  const { isAuthenticated } = useSelector(state => state.auth);

  const [verificationMethod, setVerificationMethod] = useState(
    userVerificationMethod.title && isAuthenticated
      ? userVerificationMethod.title
      : 'Captcha',
  );

  useEffect(() => {
    if (isAuthenticated) {
      if (navigator.mediaDevices && verificationMethod === 'Face Unlock WEB') {
        navigator.mediaDevices
          .getUserMedia({
            video: { facingMode: 'user' },
          })
          .then(s => {
            const tracks = s.getTracks();

            tracks &&
              tracks.forEach(track => {
                track.stop();
              });
          })
          .catch(e => {
            if (e.message === 'Requested device not found') {
              setVerificationMethod('Captcha');
            }
          });
      } else {
        setVerificationMethod('Captcha');
      }
    }
  }, []);

  const { t } = useTranslation();
  return (
    <>
      {isAuthenticated && (
        <Modal
          withCloseButton={false}
          headerText={t('Face recognition')}
          show={open && verificationMethod === 'Face Unlock WEB'}
          onHide={() => {}}
        >
          <FaceRecognition
            setVerificationMethod={type => {
              setVerificationMethod(type);
            }}
            onSuccess={onSuccess}
          />
        </Modal>
      )}

      {open && verificationMethod === 'Captcha' && (
        <div className="home-page__captcha modal-bg text-center">
          <Typography
            className="pb-3"
            variant="body1"
            align="center"
            color="black"
            bold
          >
            {t('Complete captcha to get reward')}
          </Typography>

          <Puzzle onSuccess={onSuccess} />
        </div>
      )}
    </>
  );
};

export default memo(Verification);
