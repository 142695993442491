import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';

import {
  useCitiesApi,
  useCountriesApi,
  useRegionsApi,
  useTimezonesApi,
} from 'src/hooks';

import { updateUserEmail, confirmUserEmail } from '@services/api/user';

import { toast } from 'react-toastify';

import {
  CountryOption,
  IconButton,
  Button,
  Typography,
} from 'src/views/blocks';

import { useFormik } from 'formik';

import { UserEditSchema } from 'utils/formikShema';

import { TextInput, DateGroup, Select } from 'src/views/inputs';
import { Spinner } from 'react-bootstrap';
import { CloseIcon, DoneIcon, PencilIcon } from '../../../../icons';
import { TimerEmail } from '../Timer';

const UserForm = ({ data, onSubmit, fetchData, setStateProfilePhone }) => {
  const { t } = useTranslation();
  const timezones = useTimezonesApi();
  const countries = useCountriesApi();

  const [isShowCode, setIsShowCode] = useState(false);

  const [isEditEmail, setIsEditEmail] = useState(false);

  const [isEditCountry, setIsEditCountry] = useState(true);

  const [timerActive, setTimerActive] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const handleToggleShowCode = useCallback(e => {
    e.preventDefault();
    setIsShowCode(show => !show);
  }, []);

  const {
    handleSubmit,
    values,
    touched,
    setFieldTouched,
    handleChange,
    setFieldValue,
    dirty,
    isValid,
    isSubmitting,
    errors,
  } = useFormik({
    initialValues: { ...data, code: '' },
    validationSchema: UserEditSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      await onSubmit(values);
      setSubmitting(false);
    },
  });

  useEffect(() => {
    setFieldValue('name', data.name);
  }, []);

  const isShowConfirm = useMemo(() => {
    if (data.email === values.email || !!errors.email) {
      return false;
    } else {
      setIsShowCode(false);
      return true;
    }
  }, [data.email, values.email, errors.email]);

  const country = useMemo(() => {
    return countries.find(country => {
      return country.name === values.country;
    });
  }, [values.country, countries]);

  const regions = useRegionsApi(country ? country.id : null);

  const region = useMemo(() => {
    return regions.find(region => {
      return region.name === values.region;
    });
  }, [values.region, regions]);

  const cities = useCitiesApi(region ? region.id : null);

  const handleChangeSelect = useCallback(
    (name, value) => {
      setFieldValue(name, value);
    },
    [setFieldValue],
  );

  const handleSubmitEmail = useCallback(
    async e => {
      e.preventDefault();
      setIsLoading(true);
      try {
        await updateUserEmail(values.email).then(() => {
          toast.success(t('A verification code has been sent to your email'));
          setIsShowCode(true);
        });
      } catch (e) {
        toast.error(
          t(e?.response?.data?.error || 'Error sending verification code'),
        );
      }
      setIsLoading(false);
    },
    [updateUserEmail, values.email, toast, t],
  );

  const handleConfirmEmail = useCallback(
    async e => {
      e.preventDefault();
      setIsLoading(true);

      try {
        await confirmUserEmail(values.code).then(() => {
          setFieldValue('code', '');
          setIsShowCode(false);
          setIsEditEmail(false);
          toast.success(t('Email verified successfully!'));
          fetchData();
        });
      } catch (e) {
        toast.error(t(e?.response?.data?.error || 'Verification error'));
      }
      setIsLoading(false);
    },
    [
      confirmUserEmail,
      values.code,
      toast,
      t,
      fetchData,
      setFieldValue,
      setIsEditEmail,
    ],
  );

  const handleCancelEditEmail = useCallback(() => {
    setFieldValue('email', data.email);
    setIsEditEmail(false);
    setIsShowCode(false);
  }, [setIsEditEmail, data.email, setFieldValue]);

  const receivedCode = useCallback(
    e => {
      handleSubmitEmail(e);
      setTimerActive(true);
    },
    [handleSubmitEmail, setTimerActive],
  );

  return (
    <form onSubmit={handleSubmit}>
      <Grid item>
        <TextInput
          label="Full name"
          value={values.name}
          onFocus={() => setFieldTouched('name')}
          isRequired
          onChange={handleChange}
          error={!!errors.name}
          helperText={errors.name}
          name="name"
        />
      </Grid>

      <Grid item className="mt-2">
        <TextInput
          label="E-mail"
          value={values.email}
          isRequired
          onChange={handleChange}
          error={!!errors.email}
          disabled={!isEditEmail || isShowCode}
          helperText={errors.email}
          onFocus={() => setFieldTouched('email')}
          name="email"
          rightAddons={
            <React.Fragment>
              {!isEditEmail ? (
                <>
                  {/*<IconButton*/}
                  {/*  size="small"*/}
                  {/*  color="secondary"*/}
                  {/*  icon={PencilIcon}*/}
                  {/*  onClick={() => setIsEditEmail(true)}*/}
                  {/*/>*/}
                  <IconButton
                    type="button"
                    size="mini"
                    color="white"
                    circle="green"
                    icon={DoneIcon}
                  />
                </>
              ) : (
                <IconButton
                  size="small"
                  onClick={handleCancelEditEmail}
                  icon={CloseIcon}
                />
              )}
            </React.Fragment>
          }
        />
      </Grid>

      {isShowCode && (
        <React.Fragment>
          <Typography variant="body2" color="gray" inline={true}>
            {t('Code not received?')}
          </Typography>
          {!timerActive ? (
            <Typography
              variant="body2"
              color="primary"
              className="pl-1"
              inline={true}
              pointer={true}
              onClick={receivedCode}
            >
              {t('Resend')}
            </Typography>
          ) : (
            <TimerEmail
              timerActive={timerActive}
              setTimerActive={setTimerActive}
            />
          )}
          <Grid item className="mt-2">
            <TextInput
              label={t('Verification code')}
              value={values.code}
              onChange={handleChange}
              placeholder="1234"
              error={!!errors.code}
              helperText={errors.code}
              onFocus={() => setFieldTouched('code')}
              name="code"
            />
          </Grid>
          <Grid item className="mt-3">
            <Button
              fullWidth
              variant="primary"
              onClick={handleConfirmEmail}
              size="large"
            >
              {isLoading ? (
                <Spinner animation="border" variant="light" size="sm" />
              ) : (
                t('Submit')
              )}
            </Button>
          </Grid>
        </React.Fragment>
      )}

      {isShowConfirm && !isShowCode && (
        <Grid item className="mt-3">
          <Button
            fullWidth
            variant="primary"
            onClick={handleSubmitEmail}
            size="large"
          >
            {isLoading ? (
              <Spinner animation="border" variant="light" size="sm" />
            ) : (
              t('Confirm')
            )}
          </Button>
        </Grid>
      )}

      <Grid item className="mt-2">
        <DateGroup
          label="Date of birth"
          value={values.date_of_birth}
          isRequired
          onChange={value => handleChangeSelect('date_of_birth', value)}
          helperText={errors.date_of_birth}
          name="date_of_birth"
        />
      </Grid>

      <Grid item className="mt-2">
        <Select
          labelIsValue
          value={values.gender}
          onChange={value => handleChangeSelect('gender', value)}
          label="Gender"
          isRequired
          options={[
            { label: t('Male'), value: 'male' },
            { label: t('Female'), value: 'female' },
            { label: t('Non-binary'), value: 'not_binary' },
          ]}
          error={touched.gender && !!errors.gender}
          helperText={errors.gender}
          onFocus={() => setFieldTouched('gender')}
          name="gender"
        />
      </Grid>

      <Grid item className="mt-2">
        <Select
          isRequired
          label="Country"
          renderOption={props => <CountryOption {...props} showLabel />}
          options={countries.map(country => {
            return {
              label: country.name,
              value: country.name,
              iso: country.iso_2,
            };
          })}
          value={values.country}
          errors={errors.country}
          onChange={value => handleChangeSelect('country', value)}
          name="country"
          isDisabled={true}
          isSearchable={false}
        />
      </Grid>

      <Grid item className="mt-2">
        <Select
          label="Region"
          value={values.region}
          options={regions.map(region => {
            return {
              label: region.name,
              value: region.name,
            };
          })}
          name="region"
          onChange={value => handleChangeSelect('region', value)}
        />
      </Grid>

      <Grid item className="mt-2">
        <Select
          label="City"
          options={cities.map(city => {
            return {
              label: city.name,
              value: city.name,
            };
          })}
          value={values.city}
          onChange={value => handleChangeSelect('city', value)}
          name="city"
        />
      </Grid>

      <Grid item className="mt-2">
        <Select
          label="Timezone"
          value={values.timezone}
          onChange={value => handleChangeSelect('timezone', value)}
          options={timezones.map(tz => {
            return {
              label: tz.timezone,
              value: tz.timezone,
            };
          })}
          name="timezone"
        />
      </Grid>

      <Grid item container justifyContent="center" marginTop="2rem">
        <Button
          type="submit"
          disabled={!(isValid && dirty && !isShowCode)}
          size="large"
          variant="primary"
          fullWidth
        >
          {isSubmitting ? (
            <Spinner animation="border" variant="light" size="sm" />
          ) : (
            t('Save changes')
          )}
        </Button>
      </Grid>
    </form>
  );
};

export { UserForm };
