import {
  SET_WALLET_BALANCE,
  SET_WALLET_COREFY_PAYOUT_LIMIT,
  SET_WALLET_EXCHANGE_RATE,
  SET_WALLET_LAST_TRANSACTION,
  SET_WALLET_LOADING,
  SET_WALLET_TRANSACTIONS,
  SET_WALLET_WITHDRAWAL_LIMIT,
} from '../constants';
import {
  fetchGetCorefyPayoutLimit,
  fetchGetExchangeRate,
  fetchGetTransactions,
  fetchGetUserTransactions,
  fetchGetWalletBalance,
  fetchGetWithdrawLimit,
} from '@services/api/wallet';

import { toast } from 'react-toastify';

export const setWalletLoadingAction = loading => dispatch => {
  return dispatch({
    type: SET_WALLET_LOADING,
    payload: loading,
  });
};

export const setWalletLastTransactionAction = transaction => dispatch => {
  return dispatch({
    type: SET_WALLET_LAST_TRANSACTION,
    payload: transaction,
  });
};

export const getWalletTransactionsAction =
  (start_time, end_time) => async dispatch => {
    let start = start_time;
    let end = end_time;

    if (!start_time || !end_time) {
      const today = new Date(new Date().setHours(3, 0, 0, 0));

      const day = new Date().getDate() + 1;

      const month = new Date().getMonth();

      const year = new Date().getFullYear();

      start = new Date(today.setMonth(today.getMonth() - 1)).toISOString();

      end = new Date(year, month, day).toISOString();
    }

    try {
      const { data: transactions } = await fetchGetTransactions(start, end);

      const { data: transactionsUser } = await fetchGetUserTransactions(
        start,
        end,
      );

      const result = transactions.concat(
        transactionsUser
          .map(tr => {
            if (tr.status === 'in_verifying') {
              return {
                ...tr,
                sum: `${tr.amount} IC`,
                type: tr.status,
              };
            }
          })
          .filter(tr => tr !== undefined)
          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at)),
      );

      dispatch({
        type: SET_WALLET_TRANSACTIONS,
        payload: result,
      });

      return transactions;
    } catch (e) {
      console.log(e);
      toast.error(e?.response?.data?.error || e?.message || e);
      return e;
    }
  };

export const getWithdrawalLimitAction = country => async dispatch => {
  try {
    const {
      data: { min_payout, max_payout },
    } = await fetchGetWithdrawLimit(country);

    const result = {
      min_payout: Number(min_payout.slice(0, min_payout.length - 3)),
      max_payout: Number(max_payout.slice(0, max_payout.length - 3)),
    };

    dispatch({
      type: SET_WALLET_WITHDRAWAL_LIMIT,
      payload: result,
    });

    return result;
  } catch (e) {
    console.log(e);
    toast.error(e?.response?.data?.error || e?.message || e);
    return e;
  }
};

export const getWalletBalanceAction = () => async dispatch => {
  try {
    const {
      data: { balance },
    } = await fetchGetWalletBalance();

    const currentBalance = Number(balance.slice(0, balance.length - 3));

    dispatch({
      type: SET_WALLET_BALANCE,
      payload: currentBalance,
    });

    return balance;
  } catch (e) {
    console.log(e);
    toast.error(e?.response?.data?.error || e?.message || e);
    return e;
  }
};

export const getWalletExchangeRate = country => async dispatch => {
  try {
    const { data } = await fetchGetExchangeRate(country);

    const rate = data.find(({ type }) => type === 'output').rate;

    dispatch({
      type: SET_WALLET_EXCHANGE_RATE,
      payload: rate,
    });

    return rate;
  } catch (e) {
    console.log(e);
    toast.error(e?.response?.data?.error || e?.message || e);
    return e;
  }
};

export const getWalletCorefyLimit = () => async dispatch => {
  try {
    const {
      data: { limit },
    } = await fetchGetCorefyPayoutLimit();

    return dispatch({
      type: SET_WALLET_COREFY_PAYOUT_LIMIT,
      payload: limit,
    });
  } catch (e) {
    console.log(e);
    toast.error(e?.response?.data?.error || e?.message || e);
    return e;
  }
};

export const initialWalletAction = (country, wallet) => async dispatch => {
  const {
    corefyPayoutLimit,
    exchangeRate,
    withdrawalLimit: { max_payout, min_payout },
  } = wallet;

  dispatch(setWalletLoadingAction(true));
  try {
    await dispatch(getWalletBalanceAction());

    const transactions = await dispatch(getWalletTransactionsAction());

    await dispatch(setWalletLastTransactionAction(transactions[0]));

    if (!exchangeRate) {
      await dispatch(getWalletExchangeRate(country));
    }

    if (!max_payout && !min_payout) {
      await dispatch(getWithdrawalLimitAction(country));
    }

    if (!corefyPayoutLimit) {
      await dispatch(getWalletCorefyLimit());
    }
  } catch (e) {
    console.log(e);
    toast.error(e?.response?.data?.error || e?.message || e);
    return e;
  }
  dispatch(setWalletLoadingAction(false));
};
