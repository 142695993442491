import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';

import { store } from 'src/store';
import { theme } from 'src/theme';
import App from './App';
import { PageLoader } from './views/blocks';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import reportWebVitals from './reportWebVitals';

import './theme/index.scss';
import '../src/styles/normalize.css';
import './index.css';
import { IS_PRODUCTION } from './config';

Sentry.init({
  dsn: 'https://832187128ca84f0ba6432f66a65bad18@sentry.infocoin.online/5',
  integrations: [new BrowserTracing()],
  environment: IS_PRODUCTION ? 'prod' : 'dev',
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <Suspense fallback={<PageLoader />}>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  </Suspense>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
