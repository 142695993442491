import { axiosInstance } from 'src/store/helpers';
import { toast } from 'react-toastify';

export const getPlaylist = async options => {
  const { categoryID, type, limit = 50, offset = 0, ...other } = options;

  try {
    const res = await axiosInstance.get(`/api/playlist`, {
      params: {
        type,
        ...(type
          ? {
              ...(categoryID && type !== 'common'
                ? {
                    categoryID,
                  }
                : {}),
            }
          : {}),
        limit,
        offset,
        ...other,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (res.status === 200) {
      return res.data;
    }

    return [];
  } catch (err) {
    console.error(err);

    return [];
  }
};

export const getDemoPlaylist = async () => {
  try {
    const res = await axiosInstance.get(`/auth/demo/playlist`, {
      params: {
        countryID: 0,
        order: 'random',
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (res.status === 200) {
      return res.data;
    }

    return [];
  } catch (err) {
    console.error(err);

    return [];
  }
};

export const addBtnObjectClick = async data => {
  return await axiosInstance.post(`/api/interaction`, data);
};

export const videoLike = async video => {
  const res = await axiosInstance.post(
    `/api/user/like`,
    {
      auction_id: video.auction_id,
      lot_id: video.lot_id,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  return res.data;
};

export const setVideoLotWatched = async lotId => {
  return await axiosInstance.post(`/api/user/view`, {
    lot_ids: [lotId],
  });
};

export const setVideoDemoLotWatched = id => {
  return axiosInstance.post(
    `/auth/demo-videos/views`,
    {
      demo_video_id: id,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
};

export const getVideo = async ({
  categoryID = '',
  type = '',
  limit = 50,
  offset = 0,
}) => {
  return await axiosInstance.get(`/api/playlist`, {
    params: Object.assign(
      type && { type },
      {
        limit,
        offset,
      },
      categoryID && type !== 'common' && { categoryID },
    ),
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const setVideoWatched = async body => {
  return await axiosInstance.post(`/api/user/view`, body, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const videoDislike = async id =>
  await axiosInstance.delete(`/api/user/like/` + id);

export const getLikedInVideo = async (lot_id, auction_id) => {
  return await axiosInstance.get(
    `/api/like?auctionID=${auction_id}&lotID=${lot_id}`,
  );
};
export const addPartnerView = async (id, partner, amount) => {
  return await axiosInstance.post('/api/views/partner', {
    partner: partner,
    partner_video_id: id.toString(),
    amount: amount.toString(),
  });
};
