import React, {useCallback} from "react";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";

import {authActions} from "src/store";
import {useShowMessage} from "src/hooks";
import {
    Typography,
    Form
} from "src/views/blocks";

import {PasswordForm} from "../PasswordForm";


const PasswordSection = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const showMessage = useShowMessage();

    const handleSubmit = useCallback(async (data) => {
        const {
            currentPassword,
            password
        } = data;

        const res = await dispatch(authActions.changePassword(currentPassword, password));

        showMessage(res);
    }, [showMessage, dispatch]);

    return (
        <div className="password-section mb-4">
            <Typography
              className="mb-3"
              variant="h3">
                {t("Change password")}
            </Typography>

            <Form onSubmit={handleSubmit}>
                <PasswordForm />
            </Form>
        </div>
    );
};


export {PasswordSection};