import React, {useState, useRef, useCallback} from "react";
import {
    ClickAwayListener,
    Popper as MuiPopper,
    Paper
} from "@mui/material";

import {useThemeProps} from "src/hooks";

import "./index.scss";


const Popper = (props) => {
    const {
        component: Component = "button",
        theme,
        placement = "bottom",
        content,
        onClick,
        elevation = 1,
        ...rest
    } = useThemeProps(props);

    const [isOpen, setOpen] = useState(false);
    const buttonRef = useRef(null);

    const handleClick = useCallback((e) => {
        setOpen(!isOpen);

        if(onClick) {
            onClick(e);
        }
    }, [isOpen, onClick]);

    const handleClickAway = useCallback(() => {
        setOpen(false);
    }, []);

    return (
        <React.Fragment>
            <Component
              {...rest}
              ref={buttonRef}
              onClick={handleClick} />

            <MuiPopper
              className="popper"
              open={isOpen && !!buttonRef.current}
              anchorEl={buttonRef.current}
              placement={placement}
              modifiers={[
                {
                    name: "preventOverflow",
                    enabled: true,
                    options: {
                        altAxis: false,
                        altBoundary: false,
                        tether: false,
                        rootBoundary: "viewport",
                        padding: 0
                    }
                },
                {
                    name: "offset",
                    options: {
                        offset: [0, 10]
                    }
                },
                {
                  name: "arrow",
                  enabled: true
                }
              ]}>
                <ClickAwayListener
                  onClickAway={handleClickAway}>
                    <Paper
                      className={`popper-content popper-content--${theme}`}
                      elevation={elevation}>
                        {content}
                    </Paper>
                </ClickAwayListener>
            </MuiPopper>
        </React.Fragment>
    );
};


export {Popper};