import React, { useState, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';

import { useShowMessage } from 'src/hooks';
import { authActions } from 'src/store';

import { DashboardLayout } from 'src/views/layouts';
import { PageHeader, Typography, Form, FormLoader } from 'src/views/blocks';
import Accordion from 'react-bootstrap/Accordion';
import { TermsModal } from '../../blocks/TermsModal';

import './index.scss';

const SupportPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const showMessage = useShowMessage();

  const [message, setMessage] = useState('');
  const [show, setShow] = useState(false);
  const formRef = useRef(null);

  const handleSubmit = useCallback(
    async data => {
      const res = await dispatch(authActions.support(data));

      showMessage(res);

      if (res.status === 'OK') {
        setMessage(res.message);

        if (formRef.current) {
          formRef.current.reset();
        }
      }
    },
    [dispatch, showMessage],
  );

  const BtnMailto = ({ mailto, label }) => {
    return (
      <Link
        className="btn_mailto"
        to="#"
        onClick={e => {
          window.location.href = mailto;
          e.preventDefault();
        }}
      >
        {label}
      </Link>
    );
  };

  return (
    <DashboardLayout className="support-page">
      <PageHeader className="mb-5" variant="primary" title={t('Help')} />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography
            onClick={() => setShow(true)}
            style={{ display: 'inline', color: 'rgb(255, 95, 21)' }}
            className="text_underline help--text"
          >
            {t('View Documents')}
          </Typography>
        </Grid>
        <TermsModal
          open={show}
          onCancel={() => setShow(false)}
          supportPage={true}
        />
        <Grid item xs={12}>
          <Typography className="help--text">
            {t(
              'We sincerely appreciate your questions, feedback and suggestions. Your message will be forwarded to customer support. We will definitely answer you as soon as possible.',
            )}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography className="help--text">
            {t('Send us an email:')}
          </Typography>
          <BtnMailto
            label="help@infocoin.online"
            mailto="mailto:help@infocoin.online"
          />
        </Grid>

        <Grid item xs={12} mt={2}>
          <h2 className="accordion-title">{t('Faq')}</h2>
          <Grid mt={2}>
          <Accordion defaultActiveKey={['0']} alwaysOpen>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                {t('Why are there so few videos on the platform?')}
              </Accordion.Header>
              <Accordion.Body>
                {t(
                  'Our platform has recently launched and we are now actively attracting advertisers to our platform. More videos from advertisers, more rewards. If you have a business, you can upload your video and not only get rewards, but also attract new customers. Tell your friends about our platform and when they place their  advertisings you will be rewarded.',
                )}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>{t('How to withdraw money?')}</Accordion.Header>
              <Accordion.Body>
                {t(
                  'You will be able to transfer the money to your Visa or MasterCard, you will only have to enter your credit card number and withdraw the amount you want.',
                )}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                {t('How to delete my account?')}
              </Accordion.Header>
              <Accordion.Body>
                {t("You can remove your account in the 'Account' section.")}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                {t('Why does it show that there are 17 ads but I only see 2?')}
              </Accordion.Header>
              <Accordion.Body>
                {t(
                  'The number of viewers who see these ads depends on the budget of the advertiser. One video is available for viewing to a limited number of users. Accordingly, the first one who has time to watch the video gets a reward. When the number of advertisers will be enough - this problem will disappear.',
                )}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                {t('Why is money returned to my card?')}
              </Accordion.Header>
              <Accordion.Body>
                {t(
                  'It all depends on your bank. If IC comes back, it means that your bank rejected the payment, try to withdraw to another bank card.',
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          </Grid>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export { SupportPage };
