import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import { useHistory } from 'react-router-dom';
import { useThemeProps } from 'src/hooks';
import {
  getUserCategories,
  subCategory,
  unSubCategory,
} from '@services/api/user';

import { useDispatch, useSelector } from 'react-redux';

import Loader from '@components/Loader';
import { Button } from 'src/views/blocks';

import { setFollowsAction } from '../../../../../store/modules/categories/actions';

import './index.scss';

const CategoryItem = props => {
  const { theme, category, follows } = useThemeProps(props);

  const dispatch = useDispatch();

  const { id, avatar, title } = category;

  const { t } = useTranslation();
  const history = useHistory();

  const [follow, setFollow] = useState();
  const [loading, setLoading] = useState(false);

  const handleSubscribe = useCallback(async () => {
    setLoading(true);

    try {
      await subCategory(id);

      const res = await getUserCategories();

      if (res.status === 200) {
        const { data: follows } = res;

        dispatch(setFollowsAction(follows));

        const follow = follows.find(follow => follow.id === id);

        setFollow(follow);
      }
    } catch (err) {
      console.error(err);
    }

    setLoading(false);
  }, [id, setFollowsAction]);

  const handleUnsubscribe = useCallback(async () => {
    setLoading(true);

    try {
      await unSubCategory(id);

      const res = await getUserCategories();

      if (res.status === 200) {
        const { data: follows } = res;

        dispatch(setFollowsAction(follows));

        const follow = follows.find(follow => follow.id === id);

        setFollow(follow);
      }
    } catch (err) {
      console.error(err);
    }

    setLoading(false);
  }, [id, setFollowsAction]);

  const handleToPlaylist = useCallback(async () => {
    history.push(`/playlist/${id}`);
  }, [history, id]);

  useEffect(() => {
    const follow = follows.find(item => item.id === id);

    setFollow(follow);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, JSON.stringify(follows)]);

  return (
    <div className={`category-item category-item--${theme}`}>
      <div className="category-item__icon-wrapper">
        <div className="category-item__icon-border" />

        {avatar && <ReactSVG src={avatar} className="category-item__icon" />}

        {!avatar && (
          <span className="category-item__icon-title">{t(title)[0]}</span>
        )}
      </div>

      <h6 className="category-item__title">{t(title)}</h6>
      <Button
        className="category-item__btn px-2 mt-0"
        variant={!follow ? 'primary' : 'secondary'}
        size="small"
        weight="semi-bold"
        color="dark"
        onClick={follow ? handleUnsubscribe : handleSubscribe}
      >
        {loading && <Loader width={24} height={24} color="white" />}

        {!loading && !follow && t('Follow').toUpperCase()}

        {!loading && follow && t('Unfollow').toUpperCase()}
      </Button>
    </div>
  );
};

export { CategoryItem };
