import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Grid } from '@mui/material';
import { Typography } from 'views/blocks';
import { ROUTES } from 'src/routing';
import { authActions } from 'src/store';
import { useShowMessage } from 'src/hooks';
import { AuthLayout } from 'src/views/layouts';
import { TermsModal } from 'views/blocks/TermsModal';
import { CodeForm, FirstForm } from './blocks';
import { AuthArrowBackIcon } from 'views/icons';
import { SucessBlock } from './blocks/SucessBlock';
import { countriesActions } from '@redux/actions/registration';
import { signUpContactEvent } from 'config/facebookPixelEvent';
import { AgreementModal } from '../../blocks/AgreementModal';

const RegisterPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const showMessage = useShowMessage();
  const { t } = useTranslation();
  const { isAuthenticated } = useSelector(state => state.auth);
  const { regUserData, isSendCode, stepRedux } = useSelector(
    state => state.registrationReducer,
  );

  const [showModalDocuments, setShowModal] = useState(false);
  const [showPublic, setShowPublic] = useState(false);

  const [step, setStep] = useState(stepRedux);
  const [time, setTime] = useState(600);
  const onToggleModalDocuments = () => {
    setShowModal(!showModalDocuments);
  };
  const { email } = regUserData;

  const handleSubmitCode = useCallback(
    async ({ code }) => {
      const res = await dispatch(authActions.signUp({ email, code }));

      if (res?.status === 'ERROR') {
        toast.error(t('Incorrect Verification Code'));
      } else {
        showMessage(res);
      }
      if (stepRedux === 2) {
        history.push(ROUTES.login);
        return;
      }

      if (res.status === 'OK') {
        setStep(3);
        setTimeout(async () => {
          const authRes = await dispatch(authActions.login(regUserData));
          showMessage(authRes);
          dispatch({
            type: countriesActions.CLEAR_REG_DATA,
          });
        }, 3000);
      }
    },
    [
      dispatch,
      stepRedux,
      showMessage,
      regUserData,
      authActions,
      countriesActions,
    ],
  );

  const handleSendCode = useCallback(
    async argEmail => {
      const res = await dispatch(authActions.fetchResendCodeRegister(argEmail));
      if (res.status === 'OK') {
        toast.success(t('Code resubmitted'));
      } else {
        toast.error(t('Error resend code'));
      }
      //eslint-disable-next-line
    },
    [dispatch, showMessage],
  );

  const handleSubmitFirst = useCallback(
    async data => {
      if (!isSendCode || regUserData.email !== data.email) {
        const res = await dispatch(authActions.register(data));
        signUpContactEvent();
        dispatch({
          type: countriesActions.SET_IS_SEND_CODE,
          data: true,
        });
        if (res.status === 'OK') {
          setStep(2);
        } else if (res.message === 'user with this email already exist') {
          history.push(ROUTES.login);
        } else if (res.message === 'email domain in blacklist') {
          showMessage({
            message: 'This email domain in blacklist',
            status: 'ERROR',
          });
          history.push(ROUTES.login);
        }
      } else {
        handleSendCode(data.email);
      }
      setStep(2);
      dispatch({
        type: countriesActions.SET_REG_DATA,
        data: data,
      });
    },
    [
      dispatch,
      handleSendCode,
      showMessage,
      countriesActions,
      isSendCode,
      signUpContactEvent,
    ],
  );

  return (
    <AuthLayout>
      {isAuthenticated && <Redirect to={ROUTES.home} />}

      {step === 1 && (
        <>
          <Grid container paddingBottom="60px">
            <Grid item fontWeight="700">
              <Typography variant="h2" className="auth-form-title">
                {t('Join to INFOCOIN!')}
              </Typography>
            </Grid>
          </Grid>
          <FirstForm
            setShowPublic={setShowPublic}
            setShowModal={setShowModal}
            onSubmit={handleSubmitFirst}
          />
        </>
      )}

      {step === 2 && (
        <>
          <Grid container paddingBottom="60px">
            <Grid
              item
              fontWeight="700"
              marginLeft="-30px"
              className="d-flex"
              alignItems="center"
            >
              <div onClick={() => setStep(1)}>
                <AuthArrowBackIcon
                  className="mr-2"
                  size="smallmed"
                  color="#FF5F15"
                />
              </div>
              <Typography className="auth-form-title">
                {t('Join to INFOCOIN!')}
              </Typography>
            </Grid>
          </Grid>
          <CodeForm
            onSubmit={handleSubmitCode}
            setTime={setTime}
            time={time}
            setStep={setStep}
            onSendCode={handleSendCode}
            regUserData={regUserData}
          />
        </>
      )}

      {step === 3 && <SucessBlock />}
      <TermsModal
        lightTheme={true}
        open={showModalDocuments}
        onCancel={onToggleModalDocuments}
        onAccept={onToggleModalDocuments}
      />
      <AgreementModal
        open={showPublic}
        lightTheme={true}
        onAccept={() => setShowPublic(false)}
        onCancel={() => setShowPublic(false)}
      />
    </AuthLayout>
  );
};

export { RegisterPage };
