import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import {
  authActions,
  authReducer,
  captchaActions,
  playerActions,
  playerReducer,
  registrationReducer,
  uiActions,
  uiReducer,
  videoHistoryActions,
  videoHistoryReducer,
  categoriesReducer,
  categoriesActions,
  walletReducer,
  walletActions,
} from './modules';
import notificationReducer from './modules/notifiactions/reducer';
import { partnersReducer } from './modules/partners/reducer';

const rootReducer = combineReducers({
  auth: authReducer,
  registrationReducer: registrationReducer,
  player: playerReducer,
  ui: uiReducer,
  historyVideo: videoHistoryReducer,
  notifications: notificationReducer,
  categories: categoriesReducer,
  wallet: walletReducer,
  partners: partnersReducer,
});

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk)),
);

export {
  store,
  captchaActions,
  authActions,
  uiActions,
  playerActions,
  videoHistoryActions,
  categoriesActions,
  walletActions,
};
