
import {
  ADD_NEW_NOTIFICATION,
  SAVE_NEW_NOTIFICATION,
} from "../constants";

const initialState = {
  items: [],
  loading: false,
  error: {},
};

export default function notificationReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SAVE_NEW_NOTIFICATION:
      return {
        ...state,
        items: payload,
      };
    case ADD_NEW_NOTIFICATION:
      return {
        ...state,
        items: [...state.items, payload],
      };
    default:
      return state;
  }
}
