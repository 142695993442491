import './index.scss';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { useCountriesApi } from 'hooks';
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    background: state.isSelected ? 'var(--primary-color)' : 'white',
    color: state.isSelected ? '#fff' : 'black',
    cursor: 'pointer',
    '&:hover': {
      background: 'var(--secondary-color)',
    },
  }),
  control: provided => ({
    ...provided,
    minHeight: '50px',
    borderRadius: '4px',
    boxShadow: 'none',
    border: '1px solid #E2E2EA',
    '&:hover': {
      border: '1px solid #E2E2EA',
      boxShadow: '0px 1px 4px 0px $blue',
    },
    '&:focus': {
      border: '1px solid $blue',
      boxShadow: '0px 1px 4px 0px $blue',
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
  }),
};
const AuthSelectCountry = props => {
  const {
    value,
    onChange,
    label,
    name,
    defaultValue,
    classNameContainer,
    ...rest
  } = props;
  const countries = useCountriesApi();

  const [select, setSelect] = useState({});

  const { t } = useTranslation();
  useEffect(() => {
    if (defaultValue) {
      if (countries.length) {
        const defValue = countries.filter(
          country => country.name === defaultValue,
        )[0];
        setSelect({
          value: defValue.iso_2,
          label: t(defValue.name),
        });
      }
    }
  }, [countries]);

  const onChangeCountry = value => {
    onChange(value.label);
    setSelect(value);
  };

  const formatOptionLabel = ({ value, label }) => {
    return (
      <div className="auth-country-select-option">
        <div className="flag">
          <img
            width={35}
            height={15}
            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${value}.svg`}
          />
        </div>
        <span>{label}</span>
      </div>
    );
  };

  return (
    <div className={`container-auth_select-country ${classNameContainer}`}>
      {label && <span>{label}</span>}
      <Select
        name={name}
        formatOptionLabel={formatOptionLabel}
        className="auth-country-select"
        value={select}
        styles={customStyles}
        options={countries
          .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase())
          .map(country => {
            return {
              label: t(country.name),
              value: country.iso_2,
            };
          })}
        onChange={onChangeCountry}
        placeholder=""
        components={{
          IndicatorSeparator: () => null,
        }}
      />
    </div>
  );
};

export { AuthSelectCountry };
