import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Grid } from '@mui/material';

import './index.scss';

const FaCode = props => {
  const {
    count = 4,
    disabled = false,
    value = '',
    onFocus,
    onBlur,
    onChange,
  } = props;

  const [code, setCode] = useState(value);
  const inputsRefs = useRef([]);

  const handleKeyDown = useCallback((e, index) => {
    const { key, ctrlKey, target } = e;

    if (!ctrlKey && /^\D$/i.test(key)) {
      e.preventDefault();
    } else if (key === 'ArrowLeft' && target.selectionStart === 0) {
      if (inputsRefs.current[index - 1]) {
        inputsRefs.current[index - 1].focus();
      }
    } else if (key === 'ArrowRight' && target.selectionStart === 1) {
      if (inputsRefs.current[index + 1]) {
        inputsRefs.current[index + 1].focus();
      }
    } else if (key === 'Backspace' && target.value === '') {
      e.preventDefault();

      if (inputsRefs.current[index - 1]) {
        inputsRefs.current[index - 1].focus();
      }
    }
  }, []);

  const handleChange = useCallback(
    (e, index) => {
      const {
        target: { value },
      } = e;

      const nextValue = (code.slice(0, index) + value + code.slice(index + 1))
        .replace(/\D/g, '')
        .slice(0, count);
      const nextIndex = Math.min(count - 1, index + 1, nextValue.length - 1);

      setCode(nextValue);

      if (inputsRefs.current[nextIndex]) {
        inputsRefs.current[nextIndex].focus();
      }

      if (onChange) {
        onChange(nextValue);
      }
    },
    [code, count, onChange],
  );

  const handleFocus = useCallback(() => {
    if (onFocus) {
      onFocus();
    }
  }, [onFocus]);

  const handleBlur = useCallback(() => {
    if (onBlur) {
      onBlur();
    }
  }, [onBlur]);

  useEffect(() => {
    setCode(value);
  }, [value]);

  return (
    <div className="fa-code">
      <Grid
        container
        className="fa-code__wrapper"
        justifyContent="center"
        spacing={3}
      >
        {Array.from({ length: count }).map((ignore, index) => {
          return (
            <Grid
              key={index}
              item
              justifySelf="center"
              className="fa-code-item"
            >
              <input
                ref={ref => (inputsRefs.current[index] = ref)}
                className="fa-code__input"
                placeholder="X"
                disabled={disabled}
                value={code.charAt(index)}
                onFocus={e => handleFocus(e, index)}
                onBlur={e => handleBlur(e, index)}
                onKeyDown={e => handleKeyDown(e, index)}
                onChange={e => handleChange(e, index)}
              />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export { FaCode };
