import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { userVerificationMethodsSelector } from '../../../store/modules/auth/selectors';
import * as faceapi from 'face-api.js';
import { getUserVerificationMethodAction } from '../../../store/modules/auth/actions';
import { DashboardLayout } from '../../layouts';
import { Button, PageHeader, PageLoader, Typography } from '../../blocks';
import { generatePath, Link } from 'react-router-dom';
import { ROUTES } from '../../../routing';
import { buildQuery } from '../../../utils';
import { Grid } from '@mui/material';
import Verification from '../HomePage/blocks/Verification';
import { NoItems } from '../HomePage/blocks';
import { VASTClient, VASTTracker } from '@dailymotion/vast-client';
import PartnersPlayer from './components/PartnersPlayer';
import { toast } from 'react-toastify';
import { XMLParser } from 'fast-xml-parser';
import axios from 'axios';
import { addPartnerView } from '@services/api/video/video-api';
import { partnersListSelector } from '../../../store/modules/partners/selector';
const vastClient = new VASTClient();

let vastTracker;
const Partners = ({ withoutInterface = false, name }) => {
  const { t } = useTranslation();

  const [videos, setVideos] = useState([]);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isLoading, setLoading] = useState(true);

  const [vasts, setVasts] = useState([]);

  const [ad, setAd] = useState(false);
  const [videosToVerification, setVideosToVerification] = useState(1);
  const partners = useSelector(partnersListSelector, shallowEqual);

  const ref = useRef();

  const { videos_count: videosToVerificationCount } = useSelector(
    userVerificationMethodsSelector,
  );

  const { volume } = useSelector(state => state.player, shallowEqual);

  /* const partnersUrl = useMemo(() => {
    if (!name) return null;
    const url = new URL(partners.find(p => p.name === name).url);
    if (name === 'ad_mixer') {
      url.searchParams.set('rnd', (Math.random() * 1000).toFixed(0));
    }
    return url.toString();
  }, [name, partners]);

  const partner = useMemo(() => {
    return partners.find(p => p.name === name);
  }, [name, partners]);*/

  const dispatch = useDispatch();

  /* const getByIndex = useCallback(
    activeIndex => {
      return videos.find((v, index) => {
        return index === activeIndex;
      });
    },
    [videos],
  );
*/
  const loadVast = useCallback(
    async vastXmlUrl => {
      setLoading(true);
      try {
        const vastReq = await axios.get(`https://${vastXmlUrl}`);
        const parser = new XMLParser();
        let { VAST: res } = parser.parse(vastReq.data);
        const response = await vastClient.get(res.Ad.Wrapper.VASTAdTagURI, {
          withCredentials: true,
        });
        const ad = response.ads[0];
        if (ad) {
          const creative = ad.creatives[0];
          if (creative) {
            setAd(creative);
            setVideos(
              creative.mediaFiles.filter(file => file.mimeType === 'video/mp4'),
            );
            vastTracker = new VASTTracker(
              vastClient,
              ad,
              creative,
              null,
              volume === 0,
            );
          }
        }
        setLoading(false);
      } catch (err) {
        if (err) {
          console.error('VAST request failed', err);
          setLoading(false);
          setVideos([]);
          setAd(null);
        }
      }
    },
    [volume],
  );

  useEffect(() => {
    const load = async () => {
      const xhr = await axios.get(
        `https://a.labadena.com/api/spots/429561?v2=1&s1=%subid1%&kw=`,
      );

      const parser = new XMLParser();
      let { VAST: res } = parser.parse(xhr.data);
      setVasts(
        res.Ad.map(ad => ({
          url: ad.Wrapper.VASTAdTagURI.replace('//', ''),
          id: ad.Wrapper.VASTAdTagURI.replace('//', '').split('/').pop(),
        })),
      );
      await loadVast(res.Ad[0].Wrapper.VASTAdTagURI.replace('//', ''));
    };
    load();
  }, []);

  const video = useMemo(() => {
    return videos[0];
  }, [videos]);

  const { company, category } = video || {};

  const actionNext = useCallback(async () => {
    if (activeIndex + 1 === vasts.length - 1) {
      setVideos([]);
      setActiveIndex(0);
    } else {
      setActiveIndex(activeIndex + 1);
      await loadVast(vasts[activeIndex + 1].url);
    }
    if (ref.current) {
      ref.current.goPlay();
    }
  }, [activeIndex, loadVast, vasts]);

  const handleCaptcha = useCallback(async () => {
    setShowCaptcha(false);
    vastTracker.complete();
    if (ad) {
      try {
        await addPartnerView(
          vasts[activeIndex + 1].id,
          'ad_spyglass',
          '0.0033',
        );
        setShowCaptcha(false);
        await actionNext();
      } catch (e) {
        console.error(e);
        setShowCaptcha(false);
        toast.error(t('Error of  register view'));
      }
    }
  }, [ad, vasts, activeIndex, actionNext, t]);

  const handleEnded = useCallback(async () => {
    if (videosToVerification - 1 === 0) {
      setShowCaptcha(true);
      setVideosToVerification(videosToVerificationCount);
    } else {
      setVideosToVerification(videosToVerification - 1);
      await handleCaptcha();
    }
  }, [videosToVerification, videosToVerificationCount, handleCaptcha]);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      faceapi.nets.tinyFaceDetector.loadFromUri('/models'),
      faceapi.nets.faceLandmark68Net.loadFromUri('/models'),
      faceapi.nets.faceRecognitionNet.loadFromUri('/models'),
    ]).then(() => {});
    dispatch(getUserVerificationMethodAction()).then(res => {
      if (res?.videos_count) {
        setVideosToVerification(res?.videos_count);
      }
    });
  }, []);

  const onProgress = useCallback(time => {
    vastTracker.setProgress(time);
  }, []);

  const onPlay = useCallback(() => {
    vastTracker.setPaused(false);
  }, []);

  const onPause = useCallback(() => {
    vastTracker.setPaused(true);
  }, []);

  const onMute = useCallback(mute => {
    vastTracker.setMuted(mute);
  }, []);

  const onFullscreen = useCallback(full => {
    vastTracker.setFullscreen(full);
  }, []);

  const onCanPlay = useCallback(() => {
    vastTracker.trackImpression();
  }, []);

  const onError = useCallback(() => {
    vastTracker.error();
  }, []);
  return withoutInterface ? (
    <div
      style={{
        padding: '20px 20px 0',
        fontFamily: 'Inter',
      }}
    >
      {!isLoading && videos.length > 0 && (
        <React.Fragment>
          <PartnersPlayer
            withoutInterface
            ref={ref}
            video={video}
            onEnded={handleEnded}
            onProgress={onProgress}
            onPause={onPause}
            onPlay={onPlay}
            onMute={onMute}
            onFullscreen={onFullscreen}
            onCanPlay={onCanPlay}
            onError={onError}
          />
          {showCaptcha && (
            <Verification onSuccess={handleCaptcha} open={showCaptcha} />
          )}
        </React.Fragment>
      )}

      {!isLoading && videos.length === 0 && (
        <>
          <div className="mt-4">
            <Grid
              style={{ height: '100%' }}
              container
              direction="column"
              justifyContent="center"
            >
              <Grid item>
                <Typography variant="h3" bold align="center">
                  {t('While there is no video')}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </div>
  ) : (
    <DashboardLayout className="home-page">
      {isLoading && <PageLoader />}

      <div
        style={{ display: isLoading ? 'none' : undefined }}
        className="player__header"
      >
        <PageHeader
          title={company ? t(company.title) : ''}
          titleProps={{
            className: 'pl-4',
            variant: 'h5',
            ...(company
              ? {
                  component: Link,
                  to:
                    generatePath(ROUTES.company, {
                      id: company.id,
                    }) +
                    '?' +
                    buildQuery({
                      title: company.title,
                      avatar: company.avatar,
                      description: company.description,
                      header: company.header_filepath,
                    }),
                }
              : {}),
          }}
          subtitle={category ? t(category.title) : ''}
          subtitleProps={{
            className: 'pl-4',
          }}
        />
      </div>
      {!isLoading && videos.length > 0 && (
        <React.Fragment>
          <PartnersPlayer
            ref={ref}
            video={video}
            onEnded={handleEnded}
            onProgress={onProgress}
            onPause={onPause}
            onPlay={onPlay}
            onMute={onMute}
            onFullscreen={onFullscreen}
            onCanPlay={onCanPlay}
            onError={onError}
          />
          {showCaptcha && (
            <Verification onSuccess={handleCaptcha} open={showCaptcha} />
          )}
          {video && ad && (
            <Grid container sx={{ flexShrink: 0 }} direction="column">
              <Grid item container justifyContent="center">
                <Grid item className="mt-2">
                  {ad?.videoClickThroughURLTemplate?.url && (
                    <Button
                      component="a"
                      variant="primary"
                      size="small"
                      className="video__bottom_button"
                      target="_blank"
                      rel="nofollow"
                      href={ad?.videoClickThroughURLTemplate?.url}
                    >
                      {t('Go to the site')}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
        </React.Fragment>
      )}

      {!isLoading && videos.length === 0 && (
        <>
          <div className="mt-4">
            <React.Fragment>
              <NoItems />
            </React.Fragment>
          </div>
        </>
      )}
    </DashboardLayout>
  );
};

export default memo(Partners);
