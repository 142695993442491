export const DE = `
<!DOCTYPE html>
<html>
<head>
\t<meta http-equiv="content-type" content="text/html; charset=utf-8"/>
\t<title></title>
\t<meta name="generator" content="LibreOffice 7.4.2.3 (Linux)"/>
\t<meta name="author" content="Пользователь Windows"/>
\t<meta name="created" content="2022-12-05T10:24:00"/>
\t<meta name="changed" content="00:00:00"/>
\t<meta name="GrammarlyDocumentId" content="a0145496c6966264545cfcc3907bd020bc3c8c667c03b1fe1e962bd140219a0a"/>
\t<style type="text/css">
\t\t@page { size: 8.27in 11.69in; margin-left: 1.18in; margin-right: 0.59in; margin-top: 0.79in; margin-bottom: 0.69in }
\t\t@page p { line-height: 115%; text-align: left; orphans: 2; widows: 2; margin-bottom: 0.1in; direction: ltr; background: transparent }
\t\t@page a:link { color: #0563c1; text-decoration: underline }
\t</style>
</head>
<body lang="de-DE" link="#0563c1" vlink="#800000" dir="ltr"><p style="line-height: 108%; margin-bottom: 0.11in">
<br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><br/>
</font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>NUTZUNGSVEREINBARUNG</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">AUSGABE
GILT AB 05.01.2023</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">BITTE
LESEN SIE DIESE NUTZUNGSBEDINGUNGEN SORGFÄLTIG DURCH</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Willkommen
bei Infocoin.online! Diese Nutzungsvereinbarung legt die Bedingungen
für den Zugriff auf und die Nutzung von Internetseiten, mobilen
Versionen von Internetseiten, mobilen Anwendungen und anderer
Internetportale fest, die Eigentum von Infocoin Limited sind, unter
der Marke Infocoin verwaltet oder betrieben werden oder auf die
Infocoin Limited (wie unten definiert) von Zeit zu Zeit Zugriff
gewährt, und die zu (a) elektronischen Ressourcen von Infocoin
Limited, einschließlich, aber nicht beschränkt auf: die
Internetversion und die für mobile Geräte optimierte Version von
Webseiten, die durch den einheitlichen Ressourcenindex
„Infocoin.online“ gekennzeichnet sind, sowie mobile und andere
Anwendungen des Infocoin-Projekts (im Folgenden
„Infocoin-Plattform“), gehören. Dieses Dokument ist eine
rechtsverbindliche Vereinbarung zwischen Ihnen als Benutzer(n) der
Infocoin-Plattform (im Folgenden „Sie“, „Ihr“, „Benutzer“)
und der Rechtsperson Infocoin Limited (die gemäß Klausel 2.1 unten
(im Folgenden „wir“, „unser“ oder Infocoin Limited) definiert
ist), die die Vereinbarung abschließen.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><br/>
<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>1.
Anwendung und Annahme der Bedingungen</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1.1
Die in diesem Dokument enthaltenen Bedingungen regeln Ihren Zugriff
auf und Ihre Nutzung der Infocoin-Plattform und Dienste, Software,
Anwendungen für IOS und Android und Produkte (unter Verwendung der
Webseite, die die in Abschnitt 7.1 der Bedingungen genannten
Plattformen umfasst) von Infocoin Limited (diese Dienstleistungen,
Software und Produkte werden im Folgenden gemeinsam als die
&quot;Dienstleistungen&quot; bezeichnet), zusammen mit der
Datenschutzrichtlinie (wie in Abschnitt 3.3 unten definiert) und
zusammen mit solchen anderen Regeln und Richtlinien, die Infocoin
Limited und/oder seine verbundenen Gesellschaften von Zeit zu Zeit
veröffentlichen können. Dieses Dokument und andere ähnliche Regeln
und Richtlinien werden im Folgenden gemeinsam als „Bedingungen“
bezeichnet. Wenn Sie die Webseite und „Dienstleistungen“ nutzen
oder darauf zugreifen, erklären Sie sich damit einverstanden, die
Bedingungen zu akzeptieren und sich zu deren Beachtung zu
verpflichten. Wir bitten Sie, die Dienstleistungen und die Webseite
nicht zu nutzen oder darauf zuzugreifen, wenn Sie nicht alle
Bedingungen akzeptieren.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1.2
Sie dürfen die Dienstleistungen oder die Webseite nicht nutzen und
darauf zugreifen und den Bedingungen möglicherweise nicht zustimmen,
wenn (a) Sie nicht volljährig sind, um einen Vertrag mit Infocoin
Limited abzuschließen, oder (b) Ihnen die Nutzung bestimmter
Dienstleistungen gemäß den Gesetzen eines Landes/einer Region,
einschließlich des Landes/der Region, in dem/der Sie ansässig sind
oder in dem Sie die Dienstleistungen und die Webseite nutzen oder
darauf zugreifen, untersagt ist.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1.3
Infocoin Limited kann die Bedingungen jederzeit ändern, indem die
entsprechenden geänderten oder ergänzten Bedingungen auf der
Webseite veröffentlicht werden. Indem Sie die Dienstleistungen oder
die Webseite weiterhin nutzen oder darauf zugreifen, bestätigen Sie
Ihr Einverständnis mit den geänderten und ergänzten Bedingungen.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1.4
Wenn Infocoin Limited eine Übersetzung der englischen Version der
Bedingungen veröffentlicht oder bereitgestellt hat, stimmen Sie zu,
dass die Übersetzung nur der Einfachheit wegen bereitgestellt wird
und dass die englische Version Ihre Nutzung und Ihren Zugriff auf die
Dienstleistungen oder die Webseite regelt.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1.5
In einigen Fällen müssen Sie online oder offline eine separate
Dienstleistungsvereinbarung (oder -vereinbarungen) mit Infocoin
Limited oder einem anderen verbundenen Gesellschaften von Infocoin
Limited für bestimmte Dienstleistungen (oder ein Teil der
Dienstleistungen) abschließen (die „Zusatzvereinbarung“). Für
den Fall, dass die Bestimmungen der Bedingungen und
Zusatzvereinbarungen nicht übereinstimmen oder einander
widersprechen, wird den Zusatzvereinbarungen nur in dem Teil Vorrang
eingeräumt, der die Bereitstellung der entsprechenden
Dienstleistungen (oder eines Teils der Dienstleistungen) regelt, die
unter diesen Zusatzvereinbarungen bereitgestellt werden.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1.6
Die Bedingungen dürfen auf keine andere Weise geändert oder ergänzt
werden, außer schriftlich durch einen autorisierten Vertreter von
Infocoin Limited, gefolgt von der Veröffentlichung auf der Webseite.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1.7
Wenn Sie die Webseite und „Dienstleistungen“ nutzen oder darauf
zugreifen, stimmen Sie der Verarbeitung von Personendaten gemäß den
Bestimmungen der EU-Verordnung 2016/679 –Datenschutzgrundverordnung
(im Folgenden als DSGVO bezeichnet) zu. Die Datenschutzrichtlinie
(</font></font><font color="#0563c1"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>https://www.Infocoin.online/docs</u></font></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">)
gibt direkt an, welche Daten wir sammeln, warum wir sie sammeln, wie
wir sie verwenden und an wen wir sie weitergeben können.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1.8
Zum Zeitpunkt der Anmeldung müssen Sie mindestens 16 Jahre alt sein,
um die Nutzungsvereinbarung und Datenschutzrichtlinie einzuhalten.</font></font></p>
<p style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>2.
Erbringung von Dienstleistungen und Rechtsinformationen</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">2.1.
Sie schließen diese Vereinbarung mit Infocoin Limited ab, einer in
der Republik Zypern registrierten Gesellschaft (Registrierungsnummer:
HE 433674). Da die Dienstleistungen oder einige der Dienstleistungen
möglicherweise von verbundenen Gesellschaften von Infocoin Limited
unterhalten und bereitgestellt werden, kann Infocoin Limited einige
der Dienstleistungen an seine verbundenen Gesellschaften beauftragen.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">2.2.
Sie müssen sich als Mitglied der Infocoin-Plattform anmelden, um auf
bestimmte Dienstleistungen zugreifen und diese nutzen zu können. Für
die Zukunft behält sich Infocoin Limited das Recht vor, ohne
vorherige Ankündigung den Zugang oder die Nutzung bestimmter
Dienstleistungen, Teile der Dienstleistungen oder einer Funktion der
Webseite für den Benutzer einzuschränken oder vorbehaltlich anderer
Bedingungen, die Infocoin Limited allein nach eigenem Ermessen
auferlegen kann, zu machen.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">2.3
Die Dienstleistungen (oder Teile der bereitgestellten
Dienstleistungen) können je nach Region und Land variieren. Infocoin
Limited garantiert oder sichert nicht zu, dass eine bestimmte
Dienstleistung, eine bestimmte Eigenschaft oder Funktion der
Dienstleistung oder eine ähnliche und erweiterte Dienstleistung für
Benutzer oder einen bestimmten Benutzer verfügbar sein wird.
Infocoin Limited kann nach eigenem Ermessen unterschiedliche
Zugriffs- und Nutzungsebenen sowie die Funktionalität aller
Dienstleistungen (oder eines Teils der bereitgestellten
Dienstleistungen) in Bezug auf verschiedene Benutzer einschränken,
beenden oder erstellen.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">2.4
In diesen Nutzungsbedingungen bedeutet „bezahlter Benutzer“
Benutzer, die Content-Hosting-Dienstleistungen bestellen, und alle
anderen Personen, die kommerzielle Aktivitäten auf der Webseite
durchführen. Der Mindestpreis für eine Sekunde der
Contentplatzierung beträgt 0,0012423 Euro. Infocoin Limited kann
ohne vorherige Ankündigung Bedingungen erstellen, ändern,
verbessern, Bestimmungen einführen, die Bereitstellung einer
Dienstleistung (oder eines Teils der Dienstleistung) aussetzen oder
zustellen, außer im Fall der Bereitstellung einer kostenpflichtigen
Dienstleistung, wenn solche Änderungen keine wesentlichen
nachteiligen Auswirkungen für die Fähigkeit von bezahlten
Benutzern, diesen Dienst zu nutzen, haben.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">2.5
Einige Dienstleistungen (oder Teile davon) können von verbundenen
Gesellschaften von Infocoin Limited im Namen und im Auftrag von
Infocoin Limited bereitgestellt werden.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">2.6
Die Dienstleistungen werden in der vorliegenden Form bereitgestellt.
Infocoin Limited gibt keine Garantien in Bezug auf den fehlerfreien
und ununterbrochenen Betrieb der Webseite oder Dienstleistungen, die
Eignung der Webseite oder Dienstleistungen für die spezifischen
Zwecke und Erwartungen des Benutzers.</font></font></p>
<p style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<font color="#70757a"><font face="inherit, serif"><font size="3" style="font-size: 12pt"><br/>
</font></font></font><br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>3.
Bedingungen für alle Benutzer</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">3.1
Als notwendige Bedingung für Ihren Zugriff auf und Ihre Nutzung der
Infocoin-Plattform und -Dienstleistungen bestätigen Sie die
Beachtung aller geltenden Gesetze und Vorschriften während der
Nutzung und des Zugriffs auf die Webseite und die Dienstleistungen.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">3.2
Sie bestätigen, dass Sie (a) keine Dienstleistungen oder
Informationen, Texte, Bilder, Grafikelemente, Videos, Töne, Ordner,
Dateien, Datenbanken oder Listen usw., verfügbar auf der Webseite
(Webseite-Contents) kopieren, reproduzieren, herunterladen,
widerveröffentlichen, verkaufen, verbreiten oder wiederverkaufen
werden, und (b) das Content der Webseite nicht kopieren,
reproduzieren, herunterladen, zusammenstellen oder auf andere Weise
verwendet werden, um ein Geschäft zu führen, das mit dem Geschäft
von Infocoin Limited konkurriert, oder das Content der Webseite
kommerziell nutzen. Das systematische Durchsuchen von Seitencontent
auf den Webseiten, um direkt oder indirekt Sammlungen, Ansammlungen,
Datenbanken, Ordner (unter Verwendung von Robotern, Spyware,
automatischen Geräten oder manuell) zu erstellen oder zu
kompilieren, ist ohne schriftliche Genehmigung von Infocoin Limited
verboten. Die Nutzung von Contents oder Daten auf den Webseiten für
Zwecke, die nicht ausdrücklich durch die Bedingungen gestattet sind,
ist verboten.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">3.3
Sie müssen die folgenden Dokumente lesen, die den Schutz und die
Verwendung der von Infocoin Limited und unseren verbundenen
Gesellschaften gespeicherten Benutzer-Personendaten regeln:</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">a)
für alle Benutzer, die die Webseite oder die Dienstleistungen von
Infocoin Limited nutzen oder darauf zugreifen –
Datenschutzrichtlinie von Infocoin Limited;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">b)
für alle Benutzer, die die Webseite oder die Dienstleistungen von
Infocoin nutzen oder darauf zugreifen – Infocoin Limited
Nutzungsvereinbarung;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">3.4
Infocoin Limited kann Benutzern den Zugriff auf Contents, Produkte
und Dienstleistungen ermöglichen, die von Dritten über Hyperlinks
(in Form von Textlinks, Bannern, Kanälen und anderen), APIs oder
anderweitig zu Webseiten Dritter angeboten werden. Sie werden darauf
hingewiesen, dass Sie die Nutzungsbedingungen und/oder
Datenschutzrichtlinien solcher Webseiten lesen sollten, bevor Sie die
Webseiten nutzen. Sie verstehen, dass Infocoin Limited solche
Webseiten Dritter nicht kontrolliert, solche Webseiten nicht
überprüft und gegenüber niemandem für solche Webseiten oder
Contents, Produkte oder Dienstleistungen, die auf solchen Webseiten
oder unter Verwendung solcher Webseiten bereitgestellt werden können,
verantwortlich oder haftbar ist.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">3.5
Sie bestätigen, dass Sie keine Maßnahmen ergreifen werden, die zu
einer Verletzung der Integrität der Computersysteme und Netzwerke
von Infocoin Limited und/oder Benutzern führen könnten, noch dass
Sie unbefugten Zugriff auf solche Computersysteme oder Netzwerke
haben werden.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">3.6
Durch das Hochladen oder die Vorstellung von Informationen, Contents
oder Daten (zusammen „Benutzer-Content“) auf der
Infocoin-Plattform oder durch das Bereitstellen von Benutzer-Content
an Infocoin Limited oder seine Vertreter, soweit dies nach geltendem
Recht zulässig ist, gewähren Sie an Infocoin Limited eine
unwiderrufliche, unbefristete, universelle, gebührenfreie,
übertragbare (durch mehrere Stufen) Berechtigung zur Vorstellung,
Übertragen, Verteilen, Reproduzieren, Veröffentlichen,
Weiterveräußern, Anpassen, Modifizieren, Übersetzen, Erstellen
abgeleiteter Materialien von, und auf andere Weise einige oder alle
Benutzer-Contents in irgendeiner Form, Multimedia und Technologie zu
verwenden, die in irgendeiner Weise bekannt oder derzeit unbekannt
sind, und für jeden Zweck, der für Infocoin Limited, den Betrieb
von Infocoin-Plattformen, die Bereitstellung von Dienstleistungen
und/oder Geschäft des Benutzers günstig sein kann. Sie versichern
und garantieren gegenüber Infocoin Limited, dass Sie alle Rechte und
Befugnisse haben, eine solche Berechtigung für Benutzer-Content und
die Nutzung solches Benutzer-Contents (einschließlich abgeleiteter
geistiger Eigentumsrechte) durch Infocoin Limited und/oder seine
verbundenen Gesellschaften gemäß dieser Berechtigung frei von
jeglichen Einschränkungen zu erteilen und dass diese Berechtigung
die Rechte Dritter (wie dieser Begriff in Abschnitt 5.4 der
Bedingungen definiert ist) nicht verletzt. Soweit gesetzlich
zulässig, verzichten Sie auf Ihr Recht, Ihre geistigen
Eigentumsrechte an Benutzer-Content gegenüber Infocoin Limited
und/oder seinen verbundenen Gesellschaften, Rechtsnachfolgern oder
Unterlizenznehmern im Zusammenhang mit der Nutzung solches
Benutzer-Content bezüglich der Bereitstellung von Dienstleistungen
durchzusetzen. Informationen, die gemäß der Gesetzgebung zum
Personendatenschutz geschützt sind, werden ausschließlich gemäß
den Anforderungen dieser Gesetzgebung verwendet und gespeichert.</font></font></p>
<p style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>3</b></font></font><sup><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>1</b></font></font></sup><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>.
Bedingungen für Teilnehmer, die Benutzer-Content anschauen</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">3</font></font><sup><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1</font></font></sup><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">.1
</font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>Teilnehmer</b></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">,
die sich als Zuschauer anmelden, können Anreizen über die
Infocoin-Plattform als Ergebnis des Anschauens von Benutzer-Content
erhalten.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">3</font></font><sup><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1</font></font></sup><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">.2
Der Teilnehmer, der sich auf der Infocoin-Plattform anmeldet, um eine
Belohnung zu erhalten, muss das ausgewählten Content aufrufen und
die erforderlichen Maßnahmen ergreifen, um den Aufruf zu bestätigen
und die Identität zu verifizieren.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">3</font></font></font><font><sup><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1</font></font></sup></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">.3
</font></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Der
gleichzeitige Aufruf von Content durch den Zuschauer von mehr als
einem elektronischen Gerät ist nicht möglich.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">3</font></font></font><font><sup><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1</font></font></sup></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">.4
</font></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Im
Falle der Erkennung der in Par. 5.5 dieser Vereinbarung angegebenen
Merkmalen muss der Zuschauer den Support-Service der Webseite über
den Link </font></font><a href="https://view.infocoin.online/help"><font color="#0563c1"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>https://view.infocoin.online/help</u></font></font></font></a><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
kontaktieren.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><a name="_heading=h.gjdgxs"></a>
<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">3</font></font><sup><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1</font></font></sup><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">.5
Jeder Zuschauer hat das Recht, ein einzigartiges Profil erstellen zu
lassen. Die Infocoin Limited erlaubt es dem Zuschauer nur einziges
Profil zu erstellen. Für den Fall, dass ein Zuschauer mehrere
Profile erstellt, wird Infocoin Limited diese Profile löschen und
sich an den Regeln der Unterpunkt 6.2. dieser Vereinbarung
orientieren.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">3</font></font></font><font><sup><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1</font></font></sup></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">.6
</font></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">In
Bezug auf andere Bedingungen, Rechte und Pflichten, die nicht in
diesem Abschnitt angegeben sind, unterliegen die Zuschauer dieser
Nutzungsvereinbarung, dem anwendbaren internationalen Recht, sowie
den anwendbaren Gesetzen und Vorschriften in ihrer jeweiligen
Gerichtsbarkeit.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>4.
Konten</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.1
Der Benutzer muss auf der Infocoin-Plattform angemeldet sein, um auf
einige der Dienstleistungen zugreifen oder diese nutzen zu können
(der angemeldete Benutzer wird auch „Teilnehmer“ genannt). Sofern
nicht ausdrücklich von Infocoin Limited gestattet, darf ein Benutzer
nur ein Benutzerkonto registrieren. Infocoin Limited hat das Recht,
das Konto des Benutzers zu kündigen oder zu löschen, wenn Infocoin
Limited Grund zu der Annahme hat, dass der Benutzer gleichzeitig zwei
oder mehr Konten registriert hat oder kontrolliert. In Zukunft kann
Infocoin Limited die Anmeldungsanfrage des Benutzers ohne Angabe von
Gründen ablehnen.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.2
Bei der Anmeldung auf den Infocoin-Plattformen muss Infocoin Limited
ein Konto zuweisen und eine Teilnehmer-ID-Nummer und ein Passwort
erstellen (normalerweise ist die ID-Nummer eine E-Mail-Adresse und
das Passwort muss vom angemeldeten Benutzer während der Anmeldung
gewählt werden).</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.3
Die Kombination aus Identifikationsnummer und Passwort des
Teilnehmers ist für ein Konto einmalig. Jeder Teilnehmer ist allein
verantwortlich für die Wahrung der Vertraulichkeit und Sicherheit
seiner Teilnehmeridentifikationsnummer und seines Passworts für alle
Nutzungen und Aktivitäten, die unter Ihrem Konto stattfinden
(unabhängig davon, ob diese Nutzungen oder Aktivitäten vom
Teilnehmer autorisiert sind). Ein Teilnehmer darf seine
Teilnehmer-ID-Nummer oder sein Passwort nicht an andere Personen
weitergeben, übertragen oder deren Nutzung gestatten, auch nicht
innerhalb der Gesellschaft des Teilnehmers (falls zutreffend). Der
Teilnehmer verpflichtet sich, Infocoin Limited unverzüglich über
jede unbefugte Nutzung seines Passworts oder Kontos oder jede andere
Verletzung der Kontosicherheit zu informieren.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.4
Der Teilnehmer bestätigt, dass jede Nutzung der Infocoin-Plattform
und -Dienstleistungen sowie die unter seinem Konto durchgeführten
Aktivitäten (einschließlich, aber nicht beschränkt auf das Posting
von Informationen über die Gesellschaft und die Produkte, das
Klicken auf die Bestätigungsschaltflächen zur Zustimmung zu
Zusatzvereinbarungen oder Regeln, das Abonnieren oder Bezahlen von
Dienstleistungen, das Versenden von E-Mails über ein E-Mail-Konto
oder das Versenden von Textnachrichten oder soziale Netzwerke) gelten
als vom Teilnehmer autorisiert.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.5
Der Teilnehmer erkennt an, dass die Übertragung seines Kontos an
andere oder das Ermöglichen der Nutzung seines Kontos durch mehrere
Benutzer außerhalb seiner Gesellschaft (falls zutreffend) (zusammen
„Mehrfachnutzung“) Infocoin Limited oder anderen Benutzern der
Infocoin-Plattform irreparablen Schaden zufügen kann. Der Teilnehmer
erklärt sich damit einverstanden, Infocoin Limited, seine
verbundenen Gesellschaften, das Management, Mitarbeiter, Agenten und
Vertreter alle Verluste und Schäden (einschließlich, aber nicht
beschränkt auf entgangene Einnahmen) ersetzen, die durch die
Mehrfachnutzung seines Kontos entstehen. Der Teilnehmer erkennt
ferner an, dass Infocoin Limited im Falle einer Mehrfachnutzung
seines Kontos oder eines Verstoßes gegen Ihre Kontodatenaufbewahrung
aufgrund eines Verschuldens des Teilnehmers nicht für Verluste oder
Schäden haftet, die durch einen solchen Verstoß entstehen, und das
Recht hat das Konto des Teilnehmers ohne Zustimmung des Teilnehmers
zu sperren oder zu löschen.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.6
Eine der Dienstleistungen, die allen Teilnehmern auf den
Infocoin-Plattformen zur Verfügung gestellt wird, besteht darin, den
Schutz der Konten der Teilnehmer vor Betrug, Verlust der Kontrolle
über das Konto ohne den Willen der Teilnehmer und der Verwendung von
Konten zur Begehung illegaler Handlungen zu gewährleisten, die gegen
diese Bedingungen und die Rechte der Teilnehmer verstoßen,
einschließlich, aber nicht beschränkt auf ein anderes Konto, um
unzuverlässigen Verkehr auf den Infocoin-Plattformen zu erzeugen,
Transaktionen ohne Zustimmung der Teilnehmer durchzuführen usw. (im
Folgenden als „Betrugsbekämpfungsdienst“ bezeichnet). Als Teil
des Betrugsbekämpfungsdienstes verpflichten wir uns, die mit dem
Konto des Teilnehmers durchgeführten Aktionen regelmäßig zu
überwachen, um potenzielle Bedrohungen für die Sicherheit des
Kontos des Teilnehmers auf den Infocoin-Plattformen zu
identifizieren.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.7
Im Falle der Feststellung potenzieller Bedrohungen für das Konto im
Rahmen des Betrugsbekämpfungsdienstes verpflichten wir uns:</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.7.1
Das Konto des Teilnehmers sofort (innerhalb von 24 Stunden) daran zu
beschränken, Transaktionen (Käufe) auf den Infocoin-Plattformen
durchzuführen, sobald uns eine potenzielle Bedrohung bekannt wird.
Diese Beschränkung wirkt sich nicht auf Transaktionen (Käufe) aus,
die vor der Einführung der Beschränkung getätigt wurden. Um das
Problem der Ablehnung und Widerruf solcher Transaktionen (Käufe) zu
lösen, wenn sie von Missetätern begangen wurden, sollte sich der
Teilnehmer mit uns in Verbindung setzen;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.7.2
Den Teilnehmer kontaktieren und Maßnahmen innerhalb unserer
Kontrolle ergreifen, um sicherzustellen, dass das Konto des
Teilnehmers nicht durch Hacker oder Malware kompromittiert wurde. Im
Rahmen des Betrugsbekämpfungsdienstes können unsere Experten unter
bestimmten Umständen mit dem Teilnehmer in elektronische
Korrespondenz treten, ihn über die vom Teilnehmer bei der Anmeldung
(sowie bei der Nutzung des Kontos) angegebenen Kontaktdaten
kontaktieren und/oder verlangen, dass die Person, die das Konto
kontrolliert, bestätigt hat, dass es sich um einen Teilnehmer
handelt, indem sie einen Scan/ein Foto eines offiziellen Dokuments
zum Nachweis der Identität vorlegt;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.7.3
Die Möglichkeit erwägen, Beschränkungen für Transaktionen (Käufe)
für das Konto des Teilnehmers auf der Grundlage der Ergebnisse der
in Abschnitt 4.7.2 der Bedingungen beschriebenen Maßnahmen
aufzuheben;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.8
Wir verpflichten uns, die Vertraulichkeit aller Informationen zu
wahren, die wir vom Teilnehmer als Ergebnis der in Par. 4.7.2 der
Bedingungen genannten Maßnahmen erhalten, die Tatsache selbst, dass
das Konto des Teilnehmers auf die Funktion der Durchführung von
Transaktionen (Einkäufen) beschränkt ist, sowie die
Überwachungsmethoden im Rahmen des Betrugsbekämpfungsdienstes und
die Ergebnisse dieser Überwachung.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.9
Wir verpflichten uns, Anfragen von Strafverfolgungsbehörden
bezüglich des Teilnehmers, der von ihm eingestellten Contents und
des Kontos umgehend zu beantworten.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.10.
Der Teilnehmer verpflichtet sich, uns bei der Durchführung der in
Par. 4.7.2 der Bedingungen angegebenen Aktivitäten zu unterstützen,
einschließlich der Bereitstellung vollständiger, zuverlässiger und
aktueller Informationen, die von ihm im Rahmen des
Betrugsbekämpfungsdienstes gemäß unseren Anweisungen angefordert
werden.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.11
Falls die angeforderten Informationen gemäß Par. 4.7.2 der
Bedingungen nicht innerhalb von 30 Tagen nach dem Datum der Anfrage
bereitgestellt werden, kann das Konto des Teilnehmers jederzeit nach
unserer Entscheidung gelöscht oder vollständig gesperrt werden.
Wenn Sie uns auf unsere Anfragen falsche Angaben machen, kann Ihr
Konto nach unserem Ermessen jederzeit gelöscht oder vollständig
gesperrt werden. Im Falle der Meldung falscher Informationen
betrachten wir dies als einen Umstand, der auf eine mögliche
rechtswidrige Aneignung des Kontos hindeutet, was ein Grund dafür
sein kann, die in den Bedingungen festgelegten Maßnahmen zu
ergreifen und sich an die zuständigen Strafverfolgungsbehörden zu
wenden.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.12
Wir unternehmen alle Anstrengungen, um die Cybersicherheit von Konten
zu gewährleisten. Dennoch garantieren wir nicht, dass Dritte infolge
der Bereitstellung des Betrugsbekämpfungsdienstes nicht in der Lage
sein werden, das Konto illegal zu nutzen, einschließlich, dass sie
dem Teilnehmer keinen Schaden zufügen können. Diesbezüglich muss
der Teilnehmer bei seinen Tätigkeiten mit dem Konto wachsam sein,
das Passwort nicht auf fremden Geräten eingeben, das Konto nicht an
öffentlichen Orten verwenden, das Passwort auf ungesicherten nicht
Medien speichern und andere sicherheitsgefährdende Handlungen nicht
begehen. Zur Vermeidung von Zweifeln: Alle in Klausel 8 dieser
Bedingungen dargelegten Haftungsbeschränkungen gelten in ihrer
Gesamtheit für den Betrugsbekämpfungsdienst.</font></font></p>
<p style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>5.
Verpflichtungen der Teilnehmer</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.1
Jeder zahlende Benutzer erklärt, garantiert und bestätigt, dass (a)
er die volle Befugnis hat, den Bedingungen zuzustimmen,
Berechtigungen zu erteilen und seine Verpflichtungen aus dieser
Vereinbarung zu erfüllen; (b) er die Infocoin-Plattform und
-Dienstleistungen nur zum Zwecke der Geschäftsabwicklung nutzt und
darauf zugreift, und (c) für bezahlte Benutzer, die Rechtspersonen
sind, die bei der Anmeldung angegebene Adresse der Hauptgeschäftssitz
ist. Für die Zwecke dieser Bestimmung gilt eine Zweigniederlassung
oder zusätzliche Niederlassung nicht als eigenständige Gesellschaft
und Ihre Hauptniederlassung gilt als Hauptverwaltung.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.2
Der bezahlte Benutzer ist verpflichtet, Informationen,
Gesellschafts-, Geschäfts- oder Produkt-/Dienstleistungsdaten als
Teil des Anmeldungsprozesses auf der Infocoin-Plattform oder der
Nutzung und des Zugriffs auf Dienstleistungen oder Konten
bereitzustellen. Jeder bezahlte Benutzer erklärt, garantiert und
bestätigt, dass (a) solche Informationen und Daten, die während des
Anmeldungsprozesses oder während der Nutzung der Infocoin-Plattform
oder der Dienstleistungen übermittelt werden, gültig, genau,
aktuell und vollständig sind, und (b) Sie alle Informationen und
Daten beachten und sie unverzüglich ändern, um sie gültig, genau,
aktuell und vollständig zu halten.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.3
Indem Sie Teilnehmer werden, stimmen Sie der Aufnahme von
Kontaktinformationen über Sie in unsere Datenbank zu und erlauben
Infocoin Limited und unseren verbundenen Gesellschaften und
anderweitig, Ihre Personendaten in Übereinstimmung mit der
Datenschutzrichtlinie zu verwenden.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.4
Jeder Teilnehmer erklärt, garantiert und erkennt an, dass (a) er
allein dafür verantwortlich ist, alle erforderlichen Berechtigungen
Dritter in Bezug auf alle Benutzer-Contents, die er einreicht,
hochlädt oder vorstellt, zu erhalten; (b) Benutzer-Contents, die er
einreicht, hochlädt oder vorstellt, das Urheberrecht, Patent,
Warenzeichen, Handelsnamen, Geschäftsgeheimnis oder andere
persönliche oder Eigentumsrechte Dritter („Personen mit Rechten
Dritter“) nicht verletzen; und (c) er das Recht und die Befugnis
hat, die in den Benutzer-Contents beschriebenen Produkte oder
Dienstleistungen zu verkaufen, zu kaufen, zu handeln, zu vertreiben
oder zu exportieren oder zum Verkauf, Kauf, Handel, Vertrieb oder
Export anzubieten, und einen solchen Verkauf, Kauf, Handel, Vertrieb
oder Export oder Angebot keine Rechte Dritter verletzen; (d) Sie und
Ihre verbundenen Gesellschaften keinen Handelsbeschränkungen,
Sanktionen oder anderen rechtlichen Beschränkungen, die von einem
Land, einer internationalen Organisation oder Gerichtsbarkeit
auferlegt werden, unterliegen, und sich nicht am Fernverkauf von
gefälschten oder raubkopierten Produkten beteiligt haben; (e) wenn
Benutzer-Contents Bilder von anderen Personen als Ihnen enthalten,
bestätigen Sie, dass Sie die Zustimmung dieser Personen erhalten
haben, ihre Bilder gemäß den Bedingungen dieser Vereinbarung zu
verwenden. Wenn diese Personen nach geltendem Recht minderjährig
sind, bestätigen Sie, dass Sie die Zustimmung ihrer gesetzlichen
Vertreter erhalten haben, ihre Bilder gemäß den Bedingungen dieser
Vereinbarung zu verwenden, wie dies nach geltendem Recht erforderlich
ist; (e) Wenn das Benutzer-Content Ihr Bild enthält, bestätigen
Sie, dass Sie der Verwendung Ihres Bildes gemäß den Bedingungen
dieser Vereinbarung zustimmen.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.5
Jeder Teilnehmer erklärt, gewährleistet und bestätigt, dass die
von ihm eingereichten, hochgeladenen oder vorstellten
Benutzer-Contents muss:</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">(a)
gültig, genau, vollständig und rechtmäßig sein;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">(b)
nicht falsch, irreführend oder täuschend sein;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">(c)
keine Informationen enthalten, die diskreditierend, verleumderisch,
bedrohlich oder belästigend, obszön, anstößig, beleidigend,
angreifend, sexuell eindeutig oder schädlich für Minderjährige
sind;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">(d)
keine Informationen enthalten, die diskriminierend sind oder
Diskriminierung aufgrund von Rasse, Geschlecht, Religion,
Nationalität, Behinderung, sexueller Orientierung oder Alter
fördern;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">e)
nicht gegen die Nutzungsvereinbarung, andere Bedingungen oder
anwendbare Zusatzvereinbarungen verstößt;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">f)
nicht gegen geltende Gesetze und Vorschriften verstoßen
(einschließlich, aber nicht beschränkt auf diejenigen, die
Exportkontrollen, Verbraucherschutz, Urheberrechtsschutz, unlauteren
Wettbewerb, unbegründet überhöhte Preise oder unwahrhaftige
Werbung regeln) oder Aktivitäten fördern, die gegen geltende
Gesetze und Vorschriften verstoßen könnten;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">(g)
keine direkten oder indirekten Links zu anderen Webseiten, die
Contents enthalten, die möglicherweise gegen diese Bedingungen
verstoßen, enthalten.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.6
Jeder Teilnehmer erklärt, garantiert und bestätigt außerdem, dass
er sich verpflichtet:</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">a)
Aktivitäten auf der Infocoin-Plattform in Übereinstimmung mit allen
anwendbaren Gesetzen und Vorschriften durchzuführen;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">b)
Geschäfte ehrlich abwickeln;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">c)
Aktivitäten in Übereinstimmung mit den Bedingungen und allen
anwendbaren Zusatzvereinbarungen durchzuführen;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">d)
die Dienstleistungen oder die Infocoin-Plattform nicht zu nutzen, um
eine natürliche oder Rechtsperson zu betrügen (einschließlich,
aber nicht beschränkt auf, gestohlene Gegenstände nicht zu
verkaufen, gestohlene Kredit-/Debitkarten nicht zu verwenden);</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">e)
sich nicht als eine andere Person oder Organisation auszugeben, sich
selbst oder Ihre Zugehörigkeit zu einer Person oder Organisation
nicht falsch darzustellen;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">f)
sich nicht an Spam oder Phishing zu beteiligen;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">g)
andere illegale Handlungen zu verhindern (einschließlich, aber nicht
beschränkt auf solche, die eine Straftat darstellen, zu einer
zivilrechtlichen Haftung führen usw.) oder zu illegalen Handlungen
nicht zu ermutigen;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">h)
nicht zu versuchen, verschiedene proprietäre Kataloge, Datenbanken
und Listen von Infocoin Limited zu kopieren, zu reproduzieren, zu
benutzen oder zu enteignen;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">i)
keine Computerviren oder andere zerstörerische Geräte und Codes zu
verbreiten, die Software- oder Hardwaresysteme, Daten oder
persönliche Informationen beschädigen, stören, aufnehmen oder
enteignen;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">(k)
sich nicht an Programmen zu beteiligen, die die Integrität von
Daten, Systemen oder Netzwerken, die von Infocoin Limited und/oder
einem Benutzer der Infocoin-Plattform verwendet werden, beschädigen
oder unbefugten Zugriff auf solche Daten, Systeme oder Netzwerke
erhalten;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">l)
sich nicht an Aktivitäten zu beteiligen, die Infocoin Limited und
seine verbundenen Gesellschaften einer Haftung aussetzen würden.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.7
Der Teilnehmer darf die Infocoin-Plattform, die Dienstleistungen und
das Konto nicht verwenden, um Aktivitäten durchzuführen, die
ähnlich oder identisch mit dem Geschäft der elektronischen
Anspruchsplattform von Infocoin Limited sind.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.8
Der Teilnehmer erklärt sich damit einverstanden, alle erforderlichen
Informationen, Daten und Bestätigungen bereitzustellen und alle
erforderliche Unterstützung und Zusammenarbeit zu gewährleisten,
die erforderlich sind, um Infocoin Limited die Dienstleistungen zur
Bewertung der Verletzung der Bedingungen und/oder Verarbeitung von
Ansprüche eines Teilnehmers und/oder des Betrugsbekämpfungsdienstes
zu erbringen. Wenn der Teilnehmer diese Anforderungen nicht erfüllt,
was zu einer Verzögerung, Aussetzung oder Kündigung der
Dienstleistung führt, wird Infocoin Limited weder verpflichtet, den
Bereitstellungszeitraum der betreffenden Dienstleistung zu
verlängern, noch haftet es für Verluste oder Schäden, die sich aus
oder im Zusammenhang mit einer solchen Verzögerung, Aussetzung oder
Kündigung ergeben.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.9
Der Teilnehmer erkennt an und stimmt zu, dass Infocoin Limited sich
das Recht vorbehält, aber nicht verpflichtet ist, den Inhalt von
Mitteilungen, Daten oder Informationen (einschließlich
Benutzer-Content), die über die Infocoin-Dienstleistungen oder
-Plattform erstellt, empfangen oder verfügbar sind, aktiv zu
überwachen oder eine redaktionelle Kontrolle darüber auszuüben.
Infocoin Limited unterstützt, überprüft oder zertifiziert den
Inhalt von Videos, Contents oder anderen Daten oder Informationen
(einschließlich Benutzer-Content), die von einem Teilnehmer
geschaffen, übermittelt, gepostet oder anderweitig erstellt wurden,
nicht. Jeder Teilnehmer ist für den Inhalt seiner Nachrichten
verantwortlich und kann für seine Kommentare oder andere Daten oder
Informationen rechtlich verantwortlich oder haftbar gemacht werden.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.10
Der bezahlte Benutzer erkennt an und stimmt zu, dass die
Infocoin-Plattform und -Dienstleistungen (als bezahlter Benutzer) von
Organisationen (insbesondere Einzelunternehmern) und ihren Vertretern
oder Einzelpersonen zum Zwecke der Geschäftsabwicklung genutzt
werden können.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.11
Der Teilnehmer erkennt an und stimmt zu, dass jeder Teilnehmer
persönlich dafür verantwortlich ist, sich mit den geltenden
Gesetzen und Vorschriften in seiner jeweiligen Gerichtsbarkeit
vertraut zu machen, um zu bestätigen, dass die Nutzung und der
Zugriff auf die Infocoin-Plattform und die Dienstleistungen diesen
entsprechen.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.12.
Der Teilnehmer bestätigt hiermit, dass er diese Vereinbarung gelesen
hat und zustimmt (und/oder die entsprechende schriftliche Zustimmung
seiner gesetzlichen Vertreter (falls zutreffend) eingeholt hat und
Infocoin Limited berechtigt ist, einen Nachweis dieser Zustimmung
anzufordern), dass Infocoin Limited Sie per Post, E-Mail, Telefon,
SMS, E-Mail-Nachrichten, Push-Benachrichtigungen sowie über andere
Kommunikationsmittel, einschließlich aber nicht beschränkt auf,
über soziale Netzwerke (einschließlich, aber nicht beschränkt auf
Linkedin, Facebook), Messenger (einschließlich, aber nicht
beschränkt auf Viber, Whatsapp, Telegram), für Zwecke,
einschließlich, aber nicht beschränkt auf Marketing, Werbung,
Meinungsforschung, Förderung der Infocoin-Plattform und
-Dienstleistungen, kontaktieren darf. <br/>
Dieser Teilnehmer stimmt
auch zu, dass Infocoin Limited das Recht hat, Dritte zu beauftragen,
einschließlich, aber nicht beschränkt auf verschiedene Unternehmen,
die relevante Dienste zum Zwecke des Versands von Werbung,
Informationen, Diensten und anderen Nachrichten anbieten Betreiber,
Messenger, verschiedene soziale Netzwerke, Plattformen, andere
Unternehmen, die ähnliche Dienste anbieten.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.13
Jeder Teilnehmer sichert zu, garantiert und stimmt zu, dass er und
seine verbundenen Gesellschaften geltende Gesetze und Vorschriften
einhalten (einschließlich, aber nicht beschränkt auf geltende
Gesetze und Vorschriften in Bezug auf Produktsicherheit, geistige
Eigentumsrechte, Datenschutz, Verbraucherschutz, unlauteren
Wettbewerb, Preisgestaltung, unzulässige Werbung bezüglich Umwelt,
Gesundheit und Sicherheit, Gesetze zur Korruptionsbekämpfung und
Gesetze zur Bekämpfung von Geldwäsche und Terrorismusfinanzierung);</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.14
Jeder Teilnehmer erklärt, garantiert und stimmt auch in Bezug auf
die Infocoin-Plattform, die Webversion und die für Mobilgeräte
optimierte Version der Infocoin-Plattform, die durch den
einheitlichen Infocoin.online-Ressourcenindex und die entsprechende
mobile Infocoin-Anwendung identifiziert wird, zu, dass:</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">(a)
er und seine verbundenen Gesellschaften keinen Handelsbeschränkungen,
Sanktionen oder anderen rechtlichen Beschränkungen, die von einem
Land, einer internationalen Organisation oder Gerichtsbarkeit
auferlegt oder vorgeschlagen werden („berechtigte Einrichtungen“),
unterliegen;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">(b)
weder er noch eine seiner Muttergesellschaften oder verbundenen
Gesellschaften (falls zutreffend) direkt oder indirekt Vereinbarungen
abschließt oder den betreffenden Gesellschaften Gelder, Waren oder
Dienstleistungen zur Verfügung stellt;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">(c)
er zustimmt, jederzeit alle anwendbaren Exportkontroll- und
Sanktionsgesetze und -vorschriften in Bezug auf Waren,
Dienstleistungen, Software und Technologie einzuhalten, wenn er die
Dienstleistungen nutzt, einschließlich Sanktionsbeschlüssen,
Gesetzen und Vorschriften, die vom Sicherheitsrat der Vereinten
Nationen, der Volksrepublik China, den Vereinigten Staaten von
Amerika und jedem anderen Land genehmigt und angewendet werden.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Wenn
er zu irgendeinem Zeitpunkt eine der oben genannten Anforderungen
nicht erfüllt, sollte er die Nutzung der Dienstleistungen und der
Infocoin-Plattform sofort einstellen.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.15.
Wenn Infocoin Limited vernünftigerweise der Ansicht ist, dass eine
Ihrer Handlungen und/oder Unterlassungen gegen geltende Gesetze und
Vorschriften verstößt oder zu verstoßen droht, kann Infocoin
Limited nach eigenem Ermessen jederzeit Maßnahmen ergreifen, die es
angesichts der geltenden Umstände für erforderlich hält,
einschließlich, aber nicht beschränkt auf die Beendigung der
Bereitstellung aller oder einiger der Dienstleistungen für Sie sowie
bestimmter Funktionen der Webseite, die Schließung der relevanten
Beiträgen und die Beendigung des Betriebs Ihres Kontos, wobei alle
Rechte vorbehalten bleiben, die Infocoin Limited möglicherweise in
Bezug für alle Handlungen oder Verhaltensweisen seiner Teilnehmer,
die nicht den gesetzlichen Anforderungen entsprechen, hat. Sie
stimmen zu, dass solche Handlungen der Gesellschaft nicht rechtmäßig
sind.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>6.
Verstoß der Teilnehmer</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">6.1
Infocoin Limited behält sich das Recht vor, nach eigenem Ermessen
alle Benutzer-Contents (ganz oder teilweise), die Sie auf der
Infocoin-Plattform einreichen, hochladen oder vorstellen, zu löschen,
zu ändern oder abzuweisen, wenn wir vernünftigerweise glauben, dass
diese Benutzer-Contents illegal sind und die Bedingungen verletzt
oder dazu führen können, dass Infocoin Limited oder seine
verbundenen Gesellschaften haftbar werden oder die Rechte Dritter
verletzt oder die Interessen unserer Teilnehmer, Dritter oder
Infocoin Limited oder seiner verbundenen Gesellschaften schädigen
können oder die anderweitig nach alleinigem Ermessen von Infocoin
Limited als unangemessen erachtet werden.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">6.2
Wenn ein Teilnehmer gegen eine der Bedingungen verstößt oder
Infocoin Limited Grund zu der Annahme hat, dass ein Teilnehmer gegen
eine der Bedingungen verstößt, ist Infocoin Limited berechtigt, die
ihm angemessen erscheinenden Gegenmaßnahmen zu ergreifen,
einschließlich, aber nicht beschränkt auf: (a) das Konto des
Teilnehmers und alle mit diesem Konto verbundenen Konten zu sperren
oder zu löschen, wie Infocoin Limited nach eigenem Ermessen
festlegt. In einem solchen Fall haftet Infocoin Limited nicht für
Verluste oder Schäden, die sich aus einer solchen Sperrung oder
Löschung ergeben; (b) das Abonnement einer Dienstleistung, den
Zugriff auf eine Dienstleistung oder die aktuelle oder zukünftige
Nutzung einer Dienstleistung einschränken, aussetzen oder beenden;
(c) gepostete Informationen oder andere Benutzer-Contents, die der
Teilnehmer bereitgestellt, veröffentlicht oder vorstellt hat, zu
löschen oder die Menge an Informationen, die der Teilnehmer posten
oder vorstellen darf, oder die Menge anderer Benutzer-Contents, die
der Teilnehmer posten oder vorstellen darf, zu begrenzen; (d) der
Nutzung von Merkmalen oder Funktionen einer Dienstleistung durch den
Teilnehmer andere Beschränkungen auferlegen, die Infocoin Limited
nach eigenem Ermessen für angemessen hält; und (e) alle anderen
Abhilfemaßnahmen, Auswirkungen oder Strafen, die Infocoin Limited
nach eigenem Ermessen für angemessen hält, zu verwenden.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">6.3
Ohne die Gemeinsamkeit der Bedingungen einzuschränken, gilt der
Teilnehmer unter folgenden Umständen als Verstoß gegen die
Bedingungen:</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">a)
auf Anspruch oder Forderung eines Dritten, wenn Infocoin Limited
Grund zu der Annahme hat, dass dieser Teilnehmer seinen Vertrag mit
dem Dritten nicht zu erfüllen beabsichtigt oder tatsächlich nicht
erfüllt, einschließlich, aber nicht beschränkt auf die
Nichtlieferung durch den betreffenden Teilnehmer, der Waren oder
Dienstleistungen durch die Nutzung der Infocoin-Plattform und der
-Dienstleistungen anbietet, aller von einem solchen Dritten
bestellten Waren oder Dienstleistungen nach Erhalt des Kaufpreises,
oder wenn dieser Teilnehmer während der Lieferung der Waren
tatsächlich gegen die in der mit diesem Dritten geschlossene Vertrag
verletzt.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">b)
Infocoin Limited begründeten Verdacht hat, dass ein solcher
Teilnehmer bei einer Transaktion mit einer Gegenpartei gestohlene
Kreditkarten oder andere falsche und irreführende Informationen
verwendet hat,</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">c)
Infocoin Limited begründeten Verdacht hat, dass vom Teilnehmer
bereitgestellte Informationen nicht aktuell oder vollständig oder
falsch, ungenau oder irreführend sind;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">d)
Infocoin Limited ist der Ansicht, dass die Handlungen des Teilnehmers
zu Verlusten oder finanzieller Haftung gegenüber Infocoin Limited
oder seinen verbundenen Gesellschaften oder anderen Benutzern führen
können.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">6.4
Infocoin Limited behält sich das Recht vor, bei der Untersuchung
eines mutmaßlichen kriminellen oder zivilrechtlichen Fehlverhaltens
uneingeschränkt mit Regierungs- oder Aufsichtsbehörden,
Strafverfolgungsbehörden, privaten Ermittlern und/oder betroffenen
Dritten zusammenzuarbeiten. Darüber hinaus kann Infocoin Limited
innerhalb der Grenzen des anwendbaren Rechts Kontaktinformationen
über die Identität des Teilnehmers und/oder Informationen über
das/die Konto/Konten des Teilnehmers, Transaktionen oder Aktivitäten,
die auf der Infocoin-Plattform oder über die Infocoin-Plattform
durchgeführt werden, offenlegen, nach Aufforderung einer
Aufsichtsbehörde oder Strafverfolgungsbehörden oder eines
geschädigten Dritten oder aufgrund einer gerichtlichen Vorladung
oder eines anderen Rechtsstreits. Infocoin Limited haftet nicht für
Schäden oder Folgen, die sich aus einer solchen Offenlegung ergeben,
und der Teilnehmer wird davon absehen, infolge einer solchen
Offenlegung Maßnahmen zu ergreifen oder Klage gegen Infocoin Limited
einzureichen.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">6.5
Infocoin Limited kann jederzeit nach eigenem Ermessen die Nutzung von
Infocoin-Dienstleistungen oder -Plattformen durch diesen Teilnehmer
ohne Haftung gegenüber dem Teilnehmer einschränken, aussetzen oder
beenden, wenn Infocoin Limited benachrichtigt wird, dass der
Teilnehmer gegen eine Vereinbarung oder Verpflichtungen mit einem
verbundenen Gesellschaften von Infocoin Limited verstößt und ein
solcher Verstoß unehrliche oder betrügerische Tätigkeiten zur
Folge hat oder es besteht der begründete Verdacht, an unehrlichen
oder betrügerischen Aktivitäten beteiligt zu sein. Infocoin Limited
behält sich das Recht vor, ist jedoch nicht verpflichtet, einen
solchen Verstoß zu untersuchen oder vom Mitglied eine Bestätigung
zu verlangen.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">6.6
Jeder Teilnehmer erklärt sich damit einverstanden, Infocoin Limited,
seinen verbundenen Gesellschaften, Direktoren, Mitarbeitern, Agenten
und Vertretern entschädigen und sie schadlos zu halten gegenüber
allen möglichen Verlusten, Schäden, Ansprüchen und
Verbindlichkeiten (einschließlich vollständiger Rechtskosten), die
sich aus dem Einreichen, Posting oder Vorstellung von
Benutzer-Content durch die Nutzung oder den Zugriff auf die
Infocoin-Plattform oder -Dienstleistungen oder aus die Verletzung der
Bedingungen oder Zusatzvereinbarungen ergeben können.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><br/>
6.7
Jeder Teilnehmer erklärt sich damit einverstanden, dass Infocoin
Limited ihm oder anderen gegenüber für Benutzer-Content oder andere
Daten, die über die Dienstleistungen auf der Infocoin-Plattform
übermittelt werden, einschließlich betrügerischer, unwahrer,
irreführender, ungenauer, diffamierender oder illegaler Daten, nicht
haftbar ist und sein muss und dass das Verlustrisiko aus solchem
Benutzer-Content und anderen Daten vollständig bei jedem Teilnehmer
liegt. Infocoin Limited behält sich das Recht vor, auf eigene Kosten
die ausschließliche Verteidigung und Kontrolle über alle
Angelegenheiten zu übernehmen, die der Entschädigung durch den
Teilnehmer unterliegen. In diesem Fall muss der Teilnehmer mit
Infocoin Limited zusammenarbeiten, um allen verfügbaren
Rechtsmitteln zuzustimmen.</font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>7.
Vereinbarungen</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">7.1
Über die Webseite stellt Infocoin Limited elektronische
Webplattformen für den Austausch von Informationen zwischen
Content-Lieferanten und anderen Teilnehmern bereit. Infocoin Limited
bietet den Teilnehmern zusätzlich eine elektronische webbasierte
Transaktionsplattform zum Hosten, Vereinbaren, Abschließen von
Vereinbarungen, Verwalten und Erfüllung von Aufträgen für die
Bereitstellung von Content-Hosting-Dienstleistungen im Internet unter
Verwendung der Webseite, der angepassten Version für Mobiltelefone
und entsprechende Anwendungen mit dem Dienstleistungsvertrag.
Infocoin Limited vertritt jedoch in keiner Weise die Verkäufer oder
Käufer einer bestimmten Transaktion. Infocoin Limited hat keine
Kontrolle über und ist nicht verantwortlich für die Qualität,
Sicherheit, Rechtmäßigkeit oder Verfügbarkeit von Produkten oder
Dienstleistungen, die auf der Webseite veröffentlicht werden, oder
die Fähigkeit von Verkäufern, einen Verkauf abzuschließen, oder
die Fähigkeit von Käufern, einen Kauf abzuschließen. Die
Dienstleistungen umfassen alle Funktionen der Webseite, die Sie
verwenden können. Die Dienstleistungen umfassen auch das Versenden
und Bereitstellen personalisierter Marketing- und Werbeinformationen.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">7.2
Benutzer werden hiermit darüber informiert, dass es riskante
Übergänge geben kann, wenn Links und Nachrichten zum Zweck des
Phishings oder anderer eigensüchtiger Zwecke platziert werden.
Infocoin Limited verwendet mehrere Methoden, um die Richtigkeit
bestimmter Informationen von bezahlten Benutzern, die sie uns bei
ihrer Anmeldung zur Bezahlung von Dienstleistungen auf der Webseite
zur Verfügung stellen, zu überprüfen. Aufgrund der Schwierigkeit,
Benutzer über das Internet zu identifizieren, kann und wird Infocoin
Limited jedoch nicht die angebliche Identität jedes Benutzers
(einschließlich, aber nicht beschränkt auf bezahlte Benutzer)
bestätigen. Wir empfehlen Ihnen, eine Vielzahl von Instrumenten
sowie Ihre Vernunft zu verwenden, um einzuschätzen, mit wem Sie es
zu tun haben.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">7.3
Benutzer, die die Infocoin-Plattform oder -Dienstleistungen nutzen
oder darauf zugreifen, tragen das Risiko, Links zu folgen sowie
Verkaufs- und andere Vereinbarungen im Zusammenhang mit der Nutzung
verlinkter Seiten abzuschließen. Jeder, der die Webseite oder
Dienstleistungen nutzt oder darauf zugreift, übernimmt vollständig
alle Haftungsrisiken oder Schäden im Zusammenhang mit dem Klicken
auf Links und nachfolgenden Aktivitäten im Zusammenhang mit
Produkten oder Dienstleistungen, die Gegenstand von Vereinbarungen
außerhalb der Verwaltung von Infocoin Limited sind. Beispiele für
solche Risiken sind unter anderem die Nichtlieferung von Produkten
und Dienstleistungen, betrügerische Pläne, unbefriedigende
Produktqualität, Nichteinhaltung von Spezifikationen, fehlerhafte
oder unsichere Produkte, illegale Produkte, verspätete oder nicht
fristgerechte Lieferung oder Zahlung, falsche Kostenberechnungen,
Gewährleistungsbruch, Vertragsbruch, Risiken, dass die Herstellung,
der Import, der Export, der Vertrieb, das Angebot, die Anzeige, der
Erwerb, der Verkauf und/oder die Nutzung von Produkten oder
Dienstleistungen jeweilige Rechte Dritter verletzen oder als
Verletzung geltend gemacht werden können, und das Risiko, dass dem
Nutzer Verteidigungskosten oder sonstige Kosten im Zusammenhang mit
der Verletzung von Rechten Dritter oder im Zusammenhang mit etwaigen
Ansprüchen einer verteidigung- oder schadensersatzberechtigten
Partei im Zusammenhang mit der Verletzung ihrer Rechte aufgrund der
entstehen Geltendmachung von Forderungen oder Ansprüchen und
Einreichung von Klagen solcher Dritter. Zu diesen Risiken gehören
auch die Risiken von Ansprüchen von Verbrauchern, anderen Käufern,
Endbenutzern von Produkten oder anderen Dritten, die behaupten, dass
durch Ihre Nutzung von Webseiten oder Dienstleistungen sie geschädigt
worden zu sein, wenn Sie auf Werbelinks klicken. Alle oben genannten
Risiken werden als „Drittparteienrisiken“ bezeichnet. Infocoin
Limited haftet nicht für Verluste, Ansprüche, Verpflichtungen,
Ausgaben, Schäden, Unannehmlichkeiten, Geschäftsunterbrechungen
oder Kosten jeglicher Art, die sich aus oder im Zusammenhang mit
Risikovereinbarungen mit Dritten ergeben.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">7.4
Benutzer auf der Infocoin-Plattform sind voll verantwortlich für die
Festlegung und Erfüllung der Bedingungen von Vereinbarungen, die auf
Webseiten Dritter durch oder als Ergebnis der Nutzung der
Infocoin-Plattform oder -Dienstleistungen abgeschlossen wurden,
einschließlich, aber nicht beschränkt auf Zahlungsbedingungen,
Rückzahlungen, Garantien, Lieferung, Versicherung, Gebühren,
Steuern, Titel, Lizenzen, Bußgelder, Genehmigungen, Verarbeitung,
Transport und Lagerung, vorbehaltlich etwaiger zusätzlicher
Verpflichtungen, die in einem abgeschlossenen Vertrag vorgesehen
sind.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">7.5
Der Benutzer verpflichtet sich, alle Informationen und Daten
bereitzustellen, die Infocoin Limited möglicherweise im Zusammenhang
mit der Tatsache benötigt, dass Ihre Transaktionen auf oder durch
die Nutzung der Infocoin-Plattform oder der Dienstleistungen
durchgeführt werden. Infocoin Limited hat das Recht, das Konto eines
Benutzers zu sperren oder zu löschen, wenn der Benutzer die
erforderlichen Informationen und Daten nicht bereitstellen kann. In
einem solchen Fall haftet Infocoin Limited nicht für Verluste oder
Schäden, die sich aus oder im Zusammenhang mit einer solchen
Aussetzung oder Entfernung ergeben.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">7.6
Im Falle einer Streitigkeit für einen Benutzer erklären sich diese
Benutzer damit einverstanden, Infocoin Limited (und unsere Vertreter,
verbundenen Gesellschaften, Direktoren, leitenden Angestellten und
Mitarbeiter) von allen Ansprüchen, Forderungen, Klagen, Verfahren,
Kosten und Schäden (einschließlich, aber nicht beschränkt auf alle
tatsächlichen, besonderen, zufälligen oder Folgeschäden), die sich
aus oder im Zusammenhang mit einer solchen umstrittenen Situation
oder Vereinbarung ergeben, schadlos halten.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>8.
Haftungsbeschränkung</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">8.1
SOWEIT GESETZLICH ZULÄSSIG WERDEN DIE VON Infocoin Limited AUF ODER
ÜBER DIE Infocoin-PLATTFORM BEREITGESTELLTEN DIENSTLEISTUNGEN „TEL
QUEL“, „WIE VERFÜGBAR“ UND „MIT ALLEN FEHLERN“
BEREITGESTELLT. Infocoin Limited SCHLIESST ALLE AUSDRÜCKLICHEN ODER
STILLSCHWEIGENDEN GEWÄHRLEISTUNGEN AUS, EINSCHLIESSLICH, ABER NICHT
BESCHRÄNKT AUF JEGLICHE GEWÄHRLEISTUNGEN FÜR ZUSTAND, QUALITÄT,
HALTBARKEIT, LEISTUNG, GENAUIGKEIT, ZUVERLÄSSIGKEIT,
MARKTGÄNGIGKEIT. ALLE DERARTIGEN GEWÄHRLEISTUNGEN, ZUSICHERUNGEN,
BEDINGUNGEN, VERPFLICHTUNGEN SIND AUSDRÜCKLICH AUSGESCHLOSSEN.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">8.2
IM GESETZLICH ZULÄSSIGEN UMFANG ÜBERNIMMT Infocoin Limited KEINE
GEWÄHRLEISTUNG JEGLICHER ART HINSICHTLICH DER ZUVERLÄSSIGKEIT,
GENAUIGKEIT, RICHTIGKEIT, ZUVERLÄSSIGKEIT, QUALITÄT, STABILITÄT,
VOLLSTÄNDIGKEIT ODER KONTINUITÄT. Infocoin Limited GARANTIERT
NICHT, DASS HERSTELLUNG, IMPORT, EXPORT, VERTEILUNG, ANGEBOT,
ANZEIGE, KAUF, VERKAUF UND/ODER NUTZUNG VON PRODUKTEN ODER
DIENSTLEISTUNGEN, DIE AUF DER WEBSEITE VORGESTELLT WERDEN, KEINE
RECHTE VERLETZT. Infocoin Limited ÜBERNIMMT KEINE ZUSICHERUNGEN ODER
GEWÄHRLEISTUNGEN JEGLICHER ART IN BEZUG AUF PRODUKTE ODER
DIENSTLEISTUNGEN, DIE AUF DER WEBSEITE UND DEN WEBSEITEN DRITTER
ANGEBOTEN ODER ZUR VERFÜGUNG GESTELLT WERDEN.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">8.3
Alle Daten, die über die Infocoin-Plattform oder die
Dienstleistungen untergeladen oder anderweitig erhalten werden,
liegen im Ermessen jedes Benutzers, und jeder Benutzer ist allein
verantwortlich für Schäden an seinem Computersystem oder dem
Computersystem von Infocoin Limited sowie für jeglichen
Datenverlust, der kann durch das Herunterladen solcher Daten
entstehen. Keine mündlichen oder schriftlichen Informationen oder
Ratschläge, die ein Benutzer von Infocoin Limited oder über die
Infocoin-Plattform erhält, begründen eine Garantie, die hier nicht
ausdrücklich angegeben ist.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">8.4
Die Webseite kann Dienstleistungen oder Produkte von Benutzern
bewerben, die von unabhängigen Dritten bereitgestellt werden. Es
werden keine Garantien oder Zusicherungen in Bezug auf solche
Dienstleistungen oder Produkte gegeben. Infocoin Limited und
verbundene Gesellschaften sind nicht für solche Dienstleistungen
oder Produkte verantwortlich.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">8.5
Jeder Benutzer erklärt sich damit einverstanden, Infocoin Limited,
seinen verbundenen Gesellschaften, Direktoren, leitenden Angestellten
und Mitarbeitern alle Schäden entschädigen und diese Personen von
Ansprüchen, Haftung (einschließlich vollständiger Rechtskosten)
freizustellen, die sich aus der Nutzung durch diesen Benutzer von
oder durch Zugriff auf die Infocoin-Plattform oder -Dienstleistungen
(einschließlich, aber nicht beschränkt auf die Bereitstellung,
Veröffentlichung oder Vorstellung solcher Benutzerinformationen oder
Benutzer-Content auf den Webseiten) oder aus Ihrer Verletzung einer
der Bedingungen, einschließlich Zusicherungen und Garantien, ergeben
können. Jeder Benutzer stimmt hiermit zu, Infocoin Limited, seine
verbundenen Gesellschaften, Direktoren, leitenden Angestellten und
Mitarbeiter von allen Schäden, Ansprüchen, Verbindlichkeiten
(einschließlich Rechtskosten auf einer vollständigen
Entschädigungsbasis), die sich aus der Verletzung von durch den
Benutzer an Infocoin Limited gewährten Zusicherungen und
Gewährleistungen ergeben können, einschließlich, aber nicht
beschränkt auf die in Abschnitt 5 dieser Vereinbarung aufgeführten
Zusicherungen und Gewährleistungen, freizustellen.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">8.6
Jeder Benutzer stimmt hiermit zu, Infocoin Limited, seine verbundenen
Gesellschaften, Direktoren, leitenden Angestellten und Mitarbeiter
von allen Schäden, Ansprüchen, Haftungen (einschließlich
vollständiger Rechtskosten), die direkt oder indirekt als Folge von
allen geltend gemachten Ansprüchen von Anspruchsberechtigten Dritter
oder von anderer Dritter im Zusammenhang mit den auf der Webseite
angebotenen oder vorgestellten Dienstleistungen, Produkten,
Benutzer-Contents oder anderen Informationen entstehen können,
freizustellen. Jeder Benutzer stimmt hiermit zu, dass Infocoin
Limited keine Verpflichtung und keinerlei Haftung für von anderen
gepostete Daten, einschließlich diffamierender oder illegaler Daten,
hat und dass das Risiko des Verlusts solcher Daten vollständig bei
jedem Benutzer liegt. Infocoin Limited behält sich das Recht vor,
auf eigene Kosten die ausschließliche Verteidigung und Kontrolle
über alle Angelegenheiten zu übernehmen, die seiner Entschädigung
unterliegen, und in diesem Fall wird er mit Infocoin Limited bei der
Geltendmachung von Rechtsmitteln zusammenarbeiten.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">8.7
Infocoin Limited haftet nicht für direkte, indirekte, strafbare,
zufällige oder Folgeschäden oder Schäden jeglicher Art
(einschließlich, aber nicht beschränkt auf Schäden für
entgangenen Gewinn oder Einsparungen, Geschäftsunterbrechung,
Verlust von Informationen), sei es in der Vertragsabschließung,
Fahrlässigkeit, unerlaubte Handlung, Billigkeit oder anderweitig,
oder andere Schäden, die sich aus einem der folgenden Gründe
ergeben:</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">a)
Nutzung oder Unfähigkeit zur Nutzung der Infocoin-Plattform oder
-Dienstleistungen;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">b)
Fehler in Daten, Informationen oder Dienstleistungen, die vom
Benutzer oder einem anderen Dritten über die Infocoin-Plattform oder
-Dienstleistungen gekauft oder erhalten wurden;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">c)
Verletzung der Rechte Dritter oder Ansprüche oder Forderungen für
die Produktion, den Import, den Export, den Vertrieb, das Angebot,
die Vorführung, den Kauf, den Verkauf und/oder die Nutzung der
Produkte oder Dienstleistungen des Benutzers, die auf der
Infocoin-Plattform oder über die beworben oder vorgestellt werden
oder durch Bereitstellung von Dienstleistungen, die die Rechte
Dritter verletzen oder als solche geltend gemacht werden können;
oder Ansprüche einer Partei im Zusammenhang mit dem Schutz von
Rechten,</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">d)
unbefugter Zugriff Dritter auf Daten oder persönliche Informationen
eines Benutzers;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">e)
Erklärungen oder Handlungen eines Benutzers der Infocoin-Plattform
oder -Dienstleistungen;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">f)
andere Handlungen im Zusammenhang mit der Infocoin-Plattform oder den
Dienstleistungen, die sich ergeben, einschließlich aufgrund von
Fahrlässigkeit.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">8.8
Ungeachtet des Vorstehenden gilt die Gesamthaftung von Infocoin
Limited, unseren Mitarbeitern, Agenten, verbundenen Gesellschaften,
Vertretern oder Personen, die in unserem Namen handeln, in Bezug auf
jeden Benutzer für alle Ansprüche, die sich aus der Nutzung oder
dem Zugriff auf die Infocoin-Plattform oder -Dienstleistungen während
eines Kalenderjahres ergeben, ist auf den höheren der Beträge, die
der Benutzer an Infocoin Limited für die Nutzung oder den Zugriff
auf die Infocoin-Plattform oder -Dienstleistungen während des
Kalendermonats gezahlt hat, beschränkt, jedoch nicht mehr als nach
geltendem Recht zulässig. <br/>
Die vorstehende Bestimmung schließt
die Forderung des Benutzers nach Nachweis des tatsächlichen Schadens
nicht aus. Alle Ansprüche, die im Zusammenhang mit der Nutzung der
Infocoin-Plattform oder der Dienstleistungen entstehen, müssen
innerhalb von sechs (6) Monaten ab dem Datum, an dem der Klagegrund
aufgetreten ist, oder länger, falls dies nach geltendem Recht
festgestellt ist, geltend gemacht werden.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">8.9
Die Haftungsbeschränkungen und Haftungsausschlüsse Ihnen gegenüber
gemäß diesen Bedingungen gelten im größtmöglichen gesetzlich
zulässigen Umfang und gelten unabhängig davon, ob der Benutzer auf
die Möglichkeit solcher Schäden hingewiesen wurde oder wissen
musste.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>9.
Höhere Gewalt</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">9.1
Unter keinen Umständen ist Infocoin Limited haftbar oder
verantwortlich für die Zahlung von Entschädigungen jeglicher Art
für Verluste, die sich aus der Nichtverfügbarkeit, den Beschwerden
oder dem Ausfall der Dienstleistungen, Systeme, der
Infocoin-Plattform oder der Verzögerung oder dem Versäumnis der
Bereitstellung der Dienste aus solchen Gründen ergeben: (a)
Abschalten des Systems zu Wartungszwecken; (b) Unmöglichkeit der
Datenübertragung aufgrund von Fehlfunktionen von
Kommunikationsendgeräten oder Telekommunikationseinrichtungen; (c)
Ausfall von Systemen und Unfähigkeit, ihre Funktionen aufgrund
höherer Gewalt auszuführen, einschließlich, aber nicht beschränkt
auf Taifune, Erdbeben, Tsunamis, Überschwemmungen, Stromausfälle,
Brände, Stürme, Kriege, politische Unruhen, Streiks, Arbeitskräfte-
und Stoffmangel, Aufruhre, Aufstände, Zivilunruhen, Terroranschläge,
Kriege, Explosionen, Naturkatastrophen, behördliche Maßnahmen,
Handlungen nationaler oder ausländischer Gerichte, Versäumnis
Dritter; oder (d) Aussetzung oder Verzögerung von Dienstleistungen
oder Systemausfällen aufgrund von Gründen, die außerhalb der
Kontrolle von Infocoin Limited liegen, wie z. B. wie Hacking- oder
Cyber-Angriffe, technische Anpassungen oder Fehlfunktionen von
Telekommunikationsgeräten, Website-Updates, Ausfälle Dritter oder
jegliche Aussetzung oder Unterbrechung des Logistik- oder
Geschäftsbetriebs (einschließlich, aber nicht beschränkt auf
Verzögerungen oder Unterbrechungen bei der Wiederaufnahme der Arbeit
oder des Betriebs auf Anordnung einer Regierungsbehörde), im Falle
eines nationalen oder regionalen Ausbruchs einer Epidemie oder
Pandemie.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>10.
Rechte an geistigem Eigentum</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">10.1
Infocoin Limited ist der alleinige Eigentümer oder rechtmäßige
Lizenznehmer aller Rechte und Interessen an der Infocoin-Plattform
und das Content der Infocoin-Plattform. Die Infocoin-Plattform und
das Content der Infocoin-Plattform sind Geschäftsgeheimnisse und
andere geistige Eigentumsrechte, die durch internationalen
Urheberrecht und andere Gesetze geschützt sind. Alle Eigentums-,
Titel- und geistigen Eigentumsrechte an der Infocoin-Plattform und
den Seiten-Content verbleiben bei Infocoin Limited, seinen
verbundenen Gesellschaften oder Lizenzgebern, falls vorhanden. Alle
Rechte, die gemäß den Bedingungen von Infocoin Limited geltend
gemacht werden, bleiben hiermit vorbehalten.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">10.2
„Infocoin“, „Infocoin“ und zugehörige Symbole und Logos sind
eingetragene Warenzeichen oder Handels- oder Dienstleistungsmarken
von Infocoin Limited oder seinen verbundenen Gesellschaften in
verschiedenen Gerichtsbarkeiten und sind durch geltende Urheber-,
Warenzeichen- und andere Eigentumsgesetze geschützt. Unbefugtes
Kopieren, Modifizieren, Verwenden oder Veröffentlichen dieser Marken
ist strengstens untersagt und illegal.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">10.3
Infocoin Limited kann einen unabhängigen Dritten mit der
Bereitstellung der Infocoin-Plattform oder der Dienstleistungen
beauftragen (z. B. Anbieter von Authentifizierungs- und
Verifizierungsdiensten). Sie dürfen keine Warenzeichen,
Dienstleistungsmarken oder Logos solcher unabhängiger Dritter ohne
vorherige schriftliche Zustimmung dieser Dritten verwenden.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">10.4
Soweit nach geltendem Recht zulässig, gehören alle Rechte, Titel
und Interessen an allen abgeleiteten geistigen Eigentumsrechten, die
von Infocoin Limited und/oder seinen verbundenen Gesellschaften unter
Verwendung vom Benutzer-Content gemäß den Bedingungen erstellt
wurden, Infocoin Limited und können durch Infocoin auf Dritte oder
ihre verbundenen Gesellschaften frei übertragen werden
(einschließlich im Rahmen einer Lizenzvereinbarung).</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>11.
Benachrichtigungen</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">11.1
Alle rechtlichen Mitteilungen oder Anforderungen gegen Infocoin
müssen schriftlich erfolgen und an Infocoin Limited persönlich, per
Kurier oder per Einschreiben adressiert an den Direktor unter:
Anexartisias &amp; Athinon, NORA COURT, 2nd floor, Office 203, 3040
Limassol, Cyprus, gesendet werden</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Zu
Händen der Rechtsabteilungsangestellten. Alle rechtlichen
Mitteilungen oder Anforderungen an Infocoin Limited müssen
schriftlich erfolgen und persönlich, per Kurier oder per
Einschreiben an Infocoin Limited gesendet werden an: Anexartisias &amp;
Athinon, NORA COURT, 2nd floor, Office 203, 3040 Limassol, Cyprus.
Mitteilungen müssen zum Zeitpunkt des Eingangs bei Infocoin Limited
durch eine der oben genannten Methoden wirksam sein.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">11.2
Alle an den Benutzer gerichteten rechtlichen Mitteilungen oder
Anforderungen sind wirksam, wenn sie persönlich, per Kurier,
Einschreiben, Fax oder E-Mail an die letzte bekannte Adresse, die
Faxnummer oder die E-Mail, die vom Infocoin-Benutzer angegeben
wurden, oder durch Posting einer solchen Mitteilung oder Anforderung
im kostenlosen öffentlich zugänglichen Abschnitt der Webseite. Die
Mitteilung des Benutzers gilt als von diesem Benutzer erhalten, wenn:</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">a)
Infocoin Limited kann nachweisen, dass eine solche Mitteilung oder
Anforderung, ob physisch oder elektronisch, an diesen Benutzer
gesendet wurde, oder</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">b)
unmittelbar nachdem Infocoin Limited eine solche Mitteilung in
kostenlosen öffentlich zugänglichen Abschnitten der Webseite
gepostet hat.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">11.3
Sie stimmen zu, dass alle Vereinbarungen, Nachrichten, Anforderungen,
Informationen und andere Mitteilungen, die Infocoin Limited Ihnen
elektronisch zusendet, gesetzlichen Anforderungen genügen und dass
solche Mitteilungen als schriftlich zugestellt gelten.</font></font></p>
<p style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>12.
Allgemeine Bestimmungen</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.1
Vorbehaltlich Zusatzvereinbarungen stellen die Bedingungen die
gesamte Vereinbarung zwischen Ihnen und Infocoin Limited dar und
regeln Ihre Nutzung der Infocoin-Plattform und der Dienstleistungen
und ersetzen alle vorherigen schriftlichen oder mündlichen
Vereinbarungen in Bezug auf deren Inhalt.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.2
Infocoin Limited und Sie sind unabhängige Parteien, und durch die
Bedingungen wird keine Agentur, Partnerschaft, Joint Venture,
Arbeitnehmer-Arbeitgeber- oder
Franchisegeber-Franchisenehmer-Beziehung beabsichtigt oder
geschaffen.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.3
Sollte eine Bestimmung der Bedingungen ungültig oder nicht
durchsetzbar sein, wird diese Bestimmung gelöscht und die
verbleibenden Bestimmungen bleiben in vollem Umfang in Kraft und
Wirkung.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.4
Die Titel werden nur zu Referenzzwecken verwendet und definieren,
begrenzen, interpretieren oder beschreiben in keiner Weise den Inhalt
der entsprechenden Klausel.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.5
Das Versäumnis von Infocoin Limited, ein Recht durchzusetzen oder in
Bezug auf einen Verstoß Ihrerseits gemäß den Bedingungen zu
handeln, stellt keinen Verzicht auf dieses Recht und keinen Verzicht
auf das Recht von Infocoin Limited, bei späteren oder ähnlichen
Verstößen entsprechend zu handeln dar.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.6
Infocoin Limited ist berechtigt die Bedingungen (einschließlich
aller unserer Rechte, Titel, Vorteile, Interessen und Verpflichtungen
und Pflichten in den Bedingungen) an jede natürliche oder
Rechtsperson (einschließlich aller verbundenen Gesellschaften von
Infocoin Limited) abtreten. Sie dürfen weder ganz noch teilweise die
Bedingungen an eine natürliche oder Rechtsperson abtreten.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.7
DIESE BEDINGUNGEN (VEREINBARUNG) UNTERLIEGEN DEM ANWENDBAREN RECHT,
WENN SIE EINE VEREINBARUNG GEMÄSS ABSCHNITT 2.1 ABSCHLIESSEN. DIE
PARTEIEN DIESER VEREINBARUNG ERKENNEN AN, DASS DIE GERICHTE DER
REPUBLIK ZYPERN DIE AUSSCHLIESSLICHE ZUSTÄNDIGKEIT FÜR
STREITIGKEITEN ZWISCHEN UNS ALS PARTEIEN HABEN.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.8.
Wenn Sie Kommentare zu den von uns angebotenen Dienstleistungen
haben, können Sie sich schriftlich an den Gesellschaftssitz wenden,
entweder per E-Mail an </font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>help@Infocoin</u></font></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>.</u></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>online</u></font></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
oder durch Ausfüllen eines speziellen Feedback-Formulars auf der
Webseite mit einem solchen Feedback oder Fragen.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><a name="_heading=h.30j0zll"></a>
<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.9
Die Löschung Ihres Kontos stellt eine einseitige außergerichtliche
Kündigung dieser Bedingungen zwischen Ihnen und uns dar.</font></font></p>
<p style="line-height: 100%; margin-top: 0.08in; margin-bottom: 0.08in">
<br/>
<br/>

</p>
</body>
</html>
`;
