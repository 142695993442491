import './index.scss';
import { useCallback } from 'react';
import { EyeLineIcon, EyeOffLineIcon } from 'views/icons';
import { useState } from 'react';

const AuthTextField = props => {
  const {
    value,
    error,
    onChange,
    onFocus,
    onBlur,
    label,
    placeholder,
    touched,
    rightAddons,
    name,
    type,
    classNameContainer,
    classNameInput,
  } = props;
  const [passwordType, setType] = useState(type);
  const onTogglePasswordType = () => {
    if (passwordType === 'password') {
      setType('text');
      return;
    }
    if (passwordType === 'text') {
      setType('password');
    }
  };

  const handleFocus = useCallback(
    e => {
      if (onFocus) {
        onFocus(e);
      }
    },
    [onFocus],
  );

  const handleBlur = useCallback(e => {
    if (onBlur) {
      onBlur(e);
    }
  }, onBlur);

  return (
    <div className={`container-auth_text_field ${classNameContainer}`}>
      {label && <span>{label}</span>}
      <input
        className={classNameInput}
        name={name}
        type={type === 'password' ? passwordType : type}
        value={value}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={onChange}
        placeholder={placeholder}
      />
      {type === 'password' && (
        <div className="input__icon">
          {rightAddons}
          {passwordType === 'password' && (
            <EyeOffLineIcon size="smallmed" onClick={onTogglePasswordType} />
          )}
          {passwordType === 'text' && (
            <EyeLineIcon size="smallmed" onClick={onTogglePasswordType} />
          )}
        </div>
      )}
      {touched && name !== 'password' && <p className="error-input">{error}</p>}
    </div>
  );
};

export { AuthTextField };
