import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { Button, Dialog, Typography } from 'src/views/blocks';
import React, { memo, useCallback, useState } from 'react';

import {
  corefyVerification,
  corefyVerificationRecendCode,
} from '@services/api/wallet';

import { useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';

import { useFormik } from 'formik';

import { TimerEmail } from '../../../../ProfilePage/blocks/Timer';

import ReactCodeInput from 'react-code-input';
import * as Yup from 'yup';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {
  getWalletBalanceAction,
  getWalletTransactionsAction,
} from '../../../../../../store/modules/wallet/actions';
import { connect } from 'react-redux';

const VerificationSchema = () =>
  Yup.object().shape({
    emailCode: Yup.string()
      .length(4, 'Not valid code')
      .required('Field is required'),
    numberCode: Yup.string()
      .length(4, 'Not valid code')
      .required('Field is required'),
  });
const ModalConfirm = ({ show, setShow, id }) => {
  const { t } = useTranslation();

  const [timerActive, setTimerActive] = useState(true);

  const inputStyle = {
    marginRight: '15px',
    marginTop: '8px',
    MozAppearance: 'textfield',
    width: '50px',
    borderRadius: '10px',
    backgroundColor: 'white',
    fontSize: '20px',
    height: '50px',
    paddingLeft: '18px',
    border: '0',
  };

  const {
    values,
    setFieldValue,
    isValid,
    handleSubmit,
    isSubmitting,
    resetForm,
  } = useFormik({
    initialValues: {
      emailCode: '',
      numberCode: '',
    },
    validationSchema: VerificationSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      setSubmitting(true);

      try {
        await corefyVerification({
          pst_id: id,
          phone_code: values.numberCode,
          email_code: values.emailCode,
        });

        await getWalletBalanceAction();

        await getWalletTransactionsAction();

        toast.success(t('Wait for funds to arrive'));

        setShow(false);

        resetForm();
      } catch (e) {
        console.log(e);
        toast.error(t(e?.response?.data?.error || e?.message || e));
        return e;
      }
      setSubmitting(false);
    },
  });

  const handleClose = useCallback(() => {
    setShow(false);
    resetForm();
  }, []);

  const receivedCode = useCallback(async () => {
    try {
      await corefyVerificationRecendCode(id);
      toast.success(t('Code resent'));
      setTimerActive(true);
    } catch (e) {
      console.log(e);
      toast.error(t(e?.response?.data?.error || e?.message || e));
      return e;
    }
  }, [id]);

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography>{t('Withdrawal confirmation')}</Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          maxWidth: '360px !important',
        }}
      >
        <Box>
          <Box>
            <Typography variant="body1" color="gray" inline>
              {t(
                'For security purposes, you must confirm the withdrawal of funds.',
              )}{' '}
              {t(
                'A verification code has been sent to your email and phone number',
              )}
            </Typography>
          </Box>
          <Box>
            <Typography color="primary">
              {t('Attention, codes are valid for 1 minute!')}
            </Typography>
          </Box>

          <Box mt={3}>
            <Typography variant="body2">{t('Phone code')}</Typography>
            <ReactCodeInput
              fields={4}
              inputMode="numeric"
              inputStyle={inputStyle}
              value={values.numberCode}
              type="number"
              name="numberCode"
              onChange={value => {
                setFieldValue('numberCode', value);
              }}
            />
          </Box>

          <Box mt={3}>
            <Typography variant="body2">{t('Email code')}</Typography>
            <ReactCodeInput
              autoFocus
              fields={4}
              inputMode="numeric"
              value={values.emailCode}
              inputStyle={inputStyle}
              type="number"
              name="emailCode"
              onChange={value => {
                setFieldValue('emailCode', value);
              }}
            />
          </Box>
          <Box mt={1}>
            <Typography color="gray" inline>
              {t('Code not received?')}
            </Typography>
            {!timerActive ? (
              <Typography
                className="pl-1 cursor-pointer"
                color="primary"
                inline
                onClick={receivedCode}
              >
                {t('Send again')}
              </Typography>
            ) : (
              <TimerEmail
                timerActive={timerActive}
                setTimerActive={setTimerActive}
                time={60}
              />
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box pb={2} px={2} width="100%">
          <Button
            variant="primary"
            onClick={handleSubmit}
            disabled={!isValid || isSubmitting}
            fullWidth
          >
            {isSubmitting ? (
              <Spinner animation="border" variant="light" size="sm" />
            ) : (
              t('Confirm')
            )}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const mapDispatchToProps = {
  getWalletBalanceAction,
  getWalletTransactionsAction,
};

export default connect(null, mapDispatchToProps)(memo(ModalConfirm));
