import {
  SET_WALLET_BALANCE,
  SET_WALLET_COREFY_PAYOUT_LIMIT,
  SET_WALLET_EXCHANGE_RATE,
  SET_WALLET_LAST_TRANSACTION,
  SET_WALLET_LOADING,
  SET_WALLET_TRANSACTIONS,
  SET_WALLET_WITHDRAWAL_LIMIT,
} from '../constants';

const initialState = {
  loading: true,
  balance: 0,
  transactions: [],
  lastTransaction: null,
  exchangeRate: null,
  withdrawalLimit: {
    min_payout: null,
    max_payout: null,
  },
  corefyPayoutLimit: null,
};

export function walletReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_WALLET_LOADING:
      return {
        ...state,
        loading: payload,
      };

    case SET_WALLET_BALANCE:
      return {
        ...state,
        balance: payload,
      };

    case SET_WALLET_TRANSACTIONS:
      return {
        ...state,
        transactions: payload,
      };

    case SET_WALLET_LAST_TRANSACTION:
      return {
        ...state,
        lastTransaction: payload,
      };

    case SET_WALLET_EXCHANGE_RATE: {
      return {
        ...state,
        exchangeRate: payload,
      };
    }

    case SET_WALLET_WITHDRAWAL_LIMIT: {
      return {
        ...state,
        withdrawalLimit: payload,
      };
    }

    case SET_WALLET_COREFY_PAYOUT_LIMIT: {
      return {
        ...state,
        corefyPayoutLimit: payload,
      };
    }

    default:
      return state;
  }
}
