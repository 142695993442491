import {
  SET_VIDEO_HISTORY,
  SET_VIDEO_HISTORY_LIMIT,
  SET_VIDEO_HISTORY_OFFSET,
} from '../constants';

export const setVideoHistoryOffsetAction = offset => {
  return {
    type: SET_VIDEO_HISTORY_OFFSET,
    payload: offset,
  };
};

export const setVideoHistoryLimitAction = limit => {
  return {
    type: SET_VIDEO_HISTORY_LIMIT,
    payload: limit,
  };
};

export const setVideoHistoryAction = videos => {
  return {
    type: SET_VIDEO_HISTORY,
    payload: videos,
  };
};
