import {
  DashboardIcon,
  FacebookIcon,
  InfoIcon,
  InstagramIcon,
  ListIcon,
  MessagesIcon,
  MovieIcon,
  ProfileIcon,
  TwitterIcon,
  WalletIcon,
  YoutubeIcon,
} from 'src/views/icons';
import { TikTikIcon } from '../views/icons/TikTokIcon';

const ROUTES = {
  categories: '/categories',
  company: '/company/:id',
  forgot: '/forgot',
  history: '/history',
  home: '/',
  playlistCategory: '/playlist/:id',
  login: '/login',
  profile: '/profile',
  registration: '/registration',
  success: '/success',
  support: '/help',
  wallet: '/wallet',
  test: '/test',
  partners: '/partners',
  hph: '/hph',
};

const MAIN_MENU = [
  {
    label: 'Partners',
    icon: MovieIcon,
    exact: true,
    path: ROUTES.partners,
    isPrivated: true,
  },
  {
    label: 'My playlist',
    icon: MovieIcon,
    exact: true,
    path: ROUTES.home,
    isPrivated: false,
  },

  {
    label: 'Categories',
    icon: DashboardIcon,
    path: ROUTES.categories,
    isPrivated: true,
  },
  {
    label: 'History',
    icon: ListIcon,
    path: ROUTES.history,
    isPrivated: true,
  },
  {
    label: 'Wallet',
    icon: WalletIcon,
    path: ROUTES.wallet,
    isPrivated: true,
  },
  {
    label: 'Help',
    icon: MessagesIcon,
    path: ROUTES.support,
    isPrivated: true,
  },
  {
    label: 'About',
    icon: InfoIcon,
    href: 'https://www.infocoin.online',
  },
];

const SOCIAL_MENU = [
  {
    icon: FacebookIcon,
    href: 'https://www.facebook.com/infocoin.world/',
  },
  {
    icon: InstagramIcon,
    href: 'https://www.instagram.com/infocoin_world/',
  },
  {
    icon: TwitterIcon,
    href: 'https://twitter.com/infocoin_world',
  },
  {
    icon: YoutubeIcon,
    href: 'https://www.youtube.com/channel/UC9BnEaFSWktzOQ6LcADdoHw',
  },
  {
    icon: TikTikIcon,
    href: 'https://www.tiktok.com/@infocoin.online',
  },
];

const PROFILE_MENU = [
  {
    label: 'Account',
    icon: ProfileIcon,
    path: ROUTES.profile,
  },
];

export { ROUTES, MAIN_MENU, SOCIAL_MENU, PROFILE_MENU };
