import classes from './Notifications.module.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  deleteNotifications,
  getNotifications,
  postNotifications,
} from '@services/api/user';
import { Switch } from '@mui/material';
import { toast } from 'react-toastify';
import { onToggleNotifictions } from '../../../../../hooks/useWbSc';

const dataNotifications = [
  {
    id: 1,
    message_type: 'auction_closed',
  },
  {
    id: 2,
    message_type: 'service_notification',
  },
];

const dataFormatNameNotification = {
  auction_closed: 'Closed auctions',
  auction_active: 'Active auctions',
  service_notification: 'Service notification',
  end_view_content: 'Low balance notification',
  content_moderation_status_changes: 'Uploaded video moderation',
};

const switchStyle = {
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#FF5F00',
  },
  '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
    backgroundColor: 'var(--secondary-color)',
  },
  '&:hover .MuiSwitch-switchBase': {
    color: 'var(--secondary-color)',
  },
};

const NotificationBlock = ({ t }) => {
  const [notifications, setNotifications] = useState([]);

  const updateNotifications = useCallback(() => {
    getNotifications().then(({ data }) => setNotifications(data));
  }, []);

  useEffect(() => {
    if (!notifications.length) {
      updateNotifications();
    }
  }, []);

  const isChecked = useMemo(
    (message_type, delivery_method) => {
      return notifications.some(
        n =>
          n.message_type === message_type &&
          n.delivery_method === delivery_method,
      );
    },
    [notifications],
  );

  const handleChange = useCallback(
    async (message_type, delivery_method, value) => {
      if (delivery_method === 'email') {
        if (value === true) {
          postNotifications({ message_type })
            .then(() => {
              toast.success(t('Notification enabled!'));
            })
            .catch(err => {
              toast.error(err.response?.data?.error || err.message);
            });
        }

        if (value === false) {
          await deleteNotifications(message_type)
            .then(() => {
              toast.success(t('Notification disabled!'));
            })
            .catch(err => {
              toast.error(err.response?.data?.error || err.message);
            });
        }
      }
      if (delivery_method === 'websocket') {
        await onToggleNotifictions(message_type, value);
        toast.success(t(`Notification ${!value ? 'disabled!' : 'enabled!'}`));
      }
      setTimeout(() => {
        updateNotifications();
      }, 150);
    },
    [updateNotifications, t],
  );
  return (
    <div className={classes.notifications_wrapper}>
      <div className={`${classes.label} ${classes.notofications_label}`}>
        {t('Notification settings')}
      </div>
      <div className={classes.contentNotifications}>
        <div className={classes.contentNotifications__row}>
          <div>{t('Notification')}</div>
          <div>{t('Sending by mail')}</div>
          <div>{t('Push notifications')}</div>
        </div>
        {dataNotifications.map(({ message_type, id }) => {
          return (
            <div key={id} className={classes.contentNotifications__row}>
              <div>{t(dataFormatNameNotification[message_type])}</div>
              <div>
                <Switch
                  sx={switchStyle}
                  checked={notifications.some(
                    n =>
                      n.message_type === message_type &&
                      n.delivery_method === 'email',
                  )}
                  onChange={e =>
                    handleChange(message_type, 'email', e.target.checked)
                  }
                />
              </div>
              <div>
                <Switch
                  sx={switchStyle}
                  checked={notifications.some(
                    n =>
                      n.message_type === message_type &&
                      n.delivery_method === 'websocket',
                  )}
                  onChange={e =>
                    handleChange(message_type, 'websocket', e.target.checked)
                  }
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default NotificationBlock;
