import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import {
    Select,
    MenuItem
} from "@mui/material";

import countries from "src/data/countries.json";
import { useThemeProps } from "src/hooks";
import { Typography } from "src/views/blocks";
import "./index.scss";


const SelectTranslation = (props) => {
    const {
        theme,
        expanded = false,
        auth,
    } = useThemeProps(props);
    const { i18n } = useTranslation();

    const [lang, setLang] = useState(i18n.language || "en");

    const modifications = clsx({
        "select-translation--expanded": expanded
    });

    const country = useMemo(() => {
        let country = countries.find((country) => {
            return country.lang === lang;
        });

        if (!country) {
            country = countries[0];
        }

        return country;
    }, [lang]);

    const handleChange = useCallback(async (e) => {
        const {
            target: {
                value
            }
        } = e;

        await i18n.changeLanguage(value);
    }, [i18n]);

    useEffect(() => {
        const handle = (lang) => {
            setLang(lang);
        };

        i18n.on("languageChanged", handle);

        return () => i18n.off("languageChanged", handle);
    }, [i18n]);

    return (
        <Select
            variant="standard"
            className={`select-translation select-translation--${theme} ${modifications} ${auth && 'auth-select-tranlsation'}`}
            disableUnderline
            value={country ? country.iso_2 : ""}
            onChange={handleChange}>
            {countries.map(country => (
                <MenuItem
                    key={country.id}
                    className="select-translation__option"
                    value={country.iso_2}>
                    <div className="select-translation__option">
                        <img
                            style={{
                                width: auth ? '20px' : '30px',
                                height: auth ? '20px' : '30px',
                                border: auth ? 'none' : 'solid black 2px'
                            }}
                            className="select-translation__icon"
                            alt=""
                            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${country.icon}.svg`} />

                        {expanded && (
                            <Typography
                                className="select-translation__label"
                                bold>
                                {country.name}
                            </Typography>
                        )}
                    </div>
                </MenuItem>
            ))}
        </Select>
    );
};


export { SelectTranslation };