import {useState, useEffect} from "react";

import {axiosInstance} from "src/store/helpers";


const useRegionsApi = (id) => {
    const [regions, setRegions] = useState([]);

    useEffect(() => {
        (async () => {
            if(!id) {
                setRegions([]);

                return;
            }

            try {
                const res = await axiosInstance.get(`/auth/regions/${id}`, {
                    validateStatus: () => true
                });

                if(res.status === 200) {
                    const {data} = res;

                    setRegions(data);
                }
                else {
                    setRegions([]);
                }
            }
            catch(err) {
                console.error(err);

                setRegions([]);
            }
        })();
    }, [id]);

    return regions;
};


export {useRegionsApi};