import {createTheme} from "@mui/material";

import {breakpoints} from "./breakpoints";
import "src/styles/base.css";
import "./i18n";
import "./index.scss";
import "./spaces.scss";


const theme = createTheme({
    typography: {
        fontFamily: [
            "'Mont Blanc'", "-apple-system", "BlinkMacSystemFont", "'Segoe UI'",
            "'Roboto'", "'Oxygen'", "'Ubuntu'", "'Cantarell'", "'Fira Sans'",
            "'Droid Sans'", "'Helvetica Neue'", "sans-serif"
        ].join(", ")
    },
    breakpoints
});


export {theme};

