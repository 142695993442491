import React, {
  useState,
  useMemo,
  useRef,
  useEffect,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from 'react';

import { useSelector, useDispatch } from 'react-redux';

import {
  setVolumeAction,
  setQualityAction,
} from 'store/modules/player/actions';

import clsx from 'clsx';
import { Grid, Tooltip, Typography } from '@mui/material';

import NextArrow from 'assets/icons/next-arrow.svg';
import PrevArrow from 'assets/icons/prev-arrow.svg';

import { formatTime } from 'src/utils';
import {
  HeartIcon,
  EyeCloseIcon,
  VolumeUpIcon,
  VolumeOffIcon,
  SettingsIcon,
  PictureInPictureIcon,
  FullscreenIcon,
  FullScreenExitIcon,
} from 'src/views/icons';
import { IconButton } from '../IconButton';
import { Video } from '../Video';

import {
  PlayerComplaintModal,
  PlayerQualityModal,
  PlayerSeek,
  PlayerVolume,
} from './blocks';

import './index.scss';
import { useTranslation } from 'react-i18next';

const Player = forwardRef((props, ref) => {
  const {
    videos = [],
    children,
    videoIsLike,
    onPlay,
    onEnded,
    onSlideChange,
    onLike,
    nextVideo,
    prevVideo,
    isFirst,
    showCaptcha,
  } = props;

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { volume, quality } = useSelector(state => state.player);

  const [play, setPlay] = useState(false);
  const [videoIsPlayed, setVideoIsPlayed] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [fullscreen, setFullscreen] = useState(false);
  const [pictureInPicture, setPictureInPicture] = useState(false);
  const [time, setTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [process, setProcess] = useState(0);
  const [showComplaint, setShowComplaint] = useState(false);
  const [showQuality, setShowQuality] = useState(false);
  const rootRef = useRef(null);
  const videoRef = useRef([]);

  const video = useMemo(() => videos[activeIndex] || {}, [activeIndex, videos]);

  const isLiked = useMemo(() => {
    if (video) {
      return videoIsLike;
    }

    return false;
  }, [video, videoIsLike]);

  const { category } = video || {};

  const modifications = clsx({
    'player--disable-controls': category ? category.id === 18 : false,
  });

  const actionPlay = useCallback(() => {
    setPlay(true);
    videoRef?.current?.play();
  }, []);

  const actionPause = useCallback(() => {
    const videoElement = videoRef.current;
    setPlay(false);
    if (videoElement) {
      videoElement.pause();
    }
  }, []);

  const actionNext = useCallback(() => {
    setActiveIndex(activeIndex + 1);
    setPlay(true);
    onSlideChange(activeIndex + 1);
  }, [activeIndex, onSlideChange]);

  const actionPrev = useCallback(() => {
    setActiveIndex(activeIndex - 1);
    setPlay(true);
    onSlideChange(activeIndex - 1);
  }, [activeIndex, onSlideChange]);

  const actionOpen = useCallback(() => {
    setActiveIndex(0);
  }, []);

  const handlePlay = useCallback(
    (e, video) => {
      const {
        target: { paused },
      } = e;

      setPlay(!paused);

      if (onPlay) {
        onPlay(e, video);
      }
    },
    [onPlay],
  );

  const handleFullscreen = useCallback(() => {
    setFullscreen(!fullscreen);
  }, [fullscreen]);

  const handlePictureInPictureToggle = useCallback(() => {
    setPictureInPicture(!pictureInPicture);
  }, [pictureInPicture]);

  const handleEnded = useCallback(() => {
    setFullscreen(false);

    if (onEnded) {
      onEnded(activeIndex);
    }
  }, [onEnded, activeIndex]);

  const handleTimeUpdate = useCallback(e => {
    const {
      target: { paused, currentTime, duration, buffered },
    } = e;

    let process = 0;

    if (buffered && buffered.length) {
      process = buffered.end(buffered.length - 1) / duration || 0;
    }

    setTime(currentTime);
    setDuration(duration);
    setProcess(process * 100);
    setPlay(!paused);
  }, []);

  const handleVolumeToggle = useCallback(() => {
    volume === 0 ? dispatch(setVolumeAction(50)) : dispatch(setVolumeAction(0));
  }, [volume, dispatch]);

  const handleVolumeChange = useCallback(
    volume => {
      dispatch(setVolumeAction(volume));
    },
    [dispatch],
  );

  const handleComplaintToggle = useCallback(() => {
    setShowComplaint(!showComplaint);

    if (play) {
      actionPause();
    }
  }, [showComplaint, play, actionPause]);

  const handleQualityToggle = useCallback(() => {
    setShowQuality(!showQuality);
  }, [showQuality]);

  const handleQualityChange = useCallback(
    quality => {
      setShowQuality(false);
      dispatch(setQualityAction(quality));
      videoRef.current.changeQuality(quality);
    },
    [dispatch],
  );

  const handleLike = useCallback(() => {
    if (!video) {
      return;
    }

    if (onLike) {
      onLike(video, !isLiked);
    }
  }, [video, isLiked, onLike]);

  useEffect(() => {
    const handleFullscreen = () => {
      setFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullscreen);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreen);
    };
  }, []);

  useEffect(() => {
    const visibleChange = () => {
      if (document.hidden) {
        if (!pictureInPicture) {
          setVideoIsPlayed(play);
          setPlay(false);
          actionPause();
        }
      } else {
        if (!pictureInPicture && videoIsPlayed && !showCaptcha) {
          actionPlay();
        }
      }
    };
    document.addEventListener('visibilitychange', visibleChange);

    return () => {
      document.removeEventListener('visibilitychange', visibleChange);
    };
  }, [pictureInPicture, videoIsPlayed, showCaptcha, play]);

  useEffect(() => {
    function fullScreen(element) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitEnterFullscreen) {
        element.webkitEnterFullscreen();
      } else if (element.mozRequestFullscreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      }
    }
    function fullScreenCancel(element) {
      if (element.webkitExitFullscreen) {
        element.webkitExitFullscreen();
      } else if (element.mozExitFullscreen) {
        element.mozExitFullscreen();
      } else if (element.exitFullscreen) {
        element.exitFullscreen();
      } else {
        document.exitFullscreen();
      }
    }
    if (rootRef.current) {
      if (fullscreen) {
        fullScreen(rootRef.current);
        fullScreen(videoRef.current);
      } else if (document.fullscreenElement === rootRef.current) {
        fullScreenCancel(document);
      }
    }
  }, [fullscreen]);

  useImperativeHandle(ref, () => {
    return {
      play: actionPlay,
      pause: actionPause,
      next: actionNext,
      prev: actionPrev,
      open: actionOpen,
    };
  });

  return (
    <div ref={rootRef} className={`player ${modifications}`}>
      <Video
        ref={videoRef}
        autoPlay={play}
        fullscreen={fullscreen}
        volume={volume}
        pictureInPicture={pictureInPicture}
        activeIndex={activeIndex}
        setPictureInPicture={setPictureInPicture}
        video={video || {}}
        quality={quality}
        src={video && video[`filepath_${quality}_p`]}
        onLike={onLike}
        onPlay={handlePlay}
        onTimeUpdate={handleTimeUpdate}
        onProgress={handleTimeUpdate}
        onFullscreen={handleFullscreen}
        onChangeVolume={handleVolumeChange}
        onEnded={handleEnded}
      />
      {children}
      <div className="player__footer">
        <PlayerSeek
          ariaLabel="Seek video"
          percentagePlayed={(time.toFixed() / duration.toFixed()) * 100}
          percentageBuffered={process}
        />

        <Grid container justifyContent="space-between">
          <Grid item container spacing={2} xs={4}>
            {onLike && !!video.lot_id && (
              <>
                <Grid item className="icon-container">
                  <Tooltip title={t('Like')}>
                    <div>
                      <IconButton
                        size="small"
                        className="mediaIcons"
                        color={isLiked ? 'primary' : 'white'}
                        icon={HeartIcon}
                        onClick={handleLike}
                      />
                    </div>
                  </Tooltip>
                </Grid>

                {video.lot_id && (
                  <Grid item className="icon-container">
                    <Tooltip title={t('Complain')}>
                      <div>
                        <IconButton
                          size="small"
                          className="mediaIcons"
                          color={showComplaint ? 'primary' : 'white'}
                          icon={EyeCloseIcon}
                          onClick={handleComplaintToggle}
                        />
                      </div>
                    </Tooltip>
                  </Grid>
                )}
              </>
            )}

            <Grid item className="icon-container volume-container">
              <Tooltip title={t('Volume')}>
                <div>
                  <IconButton
                    size="small"
                    className="mediaIcons"
                    color={'white'}
                    icon={volume > 0 ? VolumeUpIcon : VolumeOffIcon}
                    onClick={handleVolumeToggle}
                  />
                </div>
              </Tooltip>
              <div className="volume-slider">
                <PlayerVolume value={volume} onChange={handleVolumeChange} />
              </div>
            </Grid>

            {!isFirst && prevVideo && (
              <Grid item className="icon-container">
                <Tooltip title={t('Prev video')}>
                  <div className="icon-wrapper" onClick={prevVideo}>
                    <img
                      width="18px"
                      height="18px"
                      src={PrevArrow}
                      alt="arrow"
                    />
                  </div>
                </Tooltip>
              </Grid>
            )}

            {nextVideo && (
              <Grid item className="icon-container">
                <Tooltip title={t('Next video')}>
                  <div className="icon-wrapper" onClick={nextVideo}>
                    <img
                      width="18px"
                      height="18px"
                      src={NextArrow}
                      alt="arrow"
                    />
                  </div>
                </Tooltip>
              </Grid>
            )}
          </Grid>

          <Grid item container xs={4} justifyContent="center">
            <Grid item className="icon-container">
              <Typography align="center" color="white">
                {formatTime(time)} / {formatTime(duration)}
              </Typography>
            </Grid>
          </Grid>

          <Grid item container xs={4} spacing={2} justifyContent="flex-end">
            <Grid item className="icon-container">
              <Tooltip title={t('Quality')}>
                <div>
                  <IconButton
                    size="small"
                    className="mediaIcons"
                    color={showQuality ? 'primary' : 'white'}
                    icon={SettingsIcon}
                    onClick={handleQualityToggle}
                  />
                </div>
              </Tooltip>
            </Grid>

            <Grid item className="icon-container">
              <Tooltip title={t('Picture in Picture')}>
                <div>
                  <IconButton
                    size="medium"
                    className="mediaIcons"
                    color={pictureInPicture ? 'primary' : 'white'}
                    icon={PictureInPictureIcon}
                    onClick={handlePictureInPictureToggle}
                  />
                </div>
              </Tooltip>
            </Grid>

            <Grid item className="icon-container mediaFullscreen">
              <Tooltip title={t('Fullscreen')}>
                <div>
                  <IconButton
                    size="small"
                    color="white"
                    className="mediaIcons"
                    icon={fullscreen ? FullScreenExitIcon : FullscreenIcon}
                    onClick={handleFullscreen}
                  />
                </div>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </div>

      {onLike && (
        <PlayerComplaintModal
          open={showComplaint}
          video={video}
          onClose={() => setShowComplaint(false)}
        />
      )}

      <PlayerQualityModal
        open={showQuality}
        video={video}
        value={quality}
        onChange={handleQualityChange}
      />
    </div>
  );
});

export { Player };
