import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';

import PaymentForm from './PaymentForm';
import './index.scss';
import 'src/views/inputs/Select/index.scss';

import { connect } from 'react-redux';

import { InfinityIcon } from '../../../../icons';
import { Typography } from '../../../../blocks';

import { createCorefyPayout } from '@services/api/wallet';
import { toast } from 'react-toastify';
import {
  getWalletCorefyLimit,
  getWalletTransactionsAction,
} from '../../../../../store/modules/wallet/actions';

import ModalConfirm from './ModalConfirm';

const CardBlock = ({
  withdrawalLimit,
  corefyPayoutLimit,
  getWalletCorefyLimit,
  getWalletTransactionsAction,
}) => {
  const { min_payout, max_payout } = withdrawalLimit;

  const [isOpenModal, setIsOpenModal] = useState(false);

  const [idWithdrawal, setIdWithdrawal] = useState(null);

  const handleSubmit = useCallback(async values => {
    try {
      const {
        data: { id },
      } = await createCorefyPayout({
        amount: String(values.sum),
        card_number: values.cardNumber.replaceAll(' ', ''),
      });

      setIdWithdrawal(id);

      await getWalletCorefyLimit();

      await getWalletTransactionsAction();

      setIsOpenModal(true);
    } catch (e) {
      console.log(e);
      toast.error(t(e?.response?.data?.error || e?.message || e));
      return e;
    }
  }, []);

  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Grid container className="withdrawal">
        <Grid item className="withdrawal__head">
          <Typography variant="h2" weight="normal-bold">
            {t('Withdrawal of funds')}
          </Typography>{' '}
          <Typography color="gray" component="span">
            {t('Minimum and maximum withdrawal of funds:')}
          </Typography>
          <Typography component="span" className="ml-1" color="primary">
            {min_payout}
            {' IC - '}
            {max_payout ? max_payout : <InfinityIcon className="mb-1 mr-1" />}
          </Typography>
        </Grid>
        <Grid item>
          <PaymentForm onSubmit={handleSubmit} />
        </Grid>
        <Grid ite mt={1}>
          <Typography component="span" variant="body2" color="gray">
            {t('Number of withdrawal attempts to date')}:
          </Typography>
          <Typography component="span" className="ml-1" variant="body1">
            {corefyPayoutLimit}
          </Typography>
        </Grid>
      </Grid>
      <ModalConfirm
        show={isOpenModal}
        setShow={setIsOpenModal}
        id={idWithdrawal}
      />
    </React.Fragment>
  );
};

const mapStateToProps = ({ wallet }) => ({
  withdrawalLimit: wallet.withdrawalLimit,
  corefyPayoutLimit: wallet.corefyPayoutLimit,
});

const mapDispatchToProps = {
  getWalletCorefyLimit,
  getWalletTransactionsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(CardBlock));
