import React from "react";

import {Icon} from "src/views/blocks";

import "./index.scss";


const DoneIcon = (props) => {
    const {
        className = "",
        color,
        ...rest
    } = props;

    return (
        <Icon
          {...rest}
            color={color}
          className={`done-icon ${className}`}
          mask />
    );
};


export {DoneIcon};