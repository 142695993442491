import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useMediaQuery, Grid, Collapse } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

import { ROUTES, MAIN_MENU, SOCIAL_MENU } from 'src/routing';
import { authActions, uiActions } from 'src/store';
import { useThemeProps, useShowMessage } from 'src/hooks';
import {
  Button,
  Divider,
  IconButton,
  SelectTranslation,
  SwitchTheme,
  Typography,
} from 'src/views/blocks';
import {
  AngleLeftIcon,
  AngleRightIcon,
  LogoIcon,
  LogoDarkIcon,
  LogoutIcon,
} from 'src/views/icons';

import { DashboardHeader, MenuItem } from './blocks';

import './index.scss';

const DashboardLayout = props => {
  const { theme, beforeContent, children, onScroll } = useThemeProps(props);
  const history = useHistory();
  const { t } = useTranslation();
  const showMessage = useShowMessage();
  const dispatch = useDispatch();
  const { isSidebarExpanded: isExpanded } = useSelector(state => state.ui);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('lg'));

  const { isAuthenticated } = useSelector(state => state.auth);

  const modifications = clsx({
    'dashboard-layout--expanded': isExpanded,
  });

  const handleMenuToggle = useCallback(() => {
    dispatch(uiActions.setSidebarExpanded(!isExpanded));
  }, [isExpanded, dispatch]);

  const handleLogout = useCallback(async () => {
    const res = await dispatch(authActions.logout());

    showMessage(res);
    history.push(ROUTES.login);
  }, [showMessage, dispatch, history]);

  useEffect(() => {
    if (isMobile) {
      dispatch(uiActions.setSidebarExpanded(false));
    }
  }, [isMobile, dispatch]);

  const iconLogo = () => {
    if (theme === 'dark') {
      return <LogoDarkIcon size="medium" className="sidebar__logo" />
    } else if (theme === 'light') {
      return <LogoIcon size="medium" className="sidebar__logo" />
    }
  }

  return (
    <div className={`dashboard-layout ${modifications}`}>
      <div
        className="dashboard-layout__header"
        style={{
          backgroundColor: isExpanded
            ? theme === 'dark'
              ? '#231f20'
              : 'white'
            : 'transparent',
        }}
      >
        <DashboardHeader />
      </div>

      <div className="dashboard-layout__sidebar">
        <Grid container className={`sidebar sidebar--${theme}`}>
          <Grid
            container
            component={Link}
            className="sidebar__header"
            alignItems="center"
            wrap="nowrap"
            to={ROUTES.home}
          >
            <Grid item className="general_logo">
              {/*<LogoIcon className="sidebar__logo" />*/}
              {iconLogo()}
            </Grid>
          </Grid>

          <Divider color="white" />

          <PerfectScrollbar className="sidebar__wrapper">
            {MAIN_MENU.map((menuItem, index) => {
              return (
                <MenuItem
                  {...menuItem}
                  isAuthenticated={isAuthenticated}
                  key={index}
                  expanded={isExpanded}
                />
              );
            })}

            <Divider color="white" />

            <div className="sidebar__content">
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                wrap="nowrap"
              >
                <Grid item>
                  <SelectTranslation expanded={isExpanded} />
                </Grid>

                <Collapse orientation="horizontal" in={isExpanded}>
                  <Grid item>
                    <SwitchTheme />
                  </Grid>
                </Collapse>
              </Grid>
            </div>

            <Divider className="mb-1" color="white" />

            <div className="sidebar__content">
              <Grid container justifyContent="space-around" wrap="nowrap">
                {SOCIAL_MENU.map((menuItem, index) => {
                  const { icon: Icon, href } = menuItem;

                  return (
                    <Collapse
                      key={index}
                      orientation="horizontal"
                      in={index === 0 || isExpanded}
                    >
                      <Grid item>
                        <IconButton
                          component="a"
                          target="_blank"
                          size="medium"
                          className="social-icons"
                          icon={Icon}
                          href={href}
                        />
                      </Grid>
                    </Collapse>
                  );
                })}
              </Grid>
            </div>

            <Divider className="mb-1" color="white" />

            <div className="sidebar__content">
              {isAuthenticated ? (
                <Button
                  leftAddons={<LogoutIcon size="medium" />}
                  bold
                  onClick={handleLogout}
                >
                  <Collapse orientation="horizontal" in={isExpanded}>
                    <Typography component="span" nowrap>
                      {t('Log out')}
                    </Typography>
                  </Collapse>
                </Button>
              ) : (
                <Button
                  leftAddons={<LogoutIcon size="medium" />}
                  bold
                  onClick={() => {
                    dispatch(uiActions.setTheme('light'));
                    history.push(ROUTES.registration);
                  }}
                >
                  <Collapse orientation="horizontal" in={isExpanded}>
                    <Typography component="span" nowrap>
                      {t('Register')}
                    </Typography>
                  </Collapse>
                </Button>
              )}
            </div>
          </PerfectScrollbar>

          <Divider color="white" />

          <Grid
            container
            className="sidebar__footer"
            wrap="nowrap"
            justifyContent="space-around"
            alignItems="center"
          >
            <Collapse orientation="horizontal" in={isExpanded}>
              <Grid item>
                <Typography className="sidebar__rights" variant="caption2">
                  © 2023 INFOCOIN.
                </Typography>

                <Typography className="sidebar__rights" variant="caption2">
                  All rights reserved.
                </Typography>
              </Grid>
            </Collapse>

            <Grid item>
              <IconButton
                size="small"
                icon={isExpanded ? AngleLeftIcon : AngleRightIcon}
                onClick={handleMenuToggle}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>

      <PerfectScrollbar
        className="dashboard-layout__wrapper"
        onScroll={onScroll}
      >
        {beforeContent}

        <div className={clsx('dashboard-layout__content', props.className)}>
          {children}
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export { DashboardLayout };
