import React from "react";
import clsx from "clsx";
import {Divider as MuiDivider} from "@mui/material";

import {useThemeProps} from "src/hooks";

import "./index.scss";


const Divider = (props) => {
    const {
        theme,
        className = "",
        color
    } = useThemeProps(props);

    const modifications = clsx({
        [`divider--color-${color}`]: !!color
    });

    return (
        <MuiDivider
          className={`divider divider--${theme} ${className} ${modifications}`} />
    );
};


export {Divider};