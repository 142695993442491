import { useTranslation } from 'react-i18next';

const MyCustomLocale = () => {
  const { t } = useTranslation();
  const result = {
    // months list by order
    months: [
      t('January'),
      t('February'),
      t('March'),
      t('April'),
      t('May'),
      t('June'),
      t('July'),
      t('August'),
      t('September'),
      t('October'),
      t('November'),
      t('December'),
    ],

    // week days by order
    weekDays: [
      {
        name: t('Sunday'), // used for accessibility
        short: t('Sunday')[0], // displayed at the top of days' rows
        isWeekend: true, // is it a formal weekend or not?
      },
      {
        name: t('Monday'),
        short: t('Monday')[0],
      },
      {
        name: t('Tuesday'),
        short: t('Tuesday')[0],
      },
      {
        name: t('Wednesday'),
        short: t('Wednesday')[0],
      },
      {
        name: t('Thursday'),
        short: t('Thursday')[0],
      },
      {
        name: t('Friday'),
        short: t('Friday')[0],
      },
      {
        name: t('Saturday'),
        short: t('Saturday')[0],
        isWeekend: true,
      },
    ],

    // just play around with this number between 0 and 6
    weekStartingIndex: 0,

    // return a { year: number, month: number, day: number } object
    getToday(gregorainTodayObject) {
      return gregorainTodayObject;
    },

    // return a native JavaScript date here
    toNativeDate(date) {
      return new Date(date.year, date.month - 1, date.day);
    },

    // return a number for date's month length
    getMonthLength(date) {
      return new Date(date.year, date.month, 0).getDate();
    },

    // return a transformed digit to your locale
    transformDigit(digit) {
      return digit;
    },

    // texts in the date picker
    nextMonth: 'Next Month',
    previousMonth: 'Previous Month',
    openMonthSelector: 'Open Month Selector',
    openYearSelector: 'Open Year Selector',
    closeMonthSelector: 'Close Month Selector',
    closeYearSelector: 'Close Year Selector',
    defaultPlaceholder: 'Select...',

    // for input range value
    from: 'from',
    to: 'to',

    // used for input value when multi dates are selected
    digitSeparator: ',',

    // if your provide -2 for example, year will be 2 digited
    yearLetterSkip: 0,

    // is your language rtl or ltr?
    isRtl: false,
  };

  return result;
};

export { MyCustomLocale };
