import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Button } from '../Button';

const FormSubmit = props => {
  const { children, ...rest } = props;

  const {
    formState: { isValid, isSubmitting },
  } = useFormContext();

  return (
    <Button
      {...rest}
      type="submit"
      disabled={!isValid || isSubmitting}
      variant="primary"
    >
      {children}
    </Button>
  );
};

export { FormSubmit };
