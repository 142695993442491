import React from "react";

import { FormControl } from "src/views/blocks";
import { PasswordInput } from "src/views/inputs";


const PasswordControl = (props) => {
  const {
    validate,
    value,
    required,
    maxLength,
    minLength,
    clicked,
    setvalue,
    generate,
    label,
    name,
    defaultValue,
    ...rest
  } = props;

  return (
    <FormControl
      component={PasswordInput}
      value={value}
      componentProps={{
        ...rest,
        required,
        autoComplete: "off"
      }}
      validate={validate}
      clicked={clicked}
      setvalue={setvalue}
      required={required}
      maxLength={maxLength}
      minLength={minLength}
      label={label}
      generate={generate}
      name={name}
      defaultValue={defaultValue} />
  );
};


export { PasswordControl };