import React from "react";
import clsx from "clsx";
import {Grid} from "@mui/material";

import "./index.scss";


const Overlay = (props) => {
    const {
        className = "",
        open = false,
        children
    } = props;

    const modifications = clsx({
        "overlay--open": open
    });

    return (
        <Grid
          container
          className={`overlay ${className} ${modifications}`}
          justifyContent="center"
          alignContent="center">
            <Grid item>
                {children}
            </Grid>
        </Grid>
    );
};


export {Overlay};