export const UK = `
<!DOCTYPE html>
<html>
<head>
\t<meta http-equiv="content-type" content="text/html; charset=utf-8"/>
\t<title></title>
\t<meta name="generator" content="LibreOffice 7.4.2.3 (Linux)"/>
\t<meta name="author" content="Пользователь Windows"/>
\t<meta name="created" content="2022-03-22T13:35:00"/>
\t<meta name="changedby" content="Пользователь Windows"/>
\t<meta name="changed" content="2022-12-09T20:00:00"/>
\t<meta name="AppVersion" content="15.0000"/>
\t<meta name="GrammarlyDocumentId" content="892e445b65ffd3850410bb87ad653fb5781b6733c86a8bb9cc938b92f5758e0f"/>
\t<style type="text/css">
\t\t@page { size: 8.27in 11.69in; margin-left: 1.18in; margin-right: 0.49in; margin-top: 0.49in; margin-bottom: 0.59in, color: var(--color) }
\t\t@page p { line-height: 115%; text-align: left; orphans: 2; widows: 2; margin-bottom: 0.1in; direction: ltr; background: transparent }
\t\t@page a:link { color: #0563c1; text-decoration: underline }
\t</style>
</head>

<body lang="uk-UA" link="#0563c1" vlink="#800000" dir="ltr"><p align="center" style="line-height: 108%; margin-bottom: 0.11in">
<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>Політика
конфіденційності Infocoin Limited </b></font></font>
</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Infocoin
Limited з усією серйозністю відноситься
до захисту Ваших персональних даних.
Прямо зараз ми докладаємо усіх зусиль
для виконання Регламенту ЄС 2016/679 –
Загального регламенту по захисту даних
— General Data Protection Regulation (надалі GDPR), який
встановлює найвищі в світі стандарти
захисту конфіденційної інформації. У
цій політиці конфіденційності вказані
дані, які ми збираємо, причини, через
які ми їх збираємо, способи їх використання
та сторони, яким ми можемо їх розкрити.
Окрім того, в ній наводяться права, які
користувачі мають як суб’єкти даних і
способи їх використання. </font></font>
</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Ця
політика конфіденційності поширюється
на всі випадки обробки даних, виконуваної
нами у зв’язку з наданням наших послуг
через інтернет-сайти, мобільні версії
інтернет-сайтів (Далі – “Веб-сайт”),
мобільні додатки для IOS та Android (далі –
“Додаток”) та інші інтернет-портали,
що належать, керуються, діють під
торговельною маркою INFOCOIN або доступ до
яких надає Infocoin Limited,  що ідентифікуються
за допомогою уніфікованого покажчика
ресурсу Infocoin.online (та будь які інші сайти
на нашому домені з будь-яким під доменом).</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>1.
Хто ми такі</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Наша
компанія Infocoin Limited компанія зареєстрована
в республіці Кіпр (Реєстраційний номер:
HE 433674) зареєстрована за адресою:
Anexartisias &amp; Athinon, NORA COURT, 2nd floor, Office 203, 3040
Limassol, Cyprus, і є оператором персональних
даних.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>2.
Терміни які ми використовуємо в цій
політиці конфіденційності</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>Персональні
дані</u></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
– будь-яка інформація, яка має відношення
прямо або опосередковано ідентифікованій,
або такій, яка може бути ідентифікована
– фізичній особі. При наявності у нас
можливості ідентифікувати особу, або
її пристрій – будь-яка інформація, яка
знаходиться в нашому розпорядженні,
пов’язана з особою – вважається
Персональними даними.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>Оператор
персональних даних</u></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
– особа/організація, яка визначає цілі
і засоби обробки Персональних даних.
Наприклад, наша компанія виступає як
Оператор персональних даних, оброблюючи
Персональні дані клієнтів в цілях,
наведених нижче в цій Політиці
конфіденційності. При використанні
нашого веб-сайту або додатку ми направляємо
Ваші персональні дані особі, яка надає
матеріал для розміщення на нашій
платформі, яка в свою чергу – використовує
Персональні дані клієнта у власних
цілях. Всі Оператори персональних даних
зобов’язані використовувати персональні
дані у законний спосіб. Ви можете
ознайомитись з їх політиками
конфіденційності на їх власних сайтах.
Нижче представлена загальна інформація
про Операторів персональних даних, яким
ми можемо розкривати Ваші персональні
дані.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>Обробник
персональних даних</u></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
– третя сторона, особа, яка тільки
допомагає досягати цілей, встановлених
Оператором персональних даних. Ми, як
Оператор персональних даних, отримуємо
послуги сторонніх компаній, доручаючи
їм окремі види діяльності, які ми не
виконуємо самостійно і маємо на те
причини (наприклад виходячи з економічної
вигоди). Обробнику персональних даних
дозволяється оброблювати Ваші Персональні
дані тільки у відповідності з нашими
письмовими інструкціями.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>Треті
країни</u></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
– країни, на які не розповсюджується
правовий режим GDPR. В даний час під назвою
треті країни ми маємо на увазі всі
країни, за межами Європейської економічної
зони.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>3.
Персональні дані, які ми збираємо</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Ми
отримуємо Ваші персональні дані
безпосередньо від Вас, коли ви нам їх
надаєте, або збираємо їх самостійно,
коли ви користуєтесь нашими послугами
або взаємодієте з нами. В залежності
від цілі обробки ми можемо оброблювати
наступні категорії Персональних даних.</font></font></p>
<table width="640" cellpadding="7" cellspacing="0">
\t<col width="141"/>

\t<col width="470"/>

\t<tr valign="top">
\t\t<td width="141" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Категорія
\t\t\tперсональних даних</font></font></p>
\t\t</td>
\t\t<td width="470" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Опис</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="141" style="border: 1px solid #000000; padding: 0in 0.08in"><p align="justify" style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Ідентифікаційна
\t\t\tінформація</font></font></p>
\t\t</td>
\t\t<td width="470" style="border: 1px solid #000000; padding: 0in 0.08in"><p align="justify" style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Інформація,
\t\t\tяка використовується для ідентифікації
\t\t\tВас як фізичної особи, така як Ваше
\t\t\tім’я, прізвище, стать, національність,
\t\t\tадреса для виставлення рахунків, дата
\t\t\tнародження, або для ідентифікації вас
\t\t\tяк юридичної особи – назва, форма,
\t\t\tреєстраційний номер тощо, а також
\t\t\tстворений нами штучний онлайн-ідентифікатор
\t\t\tв системах Infocoin Limited .</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="141" style="border: 1px solid #000000; padding: 0in 0.08in"><p align="justify" style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Контактна
\t\t\tінформація</font></font></p>
\t\t</td>
\t\t<td width="470" style="border: 1px solid #000000; padding: 0in 0.08in"><p align="justify" style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Інформація,
\t\t\tяка використовується  для зв’язку з
\t\t\tВами, наприклад ваша електронна адреса
\t\t\tабо номер телефону.</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="141" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Ваша
\t\t\tповедінка при взаємодії з нами онлайн</font></font></p>
\t\t</td>
\t\t<td width="470" style="border: 1px solid #000000; padding: 0in 0.08in"><p align="justify" style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Ваша
\t\t\tповедінка на нашому Веб-сайті та у
\t\t\tДодатку, тобто те що ви відвідали, як
\t\t\tдовго залишались там, на що натискали
\t\t\tі т.д. Ми також відстежуємо Вашу реакцію
\t\t\tна електронні листи та повідомлення,
\t\t\tякі ми Вам надсилаємо, наприклад чи
\t\t\tвідкриваєте Ви їх, чи натискаєте на
\t\t\tпосилання в них.</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="141" style="border: 1px solid #000000; padding: 0in 0.08in"><p align="justify" style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Метаданні
\t\t\tпристроїв та мережі</font></font></p>
\t\t</td>
\t\t<td width="470" style="border: 1px solid #000000; padding: 0in 0.08in"><p align="justify" style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Інформація
\t\t\tпро пристрій, який Ви використовуєте
\t\t\tдля доступу до нашого Веб-сайту, або
\t\t\tпро пристрій, на який встановлено наш
\t\t\tДодаток; метадані, які відносяться до
\t\t\tВашого мережевого підключення, а також
\t\t\tінформація, яка походить від цих даних.
\t\t\tТака інформація включає серед іншого:
\t\t\tдані про Вашу операційну систему,
\t\t\tвеб-браузер, розширення екрану,
\t\t\tIP-адресу та інше.</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="141" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Інформація
\t\t\tпро Ваш профіль</font></font></p>
\t\t</td>
\t\t<td width="470" style="border: 1px solid #000000; padding: 0in 0.08in"><p align="justify" style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Налаштування
\t\t\tта інші дані, які ви створюєте при
\t\t\tкористуванні обліковим записом
\t\t\tInfocoin, такі як сповіщення, історія
\t\t\tпошуку, конкретні налаштування,
\t\t\tзображення профілю, данні облікового
\t\t\tзапису, які Ви використовуєте для
\t\t\tвходу в систему Infocoin, а саме електронну
\t\t\tпошту і пароль (які ми не зберігаємо
\t\t\tв незашифрованому вигляді), Ваші обрані
\t\t\tкатегорії та інші дані, які зберігаються
\t\t\tв обліковому записі Infocoin.</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="141" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Платіжна
\t\t\tінформація</font></font></p>
\t\t</td>
\t\t<td width="470" style="border: 1px solid #000000; padding: 0in 0.08in"><p align="justify" style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Інформація,
\t\t\tяку Ви надаєте нам для виводу коштів.
\t\t\tЗазвичай це дані платіжної картки. Ми
\t\t\tніколи не зберігаємо такі дані в
\t\t\tнезашифрованому вигляді.</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="141" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Інформація
\t\t\tпро Ваші запити і взаємодія з ними</font></font></p>
\t\t</td>
\t\t<td width="470" style="border: 1px solid #000000; padding: 0in 0.08in"><p align="justify" style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Всі
\t\t\tповідомлення між Вами та Infocoin Limited ,
\t\t\tпов’язані з Вашими запитами (наприклад
\t\t\tзверненнями до служби підтримки), а
\t\t\tтакож метадані та замітки, створені
\t\t\tнашими спеціалістами модерації та
\t\t\tпідтримки.</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="141" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Ваші
\t\t\tналаштування</font></font></p>
\t\t</td>
\t\t<td width="470" style="border: 1px solid #000000; padding: 0in 0.08in"><p align="justify" style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Деякі
\t\t\tз Ваших налаштувань, які відносяться
\t\t\tдо Веб-сайту чи Додатку – наприклад,
\t\t\tмовні налаштування, валютні налаштування
\t\t\tта інше, а також налаштування файлів
\t\t\tcookie.</font></font></p>
\t\t</td>
\t</tr>
</table>
<p style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>4.
Цілі використання даних</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>Цілі
використання даних Користувачів.</u></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
Кожен раз, коли ви відвідуєте наш Веб-сайт
чи Додаток – ми оброблюємо ваші
Персональні дані для вказаних нижче
цілей.</font></font></p>
<table width="640" cellpadding="7" cellspacing="0">
\t<col width="96"/>

\t<col width="184"/>

\t<col width="147"/>

\t<col width="155"/>

\t<tr valign="top">
\t\t<td width="96" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Назва</font></font></p>
\t\t</td>
\t\t<td width="184" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Опис</font></font></p>
\t\t</td>
\t\t<td width="147" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Категорія
\t\t\tперсональних даних</font></font></p>
\t\t</td>
\t\t<td width="155" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Правова
\t\t\tоснова</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="96" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Забезпечення
\t\t\tфункціонування Веб-сайту і Додатку</font></font></p>
\t\t</td>
\t\t<td width="184" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Ми
\t\t\tобробляємо ваші персональні дані, щоб
\t\t\tнадати вам функціональні можливості
\t\t\tнашого Веб-сайту та Додатку.</font></font></p>
\t\t</td>
\t\t<td width="147" style="border: 1px solid #000000; padding: 0in 0.08in"><p align="justify" style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Ваша
\t\t\tповедінка при взаємодії з нами онлайн;</font></font></p>
\t\t\t<p align="justify" style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Метадані
\t\t\tпристроїв та мережі;</font></font></p>
\t\t\t<p align="justify" style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Ваші
\t\t\tналаштування.</font></font></p>
\t\t</td>
\t\t<td width="155" style="border: 1px solid #000000; padding: 0in 0.08in"><p align="justify" style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">П.п.
\t\t\tf пункту 1 статті 6 — в цілях реалізації
\t\t\tзаконних інтересів Infocoin (забезпечення
\t\t\tфункціональних можливостей продукту).</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="96" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Розробка
\t\t\tнових продуктів, забезпечення
\t\t\tобслуговування та підтримки, розвиток
\t\t\tбізнесу</font></font></p>
\t\t</td>
\t\t<td width="184" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Ми
\t\t\tпрагнемо безперервно покращувати
\t\t\tнаші продукти та послуги. Для цього
\t\t\tнам потрібні точні дані про вашу
\t\t\tвзаємодію з нами. Тому ми збираємо
\t\t\tдані про ваш пристрій та ідентифікатори
\t\t\tвашої мережі, а також про вашу поведінку
\t\t\tна нашому Веб-сайті та в нашому Додатку.
\t\t\tМи аналізуємо всі ці дані та використовуємо
\t\t\tїх для створення або зміни наших
\t\t\tфункціональних можливостей та процесів.
\t\t\tТакож ми використовуємо дані для
\t\t\tрозвитку нашого бізнесу. Щоразу, коли
\t\t\tнам необхідно прийняти бізнес-рішення,
\t\t\tми вивчаємо дані, що генеруються
\t\t\tнайважливішою частиною нашого бізнесу
\t\t\t– нашими клієнтами.</font></font></p>
\t\t</td>
\t\t<td width="147" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Ваша
\t\t\tповедінка при взаємодії з нами онлайн;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Метадані
\t\t\tпристроїв та мережі.</font></font></p>
\t\t</td>
\t\t<td width="155" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">П.п.
\t\t\tf пункту 1 статті 6 — в цілях реалізації
\t\t\tзаконних інтересів Infocoin (розробка і
\t\t\tрозвиток продуктів, послуг, бізнесу).</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="96" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Обліковий
\t\t\tзапис Infocoin</font></font></p>
\t\t</td>
\t\t<td width="184" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">При
\t\t\tстворенні вами облікового запису
\t\t\tInfocoin ми обробляємо ваші персональні
\t\t\tдані для надання вам всіх її функціональних
\t\t\tможливостей відповідно до Умов
\t\t\tвикористання. Об'єм оброблюваних
\t\t\tперсональних даних змінюється в
\t\t\tзалежності від того, які дані ви
\t\t\tзберігаєте</font></font></p>
\t\t</td>
\t\t<td width="147" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Контактна
\t\t\tінформація;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Інформація
\t\t\tпро обліковий запис;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Ідентифікаційна
\t\t\tінформація;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Платіжна
\t\t\tінформація.</font></font></p>
\t\t</td>
\t\t<td width="155" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">П.п.
\t\t\tb пункту 1  статті 6 — необхідність
\t\t\tукладання і виконання договору (Умов
\t\t\tвикористання).</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="96" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Прямий
\t\t\tмаркетинг</font></font></p>
\t\t</td>
\t\t<td width="184" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Ми
\t\t\tобробляємо Персональні дані клієнтів
\t\t\tу прямих маркетингових цілях для
\t\t\tпідбору найбільш відповідних клієнтам
\t\t\tпропозицій та максимального підвищення
\t\t\tефективності маркетингу (що включає
\t\t\tвідправку пропозицій електронною
\t\t\tпоштою та супутні дії з обробки
\t\t\tінформації). Ми робимо це, якщо ви
\t\t\tпідписалися на отримання наших
\t\t\tпропозицій (на підставі вашої згоди)
\t\t\tабо якщо ви скористалися нашими
\t\t\tпослугами та не відхилили наші
\t\t\tпропозиції (не відмовилися від наших
\t\t\tпропозицій). Крім ваших контактних
\t\t\tданих, ми також зберігаємо такі дані,
\t\t\tяк історія ваших транзакцій, уподобання
\t\t\tта інші дані про вашу взаємодію з нами,
\t\t\tякі допомагають нам сегментувати
\t\t\tклієнтів та персоналізувати пропозиції.
\t\t\tҐрунтуючись на історії зворотного
\t\t\tзв'язку та взаємодії, ми можемо
\t\t\tзвернутися до клієнта з індивідуальною
\t\t\tпропозицією, підібраною спеціально
\t\t\tдля нього.</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Крім
\t\t\tтого, ми можемо надсилати клієнтам,
\t\t\tякі надають свої дані електронні
\t\t\tлисти. Ми зберігаємо персональні дані
\t\t\tзібрані у своїй базі даних лише 30 днів.</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Ми
\t\t\tза жодних обставин не розкриваємо
\t\t\tконтактну інформацію клієнта іншим
\t\t\tОператорам персональних даних без
\t\t\tйого відома та звертаємось до клієнтів
\t\t\tлише з тими пропозиціями, які пов'язані
\t\t\tз нашою основною діяльністю.</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Щоб
\t\t\tви не пропустили наші пропозиції, крім
\t\t\tпрямих повідомлень ми надсилаємо
\t\t\tpush-повідомлення через наш Веб-сайт та
\t\t\tДодаток (за вашою згодою).</font></font></p>
\t\t</td>
\t\t<td width="147" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Ваша
\t\t\tповедінка при взаємодії з нами онлайн;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Інформація
\t\t\tпро обліковий запис;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Метадані
\t\t\tпристроїв та мережі;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Ідентифікаційна
\t\t\tінформація (у разі її введення у поля);</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Контактні
\t\t\tдані (у разі їх введення у поля);</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Ваші
\t\t\tналаштування.</font></font></p>
\t\t</td>
\t\t<td width="155" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">П.п.
\t\t\tf пункту 1 статті 6 — в цілях реалізації
\t\t\tзаконних інтересів Infocoin (прямий
\t\t\tмаркетинг).</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">П.п.
\t\t\ta пункту 1  статті 6 — згода.</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Клієнт
\t\t\tзавжди може відмовитись від отримання
\t\t\tсповіщень в рамках прямого маркетингу
\t\t\tабо відкликати свою згоду та перевірити
\t\t\tстатус підписки, перейшовши в нижню
\t\t\tчастину кожного листа, отриманого в
\t\t\tрамках електронної розсилки.</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="96" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Онлайн
\t\t\tреклама</font></font></p>
\t\t</td>
\t\t<td width="184" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Також
\t\t\tдля надання вам кращих пропозицій та
\t\t\tмаксимального підвищення ефективності
\t\t\tнашої маркетингової діяльності ми
\t\t\tпоказуємо на сайті (Infocoin.online) та
\t\t\tсторонніх веб-сайтах рекламу, яка,
\t\t\tґрунтуючись на ваших персональних
\t\t\tданих, найбільше підходить вам. Тому
\t\t\tє вірогідність, що ви побачите рекламу
\t\t\tInfocoin на інших ресурсах в інтернеті.</font></font></p>
\t\t</td>
\t\t<td width="147" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Ваша
\t\t\tповедінка при взаємодії з нами онлайн;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Інформація
\t\t\tпро обліковий запис;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Метадані
\t\t\tпристроїв та мережі;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Ваші
\t\t\tналаштування.</font></font></p>
\t\t</td>
\t\t<td width="155" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">П.п.
\t\t\tf пункту 1 статті 6 — в цілях реалізації
\t\t\tзаконних інтересів Infocoin (прямий
\t\t\tмаркетинг).</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="96" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Маркетингова
\t\t\tаналітика</font></font></p>
\t\t</td>
\t\t<td width="184" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Для
\t\t\tзагального вдосконалення наших
\t\t\tмаркетингових кампаній ми здійснюємо
\t\t\tаналіз, що дозволяє відслідковувати
\t\t\tрезультативність кампаній та їх вплив
\t\t\tна показники ефективності реклами.
\t\t\tТакож ми аналізуємо вашу взаємодію з
\t\t\tInfoсoin та надсилаємо вам пропозиції,
\t\t\tякі є для вас актуальними.</font></font></p>
\t\t</td>
\t\t<td width="147" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Ваша
\t\t\tповедінка при взаємодії з нами онлайн;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Метадані
\t\t\tпристроїв та мережі.</font></font></p>
\t\t</td>
\t\t<td width="155" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">П.п.
\t\t\tf пункту 1 статті 6 — в цілях реалізації
\t\t\tзаконних інтересів Infocoin (прямий
\t\t\tмаркетинг).</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="96" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Обґрунтування
\t\t\tта пред'явлення претензій, позовів та
\t\t\tзахист від них</font></font></p>
\t\t</td>
\t\t<td width="184" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Ми
\t\t\tзберігаємо Ваші персональні дані
\t\t\tпротягом не менше 4 років з моменту
\t\t\tкористування Вами нашим Веб-сайтом
\t\t\tабо Додатком для можливості пред'явлення
\t\t\tпретензій або позовів у зв'язку з
\t\t\tкористуванням Вами Веб-сайтом або
\t\t\tДодатком з порушенням наших Умов
\t\t\tвикористання або з порушенням деяких
\t\t\tвстановлених законом обов'язків, а
\t\t\tтакож для можливості захисту від
\t\t\tпред'явлених вами претензій чи позовів.</font></font></p>
\t\t</td>
\t\t<td width="147" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Ідентифікаційна
\t\t\tінформація;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Ваша
\t\t\tповедінка при взаємодії з нами онлайн;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Метадані
\t\t\tпристроїв та мережі;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Інформація
\t\t\tпро обліковий запис</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Контактні
\t\t\tдані (у разі їх введення у поля);</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Ваші
\t\t\tналаштування.</font></font></p>
\t\t</td>
\t\t<td width="155" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">П.п.
\t\t\tf пункту 1  статті 6 — в цілях реалізації
\t\t\tзаконних інтересів Infocoin (захист наших
\t\t\tзаконних прав та інтересів).</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="96" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Захист
\t\t\tінформації</font></font></p>
\t\t</td>
\t\t<td width="184" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Нам
\t\t\tнеобхідно захищати себе від різних
\t\t\tзагроз, які намагаються використати
\t\t\tвразливість у нашій системі безпеки
\t\t\tта нашкодити нашому бізнесу. Для цього
\t\t\tнам у деяких випадках необхідно
\t\t\tобробляти персональні дані наших
\t\t\tкористувачів.</font></font></p>
\t\t</td>
\t\t<td width="147" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Ваша
\t\t\tповедінка при взаємодії з нами онлайн;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Метадані
\t\t\tпристроїв та мережі.</font></font></p>
\t\t</td>
\t\t<td width="155" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">П.п.
\t\t\tf пункту 1 статті 6 — в цілях реалізації
\t\t\tзаконних інтересів Infocoin (безпека).</font></font></p>
\t\t</td>
\t</tr>
</table>
<p style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>Цілі
використання даних особи, яка розміщує
контент.</u></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
Коли Ви замовляєте послуги, ми продовжуємо
оброблювати Ваші персональні дані для
вказаних вище цілей. Персональні дані,
які оброблюються для цих цілей включають
інформацію про розміщення вами контенту,
участь у аукціоні, інформацію про ваші
запити та інформацію, пов’язану з
обміном повідомленнями між нами. Окрім
того, ми оброблюємо Ваші Персональні
дані для наступних цілей.</font></font></p>
<table width="640" cellpadding="7" cellspacing="0">
\t<col width="103"/>

\t<col width="234"/>

\t<col width="124"/>

\t<col width="122"/>

\t<tr valign="top">
\t\t<td width="103" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Назва</font></font></p>
\t\t</td>
\t\t<td width="234" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Опис</font></font></p>
\t\t</td>
\t\t<td width="124" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Категорія
\t\t\tперсональних даних</font></font></p>
\t\t</td>
\t\t<td width="122" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Правова
\t\t\tоснова</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="103" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Розміщення
\t\t\tконтенту і послуги</font></font></p>
\t\t</td>
\t\t<td width="234" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Основна
\t\t\tпричина збору та використання нами
\t\t\tВаших персональних даних - укладання
\t\t\tз вами договору та подальше надання
\t\t\tзамовлених послуг. Залежно від обсягу
\t\t\tвикористання клієнтом наших послуг,
\t\t\tми обробляємо Персональні дані
\t\t\tвідповідно до необхідності, продиктованої
\t\t\tукладанням та виконанням домовленостей
\t\t\tщодо надання послуг, укладених шляхом
\t\t\tвикористання функціоналу сайту,
\t\t\tнатискання відповідних клавіш,
\t\t\tзаповнення блоків інформації. У процесі
\t\t\tоформлення замовлення клієнту
\t\t\tнадсилається запит на пряму згоду на
\t\t\tобробку вказаних персональних даних.
\t\t\tКлієнт завжди має право відкликати
\t\t\tсвою згоду, звернувшись до служби
\t\t\tпідтримки за наступними посиланнями:
\t\t\t</font></font><a href="https://ads.infocoin.online/support"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>https://business.infocoin.online/support</u></font></font></font></a></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">https://view.infocoin.online/help</font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">.
\t\t\tПри цьому просимо мати на увазі, що
\t\t\tпісля відкликання згоди на обробку
\t\t\tперсональних даних ми не зможемо
\t\t\tнадавати клієнту подальшу підтримку,
\t\t\tщо стосується поточних послуг.</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">У
\t\t\tразі потреби ми обробляємо ваші
\t\t\tПерсональні дані для укладання з вами
\t\t\tдоговору та надання замовлених послуг,
\t\t\tіноді обробляти Персональні дані
\t\t\tможуть сторонні Оператори, з дотриманням
\t\t\tусіх згаданих зобов'язань щодо їх
\t\t\tзбереження та збереження, під нашим
\t\t\tчуйним контролем та за письмовою
\t\t\tзгодою. Повний перелік сторонніх
\t\t\tоператорів персональних даних, яким
\t\t\tми можемо розкривати персональні дані
\t\t\tклієнтів, може бути представлений за
\t\t\tписьмовим запитом.</font></font></p>
\t\t</td>
\t\t<td width="124" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Ідентифікаційна
\t\t\tінформація;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Контактна
\t\t\tінформація;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Інформація
\t\t\tпро ваше (і) замовлення;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Інформація
\t\t\tпро надані документи;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Платіжна
\t\t\tінформація;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Документи,
\t\t\tнеобхідні для надання послуг, доручених
\t\t\tтретім особам;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Ліцензії
\t\t\tта інші документи, пов'язані із
\t\t\tзамовленням.</font></font></p>
\t\t</td>
\t\t<td width="122" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">П.п.
\t\t\tb пункту 1 статті 6 — необхідність
\t\t\tукладання та виконання договору.</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="103" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Запобігання
\t\t\tшахрайству</font></font></p>
\t\t</td>
\t\t<td width="234" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Для
\t\t\tнедопущення шахрайських повернень
\t\t\tплатежів, якщо клієнт повідомляє про
\t\t\tздійснення через його банківський
\t\t\tрахунок шахрайського перерахування
\t\t\tкоштів, ми можемо вивчити його інформацію
\t\t\tв соціальних мережах з метою перевірки
\t\t\tклієнта на наявність будь-якого зв'язку
\t\t\tз особою, що замовила послуги з
\t\t\tрозміщення контенту або інші послуги,
\t\t\tщоб переконатися у відсутності спроби
\t\t\tповернення витрачених на послуги з
\t\t\tрозміщення контенту та інші послуги
\t\t\tзасобів шахрайським шляхом. Ми
\t\t\tобробляємо лише обмежену кількість
\t\t\tінформації, необхідної для перевірки
\t\t\tклієнта на предмет зв'язку з особою,
\t\t\tщо замовила послуги з розміщення
\t\t\tконтенту або інші послуги, та публікації
\t\t\tним будь-яких даних, пов'язаних з
\t\t\tконкретним випадком.</font></font></p>
\t\t</td>
\t\t<td width="124" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Ідентифікаційна
\t\t\tінформація;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Контактна
\t\t\tінформація;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Інформація
\t\t\tпро ваше (і) замовлення;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Інформація
\t\t\tпро проїзні документи;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Платіжна
\t\t\tінформація;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Ваша
\t\t\tповедінка при взаємодії з нами онлайн;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Інформація
\t\t\tпро пристрій та мережу;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Загальнодоступна
\t\t\tінформація, пов'язана з вашим замовленням.</font></font></p>
\t\t</td>
\t\t<td width="122" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">П.п.
\t\t\tf пункту 1  статті 6 –– з метою реалізації
\t\t\tзаконних інтересів Infoсoin та індустрії
\t\t\tелектронної комерції та електронної
\t\t\tреклами загалом (запобігання шахрайству).</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="103" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Підтримка
\t\t\tклієнтів</font></font></p>
\t\t</td>
\t\t<td width="234" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Підтримка
\t\t\tклієнтів є важливою частиною наших
\t\t\tпослуг. З метою надання клієнтам
\t\t\tпослуг, які вони запитують, ми фіксуємо
\t\t\tвсю інформацію в ході комунікації з
\t\t\tклієнтами по будь-яких каналах,
\t\t\tвключаючи електронну пошту, чат і
\t\t\tтелефонний зв'язок.</font></font></p>
\t\t</td>
\t\t<td width="124" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Ідентифікаційна
\t\t\tінформація;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Контактна
\t\t\tінформація;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Інформація
\t\t\tпро ваше (і) замовлення;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Інформація
\t\t\tпро проїзні документи;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Інформація
\t\t\tпро ваші запити та взаємодію з нами.</font></font></p>
\t\t</td>
\t\t<td width="122" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">П.п.
\t\t\tb пункту 1  статті 6 — необхідність
\t\t\tукладання та виконання договору</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="103" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Обґрунтування
\t\t\tта пред'явлення претензій та позовів,
\t\t\tа також захист від претензій та позовів</font></font></p>
\t\t</td>
\t\t<td width="234" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Ми
\t\t\tзберігаємо та обробляємо Персональні
\t\t\tдані клієнтів з метою обґрунтування
\t\t\tта пред'явлення позовів та захисту
\t\t\tвід них. Щоразу, коли ви використовуєте
\t\t\tсайт, ми зберігаємо всі відповідні
\t\t\tдані на випадок можливих претензій
\t\t\tабо позовів, які можуть бути пред'явлені
\t\t\tвами чи нами, зокрема, в рамках судових
\t\t\tта інших розглядів, а також при
\t\t\tпред'явленні чи продажу прав вимог,
\t\t\tпереданих вами нам на протязі не менше
\t\t\t4 років з моменту створення відповідного
\t\t\tзамовлення. При отриманні від клієнтів
\t\t\tзапитів на захист даних ми також
\t\t\tзберігаємо всі надані клієнтами дані
\t\t\tта всю інформацію про роботу із запитом
\t\t\tіз зазначеною метою.</font></font></p>
\t\t</td>
\t\t<td width="124" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Ідентифікаційна
\t\t\tінформація;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Контактна
\t\t\tінформація;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Інформація
\t\t\tпро ваше (і) замовлення;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Платіжна
\t\t\tінформація;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Ваша
\t\t\tповедінка при взаємодії з нами онлайн;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Інформація
\t\t\tпро пристрій та мережу;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Загальнодоступна
\t\t\tінформація, пов'язана з вашим замовленням;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Інформація
\t\t\tпро ваші запити та взаємодію з нами.</font></font></p>
\t\t</td>
\t\t<td width="122" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">П.п.
\t\t\tf пункту 1  статті 6 — для реалізації
\t\t\tзаконних інтересів Infoсoin (захист наших
\t\t\tзаконних прав).</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="103" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Дотримання
\t\t\tправових зобов'язань</font></font></p>
\t\t</td>
\t\t<td width="234" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Там,
\t\t\tде це застосовно - ми повинні обробляти
\t\t\tпевні Персональні дані клієнтів для
\t\t\tвиконання правових зобов'язань, що
\t\t\tнакладаються на нашу компанію. Нам не
\t\t\tпотрібно отримувати згоду клієнтів
\t\t\tна таку обробку, оскільки це є юридичною
\t\t\tнеобхідністю. З цією метою ми обробляємо
\t\t\tідентифікаційну та контактну інформацію
\t\t\tкожного клієнта та інформацію про
\t\t\tугоди, що здійснюються. Основні норми,
\t\t\tякі зобов'язують нас вчиняти зазначені
\t\t\tдії, містяться у наступному Законодавстві:
\t\t\tЦивільному кодексі, Законі про захист
\t\t\tправ споживачів, Податковому кодексі,
\t\t\tзокрема стосовно ПДВ та податкової
\t\t\tзвітності. Якщо клієнт надсилає нам
\t\t\tзапит на реалізацію прав, що стосуються
\t\t\tйого персональних даних, ми просимо
\t\t\tйого надати певні персональні дані,
\t\t\tа потім обробляємо їх з метою дотримання
\t\t\tчинного законодавства.</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">У
\t\t\tвипадку, якщо законодавство, яке
\t\t\tпоширюється на наші з вами взаємини,
\t\t\tпрямо не наказує збирати такі дані –
\t\t\tми не будемо їх збирати.</font></font></p>
\t\t</td>
\t\t<td width="124" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Інформація
\t\t\tпро ваше (і) замовлення;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Інформація
\t\t\tпро ваші запити та взаємодію з нами.</font></font></p>
\t\t</td>
\t\t<td width="122" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">П.п.
\t\t\tс пункту статті 6 — необхідність
\t\t\tдотримання правових зобов'язань, які
\t\t\tрозповсюджуються на Infoсoin.</font></font></p>
\t\t</td>
\t</tr>
</table>
<p style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>5.
Кому і чому ми розкриваємо Ваші Персональні
дані</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
</font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>Розкриття
Персональних даних іншим Операторам
Персональних даних.</u></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">В
деяких випадках ми розкриваєм Персональні
дані третім особам, які використовують
їх в своїх цілях</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Таким
чином, Персональні дані клієнтів можуть
розкриватись деяким постачальникам
послуг, особам, які розміщують контент
і рекламодавцям, включаючи треті країни.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Всі
такі особи використовують Персональні
дані у відповідності до своєї Політики
конфіденційності (опублікованої на їх
сайті), або, якщо не мають власної, у
відповідності до наших стандартів
безпеки і використання Персональних
даних. Розкриття даних відбувається
згідно з діючими нормативно-правовими
актами у відношенні Персональних даних.
У випадку Вашої згоди на налаштування
файлів cookie, ми передаємо деякі Ваші дані
нашим партнерам в маркетингових цілях.</font></font></p>
<table width="640" cellpadding="7" cellspacing="0">
\t<col width="407"/>

\t<col width="203"/>

\t<tr valign="top">
\t\t<td width="407" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Категорія
\t\t\tотримувача Персональних даних</font></font></p>
\t\t</td>
\t\t<td width="203" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Приклади</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="407" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Особи,
\t\t\tякі розміщують контент</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Для
\t\t\tвиконання власних цілей, таких як
\t\t\tтаргетинг, Ваші персональні дані
\t\t\tобробляються відповідним чином</font></font></p>
\t\t</td>
\t\t<td width="203" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Особа,
\t\t\tяка розміщує контент на платформі
\t\t\tInfocoin</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="407" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Інші
\t\t\tпостачальники послуг</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Пов'язані
\t\t\tз основною діяльністю сайту сторонні
\t\t\tорганізації, які обробляють Вашу
\t\t\tперсональну інформацію відповідно
\t\t\tдо своїх політик конфіденційності, а
\t\t\tтакож відповідно до прийнятих норм
\t\t\tміжнародного законодавства</font></font></p>
\t\t</td>
\t\t<td width="203" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Постачальники
\t\t\tпослуг</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="407" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Оператори
\t\t\tз прийому та обробки платежів</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Виконання
\t\t\tплатіжного доручення на веб-сайті –
\t\t\tце лише початок довгого ланцюжка
\t\t\tтехнічних операцій, необхідних для
\t\t\tнадходження платежу на (банківський)
\t\t\tрахунок. Інформація повинна пройти
\t\t\tчерез різних платіжних операторів та
\t\t\tбанки-еквайєри. Усі ці суб'єкти
\t\t\tвиступають операторами персональних
\t\t\tданих, оскільки вони повністю контролюють
\t\t\tці дані.</font></font></p>
\t\t</td>
\t\t<td width="203" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">PayPal</span></font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="407" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Комунікаційні
\t\t\tта соціальні платформи</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Для
\t\t\tнадсилання вам повідомлень, пов'язаних
\t\t\tз нашими послугами, або для надання
\t\t\tвам можливості входу до Облікового
\t\t\tзапису Infocoin через сторонні платформи,
\t\t\tнам потрібно передавати ваші Персональні
\t\t\tдані постачальнику обраної платформи.</font></font></p>
\t\t</td>
\t\t<td width="203" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Facebook
\t\t\tIreland Ltd.</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Google
\t\t\tIreland Limited</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="407" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Особи,
\t\t\tщо надають юридичні послуги</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Ми
\t\t\tможемо використовувати послуги інших
\t\t\tсуб'єктів при захисті наших законних
\t\t\tправ та пред'явленні претензій.</font></font></p>
\t\t</td>
\t\t<td width="203" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Адвокати,
\t\t\tколекторські агенції</font></font></p>
\t\t</td>
\t</tr>
</table>
<p style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><u>-
</u></span></font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>Розкриття
інформації Обробникам Персональних
даних</u></font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">.</font></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Існує
велика кількість необхідних для нашої
компанії видів діяльності, які ми не
можемо здійснювати самостійно. Для їх
виконання ми користуємося послугами
сторонніх партнерів, яким ми розкриваємо
Персональні дані клієнтів, оскільки
вони часто не можуть обійтися без них.
При цьому в таких випадках наша компанія
зберігає статус оператора персональних
даних, а вони діють як обробники.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Тому
вони можуть обробляти персональні дані
тільки відповідно до наших цілей, і
відповідальність за інформацію завжди
несемо ми. Вони ні в якому разі не можуть
використовувати дані у власних цілях
або способом, що суперечить нашій угоді.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Більше
того, ми співпрацюємо лише з такими
партнерами, які дають нам достатні
гарантії дотримання вимог законодавства
та повного забезпечення безпеки
інформації клієнтів.</font></font></p>
<table width="640" cellpadding="7" cellspacing="0">
\t<col width="407"/>

\t<col width="204"/>

\t<tr valign="top">
\t\t<td width="407" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Категорія
\t\t\tотримувача</font></font></p>
\t\t</td>
\t\t<td width="204" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Приклади</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="407" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Особи,
\t\t\tщо надають послуги з примусового
\t\t\tвиконання зобов'язань.</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Захищаючи
\t\t\tнаші інтереси у спорах, ми можемо
\t\t\tвикористовувати послуги третіх осіб
\t\t\tдля захисту ваших прав та інтересів.</font></font></p>
\t\t</td>
\t\t<td width="204" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Адвокати,
\t\t\tколекторські агенції.</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="407" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Послуги
\t\t\tонлайн-реклами.</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Завдяки
\t\t\tцим послугам ми можемо показувати вам
\t\t\tперсональну рекламу в інтернеті.</font></font></p>
\t\t</td>
\t\t<td width="204" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Facebook
\t\t\tIreland Ltd. (Facebook for Business)</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Google
\t\t\tBusiness</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Criteo
\t\t\tSA</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">CityBusiness
\t\t\tMedia, LLc.</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="407" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Аналітичні
\t\t\tінструменти.</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Ми
\t\t\tвикористовуємо програмні інструменти
\t\t\tаналізу даних та ведення журналів від
\t\t\tсторонніх постачальників для
\t\t\tзабезпечення комплексного уявлення
\t\t\tпро нашу клієнтську базу та підвищення
\t\t\tзручності наших послуг.</font></font></p>
\t\t</td>
\t\t<td width="204" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Exponea
\t\t\ts.r.o.</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Facebook
\t\t\tIreland Ltd.</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Google
\t\t\tLLC.</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Keboola
\t\t\ts.r.o.</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">GoodData
\t\t\tCorporation.</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Datadog
\t\t\tInc.</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Logmole</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Loggly,
\t\t\tInc. (SolarWinds Worldwide, LLC)</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Functional
\t\t\tSoftware, Inc. dba Sentry</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Smartsupp.com,
\t\t\ts.r.o.</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Mapbox,
\t\t\tInc.</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">FullStory,
\t\t\tInc.</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="407" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Розробка
\t\t\tпрограмного забезпечення.</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Іноді
\t\t\tми можемо ділитися вашими персональними
\t\t\tданими з нашими розробниками, які
\t\t\tдопомагають створювати наші технології.</font></font></p>
\t\t</td>
\t\t<td width="204" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">В
\t\t\tданий час ми співпрацюємо з багатьма
\t\t\tрозробниками програмного забезпечення.
\t\t\tМи не ділимося їхніми персональними
\t\t\tданими для дотримання їх права на
\t\t\tконфіденційність.</font></font></p>
\t\t</td>
\t</tr>
</table>
<p style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>6.
Строк зберігання Персональних даних</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Як
правило, ми обробляємо ваші персональні
дані доти, доки у нас не відпадає
необхідність використання їх для цілей,
передбачених цією Політикою
конфіденційності. Зазвичай ми обробляємо
Ваші Персональні дані протягом
встановленого законом терміну позовної
давності, який зазвичай становить 3
роки, і додатково протягом ще одного
року через резерв часу, необхідний через
затримку доставки повідомлень та наших
додаткових дій.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">З
метою виконання правових зобов'язань
ми обробляємо ваші Персональні дані
протягом терміну, передбаченого чинним
законодавством, наприклад, протягом
терміну тривалістю 10 років, відведеного
на зберігання рахунків.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Для
цілей ведення Облікового запису Infocoin
клієнта ми зберігаємо Персональні дані
протягом 5 років з моменту останньої
дії, вчиненої вами під час знаходження
облікового запису.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">З
метою персоналізованих пропозицій ми
періодично надсилаємо клієнтам електронні
листи, у кожному з яких пропонується
простий і зрозумілий спосіб відписатися
і цим відмовитися від цього типу обробки.
Ми зберігаємо та використовуємо
Персональні дані клієнта з цією метою,
доки ви не відпишетесь від розсилки.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>7.
Отримання доступу до своїх Персональних
даних та їх контроль</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Ми
зацікавлені в тому, щоб клієнти завжди
могли контролювати свої персональні
дані. У клієнтів є певні права. Клієнти
можуть за певних умов:</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
знайомитися з вичерпною інформацією
про те, які дані ми обробляємо, та
отримувати її копію;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
надсилати нам прохання про видалення
своїх даних;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
правити наявні в нас дані при виявленні
у них помилок;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
обмежувати обробку даних;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
заперечувати проти обробки;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
отримувати свої Персональні дані у
загальноприйнятому форматі машиночитання
і самостійно розпоряджатися ними.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Для
реалізації, вищевказаних у цьому розділі,
своїх прав, Ви можете звернутись до
служби підтримки за наступними
посиланнями: </font></font><a href="https://ads.infocoin.online/support"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>https://business.infocoin.online/support</u></font></font></font></a><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
або </font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">https://view.infocoin.online/help</font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Зверніть
увагу, що з метою захисту Персональних
даних ми виконуємо лише запити, які
надсилаються з облікового запису у
якому зазначена електрона адреса,
використана під час реєстрації. Якщо
ви бажаєте змінити електронну адресу,
ми просимо надати додаткову інформацію,
щоб переконатися в тому, що клієнт дійсно
є власником Персональних даних.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>-
Доступ до ваших персональних даних</u></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Ви
можете будь-коли надіслати запит до
служби підтримки за наступними
посиланнями: </font></font><a href="https://ads.infocoin.online/support"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>https://business.infocoin.online/support</u></font></font></font></a><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
або </font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">https://view.infocoin.online/help</font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">,
щоб дізнатися, чи обробляємо ми ваші
персональні дані, і отримати наступну
інформацію:</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
цілі, для яких ми обробляємо ваші
персональні дані;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
Категорії персональних даних, які ми
обробляємо;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
список сторонніх осіб, яким ми передаємо
ваші персональні дані, особливо якщо
сторонні особи розташовані в третіх
країнах;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
тривалість обробки ваших персональних
даних або час їх зберігання;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
Ваші права як суб'єкта даних відповідно
до GDPR (Загального регламенту захисту
даних);</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
Ваше право надсилати скарги до наглядових
органів;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
Де ми отримали ваші дані у тому випадку,
якщо ми отримали їх не безпосередньо
від вас;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
будь-яку інформацію про автоматизоване
прийняття рішень (якщо є), яка може бути
застосована до вас;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
коли дані були передані до третьої
країни і які заходи безпеки застосовуються
відповідно до GDPR;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Також
на ваш запит ми надамо повну копію всіх
персональних даних, які ми обробляємо.
Перша копія даних надається безкоштовно.
Проте за наступні копії стягується
плата на покриття адміністративних
видатків.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Ви
також можете запросити надання даних
у широко використованому форматі для
переносу даних.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>-
Видалення персональних даних</u></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">У
вас також є право на повне видалення
персональних даних (або, точніше, їх
повну та безповоротну анонімізацію),
якщо до вас застосовується один із
наступних випадків:</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
Нам більше не потрібні ваші персональні
дані для цілей, визначених у цій Політиці
конфіденційності;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
Ви успішно опротестували обробку
Персональних даних згідно зі статтею
21 GDPR, і ми не маємо інших цілей обробки
Ваших Персональних даних;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
Ми незаконно обробляли ваші Персональні
дані, або</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">існує
зобов'язання, що має юридичну силу, що
зобов'язує нас видалити ваші особисті
дані;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Тим
не менш, ви не маєте права вимагати
видалення персональних даних, якщо
обробка необхідна для:</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
здійснення права на свободу вираження
думок та інформації;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
дотримання зобов'язання, що має юридичну
силу, що зобов'язує нас зберігати
персональні дані;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
нам необхідні персональні дані для
пред'явлення та реалізації позовів, а
також захисту від них.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>-
Виправлення персональних даних</u></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Якщо
ви вважаєте, що будь-які ваші персональні
дані, які ми обробляємо, неправильні,
то можете повідомити про це, і ми зробимо
все можливе, щоб внести до них виправлення.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>-
Обмеження обробки ваших персональних
даних</u></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">За
певних умов ми обмежуємо обробку ваших
персональних даних. Це означає, що ми
подбаємо про те, щоб вони не оброблялися
в жодній іншій цілі, крім архівування
або переміщення в захищений архів. Ви
маєте право вимагати обмеження, якщо:</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
ви заперечили точність ваших персональних
даних (ми продовжимо їх обробку після
вирішення проблеми);</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
ми незаконно обробляли ваші персональні
дані, проте замість видалення ви запросили
обмеження їхньої обробки;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
єдина мета обробки ваших персональних
даних, що залишилася, - пред'явлення і
реалізація позовів, а також захист проти
них;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
Ви відмовляєтесь від обробки даних
згідно з п.1 статті 21 GDPR, і ми оцінюємо
обґрунтованість вашого запиту.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>-
Заперечення проти обробки ваших
персональних даних</u></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Ви
можете заперечити проти будь-якої мети,
для якої ми обробляємо Ваші персональні
дані, виходячи з юридичного обґрунтування
правового інтересу. У випадку, коли ви
заперечуєте проти обробки даних у
маркетингових цілях, ми негайно припиняємо
використання ваших персональних даних
з цією метою.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Якщо
ви протестуєте проти будь-якої іншої
мети на підставі правового інтересу,
ми припиняємо обробку ваших персональних
даних з цією метою, якщо не доведемо, що
наші законні підстави для їхньої обробки
мають пріоритет над вашими особистими
інтересами, правами та свободами.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>-
Забезпечення перенесення ваших
персональних даних</u></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Нарешті,
у вас є право на отримання ваших
Персональних даних, оброблюваних (на
підставі згоди, або необхідності
укладання договору, або необхідності
виконання зобов'язань за ним) у широко
використовуваному та машино-зчитуваному
форматі, і ви маєте право передавати ці
дані іншим операторам персональних
даних за вашим вибору.</font></font></p>
<p style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>8.
Cookie файли та аналогічні технології</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Файли
cookie — це невеликі текстові файли, що
розміщуються на вашому пристрої, які
дозволяють нам запам'ятовувати вашу
певну інформацію для таких різних цілей,
як забезпечення функціональних
можливостей Веб-сайту та Додатку,
розробка продуктів, покращення
обслуговування та розвиток бізнесу,
надання Облікового запису Infocoin,
онлайн-реклама, маркетингова аналітика,
інформаційна безпека, запобігання
шахрайству.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">По
суті, на нашому сайті використовується
(або можуть використовуватись) три типи
cookie-файлів:</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
cookie-файли, які необхідні для роботи
веб-сайту та надання послуг (вони не
можуть бути відключені);</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
так звані &quot;експлуатаційні&quot;
cookie-файли, які використовуються для
отримання статистичних даних з метою
вдосконалення наших послуг;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
cookie-файли, які використовуються в
маркетингових цілях.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Cookie-файли,
які використовуються для отримання
статистичних даних та в маркетингових
цілях, можна відключити за допомогою
налаштувань.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Ви
можете дізнатися про файли cookie, включаючи
інформацію про те, як ними керувати або
їх видаляти, на </font></font><a href="http://www.aboutcookies.org/"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>www.aboutcookies.org</u></font></font></font></a><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">,
</font></font><a href="http://www.allaboutcookies.org/"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>www.allaboutcookies.org</u></font></font></font></a><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
або </font></font><a href="http://www.civicuk.com/cookie-control/browser-settings"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>www.civicuk.com/cookie-control/browser-settings</u></font></font></font></a><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">.
Однак, зверніть увагу на те, що деякі
функції нашого сайту можуть не працювати
у разі видалення файлів cookie.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<table width="638" cellpadding="7" cellspacing="0">
\t<col width="224"/>

\t<col width="384"/>

\t<tr valign="top">
\t\t<td width="224" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Cookie
\t\t\tфайли</font></font></p>
\t\t</td>
\t\t<td width="384" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Ціль</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="224" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">_</font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">business</span></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">.infocoin.online</font></font></p>
\t\t</td>
\t\t<td width="384" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Файли
\t\t\tнеобхідні для адміністрування,
\t\t\tстатистики та інших показників,
\t\t\tважливих для наших розробників</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="224" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">_</font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">view</span></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">.infocoin.online</font></font></p>
\t\t</td>
\t\t<td width="384" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Файли
\t\t\tнеобхідні для відображення роботи
\t\t\tархітектури, роботи коду та інших
\t\t\tнеобхідних для наших розробників
\t\t\tпоказників</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="224" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">helpcrunch-widget</font></font></p>
\t\t</td>
\t\t<td width="384" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Атрибут
\t\t\t</font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU">SameSite</span></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
\t\t\tзаголовку відповіді </font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU">HTTP</span></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
\t\t\t</font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU">Set</span></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-</font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU">Cookie</span></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
\t\t\tдозволяє заявити, чи повинні ваші
\t\t\t</font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU">cookie</span></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
\t\t\tбути обмеженими відвідуваним сайтом,
\t\t\tтретіи сторонами або субдоменами
\t\t\tсайту.</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="224" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">_ga</font></font></p>
\t\t</td>
\t\t<td width="384" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Ідентифікація
\t\t\tкористувача (Google Analytics 4)</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="224" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">_gid</font></font></p>
\t\t</td>
\t\t<td width="384" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Ідентифікація
\t\t\tкористувача (Google Analytics 4)</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="224" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">_ga_&lt;container-id&gt;</font></font></p>
\t\t</td>
\t\t<td width="384" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Збереження
\t\t\tстану сеансу (Google Analytics 4)</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="224" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">_ga</font></font></p>
\t\t</td>
\t\t<td width="384" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Ідентифікація
\t\t\tкористувача Universal Analytics (analytics.js)</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="224" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">_gid</font></font></p>
\t\t</td>
\t\t<td width="384" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Ідентифікація
\t\t\tкористувача Universal Analytics (analytics.js)</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="224" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">_gat</font></font></p>
\t\t</td>
\t\t<td width="384" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Обмежує
\t\t\tкількість запитів. Якщо підтримка
\t\t\t</font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">Google</span></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
\t\t\t</font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">Analytics</span></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
\t\t\tреалізована за допомогою </font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">Google</span></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
\t\t\t</font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">Manager</span></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-а
\t\t\tтегів, файлу буде присвоєно назву
\t\t\t_dc_gtm_&lt;property-id&gt;</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="224" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">AMP_TOKEN</font></font></p>
\t\t</td>
\t\t<td width="384" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">містить</font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU">
\t\t\tтокен, за допомогою якого можна отримати
\t\t\tClient-ID від сервісу AMP</span></font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="224" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">_gac_&lt;property-id&gt;</font></font></p>
\t\t</td>
\t\t<td width="384" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU">Містить
\t\t\tінформацію про компанію користувача.
\t\t\tПісля встановлення зв'язку між
\t\t\tаккаунтами Google Аналітики и Google Реклами
\t\t\tрозміщені на сайті теги конверсії
\t\t\tGoogle Реклами будуть отримувати дані з
\t\t\tфайлу cookie.</span></font></font></p>
\t\t</td>
\t</tr>
</table>
<p style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>9.
Передача даних за межі Європейської
економічної зони </b></font></font>
</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">У
разі потреби ми можемо передавати ваші
Персональні дані за межі Європейської
економічної зони. Це відбувається, коли
клієнт хоче придбати рекламу з третьої
країни або замовляє будь-яку з послуг
з третьої країни. Необхідність передачі
інформації клієнтів таким третім особам
є закономірною, оскільки без неї надання
послуг було б неможливим. Також ми можемо
передавати ваші Персональні дані за
межі Європейської економічної зони
Оброблювачам персональних даних, що
знаходяться у третіх країнах.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">При
передачі даних до країн, у яких ми не
можемо покладатися на відповідний
рівень захисту відповідно до статті 45
регламенту GDPR та достатні заходи щодо
забезпечення безпеки відповідно до
статті 46 регламенту GDPR, ми передаємо
Персональні дані, ґрунтуючись на
виключенні, наведеному в підпункті b
Пункту 1 статті 49 регламенту GDPR. Усі
постачальники послуг використовують
Персональні дані відповідно до своїх
Політик конфіденційності (які опубліковані
на їх веб-сайтах), або, якщо вони не мають
відповідної політики – відповідно до
нашої Політики конфіденційності.
Розкриття персональних даних іншим
постачальникам послуг здійснюється
відповідно до чинних нормативно-правових
актів щодо персональних даних.</font></font></p>
<p style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>10.
Скарги до органів нагляду</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Захист
даних - серйозне питання, і реалізація
правил є досить великою складністю.
Ніхто не досконалий, і може статися, що
ми припустимося помилки. Якщо ви виявите,
що ми неналежним чином скористалися
вашими персональними даними, зверніться
до нас в першу чергу: ми зобов'язуємося
зробити все можливе, щоб вирішити
проблему. З нами завжди можна зв'язатися
з будь-яких питань щодо захисту даних,
звернувшись до служби підтримки за
наступними посиланнями:
</font></font><a href="https://ads.infocoin.online/support"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>https://business.infocoin.online/support</u></font></font></font></a><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
чи </font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">https://view.infocoin.online/help</font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">,
або напишіть у наш офіс, адреса якого
вказана на сайті.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">При
цьому ви за будь-яких обставин маєте
право надсилати скарги в наглядові
органи. Особа з Європейського Союзу
може направити скаргу до органу в державі
— члені ЄС, де вона проживає, в державі
— члені ЄС, де вона працює, та в державі
— члені ЄС, в якій відбулося порушення.</font></font></p>
<p style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>11.
Як з нами зв'язатися та реалізувати свої
права</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Для
з'ясування всіх питань, пов'язаних із
конфіденційністю та захистом даних,
або для реалізації прав, пов'язаних з
вашими персональними даними можна
звернутись до служби підтримки за
наступними посиланнями:
</font></font><a href="https://ads.infocoin.online/support"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>https://business.infocoin.online/support</u></font></font></font></a><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
чи </font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">https://view.infocoin.online/help</font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>12.
Особливі умови для осіб, які проживають
у певних країнах</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Незалежно
від того, де ви проживаєте, ми гарантуємо
захист Ваших персональних даних. Ця
Політика конфіденційності містить
деякі умови, які відрізняються від інших
умов або доповнюють їх і можуть
поширюватися на осіб, які проживають у
певних країнах та штатах, відповідно
до умов цієї Політики Конфіденційності.</font></font></p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>-
Для осіб, які проживають у Каліфорнії</u></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Ці
особливі положення поширюються на вас,
якщо ви проживаєте в Каліфорнії.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Ми
дотримуємося Закону штату Каліфорнія
про захист особистої інформації
споживачів (California Consumer Privacy Act, далі –
CCPA), який встановлює найвищі стандарти
недоторканності особистого життя та
захисту персональних даних у США. Для
отримання інформації про обробку нами
Ваших персональних даних ви можете
будь-коли ознайомитися з цією Політикою
конфіденційності.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Ви
маєте право на запит інформації про те,
яким чином ми обробляємо Ваші персональні
дані, та на отримання їх копії. Протягом
періоду тривалістю 12 місяців ми надаємо
дві перші копії даних безкоштовно. Також
у вас є право вимагати видалення
персональних даних. Додаткову інформацію
про ваші права та про те, як ними можна
скористатися, наведено в цій Політиці
конфіденційності (розділи «Отримання
доступу до своїх Персональних даних та
їх контроль» та «Як з нами зв'язатися
та здійснити свої права»).</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Якщо
ви вирішите скористатися будь-яким із
ваших прав, від вас потрібно підтвердити
свою особу, наприклад, шляхом підтвердження
того, що ви є власником вашої адреси
електронної пошти. Якщо ви здійснюєте
ваші права через уповноваженого агента,
нам знадобиться ваш письмовий дозвіл,
а також підтвердження вашої особи та
особи агента. Ми ні за яких обставин не
піддаватимемо вас дискримінації або
ставитися до вас неприхильно через те,
що ви скористалися якимось із своїх
прав.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">В
обмін на підписку на маркетингові
повідомлення ми можемо запропонувати
вам фінансову винагороду у вигляді
купонів, кредитних балів або інших форм
знижок. Детальна інформація про конкретне
заохочення буде представлена ​​вам у
рамках пропозиції про передплату. Ми
вважаємо оцінну вартість вашої підписки
рівної вартості заохочення, що надається,
виходячи з нашого розрахунку додаткових
витрат. Після прийняття нашої пропозиції
ви можете будь-якої миті скасувати її
без будь-яких обмежень ваших прав.
Додаткову інформацію наведено в цій
Політиці конфіденційності (у розділі
«Цілі використання персональних даних»).</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Ми
не здійснюємо продаж вашої Особистої
інформації у розумінні CCPA.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Крім
того, ви можете звернутися до нашого
представника із захисту даних з будь-яких
питань, пов'язаних з обробкою ваших
персональних даних та здійсненням ваших
прав щодо персональних даних.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>13.
Внесення змін до цієї Політики
конфіденційності</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Методи
роботи з Персональними даними можуть
змінюватись у міру розвитку нашого
бізнесу. Якщо такі зміни настануть, ми
доповнимо цю Політику конфіденційності
з метою відповідності принципам
прозорості. Ці правила можуть бути
змінені будь-коли без повідомлення.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><a name="_GoBack"></a>
<font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><u>Ця
редакція Політики конфіденційності
діє з </u></font></font><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><span lang="en-US"><u>09</u></span></font></font><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><u>.</u></font></font><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><span lang="en-US"><u>12</u></span></font></font><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><u>.2022
р.</u></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
</body>
</html>
`;
