import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Loader from '@components/Loader';
import classes from './index.module.scss';
import ReactCodeInput from 'react-code-input';
import { ActivateAccountSchema } from 'utils/formikShema';
import { Link } from 'react-router-dom';
import { ROUTES } from 'routing';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useShowMessage } from 'hooks';
const CodeForm = props => {
  const showMessage = useShowMessage();

  const { onSendCode, onSubmit, setTime, time, regUserData } = props;
  const [amountClickedResendCode, setAmout] = useState(0);
  const [errorBlockCode, setErrorBlockCode] = useState(false);
  const { t } = useTranslation();
  const handleSendCode = useCallback(async () => {
    if (amountClickedResendCode === 2) {
      showMessage({
        message: 'The number of re-sending the code is limited!',
        status: 'ERROR',
      });
    } else {
      await onSendCode(regUserData.email);
      setAmout(amountClickedResendCode + 1);
      setErrorBlockCode(false);
      setTime(600);
    }
  }, [amountClickedResendCode, regUserData.email, onSendCode, setAmout, setTime]);

  const renderTimer = () => {
    return moment(time * 1000).format('mm:ss');
  };

  useEffect(() => {
    if (time > 0) {
      const timer = setTimeout(() => {
        setTime(time - 1);
      }, 1000);

      return () => clearTimeout(timer);
    } else {
      setErrorBlockCode(true);
    }
  }, [time]);

  const {
    values,
    isValid,
    setFieldValue,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    initialValues: {
      code: '',
    },
    validationSchema: ActivateAccountSchema,
    onSubmit: onSubmit,
  });

  const codeRef = useRef();


  useEffect(() => {
    if (codeRef.current) {
      // eslint-disable-next-line
      codeRef.current.textInput.map(el => {
        el.onkeypress = function (event) {
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
          }
        };
      });
    }
  }, [codeRef]);

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.block_code}>
        <span className={classes.block_code__title}>
          {t('Two-Factor Authentication')}
        </span>
        <div className={classes.block_code__content}>
          <ReactCodeInput
            disabled={time < 1}
            ref={codeRef}
            className={classes.codeInput}
            value={values.code}
            name="code"
            onChange={value => {
              setFieldValue('code', value);
            }}
            touch={handleBlur}
            inputStyle={{
              margin: '15px',
              marginLeft: '0',
              MozAppearance: 'textfield',
              width: '50px',
              borderRadius: '10px',
              backgroundColor: 'white',
              fontSize: '20px',
              height: '50px',
              paddingLeft: values.code.length === 4 ? '18px' : '19px',
              border:
                values.code.length === 4
                  ? '1px solid #FF5F15'
                  : '1px solid #E2E2EA',
            }}
            fields={4}
            inputMode="numeric"
          />
        </div>
        <div className={classes.block_code__text}>
          {t('A message with a verification code has been send to your email.')}
        </div>
        <div className={classes.block_code__text}>
          {t('Enter the code to continue.')}
        </div>
        <div className={classes.block_code__send} onClick={handleSendCode}>
          {t('Didn`t get a verification code')}
        </div>
      </div>
      <div className={classes.block_timer}>
        <div>{t('Code validity')}</div>
        {errorBlockCode ? (
          <p onClick={handleSendCode}>{t('Resend')}</p>
        ) : (
          <span>{renderTimer()}</span>
        )}
      </div>
      <div className={classes.authButton}>
        <button type="submit" disabled={!isValid}>
          {isSubmitting ? (
            <Loader color="white" width={30} height={30} />
          ) : (
            t('Register account')
          )}
        </button>
      </div>
      <div className={classes.other_content}>
        {t('Already have an account?')}
        <span>
          &nbsp;
          <Link to={ROUTES.login}>{t('Sign In')}</Link>
        </span>
      </div>
    </form>
  );
};

export { CodeForm };
