import React, { useState, useCallback, useEffect } from "react";

import {
    EyeIcon,
    EyeCloseIcon,
    GenerateIcon
} from "src/views/icons";
import { IconButton } from "src/views/blocks";
import { TooltipComponent } from "views/blocks";
import { TextInput } from "../TextInput";


const PasswordInput = (props) => {
    const {
        rightAddons,
        value,
        generate,
        name,
        setValue,
        clicked,
        label,
        onChange,
        ...rest
    } = props;

    const [inputValue, setInputValue] = useState(value);
    const [showPassword, setShowPassword] = useState(false);
    const [showGenerate, setShowGenerate] = useState(false)
    const handleToggleShowPassword = useCallback(() => {
        setShowPassword(!showPassword);
    }, [showPassword]);


    const handleChange = useCallback((e) => {
        const {
            target: {
                value
            }
        } = e;

        value.length < 1 ? setShowGenerate(true) : setShowGenerate(false)


        setInputValue(value);

        if (onChange) {
            onChange(e);
        }
    }, [onChange]);

    function getPassword() {
        let chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%&*?ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        let passwordLength = 12;
        let password = "";
        for (let i = 0; i <= passwordLength; i++) {
            let randomNumber = Math.floor(Math.random() * chars.length);
            password += chars.substring(randomNumber, randomNumber + 1);
        }
        setInputValue(password.concat('!1Ab'))
        onChange(password.concat('!1Ab'))
        !clicked && setValue(password.concat('!1Ab'))
        setShowPassword(true);
        setShowGenerate(false)
    }
    useEffect(() => {
        if (clicked) {
            setInputValue(clicked)
            onChange(clicked)
            setShowGenerate(false)
        }
        //eslint-disable-next-line
    }, [clicked])

    return (
        <div className={generate && "position-relative"}>
            <TextInput
                {...rest}
                type={showPassword ? "text" : "password"}
                autoComplete="off"
                onFocus={() => name !== 'confirmPassword' && inputValue ? inputValue.length < 1 && setShowGenerate(true) : setShowGenerate(true)}
                rightAddons={
                    <React.Fragment>
                        {name !== 'confirmPassword' && generate && showGenerate && <GenerateIcon
                            type="button"
                            tabIndex={-1}
                            size="small"
                            color="primary"
                            icon={GenerateIcon}
                            onClick={getPassword} />}
                        {!!value && (
                            <IconButton
                                type="button"
                                tabIndex={-1}
                                size="small"
                                color="primary"
                                icon={!showPassword ? EyeIcon : EyeCloseIcon}
                                onClick={handleToggleShowPassword} />
                        )}
                        {rightAddons}
                    </React.Fragment>
                }
                value={inputValue}
                onChange={handleChange} />
            {name !== 'confirmPassword' && generate && showGenerate && <TooltipComponent text={label} />}
        </div>
    );
};


export { PasswordInput };