import React, { forwardRef } from 'react';
import clsx from 'clsx';

import './index.scss';

const Typography = forwardRef((props, ref) => {
  const {
    component: Component = 'p',
    className = '',
    color = '',
    variant = '',
    align = 'left',
    bold = false,
    nowrap = false,
    weight = 'normal',
    pointer = false,
    inline = false,
    ...rest
  } = props;

  const modifications = clsx({
    [`typography--variant-${variant}`]: !!variant,
    [`typography--color-${color}`]: !!color,
    [`typography--weight-${weight}`]: !!weight,
    'typography--bold': bold,
    'typography--inline': inline,
    'typography--nowrap': nowrap,
    'cursor-pointer': pointer,
    [`typography--align-${align}`]: !!align,
  });

  return (
    <Component
      {...rest}
      ref={ref}
      className={`typography ${className} ${modifications}`}
    />
  );
});

export { Typography };
