import { useState, useEffect } from 'react';

import { axiosInstance } from 'src/store/helpers';

const useTimezonesApi = () => {
  const [timezones, setTimezones] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const res = await axiosInstance.get('/auth/timezones', {
          validateStatus: () => true,
        });

        if (res.status === 200) {
          setTimezones(res.data);
        } else {
          setTimezones([]);
        }
      } catch (err) {
        console.error(err);
        setTimezones([]);
      }
    })();
  }, []);

  return timezones;
};

export { useTimezonesApi };
