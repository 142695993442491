import { SET_PARTNERS } from './constants';

const initialState = {
  partners: {
    items: [
      {
        name: 'ad_mixer',
        url: 'https://inv-nets.admixer.net/dsp.aspx?rct=3&zone=5a8a75f5-80a9-473c-beb8-ad9275592da8&zoneInt=114799&sect=55909&site=49528&rnd=[CACHEBUSTING]&domain=infocoin.online',
      },
      {
        name: 'ad_partner',
        url: 'https://amusingsnow.pro/dxm.FDzcd/GJNsv/ZtGpUG/oeymU9SukZXU/l/kLP-TSQm2cNjjuQb2nNTT/k/tSNsDNYj2sNJD/Yx2yMBC/Z/yncY3TJNjBPLWyFRkHcj2FcP?tb=https%3A%2F%2Fa.labadena.com%2Fapi%2Fback%2Fddj5djd4xb&sId=infocoinonlinemainstreamnonadult',
        amount: 0.00051,
      },
      {
        name: 'ad_google',
        url: 'https://pubads.g.doubleclick.net/gampad/ads?iu=/21902364955,22890795948/CM_Infocoin_Type_Tech_InApp_And_Web_Top/CM_Infocoin_Type_Tech_InApp_And_Web_Out-Stream_Video_Skippable&description_url=http%3A%2F%2Finfocoin.com&tfcd=0&npa=0&sz=400x300%7C640x480&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s',
        amount: 0.00051,
      },
    ],
  },
};

export function partnersReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_PARTNERS:
      return {
        ...state,
        partners: { ...state.partners, items: payload },
      };
    default:
      return state;
  }
}
