import { SET_FOLLOWS, SET_CATEGORIES } from '../constants';

const initialState = {
  categories: [],
  follows: [],
};

export function categoriesReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_CATEGORIES: {
      return { ...state, categories: payload };
    }
    case SET_FOLLOWS: {
      return { ...state, follows: payload };
    }
    default:
      return state;
  }
}
