import React from 'react';
import clsx from 'clsx';

import { useThemeProps } from 'src/hooks';

import './index.scss';

const IconButton = props => {
  const {
    theme,
    component: Component = 'button',
    className = '',
    icon: Icon,
    circle,
    color = '',
    size = 'medium',
    ...rest
  } = useThemeProps(props);

  const modifications = clsx({
    [`icon-button--size-${size}`]: !!size,
    [`icon-button--color-${color}`]: !!color,
    [`icon-button--circle-${circle}`]: !!circle,
  });

  return (
    <Component
      {...rest}
      className={`icon-button icon-button--${theme} ${className} ${modifications}`}
    >
      <Icon size={size} />
    </Component>
  );
};

export { IconButton };
