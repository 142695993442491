import React from "react";

import {Icon} from "src/views/blocks";

import "./index.scss";


const ArrowBackIcon = (props) => {
    const {
        className = "",
        ...rest
    } = props;

    return (
        <Icon
          {...rest}
          className={`arrow-back-icon ${className}`}
          mask />
    );
};


export {ArrowBackIcon};