import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-locize-backend";
import {locizePlugin} from "locize";
import LastUsed from "locize-lastused";

import {
    IS_PRODUCTION,
    LOCIZE_API_KEY,
    LOCIZE_PROJECT_ID
} from "src/config";
import countries from "src/data/countries.json";


const locizeOptions = {
    projectId: LOCIZE_PROJECT_ID,
    apiKey: !IS_PRODUCTION ? LOCIZE_API_KEY : undefined, // The API key should only be used in development, not in production. You should not expose your apps API key to production!!!
    referenceLng: "en"
};

i18n.use(Backend).use(LastUsed).use(locizePlugin).use(LanguageDetector).use(initReactI18next).init({
    debug: !IS_PRODUCTION,
    supportedLngs: countries.map((country) => {
        return country.lang;
    }),
    fallbackLng: "en",
    saveMissing: !IS_PRODUCTION && true,
    backend: locizeOptions,
    locizeLastUsed: locizeOptions
});


export {i18n};
