import React, { useCallback, useState } from 'react';

import alarm from 'src/assets/icons/alarm_alert.svg';
import { useThemeProps } from 'src/hooks';

import './index.css';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Popover } from '@mui/material';
import { notificationsSelector } from 'src/store/modules/notifiactions/selectors';

export default function Reminder(props) {
  const { theme } = useThemeProps(props);

  const [readedAlerts, setReadedAlerts] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = useCallback(event => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const { t } = useTranslation();

  const notificationsIsOpen = Boolean(anchorEl);
  const id = notificationsIsOpen ? 'notifications-popover' : undefined;
  const notifications = useSelector(notificationsSelector);

  return (
    <>
      <div
        className="reminder-container position-relative"
        onClick={event => {
          setReadedAlerts(true);
          handleClick(event);
        }}
      >
        <img
          style={{ filter: theme === 'dark' ? 'invert(1)' : 'invert(0)' }}
          width={22}
          height={25}
          className="cursor-pointer"
          alt="alerts"
          src={alarm}
        />
        {notifications.length > 0 && !readedAlerts && (
          <span className="reminder-notification-alert">!</span>
        )}
      </div>
      <Popover
        id={id}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        sx={{
          marginTop: '10px',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        marginThreshold={12}
        PaperProps={{
          sx: {
            backgroundColor: 'transparent !important',
          },
        }}
        classes={{
          root: 'notificationOverlay',
          paper: 'worst-overload-paper',
        }}
        open={notificationsIsOpen}
        onClose={handleClose}
      >
        <div className="notificationDropDownContainer">
          {notifications.length ? (
            notifications.map((msg, id) => (
              <span key={id} className="notificationItem">
                {msg}
              </span>
            ))
          ) : (
            <span className="notificationItem">
              {t("You don't have notifications")}
            </span>
          )}
        </div>
      </Popover>
    </>
  );
}
