import React from 'react';
import { components } from 'react-select';
import { IconButton } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const { ClearIndicator: RSClearIndicator } = components;

const ClearIndicator = props => {
  return (
    <RSClearIndicator {...props}>
      <IconButton>
        <CloseOutlinedIcon sx={{ fontSize: 18 }} />
      </IconButton>
    </RSClearIndicator>
  );
};

export { ClearIndicator };
