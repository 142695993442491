import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogActions, DialogTitle, Grid } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Button, Dialog, IconButton } from 'src/views/blocks';
import { CloseIcon } from 'src/views/icons';

import './index.scss';
import { agreement } from '../../../data/agreement';

const AgreementModal = props => {
  const { open = false, onAccept, onCancel, lightTheme = false } = props;

  const { t, i18n } = useTranslation();

  const [activeLanguage, setActive] = useState(
    agreement[i18n.language] ? i18n.language : 'en',
  );
  const [language, setLanguage] = useState(
    agreement[i18n.language] ? i18n.language : 'en',
  );

  const onChangeLanguage = value => {
    setActive(value);
    setLanguage(value);
  };

  useEffect(() => {
    if (i18n.language !== language) {
      if (agreement[i18n.language]) {
        setLanguage(i18n.language);
        setActive(i18n.language);
      } else {
        setLanguage('en');
        setActive('en');
      }
    }
  }, [i18n.language]);

  const [tab, setTab] = useState(0);

  const handleAccept = useCallback(() => {
    if (onAccept) {
      onAccept();
    } else {
      onCancel();
    }
  }, [onAccept]);

  const handleCancel = useCallback(() => {
    if (onCancel) {
      onCancel();
    }
  }, [onCancel]);

  return (
    <Dialog lightTheme={lightTheme} className={`terms-modal`} open={open}>
      <DialogTitle>
        <Grid
          container
          justifyContent="space-between"
          flexWrap="wrap-reverse"
          className="doc_header_block"
        >
          <Grid item display="flex" alignItems="center">
            <div className="modal_language">
              {t('Document language: ')}
              <span
                onClick={() => onChangeLanguage('uk')}
                className={activeLanguage === 'uk' ? 'active_language' : ''}
              >
                UKR
              </span>
              /
              <span
                onClick={() => onChangeLanguage('en')}
                className={activeLanguage === 'en' ? 'active_language' : ''}
              >
                ENG
              </span>
              /
              <span
                onClick={() => onChangeLanguage('de')}
                className={activeLanguage === 'de' ? 'active_language' : ''}
              >
                DE
              </span>
            </div>
          </Grid>

          <Grid item>
            <IconButton
              // size=""
              theme={lightTheme ? 'light' : ''}
              icon={CloseIcon}
              onClick={handleCancel}
            />
          </Grid>
        </Grid>
      </DialogTitle>

      <PerfectScrollbar theme="light">
        <div
          className="terms-modal__content"
          dangerouslySetInnerHTML={{
            __html: agreement[language] || agreement.en,
          }}
        />
      </PerfectScrollbar>
      <DialogActions>
        <Grid container className="mb-2" justifyContent="center">
          <Grid item>
            <Button variant="primary" onClick={handleAccept}>
              {t('Close')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export { AgreementModal };
