import { SET_PLAYER_QUALITY, SET_PLAYER_VOLUME } from '../constants';

const initialState = {
  volume: 50,
  quality: '480',
};

export function playerReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_PLAYER_VOLUME:
      return {
        ...state,
        volume: payload,
      };
    case SET_PLAYER_QUALITY:
      return {
        ...state,
        quality: payload,
      };
    default:
      return state;
  }
}
