import React, {
    useEffect,
    useCallback,
    useImperativeHandle,
    forwardRef
} from "react";
import {useForm, FormProvider} from "react-hook-form";

import {useShowMessage} from "src/hooks";


const Form = forwardRef((props, ref) => {
    const {
        children,
        className = "",
        mode = "all",
        defaultValues,
        onChange,
        onSubmit
    } = props;

    const showMessage = useShowMessage();
    const formProps = useForm({
        mode,
        defaultValues,
        shouldUnregister: true,
        reValidateMode: "onChange"
    });

    const handleChange = useCallback(async (data) => {
        try {
            if(onChange) {
                await Promise.resolve(onChange(data));
            }
        }
        catch(err) {
            showMessage({
                status: "ERROR",
                message: err.message
            });
        }
    }, [showMessage, onChange]);

    const handleSubmit = useCallback(async (data) => {
        try {
            if(onSubmit) {
                await Promise.resolve(onSubmit(data));
            }
        }
        catch(err) {
            showMessage({
                status: "ERROR",
                message: err.message
            });
        }
    }, [showMessage, onSubmit]);

    const handleWatch = useCallback(async (data) => {
        const {
            formState: {
                isSubmitting
            },
            handleSubmit: handleFormSubmit
        } = formProps;

        await handleChange(data);

        if(mode === "onChange" && !isSubmitting) {
            if(await formProps.trigger()) {
                await handleFormSubmit(handleSubmit)();
            }
        }

    }, [mode, formProps, handleSubmit, handleChange]);

    useEffect(() => {
        const res = formProps.watch(handleWatch);

        return () => res.unsubscribe();
    }, [formProps, handleWatch]);

    useImperativeHandle(ref, () => {
        return formProps;
    }, [formProps]);

    return (
        <FormProvider {...formProps}>
            <form
              className={className}
              onSubmit={formProps.handleSubmit(handleSubmit)}>
                {children}
            </form>
        </FormProvider>
    );
});


export {Form};