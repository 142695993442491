import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { uiActions } from 'src/store';
import { useThemeProps } from 'src/hooks';

import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';

import './index.scss';

const SwitchTheme = props => {
  const { theme } = useThemeProps(props);

  const dispatch = useDispatch();

  const handleSwitch = useCallback(
    async e => {
      const {
        target: { checked },
      } = e;

      dispatch(uiActions.setTheme(checked ? 'dark' : 'light'));
    },
    [dispatch],
  );

  const MaterialUISwitch = styled(Switch)(() => ({
    width: 54,
    height: 28,
    padding: 7,
    '& .MuiSwitch-switchBase': {
      margin: 1,
      padding: 0,
      transform: 'translateX(6px)',
      '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(22px)',
        '& .MuiSwitch-thumb:before': {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
            '#000',
          )}" d="M12 21.8a9.796 9.796 0 0 1-1.245-19.513l1.286-.163-.843.984a8.285 8.285 0 0 0 8.519 13.383l1.252-.347-.696 1.096A9.755 9.755 0 0 1 12 21.8zM9.647 3.526a8.796 8.796 0 1 0 9.031 14.196 9.048 9.048 0 0 1-1.178.078A9.293 9.293 0 0 1 9.647 3.526z"/></svg>')`,
        },
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: 'var(--disabled)',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor:
        theme === 'dark' ? '#fff' : 'var(--primary-color)',
      width: 25,
      height: 25,
      '&:before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
      },
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: 'var(--disabled)',
      borderRadius: 20 / 2,
    },
  }));

  return (
    <div>
      <MaterialUISwitch checked={theme === 'dark'} onChange={handleSwitch} />
    </div>
    // <label className={`switch-them8 switch-theme--${theme}`}>
    //   <input
    //     type="checkbox"
    //     checked={theme === 'dark'}
    //     onChange={handleSwitch}
    //   />
    //   <span className="switch-theme__slider" />
    // </label>
  );
};

export { SwitchTheme };
