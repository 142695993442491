import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Avatar, Grid } from '@mui/material';

import videoLogo from 'src/assets/images/default_video_logo.jpg';
import { ROUTES } from 'src/routing';
import { useQuery } from 'src/hooks';
import { DashboardLayout } from 'src/views/layouts';
import { IconButton, Typography } from 'src/views/blocks';
import { AngleLeftIcon } from 'src/views/icons';

import './index.scss';
import { getHistory } from '@services/api/user';
import { Spinner } from 'react-bootstrap';

import moment from 'moment/min/moment-with-locales';

import { useTranslation } from 'react-i18next';

import HistoryItem from '@components/History/HistoryItem';
import DrawerInfo from '../HistoryPage/blocks/DrawerInfo';

const CompanyPage = () => {
  const { t, i18n } = useTranslation();

  moment.locale(i18n.language);

  const { avatar, title, description, header } = useQuery();
  const { id } = useParams();
  const [historyList, setHistoryList] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const [drawerInfo, setDrawerInfo] = useState(null);

  const isOpenDrawer = !!drawerInfo;

  const filteredVideo = useMemo(() => {
    let initialVideos = historyList;

    const formatVideos = initialVideos.map(item => {
      const { video } = item;

      const year = new Date(item.updated_at).getFullYear();
      const month = new Date(item.updated_at).getMonth();
      const day = new Date(item.updated_at).getDate();

      const currentDay = moment(new Date(year, month, day, 3, 0, 0, 0)).unix();

      return {
        day: currentDay,
        current_date: moment(item.updated_at).format('DD MMMM YYYY'),
        video: {
          video_categories: item.video_categories,
          ...video,
          created_at: item.updated_at,
        },
      };
    });

    const result = Object.values(
      formatVideos.reduce((r, cur) => {
        const key = 'd' + cur['day']; // символ "d" добавлен, чтобы автоматически не сортировало по цифровым ключам
        (r[key] = r[key] || []).push(cur);

        return r;
      }, {}),
    );

    return result;
  }, [historyList, moment, t]);

  const updateGetHistory = useCallback(
    (limit, offset) => {
      setLoading(true);
      getHistory(limit, offset)
        .then(res => {
          const { data } = res;

          setHistoryList(
            data.filter(v => Number(v.video.company.id) === Number(id)),
          );
          setLoading(false);
        })
        .catch(err => {
          console.error(err);
        });
    },
    [id],
  );

  useEffect(() => {
    updateGetHistory(100, 0);
  }, []);
  return (
    <DashboardLayout
      className="company-page"
      beforeContent={
        <React.Fragment>
          <div
            style={{
              width: '100%',
              height: '200px',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundImage: `url('${header || '/images/company-bg.png'}')`,
            }}
          >
            <div className="dashboard-layout__content">
              <IconButton
                component={Link}
                size="large"
                color="primary"
                icon={AngleLeftIcon}
                to={ROUTES.home}
              />
            </div>
          </div>
        </React.Fragment>
      }
    >
      <div className="company-page__header">
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <Avatar className="company-page__logo" src={avatar || videoLogo} />
          </Grid>

          <Grid item>
            <Typography variant="h3" weight="semi-bold">
              {title || ''}
            </Typography>
          </Grid>
        </Grid>
      </div>

      <Typography className="py-5">{description || ''}</Typography>
      {isLoading ? (
        <Grid item justifyContent="center">
          <Spinner animation="border" variant="warning" />
        </Grid>
      ) : (
        <Grid>
          {filteredVideo.map(item => {
            return (
              <HistoryItem
                key={item.id}
                t={t}
                item={item}
                setInfo={setDrawerInfo}
              />
            );
          })}
        </Grid>
      )}
      <DrawerInfo
        open={isOpenDrawer}
        video={drawerInfo}
        onChange={() => setDrawerInfo(null)}
      />
    </DashboardLayout>
  );
};

export { CompanyPage };
