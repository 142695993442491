import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthTextField } from 'views/blocks/AuthTextField';
import { useFormik } from 'formik';
import { ForgotPasswordSchema } from 'utils/formikShema';
import { Grid } from '@mui/material';
import Loader from '@components/Loader';
const PasswordForm = ({ onSubmit }) => {
  const { t } = useTranslation();
  const {
    values,
    isValid,
    handleChange,
    setFieldValue,
    handleSubmit,
    handleBlur,
    isSubmitting,
  } = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: ForgotPasswordSchema,
    onSubmit: onSubmit,
  });
  return (
    <form onSubmit={handleSubmit}>
      <AuthTextField
        classNameContainer="mt-2"
        name="password"
        onChange={handleChange}
        label={t('Password')}
        type="password"
        value={values.password}
      />
      <AuthTextField
        classNameContainer="mt-2"
        name="confirmPassword"
        onChange={handleChange}
        label={t('Confirm password')}
        type="password"
        value={values.confirmPassword}
      />
      <Grid fontSize="10px" color="#9596A2" marginTop="10px">
        {t(
          'The password must consist of: at least 8 characters, a lowercase character, an uppercase character, a number and a special character (!,@,#,$,% etc.)',
        )}
      </Grid>
      <Grid className="authButton" marginTop="100px">
        <button type="submit" disabled={!isValid}>
          {isSubmitting ? (
            <Loader color="white" width={30} height={30} />
          ) : (
            t('Submit')
          )}
        </button>
      </Grid>
    </form>
  );
};

export { PasswordForm };
