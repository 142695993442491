import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getCategories, getUserCategories } from '@services/api/user';

import { PageHeader, PageLoader } from 'src/views/blocks';
import { DashboardLayout } from 'src/views/layouts';

import { useSelector, useDispatch } from 'react-redux';

import { CategoryItem } from './blocks';

import {
  setCategoriesAction,
  setFollowsAction,
} from '../../../store/modules/categories/actions';

import 'styles/components/HomeContainer.scss';
import './index.scss';

const CategoryPage = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { categories, follows } = useSelector(state => state.categories);


  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        if (!categories.length) {
          const resCategories = await getCategories();

          dispatch(setCategoriesAction(resCategories.data));
        }

        if (!follows.length) {
          const resUserCategories = await getUserCategories();

          dispatch(setFollowsAction(resUserCategories.data));
        }

        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  return (
    <DashboardLayout className="category-page">
      <PageHeader variant="primary" title={t('Categories')} />

      <div className="mt-4">
        {isLoading && <PageLoader />}

        {!isLoading && (
          <div className="category-page__list">
            {categories.map(category => {
              return (
                <CategoryItem
                  key={category.id}
                  follows={follows}
                  category={category}
                />
              );
            })}
            <div
              style={{
                height: '210px',
                width: '200px',
              }}
            />
            <div
              style={{
                height: '210px',
                width: '200px',
              }}
            />
            <div
              style={{
                height: '210px',
                width: '200px',
              }}
            />
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};

export { CategoryPage };
