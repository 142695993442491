import React from "react";

import {useThemeProps} from "src/hooks";
import logoLight from "src/assets/images/logo-light.gif";
import logoDark from "src/assets/images/logo-dark.png";


const LogoLarge = (props) => {
    const {
        className = "",
        theme
    } = useThemeProps(props);

    return (
        <img
          className={className}
          alt=""
          src={theme === "dark" ? logoDark : logoLight} />
    );
};


export {LogoLarge};