import React, { useCallback, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { useFormik } from 'formik';
import { ActivateAccountSchema } from 'utils/formikShema';
import Loader from '@components/Loader';
import ReactCodeInput from 'react-code-input';
import './index.scss';
const CodeForm = ({ onSubmit, onSendCode }) => {
  const { t } = useTranslation();

  const {
    values,
    isValid,
    handleChange,
    setFieldValue,
    handleSubmit,
    handleBlur,
    isSubmitting,
  } = useFormik({
    initialValues: {
      code: '',
    },
    validationSchema: ActivateAccountSchema,
    onSubmit: onSubmit,
  });
  const codeRef = useRef();

  useEffect(() => {
    if (codeRef.current) {
      // eslint-disable-next-line
      codeRef.current.textInput.map(el => {
        el.onkeypress = function (event) {
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
          }
        };
      });
    }
  }, [codeRef]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid fontSize="16px" color="#9596A2" marginTop="15px">
        <p>{t('Two-Factor Authentication')}</p>
      </Grid>
      <Grid>
        <ReactCodeInput
          ref={codeRef}
          value={values.code}
          name="code"
          onChange={value => {
            setFieldValue('code', value);
          }}
          touch={handleBlur}
          inputStyle={{
            margin: '15px',
            marginLeft: '0',
            MozAppearance: 'textfield',
            width: '50px',
            borderRadius: '10px',
            backgroundColor: 'white',
            fontSize: '20px',
            height: '50px',
            paddingLeft: values.code.length === 4 ? '18px' : '19px',
            border:
              values.code.length === 4
                ? '1px solid #FF5F15'
                : '1px solid #E2E2EA',
          }}
          fields={4}
          inputMode="numeric"
        />
      </Grid>
      <Grid fontSize="12px" color="#9596A2" marginTop="15px">
        {t(
          'A message with a verification code has been send to your email. Enter the code to continue.',
        )}
      </Grid>
      <Grid
        fontSize="12px"
        className="button_send_code"
        color="#FF5F15"
        marginTop="20px"
        onClick={onSendCode}
      >
        {t('Didn`t get a verification code')}
      </Grid>
      <Grid className="authButton" marginTop="200px">
        <button type="submit" disabled={!isValid}>
          {isSubmitting ? (
            <Loader color="white" width={30} height={30} />
          ) : (
            t('Verify')
          )}
        </button>
      </Grid>
    </form>
  );
};

export { CodeForm };
