import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { AuthTextField } from 'views/blocks/AuthTextField';
import { useFormik } from 'formik';
import { EmailSchema } from 'utils/formikShema';
import Loader from '@components/Loader';
import './index.scss';
const EmailForm = ({ onSubmit }) => {
  const { t } = useTranslation();
  const { values, isValid, handleChange, handleSubmit, isSubmitting } =
    useFormik({
      initialValues: {
        email: '',
      },
      validationSchema: EmailSchema,
      onSubmit: onSubmit,
    });
  return (
    <form onSubmit={handleSubmit}>
      <AuthTextField
        name="email"
        onChange={handleChange}
        label={t('Email')}
        type="email"
        value={values.email}
      />
      <Grid fontSize="12px" color="#9596A2" marginTop="15px">
        <p>{t('Enter the e-mail you used during registration')}</p>
      </Grid>
      <Grid className="authButton" marginTop="200px">
        <button type="submit" disabled={!isValid}>
          {isSubmitting ? (
            <Loader color="white" width={30} height={30} />
          ) : (
            t('Submit')
          )}
        </button>
      </Grid>
    </form>
  );
};

export { EmailForm };
