import {isValid} from "date-fns";


const getDaysInMonth = (year, month) => {
    const date = new Date(year, month, 0);

    if(isValid(date)) {
        return date.getDate();
    }

    return 0;
};


export {getDaysInMonth};