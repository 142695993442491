import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Collapse } from '@mui/material';

import { ROUTES } from 'src/routing';
import { useThemeProps } from 'src/hooks';
import { Typography, Icon } from 'src/views/blocks';

import './index.scss';

const MenuItem = props => {
  const {
    theme,
    isAuthenticated,
    expanded = false,
    isPrivated,
    icon: ItemIcon = Icon,
    label = '',
    path = ROUTES.home,
    href,
    ...rest
  } = useThemeProps(props);

  const { t } = useTranslation();

  const Component = href ? 'a' : NavLink;

  const modifications = clsx({
    'menu-item--expanded': expanded,
  });

  return (
    <Component
      {...(href
        ? {
            target: '_blank',
            href,
          }
        : {
            to: path,
            activeClassName: 'menu-item--active',
            ...rest,
          })}
      className={`menu-item menu-item--${theme} ${modifications}`}
    >
      <ItemIcon className="menu-item__icon" size="medium" />

      <Collapse orientation="horizontal" in={expanded}>
        <Typography
          className="menu-item__label"
          variant="h5"
          weight="semi-bold"
        >
          {t(label)}
        </Typography>
      </Collapse>
    </Component>
  );
};

export { MenuItem };
