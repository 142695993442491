import React, {
  useState,
  useCallback,
  forwardRef,
  useRef,
  useEffect,
  useImperativeHandle,
} from 'react';

import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import { setVolumeAction } from 'store/modules/player/actions';

import clsx from 'clsx';
import { Grid, Tooltip, Typography } from '@mui/material';

import { formatTime } from 'src/utils';
import {
  VolumeUpIcon,
  VolumeOffIcon,
  PictureInPictureIcon,
  FullscreenIcon,
  FullScreenExitIcon,
  PlayIcon,
} from 'src/views/icons';

import { PlayerSeek, PlayerVolume } from 'src/views/blocks/Player/blocks';

import './PartnersPlayer.module.scss';

import { useTranslation } from 'react-i18next';
import classes from './PartnersPlayer.module.scss';
import { IconButton } from '../../../../blocks';

const PartnersPlayer = forwardRef((props, ref) => {
  const {
    video,
    onEnded,
    onPlay,
    onPause,
    onProgress,
    onMute,
    onFullscreen,
    onCanPlay,
    onError,
    withoutInterface,
  } = props;
  const { volume } = useSelector(state => state.player, shallowEqual);

  const videoRef = useRef();
  const rootRef = useRef();

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [fullscreen, setFullscreen] = useState(false);
  const [pictureInPicture, setPictureInPicture] = useState(false);
  const [time, setTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [process, setProcess] = useState(0);
  const [paused, setPaused] = useState(true);

  const actionPause = useCallback(() => {
    if (videoRef.current) {
      setPaused(true);
      videoRef.current.pause();
    }
  }, []);

  const actionPlay = useCallback(() => {
    if (videoRef.current) {
      setPaused(false);
      const promise = videoRef.current.play();
      promise
        .then(_ => {})
        .catch(() => {
          actionPause();
          setTimeout(actionPlay, 50);
        });
    }
  }, [actionPause]);

  const handleTogglePlay = useCallback(() => {
    if (paused) {
      actionPlay();
      onPlay();
    } else {
      onPause();
      actionPause();
    }
  }, [paused, actionPlay, onPlay, onPause, actionPause]);

  const handleTimeUpdate = useCallback(
    e => {
      const {
        target: { currentTime, duration, buffered },
      } = e;

      let process = 0;

      if (buffered && buffered.length) {
        process = buffered.end(buffered.length - 1) / duration || 0;
      }
      onProgress(currentTime);
      setTime(currentTime);
      setDuration(duration);
      setProcess(process * 100);
    },
    [onProgress],
  );

  const handleVolumeToggle = useCallback(() => {
    volume === 0 ? dispatch(setVolumeAction(50)) : dispatch(setVolumeAction(0));
  }, [volume, dispatch]);

  const handleVolumeChange = useCallback(
    volume => {
      dispatch(setVolumeAction(volume));
    },
    [dispatch],
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (videoRef.current) {
        if (volume === 0) {
          videoRef.current.muted = true;
          videoRef.current.volume = volume / 100;
          onMute(true);
        } else {
          videoRef.current.muted = false;
          videoRef.current.volume = volume / 100;
          onMute(false);
        }
      }
    }, 100);

    return () => clearTimeout(timeout);
  }, [volume]);

  useEffect(() => {
    if (videoRef.current) {
      const userAgent = window.navigator.userAgent;

      if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
        // iPad or iPhone
        if (pictureInPicture) {
          if (
            videoRef.current.webkitSupportsPresentationMode &&
            typeof videoRef.current.webkitSetPresentationMode === 'function'
          ) {
            // Toggle PiP when the user clicks the button.
            videoRef.current.webkitSetPresentationMode(
              videoRef.current.webkitPresentationMode === 'picture-in-picture'
                ? 'inline'
                : 'picture-in-picture',
            );
          }
        }
        if (!pictureInPicture) {
          if (
            videoRef.current.webkitSupportsPresentationMode &&
            typeof videoRef.current.webkitSetPresentationMode === 'function'
          ) {
            // Toggle PiP when the user clicks the button.
            videoRef.current.webkitSetPresentationMode('inline');
          }
        }
      } else {
        if (pictureInPicture) {
          videoRef.current.requestPictureInPicture().catch(err => {
            console.error(err);
          });
        }
        if (
          !pictureInPicture &&
          document.pictureInPictureElement === videoRef.current
        ) {
          document.exitPictureInPicture().catch(err => {
            console.error(err);
          });
        }
      }
    }
  }, [pictureInPicture]);

  useEffect(() => {
    const { current: video } = videoRef;

    if (video) {
      video.addEventListener('enterpictureinpicture', () => {
        setPictureInPicture(true);
      });
      video.addEventListener('leavepictureinpicture', () => {
        setPictureInPicture(false);
      });

      return () => {
        const { current: video } = videoRef;

        if (video) {
          video.removeEventListener('enterpictureinpicture', () => {
            setPictureInPicture(true);
          });
          video.removeEventListener('leavepictureinpicture', () => {
            setPictureInPicture(false);
          });
        }
      };
    }
  }, []);

  useEffect(() => {
    navigator.mediaSession.setActionHandler('play', function () {});
    navigator.mediaSession.setActionHandler('pause', function () {});
  }, []);

  const handleFullscreen = useCallback(() => {
    onFullscreen(!fullscreen);
    setFullscreen(!fullscreen);
  }, [fullscreen, onFullscreen]);

  const handlePictureInPictureToggle = useCallback(() => {
    setPictureInPicture(!pictureInPicture);
  }, [pictureInPicture]);

  const modifications = clsx({
    [classes.videoContainerFullscreen]: fullscreen,
    [classes.height]: withoutInterface,
  });

  useEffect(() => {
    function fullScreen(element) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitEnterFullscreen) {
        element.webkitEnterFullscreen();
      } else if (element.mozRequestFullscreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      }
    }
    function fullScreenCancel(element) {
      if (element.webkitExitFullscreen) {
        element.webkitExitFullscreen();
      } else if (element.mozExitFullscreen) {
        element.mozExitFullscreen();
      } else if (element.exitFullscreen) {
        element.exitFullscreen();
      } else {
        document.exitFullscreen();
      }
    }
    if (rootRef.current) {
      if (fullscreen) {
        fullScreen(rootRef.current);
      } else if (document.fullscreenElement === rootRef.current) {
        fullScreenCancel(document);
      }
    }
  }, [fullscreen]);

  const handlePlay = useCallback(
    e => {
      const {
        target: { paused },
      } = e;
      onPlay();
      setPaused(paused);
    },
    [onPlay],
  );

  const handlePause = useCallback(
    e => {
      const {
        target: { paused },
      } = e;
      onPause();
      setPaused(paused);
    },
    [onPause],
  );

  const handleEnded = useCallback(() => {
    setPaused(true);
    setFullscreen(false);
    onEnded();
  }, [onEnded]);

  useImperativeHandle(
    ref,
    () => {
      return {
        goPlay: actionPlay,
      };
    },
    [actionPlay],
  );

  return (
    <div ref={rootRef} className={clsx(classes.player, modifications)}>
      <div className={classes.videoContainer}>
        <div className={classes.video}>
          <video
            className={clsx(classes.video, modifications)}
            ref={videoRef}
            playsInline
            webkit-playsinline={true}
            autoPlay={false}
            loop={false}
            src={video.fileURL}
            controls={false}
            disableRemotePlayback={true}
            onProgress={handleTimeUpdate}
            onTimeUpdate={handleTimeUpdate}
            onPlay={handlePlay}
            onPause={handlePause}
            onEnded={handleEnded}
            onCanPlay={onCanPlay}
            onError={onError}
          />
          <div
            className={classes.overlay}
            onClick={handleTogglePlay}
            onDoubleClick={handleFullscreen}
          >
            {paused && (
              <div className={classes.playContainer}>
                <PlayIcon size="extra" className={classes.playIcon} />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={classes.player__footer}>
        <PlayerSeek
          ariaLabel="Seek video"
          percentagePlayed={(time.toFixed() / duration.toFixed()) * 100}
          percentageBuffered={process}
        />

        <Grid container justifyContent="space-between">
          <Grid item container spacing={2} xs={4}>
            <Grid
              item
              className={clsx(classes.iconWrapper, classes.volumeContainer)}
            >
              <Tooltip title={t('Volume')}>
                <div>
                  <IconButton
                    size="small"
                    className={classes.mediaIcons}
                    color={'white'}
                    icon={volume > 0 ? VolumeUpIcon : VolumeOffIcon}
                    onClick={handleVolumeToggle}
                  />
                </div>
              </Tooltip>
              <div className={classes.volumeSlider}>
                <PlayerVolume value={volume} onChange={handleVolumeChange} />
              </div>
            </Grid>
          </Grid>

          <Grid item container xs={4} justifyContent="center">
            <Grid item className="icon-container">
              <Typography align="center" color="white">
                {formatTime(time)} / {formatTime(duration)}
              </Typography>
            </Grid>
          </Grid>

          <Grid item container xs={4} spacing={2} justifyContent="flex-end">
            <Grid item className={classes.iconContainer}>
              <Tooltip title={t('Picture in Picture')}>
                <div>
                  <IconButton
                    size="medium"
                    className={classes.mediaIcons}
                    color={pictureInPicture ? 'primary' : 'white'}
                    icon={PictureInPictureIcon}
                    onClick={handlePictureInPictureToggle}
                  />
                </div>
              </Tooltip>
            </Grid>

            <Grid
              item
              className={clsx(classes.iconContainer, classes.fullscreenMedia)}
            >
              <Tooltip title={t('Fullscreen')}>
                <div>
                  <IconButton
                    size="small"
                    color="white"
                    className={classes.mediaIcons}
                    icon={fullscreen ? FullScreenExitIcon : FullscreenIcon}
                    onClick={handleFullscreen}
                  />
                </div>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
});

export default PartnersPlayer;
