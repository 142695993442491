import React from "react";
import clsx from "clsx";
import { Grid } from "@mui/material";

import square from "src/assets/icons/square.gif";

import "./index.scss";


const CountryOption = (props) => {
    const {
        showLabel = false,
        option
    } = props;

    const imageClass = clsx({
        "country-icons_title": showLabel
    });

    let src = square;

    if (option) {
        option.iso ?
            src = `https://purecatamphetamine.github.io/country-flag-icons/3x2/${option.iso}.svg` :
            src = 'https://i.ibb.co/qNpJL1w/image.png'
    }

    return (
        <Grid container justifyContent={!showLabel ? "center" : "flex-start"} alignContent="center">
            <Grid item flexBasis={35} alignSelf="center">
                <img
                    className={`country-option__icon ${imageClass}`}
                    width={35}
                    height={15}
                    alt={option ? option.iso : ''}
                    src={src} />
            </Grid>

            {showLabel && option && (
                <Grid item className="mx-1" alignSelf="center" flex={1}>
                    {option.label}
                </Grid>
            )}
        </Grid>
    );
};


export { CountryOption };