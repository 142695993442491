import { axiosInstance } from '../../../store/helpers';

export const createStripeAccount = async data => {
  return axiosInstance.post(`/api/stripe/account`, data);
};
export const createStripePayout = async data => {
  return axiosInstance.post(`/api/stripe/payout`, data);
};

export const createCorefyPayout = async data => {
  return axiosInstance.post(`/api/corefy/payout`, data);
};

export const getPaymentSystems = async () => {
  return axiosInstance.get(`/api/payment-systems`);
};

export const corefyVerification = async data => {
  return axiosInstance.post(`/api/corefy/payout/confirm`, data);
};

export const corefyVerificationRecendCode = async id => {
  return axiosInstance.post(`/api/corefy/payout/resend`, {
    pst_id: id,
  });
};

export const getPaymentTransactions = async () => {
  return axiosInstance.get(`/api/transactions/user`);
};

export const fetchGetTransactions = async (start_time, end_time) => {
  return await axiosInstance.get(
    `/api/transactions?start_time=${start_time}&end_time=${end_time}`,
  );
};

export const fetchGetUserTransactions = async (start_time, end_time) => {
  return await axiosInstance.get(
    `/api/transactions/user?start_time=${start_time}&end_time=${end_time}`,
  );
};

export const fetchGetCorefyPayoutLimit = async () => {
  return await axiosInstance.get(`api/corefy/payout-limit`);
};

export const fetchGetWalletBalance = async () => {
  return await axiosInstance.get('/api/wallet');
};

export const fetchGetExchangeRate = async country => {
  return await axiosInstance.get(`/api/ic-rates/${country}`);
};

export const fetchGetWithdrawLimit = async country => {
  return axiosInstance.get(`api/ic-payment-limits/${country}`);
};
