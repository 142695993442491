import { memo, useCallback } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment/min/moment-with-locales';
import { Typography } from 'src/views/blocks';

import { Grid } from '@mui/material';

const Balance = ({ exchangeRate, lastTransaction, balance }) => {
  const { t } = useTranslation();

  const lastDateTransaction = useCallback(() => {
    if (lastTransaction.created_at) {
      const currentDate = moment(lastTransaction.created_at).format(
        `DD MMM. YYYY [${t('at')}] HH:mm`,
      );

      return currentDate
        .replace(currentDate[3], currentDate[3].toUpperCase())
        .replace(/\./g, '');
    }
    return null;
  }, [lastTransaction?.created_at, moment]);

  return (
    <Grid container flexDirection="column" className="balance">
      <Grid item>
        <Typography variant="h2" weight="normal-bold">
          {t('Balance')}
        </Typography>
        {exchangeRate && (
          <Typography variant="body1" color="gray">
            {t('Current exchange rate for today:')}{' '}
            <Typography color="primary" inline>
              1 IC = {exchangeRate} EUR
            </Typography>
          </Typography>
        )}
      </Grid>

      <Grid item>
        <Typography variant="large" weight="light" inline>
          {balance}
        </Typography>
        <Typography
          className="ml-1"
          variant="h1"
          component="span"
          color="gray"
          weight="light"
        >
          IC
        </Typography>
      </Grid>

      {!lastTransaction && (
        <Typography color="gray">
          {t(
            'Start making transactions with your wallet. The last transaction will be displayed here.',
          )}
        </Typography>
      )}
      {lastTransaction && (
        <Grid item>
          <Typography component="span" color="gray">
            {lastTransaction.type === 'transfer'
              ? t('Last credited amount')
              : t('Last withdrawal')}{' '}
          </Typography>
          <Typography inline>{parseFloat(lastTransaction.sum)} IC </Typography>
          <Typography component="span" color="gray">
            {' ' + t('for')}
          </Typography>
          <Typography>{lastDateTransaction()}</Typography>
        </Grid>
      )}
    </Grid>
  );
};

const mapStateToProps = ({ wallet }) => ({
  exchangeRate: wallet.exchangeRate,
  lastTransaction: wallet.lastTransaction,
  balance: wallet.balance,
});

export default connect(mapStateToProps, {})(memo(Balance));
