const initialState = {
  currentCountry: '',
  currentRegion: '',
  regUserData: {
    email: '',
    name: '',
    date_of_birth: '1980-01-01T00:00:00.000Z',
    country: 'Ukraine',
    password: '',
    confirmPassword: '',
    acceptPrivacy: true,
  },
  stepRedux: 1,
  isSendCode: false,
  countries: [],
  cities: [],
  userDocument: [],
};

export { initialState };
