import {
    SET_THEME,
    SET_SIDEBAR_EXPANDED
} from "./action-types";


export const setTheme = (theme) => {
    localStorage.setItem("data-theme", theme);

    return {
        type: SET_THEME,
        payload: {
            theme
        }
    };
};

export const setSidebarExpanded = (isSidebarExpanded) => {
    setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
    }, 300);

    return {
        type: SET_SIDEBAR_EXPANDED,
        payload: {
            isSidebarExpanded
        }
    };
};
