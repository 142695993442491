import React from 'react';
import classes from './index.module.scss';
import { useTranslation } from 'react-i18next';

const SucessBlock = () => {
  const { t } = useTranslation();

  const ComponentDoneIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="60"
        height="42.5"
        viewBox="0 0 60 42.5"
      >
        <path
          id="Контур_321"
          data-name="Контур 321"
          d="M26.136,38.48l32.5-32.5,5,5-37.5,37.5-22.5-22.5,5-5Z"
          transform="translate(-3.636 -5.979)"
          fill="#38c28f"
        />
      </svg>
    );
  };

  return (
    <div className={classes.container_verify}>
      <span>{t('Welcome to INFOCOIN!')}</span>
      <div>
        <ComponentDoneIcon />
      </div>
      <p>{t('You will now be redirected to the main page')}</p>
    </div>
  );
};

export { SucessBlock };
