import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { ROUTES } from 'src/routing';
import { useShowMessage } from 'src/hooks';
import { authActions } from 'src/store';
import { AuthLayout } from 'src/views/layouts';
import { Typography } from 'src/views/blocks';
import { AuthArrowBackIcon } from 'views/icons';
import { Grid } from '@mui/material';
import { CodeForm, EmailForm, PasswordForm } from './blocks';
import { useTranslation } from 'react-i18next';

import { toast } from 'react-toastify';

const ForgotPasswordPage = () => {
  const dispatch = useDispatch();
  const showMessage = useShowMessage();
  const history = useHistory();
  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [amountResendCode, setAmountResendCode] = useState(0);
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');

  const handleSubmitEmail = useCallback(
    async ({ email }) => {
      const res = await dispatch(authActions.resetPassword(email));

      if (
        res?.status === 'ERROR' &&
        res?.message.includes('record not found')
      ) {
        toast.error(t('This email address is not registered'));
      } else {
        showMessage(res);
      }
      if (res.status === 'OK') {
        setStep(1);
        setEmail(email);
      }
    },
    [dispatch, showMessage, t],
  );

  const handleSendCode = useCallback(async () => {
    if (amountResendCode !== 2) {
      setAmountResendCode(amountResendCode + 1);
      setLoading(true);
      await handleSubmitEmail({ email });
      showMessage({
        status: 'OK',
        message: 'Code resubmitted',
      });
      setLoading(false);
    } else {
      showMessage({
        status: 'ERROR',
        message: 'The number of re-sending the code is limited!',
      });
    }
  }, [
    email,
    handleSubmitEmail,
    showMessage,
    amountResendCode,
    setAmountResendCode,
  ]);

  const handleSubmitCode = useCallback(({ code }) => {
    setCode(code);

    setStep(2);
  }, []);

  const handleSubmitPassword = useCallback(
    async ({ password }) => {
      const res = await dispatch(
        authActions.newPassword(email, code, password),
      );

      showMessage(res);

      if (res.status === 'OK') {
        setStep(0);
        history.push(ROUTES.login);
      } else {
        setStep(1);
        setAmountResendCode(1);
      }
    },
    [dispatch, setStep, setAmountResendCode, email, code, showMessage],
  );

  return (
    <AuthLayout>
      {step === 0 && (
        <>
          <Grid container paddingBottom="60px">
            <Grid
              item
              fontWeight="700"
              marginLeft="-30px"
              className="d-flex"
              alignItems="center"
            >
              <Link to={ROUTES.login}>
                <AuthArrowBackIcon
                  className="mr-2"
                  size="smallmed"
                  color="#FF5F15"
                />
              </Link>
              <Typography variant="h2">{t('Reset password')}</Typography>
            </Grid>
          </Grid>
          <EmailForm onSubmit={handleSubmitEmail} />
        </>
      )}

      {step === 1 && (
        <>
          <Grid container paddingBottom="60px">
            <Grid
              item
              fontWeight="700"
              marginLeft="-30px"
              className="d-flex"
              alignItems="center"
            >
              <div onClick={() => setStep(0)}>
                <AuthArrowBackIcon
                  className="mr-2"
                  size="smallmed"
                  color="#FF5F15"
                />
              </div>
              <Typography variant="h2">{t('Reset password')}</Typography>
            </Grid>
          </Grid>
          <CodeForm onSubmit={handleSubmitCode} onSendCode={handleSendCode} />
        </>
      )}

      {step === 2 && (
        <>
          <Grid container paddingBottom="60px">
            <Grid item fontWeight="700" className="d-flex" alignItems="center">
              <Typography variant="h2">{t('Create new password')}</Typography>
            </Grid>
          </Grid>
          <PasswordForm onSubmit={handleSubmitPassword} />
        </>
      )}
    </AuthLayout>
  );
};

export { ForgotPasswordPage };
