import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';

import { Typography, FormLoader } from 'src/views/blocks';
import { FaCodeControl } from 'src/views/controls';



const PhoneCodeForm = ({ onResendCode, attempts = 2 }) => {
  const { t } = useTranslation();


  return (
    <Grid position="relative" container direction="column" spacing={3}>
      <Grid item className="profile-facode-container">
        <FaCodeControl required name="code" />
      </Grid>

      <Grid item>
        <Typography align="center">
          {t(
            'A message with a verification code has been send to your phone. Enter the code to continue.',
          )}
        </Typography>

        {attempts !== 0 && (
          <Typography
            onClick={onResendCode}
            align="center"
            style={{
              textDecoration: 'underline',
              color: 'var(--primary-color)',
              cursor: 'pointer',
            }}
          >
            {t('Resend the code')}
          </Typography>
        )}

        <Typography align="center" style={{ color: 'gray' }} variant="span">
          {t('Number of attempts:')}
          {` ${attempts}`}
        </Typography>
      </Grid>

      <FormLoader />
    </Grid>
  );
};

export { PhoneCodeForm };
