import React, { useCallback } from 'react';
import clsx from 'clsx';
import { Slider } from '@mui/material';

import './index.scss';

const PlayerVolume = props => {
  const { value, onChange } = props;

  const handleChange = useCallback(
    (e, value) => {
      if (onChange) {
        onChange(value);
      }
    },
    [onChange],
  );

  return (
    <Slider
      classes={{
        root: 'player-volume-modal__slider',
        rail: 'player-volume-modal__rail',
      }}
      min={0}
      max={100}
      size="small"
      value={value}
      onChange={handleChange}
    />
  );
};

export { PlayerVolume };
