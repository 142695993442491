import {
  ADD_NEW_NOTIFICATION,
  READ_ALL_NOTIFICATIONS,
  SAVE_NEW_NOTIFICATION
} from "./constants";

export const saveNotificationsAction = data => {
  return {
    type: SAVE_NEW_NOTIFICATION,
    payload: data,
  };
};

export const addNewNotificationsAction = noti => {
  return {
    type: ADD_NEW_NOTIFICATION,
    payload: noti,
  };
};

export const deleteNotifications = id => async (dispatch, getState) => {
  const {
    notifications: { items },
  } = getState();
  const newNotifi = items.filter(n => n.id !== id);
  dispatch({
    type: SAVE_NEW_NOTIFICATION,
    payload: newNotifi,
  });
};

export const deleteAllNotifications = () => async (dispatch) => {
  dispatch({
    type: READ_ALL_NOTIFICATIONS,
  });
};
