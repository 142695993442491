import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ROUTES } from 'src/routing';
import { useFormik } from 'formik';
import Loader from '@components/Loader';
import classes from './index.module.scss';
import { LoginSchema } from 'utils/formikShema';
import { AuthTextField } from 'views/blocks/AuthTextField';
import { Checkbox, FormControlLabel } from '@mui/material';
import { Typography } from '../../../../blocks';

const LoginForm = props => {
  const { error = false, onSubmit, setShowModal } = props;
  const { t } = useTranslation();

  const {
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    handleBlur,
    handleSubmit,
    isValid,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    initialValues: {
      email: '',
      password: '',
      isRemember: true,
      acceptPrivacy: true,
    },
    validationSchema: LoginSchema,
    onSubmit: onSubmit,
  });
  const onToggleRemember = () => {
    setFieldValue('isRemember', !values.isRemember);
  };

  const handleToggleModal = useCallback(e => {
    e.preventDefault();
    setShowModal(true);
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <div>
          <AuthTextField
            name="email"
            onChange={handleChange}
            label={t('Email')}
            type="email"
            value={values.email}
          />
          <AuthTextField
            classNameContainer="mt-2"
            name="password"
            onChange={handleChange}
            label={t('Password')}
            type="password"
            value={values.password}
          />
        </div>
        <div className={classes.pass_remember}>
          <div
            onClick={onToggleRemember}
            className={values.isRemember ? classes.active_remember : ''}
          >
            <div />
            <span>{t('Remember me')}</span>
          </div>
          <div className={classes.pass_remember__forgot}>
            <Link to={ROUTES.forgot}>{t('Forgot password?')}</Link>
          </div>
        </div>
        <div>
          <FormControlLabel
            className="mt-3"
            control={
              <Checkbox
                checked={values.acceptPrivacy}
                onChange={handleChange}
                name="acceptPrivacy"
                inputProps={{ 'aria-label': 'controlled' }}
                sx={{
                  color: 'var(--primary-color)',
                  '&.Mui-checked': {
                    color: 'var(--primary-color)',
                  },
                }}
              />
            }
            label={
              <>
                <Typography variant="caption2" color="gray">
                  {t('I have read and agree to')}
                  <Typography
                    variant="caption2"
                    color="primary"
                    component="span"
                    onClick={handleToggleModal}
                  >
                    {' '}
                    {t('Terms of Service on login page', { context: 'Terms of Service' })}
                  </Typography>{' '}
                  {t('and')}{' '}
                  <Typography
                    variant="caption2"
                    color="primary"
                    component="span"
                    onClick={handleToggleModal}
                  >
                    {t('Privacy Policy on login page', { context: 'Privacy Policy' })}
                  </Typography>
                </Typography>
              </>
            }
          />
          {errors.acceptPrivacy && (
            <Typography variant="caption2" color="error">
              {t(
                'The user is responsible for the completeness and accuracy of the information provided',
              )}
            </Typography>
          )}
        </div>
        <div className={classes.authButton}>
          <button type="submit" disabled={!isValid}>
            {isSubmitting ? (
              <Loader color="white" width={30} height={30} />
            ) : (
              t('Sign in to account')
            )}
          </button>
        </div>
        <div className={classes.other_content}>
          {t('Don`t have an account?')}
          <span style={{ display: 'flex' }}>
            &nbsp; <Link to={ROUTES.registration}>{t('Sign up')}</Link>
          </span>
        </div>
        <div className={classes.other_content}>
          <span>
            &nbsp;{' '}
            <Link className={classes.other_content__demo} to={ROUTES.home}>
              {t('Try demo')}
            </Link>
          </span>
        </div>
      </div>
    </form>
  );
};

export { LoginForm };
