import React from "react";

import {Icon} from "src/views/blocks";
import "./index.scss";


const InstagramIcon = (props) => {
    const {
        className = "",
        ...rest
    } = props;

    return (
        <Icon
          {...rest}
          className={`instagram-icon ${className}`}
          />
    );
};


export {InstagramIcon};