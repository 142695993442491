import { memo } from 'react';
import { Grid } from '@mui/material';
import { Typography } from '../../../../../../blocks';
import { ArrowDownward, ArrowUpward, DoNotDisturb } from '@mui/icons-material';

import { withTranslation } from 'react-i18next';

const TransactionItem = ({ transaction, t, handleConfirm }) => {
  return (
    <Grid
      container
      mt={2}
      justifyContent="space-between"
      alignItems="center"
      width="auto"
    >
      <Grid container width="auto">
        <Grid item mr={1}>
          {transaction?.type === 'outcome' ? (
            <ArrowUpward />
          ) : transaction?.type === 'in_verifying' ? (
            <DoNotDisturb fontSize="small" />
          ) : (
            <ArrowDownward htmlColor="#009f00" />
          )}
        </Grid>
        <Grid item>
          <Typography weight="normal-bold">
            {transaction?.currentType}
          </Typography>
          <Typography color="gray" variant="body2">
            {transaction?.created_at}
          </Typography>
          {transaction?.type === 'in_verifying' && (
            <Typography
              onClick={() => handleConfirm(transaction?.id)}
              className="cursor-pointer hover-opacity"
              color="primary"
              variant="body2"
              weight="semi-bold"
            >
              {t('Confirm')}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid item>
        <Typography
          variant="h4"
          color={transaction?.type === 'outcome' ? 'default' : 'success'}
          weight="normal-bold"
        >
          {transaction?.sum}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default withTranslation()(memo(TransactionItem));
