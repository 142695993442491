import React from "react";
import {Grid} from "@mui/material";
import {Spinner} from "react-bootstrap";


const PageLoader = () => {
    return (
        <Grid
          style={{
            height: "100vh"
          }}
          container
          justifyContent="center"
          alignItems="center">
            <Grid item>
                <Spinner
                  animation="border"
                  variant="warning" />
            </Grid>
        </Grid>
    );
};


export {PageLoader};