import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCategories, getHistory } from '@services/api/user';

import { PageHeader, PageLoader, Typography } from 'src/views/blocks';

import { Grid, IconButton, Pagination } from '@mui/material';

import { DashboardLayout } from 'src/views/layouts';

import { connect } from 'react-redux';

import { toast } from 'react-toastify';

import HistoryItem from '@components/History/HistoryItem';

import moment from 'moment/min/moment-with-locales';

import { Select, TextInput } from '../../inputs';

import DatePicker from './blocks/datepicker';
import DrawerInfo from './blocks/DrawerInfo';
import { setCategoriesAction } from '../../../store/modules/categories/actions';

import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import s from './index.module.scss';

const HistoryPage = ({ categories, setCategoriesAction }) => {
  const { t, i18n } = useTranslation();

  moment.locale(i18n.language);

  const [isLoading, setLoading] = useState(true);

  const [videos, setVideos] = useState([]);

  const [search, setSearch] = useState([]);

  const [date, setDate] = useState('');

  const [show, setShow] = useState(10);

  const [drawerInfo, setDrawerInfo] = useState(null);

  const [category, setCategory] = useState('');

  const [page, setPage] = useState(1);

  const [showFilters, setShowFilters] = useState(false);

  const isOpenDrawer = !!drawerInfo;

  const filteredVideo = useMemo(() => {
    let initialVideos = videos;

    if (!initialVideos.length) {
      return {
        videos: [],
        length: 0,
      };
    }

    if (date) {
      initialVideos = initialVideos.filter(item => {
        const year = new Date(item?.updated_at).getFullYear();
        const month = new Date(item?.updated_at).getMonth();
        const day = new Date(item?.updated_at).getDate();

        const currentDay = moment(
          new Date(year, month, day, 3, 0, 0, 0),
        ).unix();

        return currentDay === moment(date).unix();
      });
    }

    if (category) {
      initialVideos = initialVideos.filter(item => {
        return item?.video_categories.map(c => c.id).includes(category);
      });
    }

    if (search.length) {
      initialVideos = initialVideos.filter(
        item =>
          item?.video?.title.toLowerCase().includes(search.toLowerCase()) ||
          item?.video?.description
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          item?.video?.company?.title
            .toLowerCase()
            .includes(search.toLowerCase()),
      );
    }

    const lengthVideos = initialVideos.length;

    if (page) {
      initialVideos = initialVideos.slice((page - 1) * 10, page * 10);
    }

    const formatVideos = initialVideos.map(item => {
      const { video } = item;

      const year = new Date(item.updated_at).getFullYear();
      const month = new Date(item.updated_at).getMonth();
      const day = new Date(item.updated_at).getDate();

      const currentDay = moment(new Date(year, month, day, 3, 0, 0, 0)).unix();

      return {
        day: currentDay,
        current_date: moment(item.updated_at).format('DD MMMM YYYY'),
        video: {
          video_categories: item.video_categories,
          ...video,
          created_at: item.updated_at,
        },
      };
    });

    const result = Object.values(
      formatVideos.reduce((r, cur) => {
        const key = 'd' + cur['day']; // символ "d" добавлен, чтобы автоматически не сортировало по цифровым ключам
        (r[key] = r[key] || []).push(cur);

        return r;
      }, {}),
    );

    return {
      videos: result,
      length: lengthVideos,
    };
  }, [videos, search, category, moment, date, page, t]);

  const handleChangeShow = useCallback(async num => {
    setLoading(true);

    try {
      const responseVideos = await getHistory(num, 0);

      setShow(num);

      setVideos(responseVideos?.data);
    } catch (e) {
      toast.error(t(e?.response?.data?.error || e?.response?.message || e));
      console.error(e);
    }

    setLoading(false);
  }, []);

  const handleChangePagination = useCallback((event, page) => {
    setPage(page);

    document.querySelector('.history-container').scrollIntoView();
  }, []);

  useEffect(() => {
    const initial = async () => {
      setLoading(true);

      try {
        const responseVideos = await getHistory(10, 0);

        setVideos(responseVideos?.data);
        if (!categories.length) {
          const responseCategories = await getCategories();

          setCategoriesAction(responseCategories?.data);
        }
      } catch (e) {
        toast.error(t(e?.response?.data?.error || e?.response?.message || e));
        console.error(e);
      }

      setLoading(false);
    };

    initial();
  }, []);

  useEffect(() => {
    setPage(1);
  }, [search, date, category, show]);

  return (
    <DashboardLayout>
      <PageHeader variant="primary" title={t('History')} />
      <Grid mt={4} className="history-container">
        <Grid container className={s.filters}>
          <Grid item className={s.filters__item}>
            <TextInput
              value={search}
              onChange={e => setSearch(e.target.value)}
              placeholder={t('Search')}
            />
          </Grid>
          <Grid
            item
            className={`${s.filters__item} ${
              showFilters ? s.filters__item_active : ''
            }`}
          >
            <DatePicker
              onChange={value => setDate(value)}
              value={date}
              t={t}
              placeholder={t('Select a date')}
            />
          </Grid>
          <Grid
            item
            className={`${s.filters__item} ${
              showFilters ? s.filters__item_active : ''
            }`}
          >
            <Select
              placeholder={t('Select a category')}
              options={categories.map(category => ({
                label: t(category.title),
                value: category.id,
              }))}
              isClearable={true}
              value={category}
              isSearchable={false}
              onChange={value => setCategory(value)}
            />
          </Grid>
          <Grid
            item
            className={`${s.filters__item} ${
              showFilters ? s.filters__item_active : ''
            }`}
          >
            <Select
              options={[
                { value: 10, label: t('Show 10') },
                { value: 25, label: t('Show 25') },
                { value: 50, label: t('Show 50') },
              ]}
              value={show}
              isSearchable={false}
              onChange={handleChangeShow}
            />
          </Grid>
          <Grid
            container
            onClick={() => setShowFilters(prev => !prev)}
            className={s.filters__item}
          >
            <IconButton>
              <Grid item mr={1}>
                <Typography variant="body2" color="default" weight="semi-bold">
                  {!showFilters
                    ? t('Show other filters')
                    : t('Close other filters')}
                </Typography>
              </Grid>
              <Grid item display="flex">
                {!showFilters ? (
                  <ExpandMoreOutlinedIcon
                    size="small"
                    htmlColor="var(--color)"
                  />
                ) : (
                  <ExpandLessOutlinedIcon
                    size="small"
                    htmlColor="var(--color)"
                  />
                )}
              </Grid>
            </IconButton>
          </Grid>
        </Grid>
        {isLoading ? (
          <PageLoader />
        ) : filteredVideo.videos.length ? (
          <Grid item mt={4}>
            {filteredVideo.videos.map(item => (
              <HistoryItem item={item} setInfo={setDrawerInfo} key={item?.id} />
            ))}
            <Grid item mt={4} display="flex" justifyContent="center">
              <Pagination
                page={page}
                onChange={handleChangePagination}
                count={Math.ceil(filteredVideo.length / 10)}
                size="large"
              />
            </Grid>
          </Grid>
        ) : (
          <Grid mt={4}>
            <Typography variant="h5" color="default" align="center">
              {t('Videos not found or missing')}
            </Typography>
          </Grid>
        )}
      </Grid>
      <DrawerInfo
        open={isOpenDrawer}
        video={drawerInfo}
        onChange={() => setDrawerInfo(null)}
      />
    </DashboardLayout>
  );
};

const mapStateToProps = ({ categories }) => ({
  categories: categories.categories,
});

export default connect(mapStateToProps, { setCategoriesAction })(
  memo(HistoryPage),
);
