import { countriesActions } from '@redux/actions/registration';
import { initialState } from 'src/store/modules/registration/state';

export function registrationReducer(state = initialState, action = {}) {
  switch (action.type) {
    case countriesActions.SET_USER_DOCUMENT:
      return { ...state, userDocument: action.data };
    case countriesActions.GET_COUNTRIES:
      return {
        ...state,
        countries: action.data,
      };
    case countriesActions.SET_COUNTRY:
      return {
        ...state,
        currentCountry: action.data,
      };
    case countriesActions.GET_CITIES:
      return {
        ...state,
        cities: action.data,
      };
    case countriesActions.SET_REGION:
      return { ...state, currentRegion: action.data };
    case countriesActions.SET_REG_DATA:return {
        ...state,
        regUserData: { ...state.regUserData, ...action.data },
      };
    case countriesActions.SET_IS_SEND_CODE:
      return {
        ...state,
        regUserData: { ...state, isSendCode :action.data },
      };
    case countriesActions.SET_REG_STEP:
      return {
        ...state,
        stepRedux: action.data,
      };
    case countriesActions.CLEAR_REG_DATA: {
      return {
        ...state,
        regUserData: {
          email: '',
          name: '',
          country: 'Ukraine',
          password: '',
          confirmPassword: '',
          acceptPrivacy: true,
        },
        step: 1,
      };
    }
    default:
      return state;
  }
}
