import React from 'react';

import { Icon } from 'src/views/blocks';
import './index.scss';

const InfinityIcon = props => {
  const { className = '', ...rest } = props;

  return <Icon {...rest} className={`infinity-icon ${className}`} mask />;
};

export { InfinityIcon };
