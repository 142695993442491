import React from "react";

import "./index.scss";


const PlayerSeek = (props) => {
  const {
    ariaLabel,
    percentagePlayed,
    percentageBuffered,
    onChange
  } = props;

  return (
    <div className="player-seek">
      <div className="player-seek__track">
        <div
          style={{ width: `${percentagePlayed || 0}%` }}
          className="player-seek__fill" />

        <div
          style={{ width: `${percentageBuffered || 0}%` }}
          className="player-seek__buffer" />

        <input
          className="player-seek__input"
          type="range"
          readOnly
          step={1}
          min="0"
          max="100"
          aria-label={ariaLabel}
          value={percentagePlayed || 0}
          onChange={onChange} />
      </div>
    </div>
  );
};


export { PlayerSeek };