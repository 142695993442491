import React from 'react';
import { Modal as ModalBase } from 'react-bootstrap';
import classes from './Modal.module.scss';
import clsx from 'clsx';
import { Button } from "src/views/blocks";

import {
  CloseIcon
} from "src/views/icons";

const Modal = ({
  show,
  onHide,
  children,
  footer,
  dialogClassName,
  headerText = '',
  size = 'lg',
  contentClassName,
  backgroundBody = 'white',
                 withCloseButton= true,
  ...props
}) => {
  return (
    <>
      <ModalBase
        show={show}
        onHide={onHide}
        size={size}
        contentClassName={clsx(classes.container, contentClassName)}
        dialogClassName={dialogClassName}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        {...props}
      >
        <ModalBase.Header className={classes.header}>
          {headerText}
          {withCloseButton && <Button leftAddons={<CloseIcon size='medium' />} onClick={onHide} />}
        </ModalBase.Header>
        <ModalBase.Body>{children}</ModalBase.Body>
        {footer && (
          <ModalBase.Footer className="flex-row-center-align">
            {footer}
          </ModalBase.Footer>
        )}
      </ModalBase>
    </>
  );
};

export default Modal;
