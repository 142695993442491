import React, { useCallback } from 'react';
import './index.scss';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Select from 'react-select';
import { CountryOption, IconButton } from 'views/blocks';
import { DoneIcon } from '../../icons';

const PhoneInputComponent = props => {
  const {
    value = '',
    onChange,
    rightAddons,
    disabled,
    error,
    touched,
    isValid,
    onBlur,
    showDone,
  } = props;

  const handleChange = useCallback(
    value => {
      onChange && onChange(value);
    },
    [onChange],
  );

  const handleBlur = useCallback(() => {
    onBlur && onBlur();
  }, [onBlur]);

  const SelectComponent = data => {
    const options = data.options.map(option => {
      return {
        label: option.label,
        value: option.value,
        iso: option.value,
      };
    });

    return (
      <Select
        value={options.filter(option => data.value === option.value)[0]}
        onChange={value => data.onChange(value.value)}
        options={options}
        disabled={disabled}
        className="react-select"
        isSearchable={false}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        classNamePrefix="react-select"
        formatOptionLabel={value => <CountryOption option={value} showLabel />}
      />
    );
  };

  return (
    <>
      <div
        id="wrapper-phone-input"
        className={`wrapper-phone-input ${error && 'input--error'} ${
          disabled && 'number-disabled'
        }`}
      >
        <PhoneInput
          disabled={disabled}
          placeholder="Enter phone number"
          countrySelectComponent={SelectComponent}
          onChange={handleChange}
          value={value}
          onBlur={handleBlur}
        />
        <div className="text-input__right-addons">
          <React.Fragment>
            {showDone && isValid && (
              <IconButton
                type="button"
                size="small"
                color="primary"
                icon={DoneIcon}
              />
            )}
            {rightAddons}
          </React.Fragment>
        </div>
        {error && touched && (
          <p
            style={{ color: '#EE1717' }}
            className="typography text-input__helper-text typography--variant-caption2 typography--weight-normal typography--align-left"
          >
            {error}
          </p>
        )}
      </div>
    </>
  );
};

export { PhoneInputComponent };
