import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { isValid, parseISO } from 'date-fns';
import { Grid } from '@mui/material';

import { getDaysInMonth } from 'src/utils';
import { Typography } from 'src/views/blocks';
import { Select } from '../Select';

import './index.scss';

const yearOptions = Array.from({ length: new Date().getFullYear() - 1950 }).map(
  (ignore, index) => {
    return {
      label: new Date().getFullYear() - index,
      value: new Date().getFullYear() - index,
    };
  },
);

const monthOptions = [
  {
    label: 'January',
    value: 1,
  },
  {
    label: 'February',
    value: 2,
  },
  {
    label: 'March',
    value: 3,
  },
  {
    label: 'April',
    value: 4,
  },
  {
    label: 'May',
    value: 5,
  },
  {
    label: 'June',
    value: 6,
  },
  {
    label: 'July',
    value: 7,
  },
  {
    label: 'August',
    value: 8,
  },
  {
    label: 'September',
    value: 9,
  },
  {
    label: 'October',
    value: 10,
  },
  {
    label: 'November',
    value: 11,
  },
  {
    label: 'December',
    value: 12,
  },
];

const DateGroup = props => {
  const {
    isRequired,
    onFocus,
    className = '',
    classNameLabel = '',
    helperText = '',
    error = false,
    value,
    isBorder = false,
    label = '',
    onChange,
  } = props;

  const { t } = useTranslation();

  const [year, setYear] = useState(1980);
  const [month, setMonth] = useState();
  const [day, setDay] = useState();

  const dayOptions = useMemo(() => {
    return Array.from({
      length: getDaysInMonth(year, month),
    }).map((ignore, index) => {
      const day = index + 1;

      return {
        label: day,
        value: day,
      };
    });
  }, [year, month]);

  const modifications = clsx({
    'date-group--error': error,
  });

  const handleChange = useCallback(
    (year, month, day) => {
      if (onChange) {
        const date = new Date(year, month - 1, day + 1, 0, 0);

        date.setUTCHours(0);

        if (isValid(date)) {
          onChange(date.toISOString());
        } else {
          onChange('');
        }
      }
    },
    [onChange],
  );

  const handleChangeYear = useCallback(
    year => {
      setYear(year);

      handleChange(year, month, day);
    },
    [month, day, handleChange],
  );

  const handleChangeMonth = useCallback(
    month => {
      setMonth(month);

      handleChange(year, month, day);
    },
    [year, day, handleChange],
  );

  const handleChangeDay = useCallback(
    day => {
      setDay(day);

      handleChange(year, month, day);
    },
    [year, month, handleChange],
  );

  const handleFocus = useCallback(() => {
    if (onFocus) {
      onFocus();
    }
  }, [onFocus]);

  useEffect(() => {
    if (value) {
      const date = parseISO(value);

      if (isValid(date)) {
        setYear(date.getFullYear());
        setMonth(date.getUTCMonth() + 1);
        setDay(date.getUTCDate());
      }
    }
  }, [value]);

  return (
    <div className={`date-group ${modifications} ${className}`}>
      {label && (
        <div
          className={`field-label ${
            isRequired ? 'field-label--required' : ''
          } ${classNameLabel}`}
        >
          {t(label)}
        </div>
      )}
      <Grid
        container
        spacing={1}
        className={isBorder ? 'date-group__border' : ''}
      >
        <Grid item flex={2}>
          <Select
            options={yearOptions}
            onFocus={handleFocus}
            error={!isBorder && error}
            value={year}
            onChange={handleChangeYear}
          />
        </Grid>

        <Grid item flex={3}>
          <Select
            options={monthOptions}
            translate
            onFocus={handleFocus}
            error={!isBorder && error}
            value={month}
            onChange={handleChangeMonth}
          />
        </Grid>

        <Grid item flex={2}>
          <Select
            options={dayOptions}
            onFocus={handleFocus}
            error={!isBorder && error}
            value={day}
            onChange={handleChangeDay}
          />
        </Grid>
      </Grid>

      {helperText && helperText !== 'Field is required' && (
        <Typography className="date-group__helper-text" variant="caption2">
          {t(helperText)}
        </Typography>
      )}
    </div>
  );
};

export { DateGroup };
