import React from "react";

import {FormControl} from "src/views/blocks";
import {FaCode} from "src/views/inputs";


const FaCodeControl = (props) => {
    const {
        validate,
        required,
        count = 4,
        label,
        name,
        defaultValue,
        ...rest
    } = props;

    return (
        <FormControl
          component={FaCode}
          componentProps={{
            ...rest,
            count
          }}
          validate={validate}
          required={required}
          maxLength={count}
          minLength={count}
          label={label}
          name={name}
          defaultValue={defaultValue} />
    );
};


export {FaCodeControl};