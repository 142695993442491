import { SET_PLAYER_QUALITY, SET_PLAYER_VOLUME } from '../constants';

export const setVolumeAction = volume => {
  return {
    type: SET_PLAYER_VOLUME,
    payload: volume,
  };
};

export const setQualityAction = volume => {
  return {
    type: SET_PLAYER_QUALITY,
    payload: volume,
  };
};
