import {useSelector} from "react-redux";


const useThemeProps = (props) => {
    const {theme} = useSelector((state) => state.ui);

    return {
        theme,
        ...props
    };
};


export {useThemeProps};