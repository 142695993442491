import React from "react";
import {CircularProgress} from "@mui/material";

import {Overlay} from "../Overlay";

import "./index.scss";


const LoaderOverlay = (props) => {
    const {
        open
    } = props;

    return (
        <Overlay
          className="loader-overlay"
          open={open}>
            {open && (
                <CircularProgress
                  size={35}
                  thickness={5}
                  value={80} />
            )}
        </Overlay>
    );
};


export {LoaderOverlay};