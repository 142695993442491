import React from "react";

import { Icon } from "src/views/blocks";

import "./index.scss";


const EyeCloseIcon = (props) => {
    const {
        className,
        ...rest
    } = props;

    return (
        <Icon
          {...rest}
          className={`eye-close-icon ${className}`}
          mask />
    );
};


export {EyeCloseIcon};