import { Fragment, memo, useCallback, useMemo, useState } from 'react';
import { Grid, IconButton, Popover } from '@mui/material';

import { TextInput } from '../../../../inputs';
import { Calendar } from '@hassanmojab/react-modern-calendar-datepicker';
import { MyCustomLocale } from '../../../../blocks/DatePicker/data';

import moment from 'moment/min/moment-with-locales';

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const DatePicker = ({ value, onChange, t, ...rest }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const [date, setDate] = useState(value);

  const open = Boolean(anchorEl);
  const id = open ? 'calendar-popover' : undefined;

  const inputValue = useMemo(() => {
    if (date) {
      const result = new Date(date.year, date.month - 1, date.day);

      return moment(result).format('DD MMMM YYYY');
    }

    return '';
  }, [date, t, moment]);

  const handleClick = useCallback(event => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClear = useCallback(
    event => {
      event.stopPropagation();

      setDate('');

      if (onChange) {
        onChange('');
      }
    },
    [onChange],
  );

  const handleChange = useCallback(
    value => {
      setDate(value);

      if (onChange) {
        const date = new Date(
          value.year,
          value.month - 1,
          value.day,
          3,
          0,
          0,
          0,
        );

        onChange(date);
      }

      handleClose();
    },
    [onChange],
  );

  return (
    <Fragment>
      <Grid onClick={handleClick}>
        <TextInput
          {...rest}
          value={inputValue}
          readOnly
          rightAddons={
            value && (
              <IconButton onClick={handleClear}>
                <CloseOutlinedIcon fontSize="small" />
              </IconButton>
            )
          }
        />
      </Grid>
      <Popover
        anchorEl={anchorEl}
        classes={{
          paper: 'date-picker-popover',
          root: 'date-picker-root',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        id={id}
        onClose={handleClose}
      >
        <Calendar
          colorPrimary="var(--primary-color)"
          calendarClassName="calendar-container__datepicker"
          colorPrimaryLight="var(--player-background)"
          locale={MyCustomLocale()}
          value={date}
          onChange={handleChange}
        />
      </Popover>
    </Fragment>
  );
};

export default memo(DatePicker);
