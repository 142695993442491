import React, { memo, useCallback, useState } from 'react';
import {
  Chip,
  Divider,
  Grid,
  IconButton,
  SwipeableDrawer,
} from '@mui/material';

import { Button, Typography } from '../../../../blocks';

import s from './index.module.scss';

import { AccessTime, Close } from '@mui/icons-material';

import { withTranslation } from 'react-i18next';

import moment from 'moment/min/moment-with-locales';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { DefaultPlayer as Video } from 'react-html5video';

const DrawerInfo = ({ open, video, onChange, t }) => {
  const [isShowEmail, setIsShowEmail] = useState(false);

  const handleClose = useCallback(() => {
    setIsShowEmail(false);

    if (onChange) {
      onChange(null);
    }
  }, [onChange]);

  return (
    <SwipeableDrawer
      anchor="right"
      onClose={handleClose}
      classes={{
        root: s.drawer,
        paper: s.paper,
      }}
      open={open}
    >
      <Grid
        container
        height="100%"
        flexDirection="column"
        flexWrap="nowrap"
        justifyContent="space-between"
      >
        <Grid
          container
          width="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item className={s.title}>
            <Typography variant="h4" weight="normal-bold" color="default">
              {video?.title}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleClose}>
              <Close htmlColor="var(--color)" />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item mb={2} display="flex" alignItems="center">
          <AccessTime fontSize="small" htmlColor="#999" sx={{ mr: 1 }} />
          <Typography variant="body2" color="gray">
            {moment(video?.created_at).format(
              `DD MMMM YYYY [${t('at')}] HH:MM`,
            )}
          </Typography>
        </Grid>

        <Divider variant="medium" className={s.divider} />

        <PerfectScrollbar>
          <Grid
            container
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            flexWrap="nowrap"
            height="100%"
          >
            <Grid item>
              <Grid item mt={2}>
                <Video
                  width="100%"
                  loop
                  class={s.video}
                  muted
                  autoPlay
                  controls={['PlayPause']}
                >
                  <source
                    src={video?.filepath_480_p || video?.filepath_720_p}
                    type="video/mp4"
                  />
                </Video>
              </Grid>
              <Grid item mt={2}>
                <Typography variant="body1" color="default">
                  {t('Description')}
                </Typography>

                <Typography variant="body2" color="gray">
                  {video?.description}
                </Typography>
              </Grid>
              <Grid item mt={2}>
                <Typography variant="body1" color="default">
                  {t('Categories')}
                </Typography>
                <Grid spacing={1}>
                  {video?.video_categories.map(category => (
                    <Chip
                      label={t(category.title)}
                      size="small"
                      sx={{ color: 'var(--color)', mr: 0.5 }}
                      variant="outlined"
                    />
                  ))}
                </Grid>
              </Grid>
            </Grid>
            <Grid item width="100%" mt={2} display="flex" alignItems="end">
              {video?.url && (
                <Button
                  component="a"
                  href={video?.url}
                  fullWidth
                  target="_blank"
                  variant="primary"
                  className={s.button}
                >
                  {t('Go to the site')}
                </Button>
              )}
              {video?.email && (
                <Button
                  component="a"
                  rel="nofollow"
                  className={s.button}
                  variant={!isShowEmail ? 'primary' : ''}
                  fullWidth
                  target="_blank"
                  onClick={() => setIsShowEmail(true)}
                  href={`mailto:${video?.email}?subject=Infocoin: ${video?.title}`}
                >
                  {isShowEmail ? video?.email : t('Send a message')}
                </Button>
              )}
            </Grid>
          </Grid>
        </PerfectScrollbar>
      </Grid>
    </SwipeableDrawer>
  );
};

export default withTranslation()(memo(DrawerInfo));
