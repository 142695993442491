import React from "react";
import {components} from "react-select";

const {SingleValue: RSSingleValue} = components;


const SingleValue = (props) => {
    const {
        component: Component,
        ...rest
    } = props;

    const {
        data: option
    } = rest;

    return (
        <RSSingleValue {...rest}>
            <Component
              option={option} />
        </RSSingleValue>
    );
};


export {SingleValue};