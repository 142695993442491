import React from 'react';
import { Dialog as MuiDialog } from '@mui/material';

import { useThemeProps } from 'src/hooks';

import './index.scss';

const Dialog = props => {
  const {
    theme,
    className = '',
    lightTheme = false,
    children,
    ...rest
  } = useThemeProps(props);

  return (
    <MuiDialog
      {...rest}
      classes={{
        root: `dialog ${
          lightTheme ? 'dialog-light' : `dialog--${theme}`
        } ${className}`,
      }}
    >
      {children}
    </MuiDialog>
  );
};

export { Dialog };
