import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { WS_URL } from '../config';
import { addNewNotificationsAction } from 'store/modules/notifiactions/notificationsActions';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { getTokenData } from '../utils/tokens';
import { Typography } from '../views/blocks';

let auctionSocket;

export const onToggleNotifictions = async (message_type, active) => {
  if (auctionSocket) {
    await auctionSocket.send(
      JSON.stringify({
        message_type: message_type,
        active: active,
      }),
    );
  }
};

export const useWbSc = () => {
  const { t } = useTranslation();

  const { token } = getTokenData();

  const { isAuthenticated } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      auctionSocket = new WebSocket(
        `wss://${WS_URL}/ws/notifications?token=${token}`,
      );
      auctionSocket.onmessage = function (event) {
        const action = JSON.parse(event.data);

        if (action.message_type === 'auction_closed') {
          toast.warning(t('Auction is closed'));

          dispatch(
            addNewNotificationsAction(
              <div>
                {t('Auction for')} {moment(action.ended_at).format('MMMM Do')}{' '}
                {t('is closed')}
              </div>,
            ),
          );
        }

        if (action.message_type === 'service_notification') {
          toast.warning(t('New message from INFOCOIN'));

          dispatch(
            addNewNotificationsAction(
              <div>
                <div>
                  <Typography bold variant="body1" color="primary">
                    {t(action.topic)}
                  </Typography>
                </div>
                <div className="mt-1">
                  <Typography variant="body2">{t(action.body)}</Typography>
                </div>
              </div>,
            ),
          );
        }
      };

      if (!isAuthenticated) {
        auctionSocket.close();
      }

      return () => {
        auctionSocket.close();
      };
    } //eslint-disable-next-line
  }, [isAuthenticated, token]);
};
