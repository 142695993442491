import * as Yup from 'yup';
import moment from 'moment';

export const LoginSchema = () =>
  Yup.object().shape({
    email: Yup.string().email('invalid email').required('Field is required'),
    password: Yup.string()
      .min(8, 'min', { num: 8 })
      .max(100, 'max', { num: 100 })
      .required('Field is required'),
    acceptPrivacy: Yup.bool()
      .oneOf([true], 'Accept Terms & Conditions is required')
      .required('Field is required'),
  });

export const EmailSchema = () =>
  Yup.object().shape({
    email: Yup.string().email('invalid email').required('Field is required'),
  });

export const SignupSchema = Yup.object().shape({
  email: Yup.string().email('Invalid Email').required('Field is required'),
  name: Yup.string().required('Field is required'),
  country: Yup.string().required('Field is required'),
  date_of_birth: Yup.string()
    .required('Field is required')
    .test(
      'DOB',
      'Your age is under 16 years old, which does not comply with our standards for using the INFOCOIN platform',
      value => {
        return moment().diff(moment(value), 'years') >= 16;
      },
    ),
  password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&_-])[A-Za-z\d@$#!%*?&_-]{8,}$/,
      {
        message:
          'The password must consist of: at least 8 characters, a lowercase character, an uppercase character, a number and a special character (!,@,#,$,%,*,&,?)',
      },
    )
    .required('Field is required'),
  confirmPassword: Yup.string()
    .required('Field is required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  acceptPrivacy: Yup.bool()
    .oneOf([true], 'Accept Terms & Conditions is required')
    .required('Field is required'),
});

export const ActivateAccountSchema = () =>
  Yup.object().shape({
    code: Yup.string()
      .length(4, 'Not valid code')
      .required('Field is required'),
  });

export const ForgotPasswordSchema = () =>
  Yup.object().shape({
    password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&_-])[A-Za-z\d@$#!%*?&_-]{8,}$/,
        {
          message:
            'The password must consist of: at least 8 characters, a lowercase character, an uppercase character, a number and a special character (!,@,#,$,%,*,&,?)',
        },
      )
      .required('Field is required'),
    confirmPassword: Yup.string()
      .required('Field is required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });

export const UserEditSchema = Yup.object().shape({
  name: Yup.string()
    .required('Field is required')
    .max(82, 'Max length name = 82 letters'),
  email: Yup.string().email('Invalid Email').required('Field is required'),
  country: Yup.string().required('Field is required'),
  date_of_birth: Yup.string()
    .test(
      'DOB',
      'Your age is under 16 years old, which does not comply with our standards for using the INFOCOIN platform',
      value => {
        return moment().diff(moment(value), 'years') >= 16;
      },
    )
    .required('Field is required'),
  gender: Yup.string().required('Field is required'),
});
