import { Fragment, memo, useCallback, useMemo, useState } from 'react';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import { Calendar, utils } from '@hassanmojab/react-modern-calendar-datepicker';
import { TextInput } from '../../inputs';
import { withTranslation } from 'react-i18next';
import './index.scss';
import { Popover } from '@mui/material';
import { MyCustomLocale } from './data';
import { CalendarIcon } from '../../icons';

import moment from 'moment/min/moment-with-locales';

const CalendarPicker = ({
  t,
  i18n,
  defaultValue,
  onChange,
  disabled,
  ...rest
}) => {
  const defaultSelectedDayRange = {
    from: {
      day: new Date(defaultValue.start_time).getDate(),
      month: new Date(defaultValue.start_time).getMonth() + 1,
      year: new Date(defaultValue.start_time).getFullYear(),
    },
    to: {
      day: new Date(defaultValue.end_time).getDate(),
      month: new Date(defaultValue.end_time).getMonth() + 1,
      year: new Date(defaultValue.end_time).getFullYear(),
    },
  };

  const [selectedDayRange, setSelectedDayRange] = useState(
    defaultSelectedDayRange,
  );

  const [anchorEl, setAnchorEl] = useState(null);

  const inputValue = useMemo(() => {
    const { from, to } = selectedDayRange;

    if (!to) {
      return '';
    }

    const start = new Date(from.year, from.month - 1, from.day);

    const end = new Date(to.year, to.month - 1, to.day);

    return `${moment(start).format(`DD MMM YYYY`)} - ${moment(end).format(
      'DD MMM YYYY',
    )}`;
  }, [selectedDayRange, moment, t]);

  const handleClick = useCallback(
    event => {
      if (!disabled) {
        setAnchorEl(event.currentTarget);
      }
    },
    [disabled],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? 'calendar-popover' : undefined;

  const handleChange = useCallback(
    date => {
      setSelectedDayRange(date);

      const { from, to } = date;

      const format = (date, where) => {
        return new Date(
          date.year,
          date.month - 1,
          date.day,
          where === 'from' ? 3 : 24,
          0,
          0,
          0,
        ).toISOString();
      };

      if (onChange) {
        if (!to) {
          return onChange(null);
        }
        onChange({
          start_time: format(from, 'from'),
          end_time: format(to, 'to'),
        });
        handleClose();
      }
    },
    [onChange, handleClose],
  );

  return (
    <Fragment>
      <div className="calendar-container" onClick={handleClick}>
        <TextInput
          id={id}
          value={inputValue}
          leftAddons={
            <CalendarIcon size="smallmed" className="ml-2" color="secondary" />
          }
          aria-describedby="input-calendar"
          readOnly
        />
      </div>
      <Popover
        anchorEl={anchorEl}
        classes={{
          paper: 'date-picker-popover',
          root: 'date-picker-root',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        id={id}
        onClose={handleClose}
      >
        <Calendar
          colorPrimary="var(--primary-color)"
          calendarClassName="calendar-container__datepicker"
          colorPrimaryLight="var(--player-background)"
          shouldHighlightWeekends
          locale={MyCustomLocale()}
          value={selectedDayRange}
          onChange={handleChange}
          maximumDate={utils().getToday()}
        />
      </Popover>
    </Fragment>
  );
};

export default withTranslation()(memo(CalendarPicker));
