import {axiosInstance} from "src/store/helpers";
import { getTokenData, setTokenData } from '../../../utils/tokens';


export const uploadAttachment = async (attachment) => {
    const {
        name,
        data
    } = attachment;

    const res = await axiosInstance.post("/api/attachment", {
        fileName: name,
        file: data.replace(/^data:image\/[a-z-]+;base64,/, "")
    }, {
        headers: {
            "Content-Type": "application/json",
        }
    });

    if(res.status === 200) {
        return res.data;
    }

    return null;
};

export const updateAttachment = async (id, attachment) => {
    const {
        name,
        data
    } = attachment;

    const res = await axiosInstance.post(`/api/attachment/${id}`, {
        fileName: name,
        file: data.replace(/^data:image\/[a-z-]+;base64,/, "")
    }, {
        headers: {
            "Content-Type": "application/json"
        }
    });

    if(res.status === 200) {
        return res.data;
    }

    return res;
};

export const getAttachmentsApi = async () => {
    return await axiosInstance.get("/api/attachments");
};

export const signUpAPI = async (data) => {
    try {
        const response = await axiosInstance.post("/auth/register", data);

        setTokenData(response.data,true)


        return response;
    }
    catch(err) {
        throw new Error(err);
    }
};

export const deleteUserAttachmentsAPI = async (id) => {
    return await axiosInstance.delete(`/api/attachment/${id}`,);
};

export const activateUser = async data => {
    const response = await axiosInstance.post("/auth/sign-up", data);

    setTokenData(response.data,true)


    return response;
};

export const refreshToken = async () => {
    const { refreshToken } = getTokenData();
    return axiosInstance.post(`auth/refresh-token`,
      { refresh_token: refreshToken }
    );
};

export const signOut = async (session_id) => {
    return axiosInstance.post(`api/sign-out`, { session_id });
};
