import {useMemo, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import * as dateFns from "date-fns";

import {authActions} from "src/store";
import {useShowMessage} from "./useShowMessage";


const useProfile = (isShowMessage) => {
    const dispatch = useDispatch();
    const {
        userData,
        isAuthenticated = false,
        isProfileLoaded = false
    } = useSelector((state) => state.auth);
    const showMessage = useShowMessage();

    useEffect(() => {
        (async () => {
            if(isAuthenticated && !isProfileLoaded) {
                const res = await dispatch(authActions.fetchMe());

                if(isShowMessage) {
                    showMessage(res);
                }
            }
        })();
    }, [isProfileLoaded, dispatch, showMessage, isAuthenticated, isShowMessage]);

    return useMemo(() => {
        if(isAuthenticated && isProfileLoaded) {
            const {
                kyc_verified: kycVerified = false,
                country,
                name,
                date_of_birth
            } = userData || {};

            const birthDate = new Date(date_of_birth);

            return {
                ...userData,
                date_of_birth: dateFns.isValid(birthDate) && dateFns.getYear(birthDate) >= 1951 ? date_of_birth : "",
                isFilled: kycVerified && !!country && !!name
            };
        }

        return null;
    }, [isAuthenticated, isProfileLoaded, userData]);
};


export {useProfile};