import { axiosInstance } from 'src/store/helpers';
import { prepareAxiosError } from 'src/utils';

import {
  SET_TOKEN,
  SET_REFRESH,
  SET_SESSION,
  SET_USER_DATA,
  SET_ATTACHMENTS,
  SET_VERIFICATION_METHODS,
  SET_USER_VERIFICATION_METHOD,
} from './action-types';
import {
  getUserVerificationMethod,
  getVerificationsMethod,
  setVerificationsMethod,
} from '@services/api/user';
import { toast } from 'react-toastify';
import {
  getTokenData,
  setTokenData,
  clearTokenData,
} from '../../../utils/tokens';
import { getPaymentTransactions } from '@services/api/wallet';

export const setTokenAction = token => {
  return {
    type: SET_TOKEN,
    payload: {
      token,
    },
  };
};

export const setRefresh = refreshToken => {
  return {
    type: SET_REFRESH,
    payload: {
      refreshToken,
    },
  };
};

export const setSessionId = sessionId => {
  return {
    type: SET_SESSION,
    payload: {
      sessionId,
    },
  };
};

export const setUserData = user => {
  return {
    type: SET_USER_DATA,
    payload: {
      user,
    },
  };
};

export const setAttachments = attachments => {
  return {
    type: SET_ATTACHMENTS,
    payload: {
      attachments,
    },
  };
};

export const resetPassword = email => {
  return async () => {
    try {
      const res = await axiosInstance.post(
        '/auth/reset-password',
        {
          email,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          validateStatus: () => true,
        },
      );

      if (res.status === 200) {
        return {
          status: 'OK',
        };
      }

      return prepareAxiosError(res);
    } catch (err) {
      return {
        status: 'ERROR',
        message: err.message,
      };
    }
  };
};

export const newPassword = (email, code, password) => {
  return async () => {
    try {
      const res = await axiosInstance.post(
        '/auth/new-password',
        {
          email,
          code,
          password,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          validateStatus: () => true,
        },
      );

      if (res.status === 200) {
        return {
          status: 'OK',
          message: 'Password changed successfully',
        };
      }

      return prepareAxiosError(res);
    } catch (err) {
      return {
        status: 'ERROR',
        message: err.message,
      };
    }
  };
};

export const changePassword = (oldPassword, newPassword) => {
  return async () => {
    try {
      const res = await axiosInstance.post(
        '/api/change-password',
        {
          password_old: oldPassword,
          password_new: newPassword,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          validateStatus: () => true,
        },
      );

      if ([200, 201].includes(res.status)) {
        return {
          status: 'OK',
          message: 'Password changed successfully',
        };
      }

      return prepareAxiosError(res);
    } catch (err) {
      return {
        status: 'ERROR',
        message: err.message,
      };
    }
  };
};

export const login = data => {
  const { isRemember = false, email, password } = data;

  return async dispatch => {
    try {
      const res = await axiosInstance.post('/auth/sign-in', {
        email,
        password,
      });

      if (res.status === 200) {
        const {
          data: { token, refresh_token, session_id },
        } = res;
        setTokenData(res.data, isRemember);
        return dispatch(fetchMe()).then(async reserve => {
          if (reserve && reserve.item.role === 'company_owner') {
            dispatch(logout());
            return {
              status: 'ERROR',
              message: 'You are an advertiser',
            };
          } else {
            dispatch(setTokenAction(token));
            dispatch(setRefresh(refresh_token));
            dispatch(setSessionId(session_id));
            const method = await dispatch(getUserVerificationMethodAction());
            if (!method) {
              const methods = await dispatch(getVerificationsMethodAction());
              dispatch(
                setVerificationMethodAction({
                  views_verification_method_id: methods.find(
                    v => v.title === 'Captcha',
                  )?.id,
                }),
              );
            }
            return {
              status: 'OK',
              message: 'You have successfully logged in',
            };
          }
        });
      }

      return prepareAxiosError(res);
    } catch (err) {
      return {
        status: 'ERROR',
        message: err.message,
      };
    }
  };
};

export const logout = () => {
  return async dispatch => {
    try {
      const { sessionId } = getTokenData();

      await axiosInstance.post(
        '/api/sign-out',
        {
          session_id: Number(sessionId),
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          validateStatus: () => true,
        },
      );

      clearTokenData();

      return {
        status: 'OK',
      };
    } catch (err) {
      console.error(err);

      clearTokenData();

      return {
        status: 'ERROR',
        message: err.message,
      };
    }
  };
};

export const refresh = () => {
  return async dispatch => {
    const { refreshToken } = getTokenData();
    const isRemember = localStorage.getItem('isRemember');
    try {
      const res = await axiosInstance.post(
        '/auth/refresh-token',
        {
          refresh_token: refreshToken,
        },
        {
          validateStatus: () => true,
        },
      );

      if (res.status === 200) {
        const {
          data: { token, refresh_token, session_id },
        } = res;
        setTokenData(res.data, isRemember === 'true');
        dispatch(setTokenAction(token));
        dispatch(setRefresh(refresh_token));
        dispatch(setSessionId(session_id));

        return {
          status: 'OK',
        };
      }

      await dispatch(logout());

      return prepareAxiosError(res);
    } catch (err) {
      return {
        status: 'ERROR',
        message: err.message,
      };
    }
  };
};

export const fetchMe = () => {
  return async dispatch => {
    try {
      const res = await axiosInstance.get('/api/user');

      if (res.status === 200) {
        const { data } = res;

        dispatch(setUserData(data));

        return {
          status: 'OK',
          item: data,
        };
      }

      return prepareAxiosError(res);
    } catch (err) {
      return {
        status: 'ERROR',
        message: err.message,
      };
    }
  };
};

export const fetchAttachments = () => {
  return async dispatch => {
    try {
      const res = await axiosInstance.get('/api/attachments');

      if (res.status === 200) {
        const { data } = res;

        dispatch(setAttachments(data));

        return {
          status: 'OK',
          message: '',
        };
      }

      return prepareAxiosError(res);
    } catch (err) {
      return {
        status: 'ERROR',
        message: err.message,
      };
    }
  };
};

export const updateProfile = data => {
  return async dispatch => {
    try {
      const res = await axiosInstance.put('/api/user', data, {
        validateStatus: () => true,
      });

      if (res.status === 200) {
        const { data } = res;

        dispatch(setUserData(data));

        return {
          status: 'OK',
          message: 'You success edit profile',
          item: data,
        };
      }

      return prepareAxiosError(res);
    } catch (err) {
      return {
        status: 'ERROR',
        message: err.message,
      };
    }
  };
};

export const register = data => {
  return async () => {
    try {
      const { attachments = [], ...otherData } = data;

      const res = await axiosInstance.post(
        '/auth/register',
        {
          ...(attachments.length > 0
            ? {
                attachments: attachments.map(attachment => {
                  const { file } = attachment;

                  return {
                    ...attachment,
                    file: file.replace(/^data:image\/[a-z-]+;base64,/, ''),
                  };
                }),
              }
            : {}),
          ...otherData,
        },
        {
          validateStatus: () => true,
        },
      );

      if ([200, 201].includes(res.status)) {
        return {
          status: 'OK',
        };
      }

      return prepareAxiosError(
        res,
        'An error occurred while trying to register with INFOCOIN',
      );
    } catch (err) {
      return {
        status: 'ERROR',
        message: err.message,
      };
    }
  };
};

export const signUp = data => {
  return async () => {
    try {
      const res = await axiosInstance.post('/auth/sign-up', data, {
        validateStatus: () => true,
      });

      if ([200, 201].includes(res.status)) {
        return {
          status: 'OK',
          message: 'You have successfully registered',
        };
      }

      return prepareAxiosError(res);
    } catch (err) {
      return {
        status: 'ERROR',
        message: err.message,
      };
    }
  };
};

export const registerPhone = phone => {
  return async () => {
    try {
      const res = await axiosInstance.post(
        '/api/register-phone',
        { phone },
        {
          validateStatus: () => true,
        },
      );

      if (res.status === 200) {
        return {
          status: 'OK',
        };
      }

      return prepareAxiosError(res);
    } catch (err) {
      return {
        status: 'ERROR',
        message: err.message,
      };
    }
  };
};

export const confirmPhone = (phone, code) => {
  return async () => {
    try {
      const res = await axiosInstance.post(
        '/api/confirm-phone',
        {
          phone,
          code,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          validateStatus: () => true,
        },
      );

      if (res.status === 200) {
        return {
          status: 'OK',
          message: 'Your phone has been successfully registered',
        };
      }

      return prepareAxiosError(res);
    } catch (err) {
      return {
        status: 'ERROR',
        message: err.message,
      };
    }
  };
};

export const setPayout = data => async () => {
  try {
    const res = await axiosInstance.post('/api/payout/pay-pal', data, {
      validateStatus: () => true,
    });

    if (res.status === 200) {
      return {
        status: 'OK',
      };
    }

    return prepareAxiosError(res);
  } catch (err) {
    return {
      status: 'ERROR',
      message: err.message,
    };
  }
};

export const fetchTransactions = () => {
  return async () => {
    try {
      const res = await axiosInstance.get('/api/transactions', {
        validateStatus: () => true,
      });

      const res1 = await axiosInstance.get('/api/transactions/user', {
        validateStatus: () => true,
      });

      if (res.status === 200) {
        const { data } = res;
        const { data: data1 } = res1;

        const result = data.concat(
          data1
            .filter(tr => tr.status === 'in_verifying')
            .map(tr => {
              return {
                sum: `${tr.amount} IC`,
                type: tr.status,
                id: tr.id,
                created_at: tr.created_at,
              };
            }),
        );

        return {
          status: 'OK',
          items: result,
        };
      }

      return prepareAxiosError(res);
    } catch (err) {
      return {
        status: 'ERROR',
        message: err.message,
      };
    }
  };
};

export const fetchVideoComplaints = params => {
  return async () => {
    try {
      const res = await axiosInstance.get('/api/check-lists', {
        params,
        validateStatus: () => true,
      });

      if (res.status === 200) {
        const { data } = res;

        const deepToArray = (list, parentId = 0) => {
          return list.reduce((res, item) => {
            const { children = [], ...data } = item;

            return [
              ...res,
              { ...data, parentId },
              ...deepToArray(children, data.id),
            ];
          }, []);
        };

        return {
          status: 'OK',
          items: data,
        };
      }

      return prepareAxiosError(res);
    } catch (err) {
      return {
        status: 'ERROR',
        message: err.message,
      };
    }
  };
};

export const fetchEmailStatus = email => {
  return async () => {
    try {
      const res = await axiosInstance.post('/auth/user/status', { email });
      if (res.status === 200) {
        const {
          data: { activated },
        } = res;

        return {
          status: 'OK',
          activated,
        };
      }

      return prepareAxiosError(res);
    } catch (err) {
      return {
        status: 'ERROR',
        message: err.message,
      };
    }
  };
};

export const fetchResendCodeRegister = email => {
  return async () => {
    try {
      const res = await axiosInstance.post(
        '/auth/temp-code',
        { email },
        {
          validateStatus: () => true,
        },
      );

      if (res.status === 200) {
        const {
          data: { activated },
        } = res;

        return {
          status: 'OK',
          activated,
        };
      }

      return prepareAxiosError(res);
    } catch (err) {
      return {
        status: 'ERROR',
        message: err.message,
      };
    }
  };
};

export const createIntolerable = data => {
  return async () => {
    try {
      const res = await axiosInstance.post('/api/intolerable', data, {
        validateStatus: () => true,
      });

      if ([200, 201].includes(res.status)) {
        return {
          status: 'OK',
        };
      }

      return prepareAxiosError(res);
    } catch (err) {
      return {
        status: 'ERROR',
        message: err.message,
      };
    }
  };
};

export const support = data => {
  return async () => {
    try {
      const res = await axiosInstance.post('/api/support', data, {
        validateStatus: () => true,
      });

      if ([200, 201].includes(res.status)) {
        return {
          status: 'OK',
          message: 'Your message has been successfully sent',
        };
      }

      return prepareAxiosError();
    } catch (err) {
      return {
        status: 'ERROR',
        message: err.message,
      };
    }
  };
};

export const getVerificationsMethodAction = () => async dispatch => {
  try {
    const methods = await getVerificationsMethod();
    dispatch({
      type: SET_VERIFICATION_METHODS,
      payload: methods.data,
    });
    return methods.data;
  } catch (err) {
    toast.error(err.message);
  }
};

export const getUserVerificationMethodAction = () => async dispatch => {
  try {
    const method = await getUserVerificationMethod();
    dispatch({
      type: SET_USER_VERIFICATION_METHOD,
      payload: method.data,
    });
    return method.data;
  } catch (err) {}
};

export const setVerificationMethodAction = data => async dispatch => {
  try {
    await setVerificationsMethod(data);
    dispatch(getUserVerificationMethodAction());
  } catch (err) {
    toast.error(err.message);
  }
};
