import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";
import {Grid} from "@mui/material";
import {useFormContext} from "react-hook-form";

import {validatePassword} from "src/utils";
import {
    Typography,
    FormSubmit
} from "src/views/blocks";
import {PasswordControl} from "src/views/controls";


const PasswordForm = () => {
    const {t} = useTranslation();
    const {watch} = useFormContext();
    const password = watch("password");

    const validateConfirm = useCallback((value) => {
        if(value !== password) {
            return "Passwords don't match";
        }

        return true;
    }, [password]);

    return (
        <Grid container direction="column" spacing={3}>
            <Grid item>
                <PasswordControl
                  required
                  clear
                  placeholder="Current password"
                  name="currentPassword" />
            </Grid>

            <Grid item>
                <Typography
                  className="mb-1"
                  variant="caption2">
                    {t("The password must consist of: at least 8 characters, a lowercase character, an uppercase character, a number and a special character (!,@,#,$,% etc.)")}
                </Typography>

                <PasswordControl
                  required
                  validate={{validatePassword}}
                  clear
                  placeholder="Password"
                  name="password" />
            </Grid>

            <Grid item>
                <PasswordControl
                  required
                  validate={{validateConfirm}}
                  clear
                  placeholder="Confirm password"
                  name="confirmPassword" />
            </Grid>

            <Grid item container justifyContent="center">
                <Grid item>
                    <FormSubmit size="large">
                        {t("Submit")}
                    </FormSubmit>
                </Grid>
            </Grid>
        </Grid>
    );
};


export {PasswordForm};