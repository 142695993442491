import {registrationReducer} from "src/redux/reducers/registration";
import {initialState} from "./state";


const reducer = (state = initialState, action) => {
    switch(action.type) {
        default:
            return registrationReducer(state, action);
    }
};


export {reducer};