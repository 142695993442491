import { axiosInstance } from 'src/store/helpers';

export const getHistory = async (limit = 10, offset = 0) => {
  return await axiosInstance.get(
    `api/user/view/history?limit=${limit}&offset=${offset}`,
  );
};

export const subCategory = async id => {
  return axiosInstance.post(`api/user/category`, { category_id: id });
};

export const getSessions = async () => {
  return axiosInstance.get('/api/sessions');
};

export const unSubCategory = async id => {
  return axiosInstance.delete(
    `api/user/category/${id}` /*{
    headers: {
      'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE',
    },
  }*/,
  );
};

export const getCategories = async () => {
  return axiosInstance.get(`api/categories`);
};

export const getUserCategories = async () => {
  return axiosInstance.get(`api/user/categories`);
};

export const getWallet = async () => {
  return axiosInstance.get(`api/wallet`);
};

export const addUserView = async () => {
  return axiosInstance.post(`api/user/view`, { video_ids: [89, 90] });
};

export const getNotifications = async () => {
  return await axiosInstance.get(`/api/notifications`);
};

export const postNotifications = async data => {
  return await axiosInstance.post(`/api/notification/sub`, data);
};

export const deleteNotifications = async data => {
  return await axiosInstance.delete(
    `/api/notification/unsub?message_type=${data}`,
  );
};

export const getLikesByVideos = async (id, start_time, end_time) => {
  return await axiosInstance.get(
    `/api/likes/${id}?start_time=${start_time}&end_time=${end_time}`,
  );
};

export const getVerificationsMethod = () => {
  return axiosInstance.get(`auth/views-verification-methods`);
};

export const getUserVerificationMethod = () => {
  return axiosInstance.get(`api/views-verification-method`);
};

export const setVerificationsMethod = data => {
  return axiosInstance.post(`api/views-verification-method`, data);
};

export const updateUserEmail = async email => {
  return await axiosInstance.post(`api/user/email`, {
    email,
  });
};

export const confirmUserEmail = async code => {
  return await axiosInstance.post(`api/user/confirm-email`, {
    code,
  });
};

export const getTempCodeLimit = () => {
  return axiosInstance.get(`api/temp-code-limit`);
};
