import {
    parseISO,
    isValid,
    format
} from "date-fns";


const isoFormat = (iso, dateFormat) => {
    const date = parseISO(iso);

    if(isValid(date)) {
        return format(date, dateFormat);
    }

    return "";
};


export {isoFormat};