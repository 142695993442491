import { SET_CATEGORIES, SET_FOLLOWS } from '../constants';

export const setCategoriesAction = categories => dispatch => {
  return dispatch({
    type: SET_CATEGORIES,
    payload: categories,
  });
};

export const setFollowsAction = follows => {
  return {
    type: SET_FOLLOWS,
    payload: follows,
  };
};
