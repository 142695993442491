import {
  SET_VIDEO_HISTORY,
  SET_VIDEO_HISTORY_LIMIT,
  SET_VIDEO_HISTORY_OFFSET,
} from '../constants';

const initialState = {
  limit: 10,
  offset: 0,
  videos: [],
};

export function videoHistoryReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_VIDEO_HISTORY:
      return {
        ...state,
        videos: payload,
      };
    case SET_VIDEO_HISTORY_LIMIT:
      return {
        ...state,
        limit: payload,
      };
    case SET_VIDEO_HISTORY_OFFSET:
      return {
        ...state,
        offset: payload,
      };
    default:
      return state;
  }
}
