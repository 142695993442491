import React, { memo, useMemo } from 'react';

import { TextInput } from '../../../../../inputs';
import { Grid } from '@mui/material';
import { Button, Typography } from '../../../../../blocks';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import { Spinner } from 'react-bootstrap';

import { usePaymentInputs } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';
import * as Yup from 'yup';

const PaymentForm = ({ onSubmit, withdrawalLimit, balance, user }) => {
  const { t } = useTranslation();

  const { max_payout, min_payout } = withdrawalLimit;

  const ERROR_MESSAGES = useMemo(
    () => ({
      emptyCardNumber: t('The card number is empty'),
      invalidCardNumber: t('The card number is invalid'),
    }),
    [t],
  );

  const { getCardNumberProps, getCardImageProps, meta } = usePaymentInputs({
    errorMessages: ERROR_MESSAGES,
  });

  const PaymentSchema = Yup.object().shape({
    sum: Yup.number()
      .min(min_payout, `The minimum withdrawal starts from ${min_payout} IC`)
      .test('DOB', 'Withdrawal limit exceeded', value => {
        return max_payout !== 0 ? Number(value) <= max_payout : true;
      })
      .max(balance, 'Insufficient funds'),
    cardNumber: Yup.string().test('cardNumber', 'error', () => {
      return !meta.erroredInputs.cardNumber;
    }),
  });

  const {
    handleChange,
    values,
    setFieldTouched,
    touched,
    handleSubmit,
    errors,
    isSubmitting,
  } = useFormik({
    initialValues: {
      cardNumber: '',
      sum: '50',
    },
    validationSchema: PaymentSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      setSubmitting(true);

      await onSubmit(values);

      setSubmitting(false);
      resetForm();
    },
  });

  return (
    <Grid mt={2}>
      <form onSubmit={handleSubmit}>
        <Grid item>
          <TextInput
            leftAddons={
              <svg className="ml-2" {...getCardImageProps({ images })} />
            }
            {...getCardNumberProps({
              refKey: 'inputRef',
              onChange: handleChange,
              value: values.cardNumber,
            })}
            mask="9999 9999 9999 9999"
            error={!!meta.error}
            maskChar={null}
            onFocus={() => setFieldTouched('cardNumber', true)}
            helperText={touched.cardNumber && meta.error}
          />
        </Grid>
        <Grid mt={3}>
          <TextInput
            placeholder={t('Amount')}
            type="sum"
            value={values.sum}
            error={!!errors.sum}
            helperText={touched.sum && t(errors.sum)}
            onBlur={() => setFieldTouched('sum', true)}
            name="sum"
            onChange={handleChange}
            leftAddons={
              <Grid ml={1} color="gray">
                IC
              </Grid>
            }
          />
        </Grid>
        <Grid item width="100%" mt={3}>
          {user?.kyc_verified ? (
            <Button
              variant="primary"
              size="medium"
              disabled={isSubmitting}
              type="submit"
              onClick={handleSubmit}
              fullWidth
            >
              {isSubmitting ? (
                <Spinner animation="border" variant="light" size="sm" />
              ) : (
                t('Accept')
              )}
            </Button>
          ) : (
            <>
              <Typography
                component="span"
                variant="body2"
                color="gray"
                weight="normal-bold"
                className="mr-1"
              >
                {t(
                  'You have not activated your phone number, to activate go to the',
                )}
              </Typography>
              <Typography
                component={Link}
                variant="body2"
                color="primary"
                weight="normal-bold"
                to="/profile"
                className="cursor-pointer hover-opacity"
                inline
              >
                {t('Profile page')}
              </Typography>
            </>
          )}
        </Grid>
      </form>
    </Grid>
  );
};

const mapStateToProps = ({ wallet, auth }) => ({
  withdrawalLimit: wallet.withdrawalLimit,
  balance: wallet.balance,
  user: auth.userData,
});

export default connect(mapStateToProps, {})(memo(PaymentForm));
