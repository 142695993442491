import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Loader from '@components/Loader';
import { ROUTES } from 'src/routing';
import classes from './index.module.scss';
import { AuthSelectCountry } from './AuthSelectCountry';
import { AuthTextField } from 'views/blocks/AuthTextField';
import { SignupSchema } from 'utils/formikShema';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { DateGroup } from 'views/inputs';
import { GenerateIcon } from 'views/icons';
import { Tooltip, Checkbox, FormControlLabel } from '@mui/material';
import { Typography } from '../../../../blocks';

const FirstForm = ({ setShowModal, onSubmit }) => {
  const { t } = useTranslation();
  const { regUserData } = useSelector(state => state.registrationReducer);
  const {
    values,
    errors,
    touched,
    setFieldTouched,
    handleChange,
    setFieldValue,
    handleSubmit,
    isValid,
    isSubmitting,
    setValues,
  } = useFormik({
    enableReinitialize: true,
    initialValues: regUserData,
    validationSchema: SignupSchema,
    onSubmit: onSubmit,
  });

  const handleChangeCountry = country => setFieldValue('country', country);

  const handleChangeDateOfBirth = date => {
    setFieldValue('date_of_birth', date);
  };

  const handleToggleModal = useCallback(e => {
    e.preventDefault();
    setShowModal(true);
  }, []);

  const generatePassword = useCallback(() => {
    const field =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz$#!%*?&0123456789';

    function generate(length, charset) {
      let password = '';
      for (let i = 0; i < length; ++i) {
        password += charset[Math.floor(Math.random() * charset.length)];
      }
      return password;
    }

    function placeInCenter(str, substr) {
      let index = (str.length - substr.length) / 2;
      return str.substr(0, index) + substr + str.substr(index + substr.length);
    }

    const pass = placeInCenter(generate(10, field), '1Q!w');

    setValues({
      ...values,
      password: pass,
      confirmPassword: pass,
    });
  }, [setValues, values]);

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <div>
          <AuthTextField
            name="email"
            onChange={handleChange}
            touched={touched.email}
            onBlur={() => setFieldTouched('email')}
            label={t('Email')}
            error={t(errors.email)}
            type="email"
            value={values.email}
          />
          <AuthTextField
            classNameContainer="mt-2"
            name="name"
            onChange={handleChange}
            onBlur={() => setFieldTouched('name')}
            touched={touched.name}
            error={t(errors.name)}
            label={t('Full name')}
            type="text"
            value={values.name}
          />
          <DateGroup
            onChange={handleChangeDateOfBirth}
            value={values.date_of_birth}
            isBorder={true}
            helperText={errors.date_of_birth}
            label={t('Date of Birthday')}
            className="mt-2 auth-date-group"
          />
          <AuthTextField
            classNameContainer="mt-2"
            name="password"
            touched={touched.password}
            onBlur={() => setFieldTouched('password')}
            error={t(errors.password)}
            onChange={handleChange}
            label={t('Password')}
            type="password"
            rightAddons={
              <Tooltip title={t('Generate password')}>
                <div onClick={generatePassword}>
                  <GenerateIcon className="mr-2" />
                </div>
              </Tooltip>
            }
            value={values.password}
          />
        </div>
        <p
          className={classes.message_password}
          style={{
            color: errors.password && touched.password ? '#ee1717' : '#9596a2',
          }}
        >
          {t(
            'The password must consist of: at least 8 characters, a lowercase character, an uppercase character, a number and a special character (!,@,#,$,% etc.)',
          )}
        </p>
        <div>
          <AuthTextField
            classNameContainer="mt-2"
            name="confirmPassword"
            onBlur={() => setFieldTouched('confirmPassword')}
            onChange={handleChange}
            touched={touched.confirmPassword}
            label={t('Confirm password')}
            error={t(errors.confirmPassword)}
            type="password"
            value={values.confirmPassword}
          />
        </div>
        <div>
          <AuthSelectCountry
            label={t('Country')}
            onChange={handleChangeCountry}
            value={values.country}
            defaultValue={values.country}
            name="country"
            classNameContainer="mt-2"
          />
        </div>

        <div>
          <FormControlLabel
            className="mt-3"
            control={
              <Checkbox
                checked={values.acceptPrivacy}
                onChange={handleChange}
                name="acceptPrivacy"
                inputProps={{ 'aria-label': 'controlled' }}
                sx={{
                  color: 'var(--primary-color)',
                  '&.Mui-checked': {
                    color: 'var(--primary-color)',
                  },
                }}
              />
            }
            label={
              <>
                <Typography variant="caption2" color="gray">
                  {t('I have read and agree to')}
                  <Typography
                    variant="caption2"
                    color="primary"
                    component="span"
                    onClick={handleToggleModal}
                  >
                    {' '}
                    {t('Terms of Service on login page', {
                      context: 'Terms of Service',
                    })}
                  </Typography>{' '}
                  {t('and')}{' '}
                  <Typography
                    variant="caption2"
                    color="primary"
                    component="span"
                    onClick={handleToggleModal}
                  >
                    {t('Privacy Policy on login page', {
                      context: 'Privacy Policy',
                    })}
                  </Typography>
                </Typography>
              </>
            }
          />
          {errors.acceptPrivacy && (
            <Typography variant="caption2" color="error">
              {t(
                'The user is responsible for the completeness and accuracy of the information provided',
              )}
            </Typography>
          )}
        </div>
        <div className={classes.authButton}>
          <button type="submit" disabled={!isValid}>
            {isSubmitting ? (
              <Loader color="white" width={30} height={30} />
            ) : (
              t('Register account')
            )}
          </button>
        </div>
        <div className={classes.other_content}>
          {t('Already have an account?')}
          <span>
            &nbsp;
            <Link to={ROUTES.login}>{t('Sign In')}</Link>
          </span>
        </div>
      </div>
    </form>
  );
};

export { FirstForm };
