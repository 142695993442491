import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Collapse, Grid } from '@mui/material';
import Scrollbar from 'react-perfect-scrollbar';

import { authActions } from 'src/store';
import { useShowMessage } from 'src/hooks';
import {
  Button,
  IconButton,
  LoaderOverlay,
  Typography,
} from 'src/views/blocks';
import {
  AngleRightIcon,
  CloseIcon,
  SuccessIcon,
  LogoIcon,
} from 'src/views/icons';
import { RadioButton } from 'src/views/inputs';

import './index.scss';

const PlayerComplaintModal = props => {
  const { open = false, video, onClose } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const showMessage = useShowMessage();
  const [isLoading, setLoading] = useState(false);
  const [parentId, setParentId] = useState(0);
  const [selectId, setSelectId] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [complaints, setComplaints] = useState([]);
  const [success, setSuccess] = useState(false);

  const modifications = clsx({
    'complaint-modal--open': open,
  });

  const handleParentClick = useCallback(
    id => {
      if (parentId === id) {
        setParentId(null);
      } else {
        setParentId(id);
      }

      setSelectId(null);
    },
    [parentId],
  );

  const handleChange = useCallback(
    e => {
      const {
        target: { value },
      } = e;

      const id = parseInt(value);

      const hasChild =
        complaints.filter(complaint => complaint.parentId === id).length > 0;

      if (hasChild) {
        setParentId(id);
        setSelectId(null);
      } else {
        setSelectId(id);
      }
    },
    [complaints],
  );

  /*const handleBack = useCallback(() => {
        const item = complaints.find((complaint) => complaint.id === parentId);

        setSelectId(null);

        if(item) {
            setParentId(item.parentId);
        }
        else {
            setParentId(0);
        }
    }, [parentId, complaints]);*/

  const handleClose = useCallback(() => {
    setSelectId(null);
    setParentId(0);
    setSuccess(false);

    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const handleSubmit = useCallback(async () => {
    if (!selectId || submitting) {
      return;
    }

    setSubmitting(true);

    const res = await dispatch(
      authActions.createIntolerable({
        video_id: video.id,
        video_check_list_id: selectId,
      }),
    );

    showMessage(res);

    if (res.status === 'OK') {
      setSuccess(true);
    }

    setSubmitting(false);
  }, [selectId, submitting, video, dispatch, showMessage]);

  useEffect(() => {
    (async () => {
      setLoading(true);

      const res = await dispatch(
        authActions.fetchVideoComplaints({
          country: 'default',
        }),
      );

      showMessage(res);

      if (res.status === 'OK') {
        const { items } = res;

        setComplaints(items);
        setLoading(false);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`complaint-modal ${modifications}`}>
      <div className="complaint-modal__wrapper">
        <Grid container className="mb-3" justifyContent="space-between">
          <Grid item sx={{ color: 'white' }}>
            {t('Submit a video complaint')}
          </Grid>
          <Grid item>
            <IconButton
              color="primary"
              size="medium"
              icon={CloseIcon}
              onClick={handleClose}
            />
          </Grid>
        </Grid>

        <Scrollbar className="complaint-modal__content">
          <Grid container spacing={3} direction="column">
            {!success &&
              complaints.map(complaint => {
                const { id, title } = complaint;

                const hasChild = complaint?.children?.length > 0;

                return (
                  <Grid key={complaint.id} item>
                    {hasChild && (
                      <>
                        <Button
                          theme="dark"
                          leftAddons={<AngleRightIcon size="small" />}
                          color="secondary"
                          align="left"
                          value={id}
                          onClick={() => {
                            handleParentClick(id);
                          }}
                        >
                          {t(title)}
                        </Button>
                        <Collapse in={parentId === id} className="collapse_hasChild" orientation="vertical">
                          {complaint.children.map(child => (
                            <Grid
                              sx={{
                                padding: '10px 0 0',
                              }}
                              key={child.id}
                              item
                            >
                              <RadioButton
                                name="complaint"
                                color="white"
                                checked={child.id === selectId}
                                value={child.id}
                                onClick={handleChange}
                              >
                                {t(child.title)}
                              </RadioButton>
                            </Grid>
                          ))}
                        </Collapse>
                      </>
                    )}

                    {!hasChild && (
                      <RadioButton
                        name="complaint"
                        color="white"
                        checked={id === selectId}
                        value={id}
                        onClick={handleChange}
                      >
                        {t(title)}
                      </RadioButton>
                    )}
                  </Grid>
                );
              })}

            {success && (
              <React.Fragment>
                <Grid item container justifyContent="center">
                  <Grid item>
                    <LogoIcon size="large" />
                  </Grid>
                </Grid>

                <Grid item>
                  <Typography variant="h3" color="white" align="center" bold>
                    {t('Complaint sent')}
                  </Typography>
                </Grid>

                <Grid item container justifyContent="center">
                  <Grid item className="mt-5 mb-5">
                    <SuccessIcon size="large" />
                  </Grid>
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        </Scrollbar>

        <Grid
          container
          className="complaint-modal__footer"
          justifyContent="center"
        >
          {!success && (
            <Grid item>
              <Button
                variant="primary"
                size="large"
                disabled={!selectId || submitting}
                onClick={handleSubmit}
              >
                {t('Submit')}
              </Button>
            </Grid>
          )}
        </Grid>

        <LoaderOverlay open={isLoading} />
      </div>
    </div>
  );
};

export { PlayerComplaintModal };
