import React, { useCallback } from 'react';
import clsx from 'clsx';
import { Grid } from '@mui/material';

import { Typography } from 'src/views/blocks';

import './index.scss';

const qualities = [
  {
    label: '1080p Full HD',
    value: '1080',
  },
  {
    label: '720p HD',
    value: '720',
  },
  {
    label: '480p',
    value: '480',
  },
];

const PlayerQualityModal = props => {
  const { className = '', open = false, value, video, onChange } = props;

  const modifications = clsx({
    'player-quality-modal--open': open,
  });

  const handleClick = useCallback(
    quantity => {
      if (onChange) {
        onChange(quantity);
      }
    },
    [onChange],
  );

  return (
    <div className={`player-quality-modal ${className} ${modifications}`}>
      <Grid container direction="column" spacing={2}>
        {video.filepath_1080_p && (
          <Grid item>
            <Typography
              className="quality-text"
              color={value === '1080' ? 'primary' : 'white'}
              bold
              onClick={() => handleClick('1080')}
            >
              1080p Full HD
            </Typography>
          </Grid>
        )}
        {video.filepath_720_p && (
          <Grid item>
            <Typography
              className="quality-text"
              color={value === '720' ? 'primary' : 'white'}
              bold
              onClick={() => handleClick('720')}
            >
              720p HD
            </Typography>
          </Grid>
        )}
        {video.filepath_480_p && (
          <Grid item>
            <Typography
              className="quality-text"
              color={value === '480' ? 'primary' : 'white'}
              bold
              onClick={() => handleClick('480')}
            >
              480p
            </Typography>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export { PlayerQualityModal };
