import axios from 'axios';

import { API_URL } from 'src/config';

import { httpMockFactory } from './httpMockFactory';
import { clearTokenData, getTokenData, setTokenData } from '../../utils/tokens';
import { refreshToken, signOut } from '@services/api/auth';

let isRefreshing = null;

async function requestInterceptor(config) {
  if (
    !config.headers ||
    !config.headers['Authorization'] ||
    !config.headers['Content-Type']
  ) {
    if (!config.headers) {
      config.headers = {};
    }

    config.headers['Content-Type'] = 'application/json';
  }
  const { token } = getTokenData();

  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}

const responseErrorInterceptor = async error => {
  console.dir(error)
  if (error.response && error.response.status === 401) {
    if (error.config.url === 'api/sign-out') {
      clearTokenData();

      return;
    }
    if (error.config.url === 'auth/refresh-token') {
      clearTokenData();

      return;
    }
    try {
      if (!isRefreshing) {
        isRefreshing = refreshToken();
      }

      const {
        data: { token, refresh_token, session_id: newSessionId },
        status,
      } = await isRefreshing;

      if (status === 200) {
        setTokenData(
          { token, refresh_token, session_id: newSessionId },
          localStorage.getItem('isRemember') === 'true',
        );

        return axiosInstance.request({
          ...error.config,
          headers: {
            ...(error.config.headers || {}),
            Authorization: `Bearer ${token}`,
          },
        });
      } else {
        clearTokenData();

        await signOut(newSessionId);
      }
    } catch (e) {
      clearTokenData();
    } finally {
      isRefreshing = null;
    }
  }

  throw error;
};

const createRequestHandler = () => {
  const instance = axios.create({
    baseURL: API_URL,
  });

  instance.interceptors.response.use(
    response => response,
    responseErrorInterceptor,
  );
  instance.interceptors.request.use(requestInterceptor);

  return instance;
};

const axiosInstance = createRequestHandler();

httpMockFactory(axiosInstance);
// httpInstance.interceptors.request.use(handlePreRequest)

export { axiosInstance };
