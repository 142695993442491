import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { authActions } from 'src/store';

import { PageLoader, Toast, CookieConsentModal } from 'src/views/blocks';
import AppRouter from './views/AppRouter';
import { useWbSc } from 'hooks/useWbSc';
import './App.css';
import { clearTokenData, getTokenData, setTokenData } from './utils/tokens';
import { useHistory } from 'react-router-dom';
import { i18n } from 'src/theme/i18n';
import { setTheme } from './store/modules/ui/actions';
const App = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const [loading, setLoading] = useState(true);

  const { theme } = useSelector(state => state.ui);

  useWbSc();

  useEffect(() => {
    const { token } = getTokenData();
    const urlParams = new URLSearchParams(window.location.search);
    const urlToken = urlParams.get('token');
    const urlRefresh = urlParams.get('token');

    const language = urlParams.get('language');
    const urlTheme = urlParams.get('theme');

    if (language) {
      i18n.changeLanguage(language);
    }
    if (urlTheme) {
      dispatch(setTheme(urlTheme));
      document.body.setAttribute('data-theme', urlTheme);
    }

    if (urlToken && urlRefresh) {
      setTokenData({ token: urlToken, refresh_token: urlRefresh }, false);
      (async function () {
        try {
          await dispatch(authActions.fetchMe());
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      })();
    } else {
      if (token) {
        (async function () {
          try {
            const { status } = await dispatch(authActions.refresh());
            if (status === 'OK') {
              await dispatch(authActions.fetchMe());
            }
            setLoading(false);
          } catch (error) {
            setLoading(false);
          }
        })();
      } else {
        clearTokenData();

        setLoading(false);

        history.push('/login');
      }
    }

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    document.body.setAttribute('data-theme', theme);
  }, [theme]);

  return (
    <React.Fragment>
      <Toast />

      {loading && <PageLoader />}

      {!loading && (
        <React.Fragment>
          <AppRouter />

          <CookieConsentModal />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default App;
