import React, {
  useState,
  useRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from 'react';
import clsx from 'clsx';

import { PlayIcon } from 'src/views/icons';

import './index.scss';

const Video = memo(
  forwardRef((props, ref) => {
    const {
      className = '',
      autoPlay = false,
      volume,
      quality,
      disableControls = false,
      pictureInPicture = false,
      setPictureInPicture,
      fullscreen: propsFullscreen = false,
      video,
      onPlay,
      onTimeUpdate,
      onProgress,
      onFullscreen,
      onEnded,
    } = props;

    const { id } = video || {};

    const [fullscreen, setFullscreen] = useState(false);
    const [showQuality, setShowQuality] = useState(false);

    const [paused, setPaused] = useState(!autoPlay);

    const rootRef = useRef(null);
    const videoRef = useRef(null);

    const modifications = clsx({
      'player-item--fullscreen': fullscreen,
      'player-item--picture-in-picture': pictureInPicture,
    });

    const actionPause = useCallback(() => {
      if (videoRef.current) {
        videoRef.current.pause();
      }
    }, []);

    const actionPlay = useCallback(() => {
      if (videoRef.current) {
        const promise = videoRef.current.play();
        promise
          .then(_ => {})
          .catch(() => {
            actionPause();
            setTimeout(actionPlay, 50);
          });
      }
    }, [actionPause]);

    const handleTogglePlay = useCallback(() => {
      if (paused) {
        actionPlay();
      } else {
        actionPause();
      }
    }, [paused, actionPlay, actionPause]);

    const handlePlay = useCallback(
      e => {
        const {
          target: { paused },
        } = e;

        setPaused(paused);

        if (onPlay) {
          onPlay(e, video);
        }
      },
      [onPlay, video],
    );

    const handlePause = useCallback(e => {
      const {
        target: { paused },
      } = e;

      setPaused(paused);
    }, []);

    useEffect(() => {
      setPaused(true);
    }, [quality]);

    const handleTimeUpdate = useCallback(
      e => {
        if (onTimeUpdate) {
          onTimeUpdate(e);
        }
      },
      [onTimeUpdate],
    );

    const handleProgress = useCallback(
      e => {
        if (onProgress) {
          onProgress(e);
        }
      },
      [onProgress],
    );

    const handleEnded = useCallback(() => {
      setPaused(true);

      if (onEnded) {
        onEnded(id);
      }
    }, [id, onEnded]);

    const handleEnterPictureInPicture = useCallback(() => {
      setPictureInPicture(true);
    }, []);

    const handleLeavePictureInPicture = useCallback(() => {
      setPictureInPicture(false);
    }, []);

    const handleFullscreen = useCallback(() => {
      setFullscreen(!fullscreen);

      if (onFullscreen) {
        onFullscreen(!fullscreen);
      }
    }, [fullscreen, onFullscreen]);

    useEffect(() => {
      setFullscreen(propsFullscreen);
    }, [propsFullscreen]);

    useEffect(() => {
      if (videoRef.current) {
        const userAgent = window.navigator.userAgent;

        if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
          // iPad or iPhone
          if (pictureInPicture) {
            if (
              videoRef.current.webkitSupportsPresentationMode &&
              typeof videoRef.current.webkitSetPresentationMode === 'function'
            ) {
              // Toggle PiP when the user clicks the button.
              videoRef.current.webkitSetPresentationMode(
                videoRef.current.webkitPresentationMode === 'picture-in-picture'
                  ? 'inline'
                  : 'picture-in-picture',
              );
            }
          }
          if (!pictureInPicture) {
            if (
              videoRef.current.webkitSupportsPresentationMode &&
              typeof videoRef.current.webkitSetPresentationMode === 'function'
            ) {
              // Toggle PiP when the user clicks the button.
              videoRef.current.webkitSetPresentationMode('inline');
            }
          }
        } else {
          if (pictureInPicture) {
            videoRef.current.requestPictureInPicture().catch(err => {
              console.error(err);
            });
          }
          if (
            !pictureInPicture &&
            document.pictureInPictureElement === videoRef.current
          ) {
            document.exitPictureInPicture().catch(err => {
              console.error(err);
            });
          }
        }
      }
    }, [pictureInPicture]);

    useEffect(() => {
      const { current: video } = videoRef;

      if (video) {
        video.addEventListener(
          'enterpictureinpicture',
          handleEnterPictureInPicture,
        );
        video.addEventListener(
          'leavepictureinpicture',
          handleLeavePictureInPicture,
        );

        return () => {
          const { current: video } = videoRef;

          if (video) {
            video.removeEventListener(
              'enterpictureinpicture',
              handleEnterPictureInPicture,
            );
            video.removeEventListener(
              'leavepictureinpicture',
              handleLeavePictureInPicture,
            );
          }
        };
      }
    }, [handleEnterPictureInPicture, handleLeavePictureInPicture]);

    useEffect(() => {
      const timeout = setTimeout(() => {
        if (videoRef.current) {
          if (volume === 0) {
            videoRef.current.muted = true;
            videoRef.current.volume = volume / 100;
          } else {
            videoRef.current.muted = false;
            videoRef.current.volume = volume / 100;
          }
        }
      }, 100);

      return () => clearTimeout(timeout);
    }, [volume]);

    useEffect(() => {
      navigator.mediaSession.setActionHandler('play', function () {});
      navigator.mediaSession.setActionHandler('pause', function () {});
    }, []);

    useEffect(() => {
      const timer = setTimeout(() => {
        setShowQuality(false);
      }, 5000);

      return () => clearTimeout(timer);
    }, [showQuality]);

    useImperativeHandle(
      ref,
      () => {
        return {
          paused,
          play: actionPlay,
          pause: actionPause,
          changeQuality: quality => {
            const time = videoRef.current.currentTime;
            actionPause();
            videoRef.current.src = video[`filepath_${quality}_p`];
            actionPlay();
            videoRef.current.currentTime = time;
          },
        };
      },
      [actionPause, actionPlay, paused, video],
    );
    useEffect(() => {}, []);

    return (
      <div
        ref={rootRef}
        className={`player-item ${className} ${modifications}`}
      >
        <div className="player-item__video">
          {quality && (
            <video
              ref={videoRef}
              playsInline={!fullscreen}
              autoPlay={autoPlay}
              loop={false}
              onPlay={handlePlay}
              onPause={handlePause}
              onTimeUpdate={handleTimeUpdate}
              onProgress={handleProgress}
              onEnded={handleEnded}
              src={video[`filepath_${quality}_p`]}
              controls={false}
              disableRemotePlayback={true}
            >
              <source type="video/MP4" src={video[`filepath_720_p`]} />
              <source type="video/MP4" src={video[`filepath_480_p`]} />
              <source type="video/MP4" src={video[`filepath_1080_p`]} />
            </video>
          )}

          {!disableControls && (
            <div
              className="player-item__overlay"
              onClick={handleTogglePlay}
              onDoubleClick={handleFullscreen}
            >
              {paused && (
                <div className={'player-item__play_container'}>
                  <PlayIcon size="extra" className={'player-item__play_icon'} />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }),
);

export { Video };
