const getStorageValue = (key, defaultValue) => {
    let value = sessionStorage.getItem(key);

    if(typeof value !== "string") {
        value = localStorage.getItem(key);
    }

    return value || defaultValue;
};


export {getStorageValue};