import { useSelector } from 'react-redux';
import './index.scss';
import { AuthHomePage, NotAuthHomePage } from './blocks';
import { useProfile } from '../../../hooks';

const HomePage = () => {
  const profile = useProfile();

  let { kyc_verified: kycVerified = false } = profile || {};
  const { isAuthenticated } = useSelector(state => state.auth);

  return isAuthenticated && kycVerified ? <AuthHomePage /> : <NotAuthHomePage />;
};
export { HomePage };
