import {useState, useEffect} from "react";

import {axiosInstance} from "src/store/helpers";


const useCountriesApi = () => {
    const [counties, setCountries] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                const res = await axiosInstance.get("/auth/countries", {
                    validateStatus: () => true
                });

                if(res.status === 200) {
                    const {
                        data: items
                    } = res;

                    setCountries(items);
                }
                else {
                    setCountries([]);
                }
            }
            catch(err) {
                console.error(err);

                setCountries([]);
            }
        })();
    }, []);

    return counties;
};


export {useCountriesApi};