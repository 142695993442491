export const countriesActions = {
  GET_COUNTRIES: 'GET_COUNTRIES',
  SET_COUNTRY: 'SET_COUNTRY',
  GET_CITIES: 'GET_CITIES',
  SET_REGION: 'SET_REGION',
  SET_REG_DATA: 'SET_REG_DATA',
  SET_USER_DOCUMENT: 'SET_USER_DOCUMENT',
  SET_REG_STEP: 'SET_REG_STEP',
  CLEAR_REG_DATA: 'CLEAR_REG_DATA',
  SET_IS_SEND_CODE: 'SET_IS_SEND_CODE',
};
