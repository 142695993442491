import React from "react";

import { Icon } from "src/views/blocks";
import "./index.scss";


const ReleaseIcon = (props) => {
    const {
        className = "",
        ...rest
    } = props;

    return (
        <Icon
            {...rest}
            className={`process-icon ${className}`}
            mask />
    );
};


export { ReleaseIcon };