import React from "react";
import ScrollBar from "react-perfect-scrollbar";

import "./index.scss";


const DialogContent = (props) => {
    const {
        children
    } = props;

    return (
        <ScrollBar>
            <div className="dialog-content">
                {children}
            </div>
        </ScrollBar>
    )
};


export {DialogContent};