export const releaseData = `[
    "v.0.00.1|20.06.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>Добавлены Release Notes (Заметки но свежим релизам приложения)</li><li>Добавлена функция повторной отправки кода на странице подтверждения аккаунта (Реализован таймер отображения валидности кода)</li><li>Правка дизайна модального окна Release notes и его адаптивность с темной темой</li><li>Доработка страницы Кошелек (Выбор платежной системы, с помощью которой осуществлять вывод; Уведомление об успешном или не успешном выводе средств; Стилистичиские изменения; Связь графика и даты последней транзакции с актуальными транзакциями пользователя;)</li></ul></body></html>",
    "v.0.00.2|24.06.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>Совместимость дизайна модального окна с темной темой</li><li>Доработка графика вывода средств на странице Кошелек (Исправление ошибок связанных с неправильным отображением данных по выводу средств в графике)</li><li>Переход с $ на €</li><li>Доработка переводов на сайте (Перевод непереведенных фраз, правка фраз недоступных для перевода и их последующий перевод)</li></ul></body></html>",
    "v.0.00.3|01.07.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>Обновление значка и блока с уведомлениями (Перенос стилей и логики с ресурса поставщика информации)</li><li>Запрет на вход пользователям имеющим роль Владалец компании, которые имеют аккаунт на ресурсе поставщика информации</li><li>Обновление текущего курса IC на вывод</li><li>Доработка страницы кошелька (Исправление бага при котором была вечная загрузка в блоке с выводом средств)</li><li>Обновление наименовая путей страниц</li></ul></body></html>",
    "v.0.00.4|08.07.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>Изменена функция отображения аватара категорий контента</li><li>Добавлен значок шестеренки на аватар пользователя, в шапке страниц</li><li>В шапку страниц, к уведомлению о необходимости заполнения контактной информации, добавлена кнопка (Go to Profile), ведущая на страницу профиля</li><li>На страницу профиля добавлен текст * - required, помошающий пользователю понять какие поля обязательны к заполнению</li></ul></body></html>",
    "v.0.00.5|16.07.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>Обновленые стили кнопки Перейти к профилю (Изменена высота и шрифт)</li><li>На странице профиля поставщика контента исправлено отображение шапки профиля (теперь фото в шапке не повторяется)</li><li>Добавлена функция генерации пароля</li><li>Обновлена иконка шестеренки рядом с аватаром пользователя и ее взаимодействие</li><li>Доработана страница История просмотров (исправлена ошибкой при которой не было возможности просмотреть видео; доработан видео-плеер; добавлены кнопки взаимодействия с контентом)</li><li>Доработка мобильной версии (исправлены ошибки при которых ширина блока была выше ширины экрана и создавался отступ)</li></ul></body></html>",
    "v.0.00.6|04.08.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>Реализовано масштабирование и центрирование главного контейнера с конентом страниц, для высоких разрешений экрана</li></ul></body></html>",
    "v.0.00.7|04.08.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>Добавлен функционал вывода средств через Stripe. Для вывода необходимо зарегистрировать Stripe аккаунт, также сам сервис Stripe недоступен в Украине</li></ul></body></html>",
    "v.0.00.8|05.08.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>Обновлена форма вывода для Stripe, в странице Кошелек - удалено поле для ввода Email адреса; если Stripe аккаунта у пользователя нет, то буде предложено пройти регистрацию; обновлены стили кнопок и текста; если выбрана страна Украина то будет отображен текст о том, что в данной стране Stripe не поддерживается</li></ul></body></html>",
    "v.0.00.9|08.08.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>На страницу Кошелек добавлена таблица с транзакцими, где отображаются зачисления средств и их снятие, график удален</li></ul></body></html>",
    "v.0.01.1|09.08.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>Реализован функционал при котором сразу после снятия средств, меняеться текст под текущим балансом. В случае если это зачисление, пишется текст про последнее зачисление средств</li></ul></body></html>",
    "v.0.01.2|09.08.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>Обновлено поле номер телефона, на странице настроек профиля. Теперь можно указать любой номер, любой страны и выбрать необходимую маску</li></ul></body></html>",
    "v.0.01.3|10.08.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>Исправлен неправильный перевод слова максимального лимита, в блоке вывода, на странице кошелек. Теперь слово не покидает пределы блока</li></ul></body></html>",
    "v.0.01.4|10.08.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>В блоке вывода, на странице Кошелек, при заполнении поля суммы, точка меняется на запятую после отправки запроса на вывод</li></ul></body></html>",
    "v.0.01.5|11.08.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>Настроено подключение по веб сокету, для получения уведомлений о закрытии аукциона</li></ul></body></html>",
    "v.0.01.6|11.08.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>На страницу профиля, добавлен блок с настройкой почтовых уведомлений</li></ul></body></html>",
    "v.0.01.7|12.08.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>Реализован выбор платежной системы для вывода - Stripe или PayPal</li></ul></body></html>",
    "v.0.01.8|15.08.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>Доработан дизайн блока с настройкой уведомлений, на странице настроек профиля</li></ul></body></html>",
    "v.0.01.9|18.08.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>Удалена страница с отображением главного плейлиста, теперь главная страница Мой плейлист, которая выводит плейлист по всем подпискам пользователя</li></ul></body></html>",
    "v.0.02.0|18.08.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>Внесены исправления в отображение документов; исправлено отображения международного флага, в контекстном меню выбора маски номера телефона</li></ul></body></html>",
    "v.0.02.1|19.08.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>Отображение доступных платежных систем для вывода через необходимый запрос</li></ul></body></html>",
    "v.0.02.1|23.08.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>Добавление функционала скачивания выписки по транзакциям, на странице Кошелек</li></ul></body></html>",
    "v.0.02.2|24.08.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>Исправление бага при котором при выборе платежной системы PayPal и нажатия на кнопку Вывести, ничего не происходило</li></ul></body></html>",
    "v.0.02.3|24.08.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>Добавлена функция распознавания лица</li></ul></body></html>",
    "v.0.02.4|24.08.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>Работа над ошибками - распознавание лица (исправление бага при котором было два блока с каптчей; исправление бага при котором окно с распознаванием лица невозможно было закрыть; исправление бага при котором был запрос на распознавание лица даже если нет камеры)</li></ul></body></html>",
    "v.0.02.5|25.08.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>Исправление ошибки, при которой не возможно было находиться на главной странице приложения</li></ul></body></html>",
    "v.0.02.6|25.08.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>Реализован функционал выбора качества видео</li></ul></body></html>",
    "v.0.02.7|29.08.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>Реализован функционал выбора метода прохождения верификации на видео, на странице Профиль</li></ul></body></html>",
    "v.0.02.8|30.08.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>Обновлен запрос на изменение активных/неактивных подписок на уведомления</li></ul></body></html>",
    "v.0.02.9|30.08.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>Исправлена ошибка, при которой не отображались способы верификации, в блоке с выбором способа верификации, на странице профиль</li></ul></body></html>",
    "v.0.03.0|31.08.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>Доработана функция распознавания лица - теперь прохождение верификации на видео доступно без загрузки фотографии; при прохождении верификации записи с видео нет - видны только точки распознавания лица</li></ul></body></html>",
    "v.0.03.1|01.09.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>Обновлены иконки соц-сетей в главном меню</li></ul></body></html>",
    "v.0.03.2|02.09.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>Правка документов - удаление пробелов под буквами</li></ul></body></html>",
    "v.0.03.3|02.09.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>Обновление функции распознавания лица - добавлены точки отслеживающие движение лица, без записи видео</li></ul></body></html>",
    "v.0.03.4|05.09.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>В окно распознавания лица, добавлен круг, для корректного отображения в нем точек двигающегося лица</li></ul></body></html>",
    "v.0.03.5|05.09.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>Подключен Sentry</li></ul></body></html>",
    "v.0.03.6|06.09.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>Добавлено исправление ошибки, при которой при распознавании лица ничего не происходило</li></ul></body></html>",
    "v.0.03.7|06.09.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>В окно распознавания лица, добавлен лоадер для корректной загрузки точек распознавания</li></ul></body></html>",
    "v.0.03.8|07.09.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>Переписана функция отправки запроса на обновление токена сессии</li></ul></body></html>",
    "v.0.03.9|09.09.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>Обновлен дизайн страниц входа, регистрации, восстановления пароля</li></ul></body></html>",
    "v.0.04.0|12.09.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>Обновлен функционал масштабирования плеера на больших разрешениях экрана</li></ul></body></html>",
    "v.0.04.1|12.09.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>Исправлен баг, при котором, кнопка режима картинка в картинке остается активной даже после закрытия данного режима</li></ul></body></html>",
    "v.0.04.2|12.09.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>Обновлен дизайн отображения Cookies политики на начальной странице</li></ul></body></html>",
    "v.0.04.3|12.09.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>Обновлен дизайн отображения Cookies политики на начальной странице</li></ul></body></html>",
    "v.0.04.4|13.09.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>Исправлена ошибка, при которой невозможно было завершить регистрацию</li></ul></body></html>",
    "v.0.04.5|13.09.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>Обновлен дизайн отображения каптчи, при верификации, в мобильной версии</li></ul></body></html>",
    "v.0.04.6|15.09.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>Добавлен внутренний скролл в модальное окно заметок по релизам</li></ul></body></html>",
    "v.0.04.7|16.09.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>Пересобран плеер на главной странице - резиновый плеер (корректное масштабирование плеера на разных экранах); синхронизация функции распознавания лица с корректной работой режима картинка в картинке</li></ul></body></html>",
    "v.0.04.8|19.09.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>Доработка плеера на главной странице - изменения списка жалоб (вывод всех пунктов в один список)</li></ul></body></html>",
    "v.0.04.9|20.09.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>Доработка плеера на главной странице - добавление всплывающих подсказок на кнопки управления плеером</li></ul></body></html>",
    "v.0.05.0|21.09.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>Добавлен код Facebook</li></ul></body></html>",
    "v.0.05.1|22.09.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>Доработка функции масштабирования всех страниц приложения</li></ul></body></html>",
    "v.0.05.2|22.09.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>Обновление информации на странице регистрации</li></ul></body></html>",
    "v.0.05.3|22.09.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>Исправлен баг, при котором, при выходе с приложения в цвете темной темы, некорректно отображались страницы входа и регистрации</li></ul></body></html>",
    "v.0.05.4|27.09.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>Исправлен баг, при котором, невозможно было корректно переключить на Сербский или Хорватский языки</li></ul></body></html>",
    "v.0.05.5|27.09.2022|<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'><style type='text/css'>.release-p{list-style-type: disc; padding-left: 15px;}</style></head><body><ul class='release-p'><li>Исправлен баг, при котором, при смене языков, сбивались адаптивные стили приложения в левом, главном меню</li></ul></body></html>"
    ]`
