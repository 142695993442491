import React from 'react';

import { Icon } from 'src/views/blocks';

import './index.scss';

const EyeLineIcon = props => {
  const { className = '', ...rest } = props;

  return <Icon {...rest} className={`eye-line-icon ${className}`} mask />;
};

export { EyeLineIcon };
