import React, {useCallback} from "react";
import {
    CircleNotificationsRounded as CircleNotificationsRoundedIcon,
    CancelRounded as CancelRoundedIcon,
    CheckCircleRounded as CheckCircleRoundedIcon
} from "@mui/icons-material";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./index.scss";


const Toast = () => {
    const icon = useCallback(({ type }) => {
        switch (type) {
            case "success":
                return (
                    <CheckCircleRoundedIcon className="toastIcon" />
                );

            case "error":
                return (
                    <CancelRoundedIcon className="toastIcon" />
                );

            case "warning":
                return (
                    <CircleNotificationsRoundedIcon className="toastIcon" />
                );

            default:
                return null;
        }
    }, []);

    return (
        <ToastContainer
          position="top-right"
          theme="colored"
          closeButton={false}
          autoClose={4000}
          hideProgressBar
          icon={icon} />
    );
};

export {Toast};
