import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import InputMask from 'react-input-mask';

import { useThemeProps } from 'src/hooks';
import { CloseIcon, DoneIcon } from 'src/views/icons';
import { IconButton, Typography } from 'src/views/blocks';

import './index.scss';

const TextInput = props => {
  const {
    theme,
    inputRef,
    className = '',
    variant = 'filled',
    required,
    notBorderError = true,
    showDone = false,
    clear = false,
    error = false,
    fullWidth = false,
    disableTranslate = false,
    disabled,
    onFocus,
    onBlur,
    leftAddons,
    rightAddons,
    isRequired = false,
    helperText,
    type,
    label,
    placeholder,
    value = '',
    onChange,
    setValidate,
    ...rest
  } = useThemeProps(props);

  const { t } = useTranslation();

  let Input = 'input';

  if (rest.mask) {
    Input = InputMask;
  }

  const [inputValue, setInputValue] = useState(value);
  const [isFocus, setFocus] = useState(false);

  const ref = useRef(null);

  const valueLength = useMemo(() => {
    if (typeof inputValue === 'string') {
      return inputValue.length;
    }

    return 0;
  }, [inputValue]);

  const modifications = clsx({
    [`text-input--variant-${variant}`]: !!variant,
    'text-input--full-width': fullWidth,
    'text-input--focus': isFocus,
    'text-input--disabled': disabled,
    'text-input--error': !isFocus && error,
    'text-input--error-border': notBorderError && !isFocus && error,
  });

  const handleFocus = useCallback(() => {
    setFocus(true);

    if (onFocus) {
      onFocus();
    }
  }, [onFocus]);

  const handleBlur = useCallback(() => {
    setFocus(false);

    if (onBlur) {
      onBlur();
    }
  }, [onBlur]);

  const handleChange = useCallback(
    e => {
      const {
        target: { value },
      } = e;

      if (type === 'sum') {
        let num = value.replace(/[^0-9\.]/g, '');

        if (
          num === '.' ||
          num === ',' ||
          num.includes('..') ||
          num.length > 10
        ) {
          return;
        }

        if (num[0] === '.') {
          num = '0' + num;
        }

        if (num.length >= 2 && num[0] === '0' && !isNaN(Number(num[1]))) {
          return;
        }

        if (num.match(/\./g) && num.match(/\./g).length === 2) {
          return;
        }

        if (num.indexOf('.') != '-1') {
          num = num.substring(0, num.indexOf('.') + 2);
        }

        setInputValue(num);

        e.target.value = num;

        if (onChange) {
          onChange(e);
        }
        return;
      }

      setInputValue(value);

      if (onChange) {
        onChange(e);
      }
    },
    [onChange, type],
  );

  function deletePropertySafe(elem, prop) {
    const desc = Object.getOwnPropertyDescriptor(elem, prop);

    if (desc && desc.configurable) {
      delete elem[prop];
    }
  }

  const handleClear = useCallback(() => {
    const { current: input } = inputRef || ref;

    if (input) {
      deletePropertySafe(input, 'value');
      input.value = '';

      if (window.Event) {
        input.dispatchEvent(new Event('change', { bubbles: true }));
      } else if (document.createEvent) {
        let event = document.createEvent('HTMLEvents');
        event.initEvent('propertychange', false, false);
        event.propertyName = 'value';
        input.dispatchEvent(event);

        event = document.createEvent('HTMLEvents');
        event.initEvent('input', true, false);
        input.dispatchEvent(event);
      }
    }
  }, []);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <div
      className={`text-input text-input--${theme} ${className} ${modifications}`}
    >
      {label && (
        <div
          className={`field-label ${isRequired ? 'field-label--required' : ''}`}
        >
          {t(label)}
        </div>
      )}
      <div className="text-input__wrapper">
        {leftAddons && (
          <div className="text-input__left-addons">{leftAddons}</div>
        )}

        <Input
          {...rest}
          ref={inputRef || ref}
          className="text-input__input"
          placeholder={disableTranslate ? placeholder : t(placeholder)}
          disabled={disabled}
          value={inputValue}
          onFocus={handleFocus}
          onBlur={handleBlur}
          type={type}
          onChange={handleChange}
        />

        <div className="text-input__right-addons">
          {rightAddons}

          {showDone && !error && inputValue && (
            <IconButton
              type="button"
              size="small"
              color="primary"
              icon={DoneIcon}
            />
          )}

          {clear && valueLength > 0 && (
            <IconButton
              type="button"
              size="small"
              color="secondary"
              icon={CloseIcon}
              onClick={handleClear}
            />
          )}
        </div>
      </div>

      {!isFocus && error && helperText && (
        <Typography className="text-input__helper-text" variant="caption2">
          {helperText}
        </Typography>
      )}
    </div>
  );
};

export { TextInput };
