import { Typography } from '../../../../blocks';
import { useEffect, useMemo, useState } from 'react';

const TimerEmail = ({ timerActive, setTimerActive, time }) => {
  const [seconds, setSeconds] = useState(time ? time : 600);
  useEffect(() => {
    if (timerActive && seconds > 0) {
      const timer = setInterval(() => setSeconds(seconds - 1), 1000);
      return () => clearInterval(timer);
    } else {
      setSeconds(time ? time : 600);
      setTimerActive(false);
    }
  }, [seconds, timerActive]);

  const timer = useMemo(() => {
    const totalMinutes = Math.floor(seconds / 60);

    const s = seconds % 60;
    const m = totalMinutes % 60;

    return `${m}:${s < 10 ? `0${s}` : s}`;
  }, [seconds]);

  return (
    <Typography variant="body2" className="pl-1" color="gray" inline>
      {timer}
    </Typography>
  );
};

export { TimerEmail };
