import React, {
  useState,
  useMemo,
  useRef,
  useEffect,
  useCallback,
} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { generatePath, Link } from 'react-router-dom';
import videoLogo from 'src/assets/images/default_video_logo.jpg';
import { ROUTES } from 'src/routing';

import { useTranslation } from 'react-i18next';
import { Grid, Avatar } from '@mui/material';
import { buildQuery } from 'src/utils';

import {
  getPlaylist,
  videoLike,
  videoDislike,
  setVideoLotWatched,
  getLikedInVideo,
} from '@services/api/video/video-api';

import {
  PageHeader,
  Player,
  Typography,
  PageLoader,
  Button,
} from 'src/views/blocks';
import { DashboardLayout } from 'src/views/layouts';

import { NoItems } from './blocks';

import './index.scss';
import Verification from '../HomePage/blocks/Verification';

const PlaylistCategoryPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const [videos, setVideos] = useState([]);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [isEmailClicked, setEmailClicked] = useState(false);
  const [videoIsLike, setVideoIsLike] = useState(false);
  const [dataVideoLike, setDataVideoLike] = useState({});

  const getByIndex = useCallback(
    activeIndex => {
      return videos.find((v, index) => {
        return index === activeIndex;
      });

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [videos],
  );

  const video = useMemo(() => {
    return getByIndex(activeIndex);
  }, [activeIndex, getByIndex]);

  const { company, category } = video || {};

  const isLast = useMemo(() => {
    return activeIndex === videos.length - 1;
  }, [activeIndex, videos.length]);

  const playerRef = useRef(null);

  const actionGetVideos = useCallback(async () => {
    const videos = await getPlaylist({
      type: 'category',
      categoryID: id,
      limit: 3,
      offset: 0,
    });

    setVideos(videos);
  }, [id]);

  const updateVideosLike = useCallback(data => {
    getLikedInVideo(data.lot_id, data.auction_id)
      .then(res => {
        if (res.data) {
          setVideoIsLike(true);
          setDataVideoLike(res.data);
        } else {
          setVideoIsLike(false);
        }
      })
      .catch(() => setVideoIsLike(false));
  }, []);

  useEffect(() => {
    if (video) {
      updateVideosLike(video);
    }
  }, [video]);

  const actionNext = useCallback(async () => {
    const { current: player } = playerRef;

    if (player) {
      if (isLast) {
        await actionGetVideos();
        setActiveIndex(0);
        player.open(0);
        player.play();
      } else {
        player.next();
      }
    }
  }, [actionGetVideos, isLast]);

  const handleEnded = useCallback(async () => {
    if (video?.id) {
      const {
        categories: [{ id: categoryId }],
      } = video;

      if (categoryId === 18) {
        await actionNext();
      } else {
        setShowCaptcha(true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex, actionNext, video, isLast]);

  const handleCaptcha = useCallback(async () => {
    if (video) {
      const { lot_id } = video;

      if (playerRef.current) {
        playerRef.current.pause();
      }

      if (lot_id) {
        await setVideoLotWatched(lot_id);
      }

      setShowCaptcha(false);

      await actionNext();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex, actionNext, video, isLast]);

  const handlePlay = useCallback(() => {
    setShowCaptcha(false);
  }, []);

  const handleSlideChange = useCallback(activeIndex => {
    setActiveIndex(activeIndex);
    setEmailClicked(false);

    if (playerRef.current) {
      playerRef.current.play();
    }
  }, []);

  const handleToggleLike = useCallback(
    async (video, isLiked) => {
      setVideoIsLike(isLiked);
      if (isLiked) {
        await videoLike(video).then(() => updateVideosLike(video));
      } else {
        await videoDislike(dataVideoLike.id).then(() =>
          updateVideosLike(video),
        );
      }
    },
    [dataVideoLike.id, updateVideosLike],
  );

  useEffect(() => {
    setLoading(true);

    actionGetVideos().then(() => {
      setLoading(false);
    });
  }, [actionGetVideos]);

  return (
    <DashboardLayout className="home-page">
      {isLoading && <PageLoader />}

      <div className="player__header">
        <PageHeader
          title={company ? t(company.title) : ''}
          titleProps={{
            className: 'pl-4',
            variant: 'h5',
            ...(company
              ? {
                  component: Link,
                  to:
                    generatePath(ROUTES.company, {
                      id: company.id,
                    }) +
                    '?' +
                    buildQuery({
                      title: company.title,
                      avatar: company.avatar,
                    }),
                }
              : {}),
          }}
          subtitle={category ? t(category.title) : ''}
          subtitleProps={{
            className: 'pl-4',
          }}
          leftAddons={
            <React.Fragment>
              {company && (
                <Avatar
                  sx={{
                    width: 45,
                    height: 45,
                  }}
                  component={Link}
                  src={company.avatar || videoLogo}
                  to={
                    generatePath(ROUTES.company, {
                      id: company.id,
                    }) +
                    '?' +
                    buildQuery({
                      title: company.title,
                      avatar: company.avatar,
                    })
                  }
                />
              )}
            </React.Fragment>
          }
        />
      </div>

      {!isLoading && videos.length > 0 && (
        <React.Fragment>
          <div
            style={{
              color: 'var(--primary-color)',
              textTransform: 'uppercase',
              cursor: 'pointer',
              fontSize: '24px',
            }}
            className="mb-3"
            onClick={() => history.goBack()}
          >
            ← {t('Back')}
          </div>
          <Player
            ref={playerRef}
            videos={videos}
            videoIsLike={videoIsLike}
            onSlideChange={handleSlideChange}
            onPlay={handlePlay}
            onEnded={handleEnded}
            onLike={handleToggleLike}
          ></Player>
          {showCaptcha && (
            <Verification onSuccess={handleCaptcha} open={showCaptcha} />
          )}
          {video && (
            <Grid container direction="column">
              <Grid item container justifyContent="center">
                <Grid item className="mt-2">
                  {video.url && (
                    <Button
                      component="a"
                      variant="primary"
                      size="small"
                      target="_blank"
                      rel="nofollow"
                      className="video__bottom_button"
                      href={video.url}
                    >
                      {t('Go to the site')}
                    </Button>
                  )}

                  {video.email && (
                    <Button
                      component="a"
                      variant={!isEmailClicked ? 'primary' : ''}
                      size="small"
                      rel="nofollow"
                      href={`mailto:${video.email}?subject=Infocoin: ${t(
                        video.title,
                      )}`}
                      onClick={() => setEmailClicked(true)}
                    >
                      {!isEmailClicked && t('Send a message')}

                      {isEmailClicked && video.email}
                    </Button>
                  )}
                </Grid>
              </Grid>

              <Grid item>
                <Typography className="my-4 video--title" variant="h3">
                  {t(video.title)}
                </Typography>
              </Grid>

              <Grid item>
                <Typography className="video--description">
                  {t(video.description)}
                </Typography>
              </Grid>
            </Grid>
          )}
        </React.Fragment>
      )}

      {!isLoading && videos.length === 0 && (
        <>
          <div className="mt-4">
            <React.Fragment>
              <NoItems />
            </React.Fragment>
          </div>
        </>
      )}
    </DashboardLayout>
  );
};

export { PlaylistCategoryPage };
