import React from "react";
import {components} from "react-select";

const {
    Option: RSOption
} = components;


const Option = (props) => {
    const {
        component: Component,
        ...rest
    } = props;

    const {
        data: option
    } = rest;

    return (
        <RSOption {...rest}>
            <Component
              option={option} />
        </RSOption>
    );
};


export {Option};