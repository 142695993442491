import { memo, useEffect, useRef, useState } from 'react';
import './style.scss';
import * as faceapi from 'face-api.js';
import { Button } from '../../views/blocks';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTranslation } from 'react-i18next';
import Loader from '@components/Loader';
import { useSelector } from 'react-redux';

function FaceRecognition({
  setVerificationMethod,
  onSuccess,
  onError,
  witchCaptcha = true,
}) {
  const videoRef = useRef();
  const canvasRef = useRef();

  const { t } = useTranslation();

  const { isAuthenticated } = useSelector(state => state.auth);

  const [sizes, setSizes] = useState({ width: 0, height: 0 });

  const [intId, setIntId] = useState(null);
  const [tracks, setTracks] = useState(null);

  const [success, setSuccess] = useState(false);

  const [loading, setLoading] = useState(true);

  const [notPermission, setNotPermission] = useState(false);

  useEffect(() => {
    (async function () {
      await startVideo();
      videoRef && loadModels();
    })();
  }, []);

  const loadModels = () => {
    Promise.all([
      faceapi.nets.tinyFaceDetector.loadFromUri('/models'),
      faceapi.nets.faceLandmark68Net.loadFromUri('/models'),
      faceapi.nets.faceRecognitionNet.loadFromUri('/models'),
    ]).then(() => {
      setLoading(false);
      recognition();
    });
  };

  const startVideo = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: 'user' },
        audio: false,
      });
      videoRef.current.srcObject = stream;
      setTracks(stream.getTracks());
    } catch (e) {
      onError && onError();
      if (e.message === 'Requested device not found') {
        setVerificationMethod('Captcha');
      }
      if (e.message === 'Permission denied') {
        setNotPermission(true);
      }
    }
  };

  const faceLandmarks = async () => {
    if (!success) {
      try {
        if (videoRef.current && canvasRef.current) {
          const detections = await faceapi
            .detectSingleFace(
              videoRef.current,
              new faceapi.TinyFaceDetectorOptions(),
            )
            .withFaceLandmarks();

          setSizes({
            width: videoRef?.current?.clientWidth,
            height: videoRef?.current?.clientHeight,
          });

          if (canvasRef.current) {
            canvasRef.current.width = videoRef.current?.clientWidth;
            canvasRef.current.height = videoRef.current?.clientHeight;
          }

          if (detections) {
            faceapi.matchDimensions(canvasRef.current, {
              width: videoRef.current.clientWidth,
              height: videoRef.current.clientHeight,
            });

            const resized = await faceapi.resizeResults(detections, {
              width: videoRef.current.clientWidth,
              height: videoRef.current.clientHeight,
            });

            faceapi.draw.drawFaceLandmarks(canvasRef.current, resized);
            tracks &&
              tracks.forEach(track => {
                track.stop();
              });
            setTracks(null);
          }
          setTimeout(() => {
            faceLandmarks();
          }, 30);
        } else
          setTimeout(() => {
            faceLandmarks();
          }, 30);
      } catch (e) {
        setTimeout(() => {
          faceLandmarks();
        }, 60);
      }
    }
  };

  const recognition = () => {
    if (!notPermission) {
      faceLandmarks();
      faceRecognition();
    }
  };

  const faceRecognition = async () => {
    try {
      if (!success) {
        if (videoRef.current && canvasRef?.current) {
          const detections = await faceapi.detectSingleFace(
            videoRef.current,
            new faceapi.TinyFaceDetectorOptions(),
          );

          if (detections && Number(detections.score) >= 0.7) {
            clearInterval(intId);
            setIntId(null);

            setTimeout(() => {
              tracks &&
                tracks.forEach(track => {
                  track.stop();
                });
              setTracks(null);

              if (videoRef?.current) {
                videoRef.current.srcObject = null;
              }
              setSuccess(true);
              setTimeout(() => {
                onSuccess && onSuccess();
              }, 2000);
            }, 1000);

            /*canvasRef.current.innerHtml = await faceapi.createCanvasFromMedia(
            videoRef.current,
          );
          const context = canvasRef.current.getContext('2d');*/

            /*      context.drawImage(
            videoRef.current,
            0,
            0,
            videoRef.current.clientWidth,
            videoRef.current.clientHeight,
          );

          const data = canvasRef.current.toDataURL('image/png');*/

            /*if (verificationMethods?.length) {
            /!* dispatch(
              setVerificationMethodAction({
                views_verification_method_id: verificationMethods.find(
                  v => v.title === 'Face Unlock',
                )?.id,
                user_photo_filename: `${user.name}_face_unlock.png`,
                user_photo_filepath: deleteBase64Info(data),
              }),
            ).then(() => {
              setSuccess(true);
              setTimeout(() => {
                onSuccess && onSuccess(deleteBase64Info(data));
              }, 2000);
            });*!/
          } else {
            setTimeout(() => {
              faceRecognition();
            }, 500);
          }*/
          } else
            setTimeout(() => {
              faceRecognition();
            }, 20);
        }
      } else {
        tracks &&
          tracks.forEach(track => {
            track.stop();
          });
        setTracks(null);
      }
    } catch (e) {
      setTimeout(() => {
        faceRecognition();
      }, 300);
    }
  };

  useEffect(() => {
    return () => {
      tracks &&
        tracks.forEach(track => {
          track.stop();
        });
      setTracks(null);
    };
  }, []);
  /*
  const compare = () => {
    const detectInterval = setInterval(async () => {
      if (videoRef?.current && canvasRef?.current) {
        const detections = await faceapi
          .detectSingleFace(
            videoRef.current,
            new faceapi.TinyFaceDetectorOptions(),
          )
          .withFaceLandmarks()
          .withFaceDescriptor();
        const img = new window.Image();
        img.crossOrigin = 'anonymous';
        img.addEventListener('load', async function () {
          canvasRef.current
            .getContext('2d')
            .drawImage(
              img,
              0,
              0,
              videoRef.current.clientWidth,
              videoRef.current.clientHeight,
            );

          faceapi
            .fetchImage(canvasRef.current.toDataURL('image/png'))
            .then(async photo => {
              const detections2 = await faceapi
                .detectSingleFace(photo, new faceapi.TinyFaceDetectorOptions())
                .withFaceLandmarks()
                .withFaceDescriptor();

              if (detections && detections2) {
                const distance = faceapi.euclideanDistance(
                  detections.descriptor,
                  detections2.descriptor,
                );
                if (distance < 0.6) {
                  clearInterval(detectInterval);
                  setSuccess(true);
                  setTimeout(() => {
                    onSuccess && onSuccess();
                  }, 2000);
                }
              }
            });
        });

        img.setAttribute('src', userVerificationMethod.user_photo_filepath);
      }
    }, 2000);
  };*/

  return (
    <div className="face-api">
      {success ? (
        <div
          style={{
            width: videoRef.current?.clientWidth || sizes.width,
            height: videoRef.current?.clientHeight || sizes.height,
          }}
          className="face-api-success"
        >
          <CheckCircleIcon className="face-api-success-icon" />
          <span>{t('Verification passed successfully')}</span>
        </div>
      ) : (
        <>
          {notPermission ? (
            <div className="face-api-permission">
              <span style={{ marginBottom: '5px' }}>
                {t('Turn on camera for face recognition')}
              </span>
              {witchCaptcha && (
                <Button
                  onClick={() => {
                    clearInterval(intId);
                    setVerificationMethod('Captcha');
                  }}
                  variant="primary"
                >
                  {t('Verify with captcha')}
                </Button>
              )}
            </div>
          ) : (
            <>
              <span
                style={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                }}
              >
                {t(
                  'We don`t show a picture from the video, but the recognition process is in progress',
                )}
              </span>
              <div className="face-api__video">
                {loading && (
                  <div className="face-api-loader">
                    <Loader width={60} height={60} color="#FFC107" />
                  </div>
                )}
                <video
                  crossOrigin="anonymous"
                  style={{ width: '100%', opacity: 0 }}
                  ref={videoRef}
                  autoPlay
                />
                <div className="face-api__circle">
                  <canvas
                    ref={canvasRef}
                    width={videoRef.current?.clientWidth || sizes.width}
                    height={videoRef.current?.clientHeight || sizes.height}
                    className="face-api__canvas"
                  />
                </div>
              </div>
              {witchCaptcha && (
                <Button
                  onClick={() => {
                    tracks.forEach(track => {
                      track.stop();
                    });
                    setTracks(null);
                    setVerificationMethod('Captcha');
                  }}
                  variant="primary"
                >
                  {t('Try captcha')}
                </Button>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}
export default memo(FaceRecognition);
