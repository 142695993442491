import React, { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';
import { Typography } from 'views/blocks/Typography';

import CalendarPicker from 'views/blocks/DatePicker';

import { Grid, IconButton, Tooltip } from '@mui/material';

import ModalConfirm from '../CardBlock/ModalConfirm';
import {
  getWalletCorefyLimit,
  getWalletTransactionsAction,
} from '../../../../../store/modules/wallet/actions';
import { connect } from 'react-redux';

import FileDownloadIcon from '@mui/icons-material/FileDownload';

import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

import { TextInput } from '../../../../inputs';

import { SearchIcon } from '../../../../icons';

import TransactionItem from './blocks/TransactionItem';

import moment from 'moment/min/moment-with-locales';

import { Spinner } from 'react-bootstrap';

import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';

import TransactionInvoice from './blocks/invoice';

import { corefyVerificationRecendCode } from 'services/api/wallet';
import { toast } from 'react-toastify';

const startToday = new Date(new Date().setHours(3, 0, 0, 0));

const start_time = new Date(
  startToday.setMonth(startToday.getMonth() - 1),
).toISOString();

const TransactionTable = ({ transactions, getWalletTransactionsAction }) => {
  const { t } = useTranslation();

  const defaultRange = {
    start_time: start_time,
    end_time: new Date().toISOString(),
  };

  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState('');

  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);

  const [idWithdrawal, setIdWithdrawal] = useState(null);

  const currentType = useMemo(() => {
    return {
      transfer: t('Video viewing'),
      outcome: t('Withdrawal of funds'),
      in_verifying: t('Unconfirmed withdrawal'),
      income: t('Return'),
    };
  }, [t]);

  const formatTransactions = useMemo(() => {
    let initialTransaction = transactions;

    let formatTransactions = initialTransaction.map(tr => {
      const year = new Date(tr.created_at).getFullYear();
      const month = new Date(tr.created_at).getMonth();
      const day = new Date(tr.created_at).getDate();

      const currentDay = moment(new Date(year, month, day, 3, 0, 0, 0)).unix();

      return {
        day: currentDay,
        created_at: moment(tr.created_at).format('DD MMMM YYYY'),
        children: {
          ...tr,
          sum: `${tr.type === 'outcome' ? '-' : '+'} ${Number(
            tr.sum.slice(0, tr.sum.length - 3),
          )} IC`,
          currentType: currentType[tr.type] || tr.type,
          created_at: moment(tr.created_at).format(
            `DD MMM YYYY [${t('at')}] LT`,
          ),
        },
      };
    });

    if (search) {
      formatTransactions = formatTransactions.filter(
        ({ children }) =>
          children.created_at.toLowerCase().includes(search.toLowerCase()) ||
          children.currentType.toLowerCase().includes(search.toLowerCase()) ||
          children.sum.toLowerCase().includes(search.toLowerCase()),
      );
    }
    const result = Object.values(
      formatTransactions.reduce((r, cur) => {
        const key = 'd' + cur['day']; // символ "d" добавлен, чтобы автоматически не сортировало по цифровым ключам
        (r[key] = r[key] || []).push(cur);

        return r;
      }, {}),
    );

    return result;
  }, [transactions, search, moment, t, currentType]);

  const handleChangeRange = useCallback(async value => {
    if (!value) {
      return;
    }
    setLoading(true);

    await getWalletTransactionsAction(value.start_time, value.end_time);

    setLoading(false);
  }, []);

  const handleConfirm = useCallback(
    async id => {
      try {
        setIdWithdrawal(id);

        await corefyVerificationRecendCode(id);

        setIsOpenConfirmModal(true);
      } catch (e) {
        console.log(e);
        toast.error(t(e?.response?.data?.error || e?.message || e));
      }
    },
    [t],
  );

  return (
    <React.Fragment>
      <Grid container className="transactions">
        <Grid container flexDirection="column">
          <Typography variant="h2" weight="normal-bold">
            {t('History transactions')}
          </Typography>

          <Typography variant="body1" color="gray">
            {t('Here you can see the latest transactions')}
          </Typography>
        </Grid>

        <Grid container className="transactions-action" mt={2}>
          <Grid item className="transactions-action__item">
            <TextInput
              placeholder={t('Search')}
              value={search}
              onChange={e => setSearch(e.target.value)}
              leftAddons={
                <SearchIcon
                  size="smallmed"
                  className="ml-2"
                  color="secondary"
                />
              }
            />
          </Grid>
          <Grid container className="transactions-action__item">
            <Grid item>
              <CalendarPicker
                defaultValue={defaultRange}
                onChange={handleChangeRange}
              />
            </Grid>
            <Grid item>
              <TransactionInvoice transactions={transactions}>
                <Tooltip title={t('Download file')}>
                  <IconButton className="transactions-action__item_button">
                    <FileDownloadIcon htmlColor="var(--color)" />
                  </IconButton>
                </Tooltip>
              </TransactionInvoice>
            </Grid>
          </Grid>
        </Grid>

        {loading ? (
          <Grid container justifyContent="center" mt={6}>
            <Spinner animation="border" />
          </Grid>
        ) : (
          <Grid item className="transactions-table" mt={2}>
            <PerfectScrollbar className="transactions-table__scroll">
              <Grid container flexDirection="column" flexWrap="nowrap">
                {formatTransactions.map((transaction, key) => (
                  <Grid item key={key} mt={2}>
                    <Typography variant="h5" color="gray" weight="normal-bold">
                      {transaction[0].created_at}
                    </Typography>
                    {transaction.map(tr => (
                      <TransactionItem
                        handleConfirm={handleConfirm}
                        key={tr.id}
                        transaction={tr.children}
                      />
                    ))}
                  </Grid>
                ))}
              </Grid>
            </PerfectScrollbar>
          </Grid>
        )}
        {!transactions.length && !loading && (
          <Grid container mt={2}>
            <Grid item display="flex" justifyContent="center" width="100%">
              <FormatListBulletedIcon fontSize="large" />
            </Grid>
            <Grid container mt={1}>
              <Grid item>
                <Typography variant="h5" weight="normal-bold" align="center">
                  {t('No transactions yet')}
                </Typography>
                <Grid item mt={1}>
                  <Typography color="gray" variant="body2" align="center">
                    {t(
                      'Start transacting with your wallet. All transactions made will be displayed here.',
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>

      <ModalConfirm
        id={idWithdrawal}
        show={isOpenConfirmModal}
        setShow={setIsOpenConfirmModal}
      />
    </React.Fragment>
  );
};

const mapStateToProps = ({ wallet }) => ({
  transactions: wallet.transactions,
  corefyPayoutLimit: wallet.corefyPayoutLimit,
});

const mapDispatchToProps = {
  getWalletCorefyLimit,
  getWalletTransactionsAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(memo(TransactionTable));
