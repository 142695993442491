import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Select } from '../../../../inputs';
import {
  getUserVerificationMethodAction,
  getVerificationsMethodAction,
  setVerificationMethodAction,
} from '../../../../../store/modules/auth/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  userVerificationMethodsSelector,
  verificationMethodsSelector,
} from '../../../../../store/modules/auth/selectors';
import { useTranslation } from 'react-i18next';
import FaceRecognition from '@components/FaceRecognition';
import Modal from '@components/Modal';
import { toast } from 'react-toastify';
import classes from './VerificationSelect.module.scss';

const VerificationSelect = () => {
  const [showFaceRec, setShowFaceRec] = useState(false);

  const verificationMethods = useSelector(verificationMethodsSelector);

  const userVerificationMethod = useSelector(userVerificationMethodsSelector);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const options = useMemo(() => {
    return (
      verificationMethods &&
      verificationMethods
        .filter(mth => mth.title !== 'Face ID' && mth.title !== 'Touch ID')
        .map(v => ({
          label: v.title,
          value: v.title,
        }))
    );
  }, [verificationMethods]);

  useEffect(() => {
    dispatch(getUserVerificationMethodAction());
    if (!verificationMethods?.length) dispatch(getVerificationsMethodAction());
  }, []);

  const onSuccess = useCallback(() => {
    setShowFaceRec(false);
    dispatch(
      setVerificationMethodAction({
        views_verification_method_id: verificationMethods.find(
          v => v.title === 'Face Unlock',
        )?.id,
      }),
    ).then(() => {
      toast.success(t('Person verification method changed'));
    });
  }, [dispatch, t, verificationMethods]);
  return (
    <>
      <div className={classes.label}>
        {t('Setting up the person verification method')}
      </div>
      <Select
        value={userVerificationMethod?.title}
        options={options}
        onChange={value => {
          if (value === 'Face Unlock') {
            setShowFaceRec(true);
          } else {
            dispatch(
              setVerificationMethodAction({
                views_verification_method_id: verificationMethods.find(
                  v => v.title === value,
                ).id,
              }),
            ).then(() => {
              toast.success(t('Person verification method changed'));
            });
          }
        }}
      />
      <Modal
        headerText={t('Face recognition')}
        show={showFaceRec}
        onHide={() => {
          setShowFaceRec(false);
        }}
      >
        <FaceRecognition
          onError={() => {
            setShowFaceRec(false);
            toast.warning(t('You haven`t connected camera'));
          }}
          witchCaptcha={false}
          setVerificationMethod={() => {}}
          onSuccess={onSuccess}
        />
      </Modal>
    </>
  );
};

export default memo(VerificationSelect);
