import React from 'react';
import { useSelector } from 'react-redux';
import LOGO from 'assets/images/logo-light.gif';
import { useTranslation } from 'react-i18next';
import { useProfile } from 'src/hooks';
import { PageLoader } from 'src/views/blocks';
import { Button } from 'src/views/blocks';
import { Link } from 'react-router-dom';
import './index.scss';

const NotAuthenticatedMessage = () => {
  const { t } = useTranslation();

  return (
    <div className="container">
      <div className="notAuthMessageContent">
        <div className="notAuthMessageContent__block">
          <img src={LOGO} alt="logo" />
          <h3 className="mt-4">{t('You are not authorized')}</h3>
          <Link to="/login">
            <Button
              className="mt-2"
              component="span"
              variant="primary"
              size="medium"
              style={{
                width: '270px',
              }}
            >
              {t('Sign in')}
            </Button>
          </Link>
          <p className="mt-2">{t('For the first time in INFOCOIN?')}</p>
          <Link className="underline" to="/registration">
            {t('Sign up')}
          </Link>
        </div>
      </div>
    </div>
  );
};

const PrivateLayout = props => {
  const { children } = props;

  const { isAuthenticated } = useSelector(state => state.auth);
  const profile = useProfile();

  return (
    <React.Fragment>
      {isAuthenticated && !profile && <PageLoader />}

      {isAuthenticated ? children : <NotAuthenticatedMessage />}
    </React.Fragment>
  );
};

export { PrivateLayout };
