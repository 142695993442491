export const agreement = {
  en: `<!DOCTYPE html>
  <html>
  <head>
    <meta http-equiv="content-type" content="text/html; charset=utf-8"/>
    <title></title>
    <meta name="generator" content="LibreOffice 7.3.2.2 (Linux)"/>
    <meta name="created" content="00:00:00"/>
    <meta name="changed" content="00:00:00"/>
    <style type="text/css">
      @page { size: 8.5in 11in; margin: 1in }
      p { margin-bottom: 0.1in; background: transparent }
      a:link { color: #000080; so-language: zxx; text-decoration: underline; text-underline-offset: 4px; }
    </style>
  </head>
  <body lang="en-US" link="#000080" vlink="#800000" dir="ltr"><p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <br/>
  <br/>
  
  </p>
  <p align="center" style="line-height: 0.18in; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><b>PUBLIC
  AGREEMENT (PROPOSAL)</b></span></font></font></p>
  <p align="center" style="line-height: 0.18in; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><b>ON
  THE PLACEMENT OF USER CONTENT</b></span></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="font-weight: normal">This
  Agreement (defined below) concluded by accepting this proposal, which
  contains all the essential terms of the Agreement, by you, a legal
  entity or individual, who: (i) accepts this Agreement online; or (ii)
  is designated as &quot;Principal&quot; and signs the Agreement
  (hereinafter - &quot;Customer&quot; / &quot;You&quot; / &quot;Principal&quot;)
  and Infocoin Limited in the person of Director Michalis Michail
  hereinafter referred to as the &quot;Agent&quot;, &quot;the Company&quot;)
  (hereinafter referred to as the &quot;Parties&quot;, or each
  separately as the &quot;Party&quot;). This Agreement governs your
  participation in the Program (defined below).</span></span></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="font-weight: normal">Terms
  used in this agreement:</span></span></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="font-weight: normal">-
  &quot;Infocoin&quot; - an application and site, united under the name
  Infocoin, which have a common functionality and belong to the company
  Infocoin Limited;</span></span></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="font-weight: normal">-
  &quot;Confidential Information&quot; - information that is defined as
  such in accordance with the Privacy Policy of our company;</span></span></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="font-weight: normal">-
  &quot;Privacy Policy&quot; - a document that is mandatory for all
  users of the site and the Infocoin Services, which is posted on the
  site at the link: (<a href="https://www.infocoin.online/docs/">https://www.infocoin.online/docs/</a>).
  Section 7 of this agreement is not Privacy Policy, and refers to the
  full Privacy Policy, which we are guided by, and which you should be
  guided by, in your interaction with us;</span></span></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="font-weight: normal">-
  &quot;Placement company (companies)&quot; - means the placement of
  user content and amount of funds. One company - one placement;</span></span></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="font-weight: normal">-
  &quot;Rules&quot; - means the rules of the Program posted at
  (<a href="https://www.infocoin.online/docs/">https://www.infocoin.online/docs/</a>)
  including the Privacy Policy posted at
  (<a href="https://www.infocoin.online/docs/">https://www.infocoin.online/docs/</a>);</span></span></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="font-weight: normal">-
  &quot;Infocoin Program&quot; - means an online content placement
  program.</span></span></font></font></p>
  <p align="center" style="line-height: 0.18in; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><b>1.
  Subject of the contract</b></span></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="font-weight: normal">1.1.
  The Agent undertakes to take all necessary actions on behalf of the
  Principal to place the Principal's user through the Infocoin system
  on its own behalf, but at the expense of the Principal.</span></span></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="font-weight: normal">1.2.
  The Agent acquires rights and obligations by an agreement concluded
  with a third party on his own behalf at the expense of the Principal.</span></span></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="font-weight: normal">1.3.
  Under an agreement entered into by the Agent with a third party on
  behalf of and at the expense of the Principal, the rights and
  obligations of the Principal arise.</span></span></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="font-weight: normal">1.4.
  The Agent's remuneration may vary depending on many factors. The
  current amount of the Agent's remuneration is available in the
  personal account of Infocoin and is valid only on the date of signing
  the contract. The Agent's remuneration may change at any time, you
  agree that you are sufficiently informed about such remuneration, if
  it is at least posted in the personal account of Infocoin.</span></span></font></font></p>
  <p align="center" style="line-height: 0.18in; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><b>2.
  Placement and targeting</b></span></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="font-weight: normal">2.1.
  Infocoin Limited will make every effort to place the Principal's user
  content, in accordance with the placement and targeting parameters
  provided and selected by the Principal. </span></span></font></font>
  </p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="font-weight: normal">2.2.
  Infocoin Limited does not guarantee: (a) the pacing, location or
  speed of finding any user content, or (b) the number (if any) of
  clicks per link and the number of publications, conversions or clicks
  on any content; posted on the site or in the Infocoin application or
  in sections of such site / application.</span></span></font></font></p>
  <p align="center" style="line-height: 0.18in; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><b>3.
  Rights and obligations of the parties</b></span></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><b>3.1.
  The principal obligations:</b></span></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="font-weight: normal">-
  pay the amount allocated by the Principal for one marketing company.
  Payment processing by performing actions on the site in the Infocoin
  system and transferring funds to the current bank account of Infocoin
  Limited. Such obligations of the Agent shall be deemed to have been
  fulfilled in full after the Agent has provided a report for the
  relevant period or a report on the fulfillment of obligations under
  certain agreements made by the Agent;</span></span></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="font-weight: normal">-
  not to conclude similar agreements with other persons;</span></span></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="font-weight: normal">-
  notify the Agent of any objections to the submitted report within 5
  (five) days after its submission. If the objections are not submitted
  within the specified period, the report shall be deemed accepted by
  the Principal.</span></span></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><b>3.2.
  The principal rights:</b></span></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><b>-
  </b></span></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="font-weight: normal">before
  the start of the broadcast of user content, contact the support
  service via the link <a href="https://business.infocoin.online/support">https://business.infocoin.online/support</a>
  with a request for a refund of funds reserved for the broadcast of
  the relevant video;</span></span></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="font-weight: normal">-
  after the end of the broadcast period of the clip (after 24 hours),
  contact the support service via the link
  <a href="https://business.infocoin.online/support">https://business.infocoin.online/support</a>
  with a request to return the balance of the amount reserved for the
  broadcast of the relevant clip.</span></span></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><b>3.3.
  The agent obligations:</b></span></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="font-weight: normal">-
  provide reports to the Principal as the Agreement is implemented, but
  at least once a month. The necessary evidence of costs must be
  attached to the Agent's report. All data from personal Infocoin
  account and provided reports on Principal’s e-mail, are normal
  evidence paying off money and providing services; </span></span></font></font>
  </p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="font-weight: normal">-
  follow the instructions of the Principal concerning the agreements
  and other obligations made by the Agent, if these instructions do not
  contradict the requirements of the law;</span></span></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="font-weight: normal">-
  in the event of an appeal by the Principal for the reasons specified
  in paragraph 3.2. of this Agreement, within 72 hours to review and
  report on the results of consideration of such an appeal by sending a
  letter to the Principal's e-mail;</span></span></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="font-weight: normal">-
  fulfill all obligations in good faith.</span></span></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><b>3.4.
  The agent rights:</b></span></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="font-weight: normal">-
  in order to perform the contract to enter into a subagent agreement
  with another person, remaining responsible for the actions of the
  subagent before the Principal, unless the parties agree otherwise.
  The Subagent is not entitled to enter into agreements with third
  parties on behalf of the person who is the Principal under the agency
  agreement, except in cases expressly provided by the subagent
  agreement. The Agent is obliged to agree on the terms of the subagent
  agreement with the Principal;</span></span></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="font-weight: normal">-
  unilaterally terminate this Agreement in cases of violation by the
  Principal of the Rules of content placement, this Agreement, the laws
  of the Republic of Cyprus, support for the actions of the Russian
  Federation, denial of aggression against Ukraine.</span></span></font></font></p>
  <p align="center" style="line-height: 0.18in; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><b>4.
  The action of force majeure</b></span></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="font-weight: normal">4.1.
  Neither party shall be liable to the other party for delay or failure
  to perform due to force majeure, which arose independently of the
  will and desire of the parties and which could not have been foreseen
  or avoided, including declared or actual war, public unrest,
  epidemics, blockade, embargo, as well as earthquakes, floods, fires
  and other natural disasters.</span></span></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="font-weight: normal">4.2.
  Party which is unable to fulfill its obligations due to force majeure
  is obliged to immediately notify the other party of these
  circumstances.</span></span></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="font-weight: normal">4.3.
  In connection with the circumstances of force majeure, the parties
  must sign a protocol on termination of this Agreement or agree on
  joint action to overcome the adverse effects of these circumstances.</span></span></font></font></p>
  <p align="center" style="line-height: 0.18in; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><b>5.
  Cancel the placement of user’s content and refund</b></span></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="font-weight: normal">5.1.
  Unless otherwise provided in the Rules or the Agreement (if any) or
  other online interface that refers to the Terms of Content or this
  Agreement, either Party may at any time terminate any placement of
  user content prior to posting. The Principal may cancel the placement
  of user content in advance by providing Infocoin Limited with a
  written notice, including, but not limited to, an email to the
  support service at </span></span></font></font><font color="#0563c1"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><u><span style="font-weight: normal"><a href="https://ads.infocoin.online/support">https://</a><a href="https://ads.infocoin.online/support">business</a></span></u></span></font></font></font><a href="https://ads.infocoin.online/support"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="font-weight: normal">.infocoin.online/support</span></span></font></font></a><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">.</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">5.2.
  The placement of user content ends shortly after cancellation. Any
  other canceled user content placements may be published despite the
  fact that all actions have been taken to cancel them, primarily due
  to lack of time for response from the Agent, if such business are
  published and reviewed, and therefore there are obligations to pay
  from by the Agent, the Principal must pay in favor of Infocoin
  Limited for such published user content.</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">5.3.
  In the event of a decision to return funds at the Principal's
  request, in accordance with article 3.2. of this Agreement, the Agent
  sends to the Principal's e-mail an act of reconciliation of mutual
  settlements. The specified act is considered agreed upon by the
  Principal in case of: i) signing with an electronic digital
  signature; ii) performing the necessary actions in a personal
  account) iii) sending a signed scan copy of such an act in response.</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">5.4.
  After agreeing on the reconciliation act, the Agent, by transferring
  funds to the Principal's account from which the corresponding payment
  was made, returns the funds to the Principal. All expenses related to
  the transfer of funds are paid at the expense of the Principal.</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">5.5.
  The Principal's Agent shall refund funds within 60 calendar days. The
  time of refund is affected by the speed of transaction processing by
  the respective banks and/or payment systems, and in this regard, the
  refund period may be extended.</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">5.6.
  Refund applications are not accepted during the broadcast of the
  video.</span></span></span></font></font></font></p>
  <p align="center" style="line-height: 0.18in; margin-bottom: 0.11in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><b>6.
  Statements and guarantees</b></span></span></font></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">6.1.
  The Principal declares and warrants that (i) all information provided
  by the Principal to Infocoin Limited is complete, accurate and up to
  date; (ii) it has all the necessary rights to grant permission and
  hereby grants Infocoin Limited and any Partners all such rights
  necessary for Infocoin Limited and for any Partner (s) (in as the
  case may be) for the use, hosting, caching, tracking, storage,
  copying, modification, distribution, modification, reproduction,
  publication, demonstration, transmission and distribution of the
  Principal's user content (including any Contextual projects) (&quot;Use
  of Content&quot;); and (iii) The use of the Content in accordance
  with this Agreement, as well as the websites and / or landing pages
  to which the Channel's user content channels are transmitted, shall
  not: (a) violate or encourage violations of any legislation or
  applicable regulatory or procedural norms; (b) infringe any
  intellectual property rights of any third party or contain any
  material that may harm, be abusive, obscene, threatening or
  degrading.</span></span></span></font></font></font></p>
  <p align="center" style="line-height: 0.18in; margin-bottom: 0.11in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><b>7.
  Confidentiality</b></span></span></font></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">7.1.
  According to this Agreement, for the duration of this Agreement and
  for two years after its termination, the Parties undertake not to
  disclose Confidential Information of the other Party to third parties
  without prior written (including shared by e-mail) consent except as
  provided in this Agreement. The Principal is solely responsible for
  the use by third parties of any of the Principal's online reports.
  &quot;Confidential Information&quot; includes (i) the Principal's
  passwords; (ii) user content (including Contextual Objects) prior to
  publication; and (iv) any other Program Information or access to the
  Technology prior to its public disclosure provided by Infocoin
  Limited to the Principal and identified at the time of disclosure in
  writing as confidential and / or official; (v) features of the
  Program that are not public and are defined as &quot;Beta&quot; or
  &quot;Trial Content&quot;. This Confidential Information does not
  include information that has become public knowledge not because of a
  breach by either Party or (a) that was self-developed without access
  to the Confidential Information of the other Party; (b) lawfully
  obtained from a third party; or (c) must be disclosed in accordance
  with the law or a requirement of a public authority.</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">7.2.
  Infocoin may provide Confidential Information of Principal: (i) to
  any third party posting the Content or to the relevant Principal as
  appropriate; or (ii) to any Principal holding company or to any
  Principal's subsidiary or to any subsidiary of the Principal's
  holding company.</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">7.3.
  The Principal acknowledges and agrees that any information relating
  to any invoice, credit card, invoicing and payment provided by the
  Principal to Infocoin Limited may be provided by Infocoin Limited to
  operating companies, on behalf of Infocoin Limited, exclusively for
  the purposes of checking the balance on the accounts, making payments
  in favor of Infocoin Limited and / or for servicing the Principal's
  account.  </span></span></span></font></font></font>
  </p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">7.4.
  This section of the agreement is not executive and refers to the
  Privacy Policy, which we are guided by, and you accept that you
  should also be guided by it in dealing with us.</span></span></span></font></font></font></p>
  <p align="center" style="line-height: 0.18in; margin-bottom: 0.11in; text-decoration: none">
  <br/>
  <br/>
  
  </p>
  <p align="center" style="line-height: 0.18in; margin-bottom: 0.11in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><b>8.
  Term and term</b></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><b>цination</b></span></span></font></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">8.1.
  The term of this Agreement begins on the Date of signing (including
  online by performing actions on the site) and lasts until the
  termination of the Agreement or until the expiration date of the
  agreement. As a general rule, the contract lasts 1 calendar year. The
  Principal, subject to prior approval from Infocoin Limited by e-mail,
  may extend this Agreement by providing e-mail, or by performing
  online actions on our website may extend the validity of this
  agreement for the next term in 1 calendar year, or for such other
  period, which can be agreed by Infocoin Limited in writing.</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">8.2.
  These Terms are considered as a proposal to enter into an Agreement
  in accordance with the laws of the Republic of Cyprus. The Agreement
  is concluded at the time of acceptance of the Terms online, and which
  is considered the Date of Acceptance, unless another Date of
  Acceptance is specified in the Service Agreement (if any).</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">8.3.
  This Agreement is concluded in writing by exchanging electronic
  messages between the Parties, in the absence of the Agreement on the
  provision of services, which was concluded earlier. At the request of
  either Party, the Parties may prepare a paper copy of the Agreement.
  To do this, a paper copy will be dated the Date of Acceptance, the
  place of commission will be considered the city at the location of
  Infocoin Limited.</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">8.4.
  For the purposes of applicable consumer protection law, this
  Agreement, which is available online prior to the conclusion of the
  Agreement, is considered as information about the services provided
  by Infocoin Limited and / or Infocoin at a distance.</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">8.5.
  This Agreement may be terminated with immediate effect by either
  Party by giving written notice (including without limitation
  including notice by e-mail) to the other Party.</span></span></span></font></font></font></p>
  <p align="center" style="line-height: 0.18in; margin-bottom: 0.11in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><b>9.
  Consequences of termination and cancellation of content placement</b></span></span></font></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">9.1.
  In the event of expiration or termination of this Agreement: (i) in
  the event of the Principal establishing new campaigns or in any other
  way continuing to use the Program, he shall continue to be bound by
  the obligations under this Agreement in the same way as the term of
  this Agreement would not have expired or had not been terminated
  (including without limitation, including the obligation to pay the
  charges incurred in connection with the Program); (ii) until the
  Principal has revoked its placement of User Content, the Principal
  shall remain liable for the obligations under this Agreement and
  shall pay the fees incurred in connection therewith; (iii) the
  Principal shall continue to be responsible for the payment of any
  amounts remaining unpaid as of the date of expiry or termination;
  (iv) each Party shall return Confidential Information to the other
  Party upon request; and (v) Infocoin Limited may, in its sole
  discretion, block or delete any Principal account in the Program.</span></span></span></font></font></font></p>
  <p align="center" style="line-height: 0.18in; margin-bottom: 0.11in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><b>10.
  Retreat</b></span></span></font></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">10.1.
  The Parties have the right to assign or otherwise transfer their
  rights and / or obligations under this Agreement only with the
  written consent of the other Party (such consent may not be refused
  or withheld without sufficient grounds), except that Infocoin Limited
  has the right to assign its rights and / or obligations under this
  Agreement to a third party. By signing this agreement, the Principal
  agrees to the assignment of his rights and / or obligations to
  Infocoin Limited.</span></span></span></font></font></font></p>
  <p align="center" style="line-height: 0.18in; margin-bottom: 0.11in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><b>11.
  Dispute resolution procedure. Responsibilities of the parties</b></span></span></font></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">11.1.
  All disputes or disagreements that arise between the parties under
  this agreement or in connection with it, must be resolved through
  negotiations.</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">11.2.
  In case of impossibility to resolve differences through negotiations
  between the parties, disputes shall be considered in the relevant
  court, in accordance with applicable law.</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">11.3.
  In case of breach of the terms of this Agreement, the violating party
  is obliged to compensate the other party for damages, including lost
  profits.</span></span></span></font></font></font></p>
  <p align="center" style="line-height: 0.18in; margin-bottom: 0.11in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><b>12.
  Other provisions</b></span></span></font></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">12.1.
  This Agreement may be concluded about several placement companies. In
  relation to one placement campaign, the Parties may enter into
  several successive Agreements, including as a result the full use of
  the Budget under the Agreement.</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">12.2.
  The Parties agree that the information from the control panel or from
  the account of the online system Infocoin, certified in writing by
  Infocoin Limited and / or Infocoin, is appropriate evidence of the
  conclusion or change of the terms of the Agreement.</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">12.3.
  By providing personal information under this Agreement, you consent
  to the processing of Infocoin in accordance with the Privacy Policy
  located at: (<a href="https://www.infocoin.online/docs/">https://www.infocoin.online/docs/</a>).</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">12.4.
  This Agreement sets out all the terms agreed between the Parties with
  respect to its subject matter and supersedes all previous agreements,
  declarations and any kind of agreement between the Parties with
  respect to its subject matter.</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">12.5.
  The Rules may be amended unilaterally at any time by Infocoin Limited
  to reflect changes in how Infocoin ensures the overall commercial
  viability of the Program. No amendment or modification of the
  provisions of this Agreement shall be binding on the Parties prior to
  confirmation online or unless it has been documented in writing and
  clearly agreed by the parties in writing and bilaterally (including
  without limitation including by clicking the mouse and accepting or
  exchanging emails).</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">12.6.
  No failure or delay in the exercise of any right or remedy under this
  Agreement shall be deemed a waiver of such right or remedy, and no
  one-time or partial exercise of any right or remedy under this
  Agreement shall deprive the possibility of any subsequent exercise of
  the right or remedy or the exercise of any other right or remedy. The
  rights and remedies provided for in this Agreement are cumulative and
  do not preclude the application of any rights or remedies provided by
  law.</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">12.7.
  Invalidity, illegality or inability to perform of any provision of
  this Agreement shall not entail invalidity or affect the further
  legal force of any other provisions of this Agreement.</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">12.8.
  Nothing in this Agreement shall be construed to constitute a
  partnership (joint partnership) or joint venture of any kind between
  the Parties to the Agreement or to provide for an agency or
  representative relationship between the Parties to achieve any
  purpose of any kind. In this case, neither Party has the right and
  authority to bind the other Party with obligations or to enter into a
  contractual relationship on behalf of such Party or to create
  obligations to a third party in any way and for any purpose.</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">12.9.
  Unless otherwise provided in this Agreement, all communications shall
  be sent to the addresses specified in this Agreement (or online),
  with a copy to the Legal Department by (i) sending by facsimile with
  acknowledgment of receipt provided a copy is sent first class mail or
  airmail; (ii) dispatch by courier service, such notifications being
  deemed to have been received at the time of receipt; or (iii) by
  e-mail.</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 0.18in; margin-bottom: 0.11in">
  <font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">12.10.
  The agent is a payer of corporate income tax in accordance with the
  provisions of the laws of the Republic of Cyprus.</span></span></span></font></font></font></p>
  <p align="center" style="line-height: 0.18in; margin-bottom: 0.11in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><b>13.
  Legal address and details of the Agent</b></span></span></font></font></font></p>
  <p align="center" style="line-height: 0.18in; margin-bottom: 0.11in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><b>INFOCOIN
  LIMITED</b></span></span></font></font></font></p>
  <p align="justify" style="font-weight: normal; line-height: 0.18in; margin-bottom: 0.11in; text-decoration: none">
  <br/>
  <br/>
  
  </p>
  <p align="center" style="line-height: 0.18in; margin-bottom: 0.11in"><font color="#19191b"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">Anexartisias
  &amp; Athinon, </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">NORA
  COURT, 2nd floor, Office 203, 3040, Limassol, Cyprus</span></span></span></font></font></font></p>
  <p align="center" style="line-height: 0.18in; margin-bottom: 0.11in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">e-mail:
  info@infocoin-online.email</span></span></span></font></font></font></p>
  <p align="center" style="font-weight: normal; line-height: 0.18in; margin-bottom: 0.11in; text-decoration: none">
  <br/>
  <br/>
  
  </p>
  <p align="center" style="line-height: 0.18in; margin-bottom: 0.11in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">Michalis
  Michail</span></span></span></font></font></font></p>
  <p align="center" style="line-height: 0.18in; margin-bottom: 0.11in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">Director.</span></span></span></font></font></font></p>
  </body>
  </html>`,
  uk: `<!DOCTYPE html>
  <html>
  <head>
    <meta http-equiv="content-type" content="text/html; charset=utf-8"/>
    <title></title>
    <meta name="generator" content="LibreOffice 7.3.2.2 (Linux)"/>
    <meta name="created" content="00:00:00"/>
    <meta name="changed" content="00:00:00"/>
    <style type="text/css">
      @page { size: 8.5in 11in; margin: 1in }
      p { margin-bottom: 0.1in; background: transparent }
      a:link { color: #000080; so-language: zxx; text-decoration: underline;text-underline-offset: 4px; }
    </style>
  </head>
  <body lang="en-US" link="#000080" vlink="#800000" dir="ltr"><p align="center" style="line-height: 100%; margin-bottom: 0in">
  <br/>
  
  </p>
  <p align="center" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><b>ПУБЛІЧНИЙ
  ДОГОВІР (ОФЕРТА)</b></span></font></font></font></p>
  <p align="center" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><b>НА
  РОЗМІЩЕННЯ КОРИСТУВАЦЬОГО КОНТЕНТУ</b></span></font></font></font></p>
  <p style="line-height: 100%; margin-bottom: 0in"><br/>
  
  </p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000">&nbsp;</font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="font-weight: normal">Цей
  Договір (визначений нижче) укладено
  шляхом прийняття цієї пропозиції, що
  містить усі істотні умови Договору,
  вами, що виступає юридичною особою або
  фізичною особою,  та яка: (і) приймає цей
  Договір в режимі он-лайн; або (іі) визначена
  як </span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><b>&quot;Принципал&quot;</b></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="font-weight: normal">
  і підписує Договір (надалі - </span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><b>&quot;Замовник&quot;</b></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="font-weight: normal">
  / </span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><b>&quot;Ви&quot;</b></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="font-weight: normal">/
  </span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><b>&quot;Принципал&quot;</b></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="font-weight: normal">)
  і </span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="font-weight: normal">Infocoin
  Limited</span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="font-weight: normal">
  в особі директора Міхаліса Михайла,
  який діє на підставі Статуту (надалі -
  </span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><b>&quot;Агент&quot;,
  &quot;Компанія&quot;</b></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="font-weight: normal">)
  (надалі разом - &quot;</span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><b>Сторони</b></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="font-weight: normal">&quot;,
  або кожна окремо - </span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><b>&quot;Сторона&quot;</b></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="font-weight: normal">).
  Цей Договір регулює Вашу участь у
  Програмі (визначеній нижче).</span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>
  
  </p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><b>Терміни,
  які використовуються в даному договорі:</b></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="font-weight: normal">-
  </span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><b>&quot;Infocoin&quot;</b></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="font-weight: normal">
  – </span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="font-weight: normal">додаток
  та сайт, об’єднані назвою Infocoin, які
  мають спільний функціонал і відносяться
  до компанії </span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="font-weight: normal">Infocoin
  Limited</span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="font-weight: normal">;</span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="font-weight: normal">-
  &quot;</span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="font-weight: normal">Конфіденційна
  інформація&quot; – інформація, яка визначена
  як така, відповідно до Політики
  Конфіденційності нашої компанії;</span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="font-weight: normal">-
  </span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><b>&quot;</b></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><b>Політика
  Конфіденційності&quot;</b></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="font-weight: normal">
  – документ, який є обов’язковим до
  прийняття всіма користувачами сайту і
  Послугами Infocoin, який розміщено на сайті
  за посиланням: </span></span></font></font></font><font color="#0000ff"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><u><span style="font-weight: normal">(<a href="https://www.infocoin.online/docs/">https://www.infocoin.online/docs/</a></span></u></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">).
  Розділ 7 цього договору не є вичерпним,
  і відсилає до повної Політики
  Конфіденційності, якою керуємось ми, і
  якою маєте керуватись ви, у своїй
  взаємодії з нами;</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">-
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><b>&quot;</b></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><b>Компанія
  (компанії) з розміщення&quot;</b></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">
  – мається на увазі розміщення
  користувацького контенту і відповідної
  суми коштів. Одна компанія – одне
  розміщення;</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">-
  &quot;</span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><b>Правила</b></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">&quot;
  – означає правила Програми, які
  розміщуються за адресою
  </span></span></span></font></font></font><font color="#0000ff"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><u><span style="font-weight: normal">(<a href="https://www.infocoin.online/docs/">https://www.infocoin.online/docs/</a></span></u></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">)&nbsp;включаючи
  Правила Конфіденційності, які розміщуються
  за адресою (</span></span></span></font></font></font><a href="https://www.infocoin.online/docs/"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">https://www.infocoin.online/docs/</span></span></span></font></font></font></a><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">);</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">-
  &quot;</span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><b>Програма
  Infocoin</b></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">&quot;
  – означає програму розміщення контенту
  он-лайн.	</span></span></span></font></font></font></p>
  <p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
  <br/>
  
  </p>
  <p align="center" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><b>1.
  </b></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><b>Предмет
  договору</b></span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">1.1.
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Агент
  зобов'язується за винагороду робити з
  доручення Принципала всі необхідні дії
  для розміщення користувацького контенту
  Принципала через систему Infocoin від свого
  імені, але за рахунок Принципала.</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">1.2.
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Агент
  здобуває права й стає зобов'язаним по
  угоді, укладеної із третьою особою від
  свого імені за рахунок Принципала.</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">1.3.
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">По
  угоді, укладеної Агентом із третьою
  особою від імені й за рахунок Принципала,
  права й обов'язки виникають у Принципала.</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">1.4.
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Винагорода
  Агента може змінюватись в залежності
  від багатьох чинників. Актуальна сума
  винагороди Агента доступна в особистому
  кабінеті Infocoin і діє лише на дату підписання
  договору. Винагорода Агента може
  змінюватись будь-коли, ви погоджуєтесь
  що достатнім чином проінформовані про
  таку винагороду, якщо вона як мінімум
  розміщена в особистому кабінеті Infocoin.</span></span></span></font></font></font></p>
  <p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
  <br/>
  
  </p>
  <p align="center" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><b>2.
  </b></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><b>Розміщення
  і таргетування</b></span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">2.1.
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">Infocoin
  Limited</span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">докладатиме
  усіх зусиль для розміщення користувацького
  контенту Принципала,  у відповідності
  до параметрів розміщення, наданих і
  обраних Принципалом.</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">2.2.
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">Infocoin
  Limited</span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">не
  гарантує: (а) розміщення, місцеположення
  або швидкість знаходження будь-якого
  користувацького контенту, або (б)
  кількість (якщо такі є) кліків за посилання
  і число опублікувань, перетворень або
  кліків стосовно будь-якого контенту,
  розміщеного на сайті або в додатку
  Infocoin або в розділах такого сайту/додатку.</span></span></span></font></font></font></p>
  <p align="center" style="line-height: 100%; margin-bottom: 0in; text-decoration: none">
  <br/>
  
  </p>
  <p align="center" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><b>3.
  </b></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><b>Права
  та обов'язки сторін</b></span></span></font></font></font></p>
  <p style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><b>3.1.
  </b></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><b>Принципал
  зобов'язаний:</b></span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">-
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">сплатити
  суму виділену Принципалом на одну
  компанію. Оплата здійснюється шляхом
  виконання дій на сайті у системі Infocoin
  і перерахування коштів на відповідний
  рахунок компанії </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Infocoin
  Limited</span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">.
  Такі зобов’язання Агента вважаються
  виконаними в повному обсязі після
  надання Агентом звіту за відповідний
  період або звіту про виконання зобов'язань
  по окремим зробленим Агентом угодам;</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">-
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">не
  укладати аналогічних договорів з іншими
  особами;</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">-
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">повідомляти
  Агентові про наявні заперечення по
  представленому звіту протягом&nbsp; 5
  (п’яти) днів після його подання. У випадку
  якщо в зазначений строк заперечення не
  будуть представлені звіт вважається
  прийнятим Принципалом.</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><b>3.2.
  </b></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><b>Принципал
  вправі:</b></span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><b>-
  </b></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">до
  початку трансляції користувацького
  контенту звернутись до служби підтримки
  за посиланням </span></span></span></font></font></font><a href="https://ads.infocoin.online/support"><font color="#0563c1"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><u><span style="font-weight: normal">https://</span></u></span></font></font></font></a><a href="https://ads.infocoin.online/support"><font color="#0563c1"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><u><span style="font-weight: normal">business</span></u></span></font></font></font></a><a href="https://ads.infocoin.online/support"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">.infocoin.online/support</span></span></span></font></font></font></a><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">із
  заявою про повернення грошових коштів,
  зарезервованих під трансляцію відповідного
  ролику;</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">-
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">після
  завершення періоду трансляції ролику
  (після сливу 24 годин), звернутись до
  служби підтримки</span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><b>
  </b></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">за
  посиланням </span></span></span></font></font></font><a href="https://ads.infocoin.online/support"><font color="#0563c1"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><u><span style="font-weight: normal">https://</span></u></span></font></font></font></a><a href="https://ads.infocoin.online/support"><font color="#0563c1"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><u><span style="font-weight: normal">business</span></u></span></font></font></font></a><a href="https://ads.infocoin.online/support"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">.infocoin.online/support</span></span></span></font></font></font></a><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">з
  проханням повернути залишок несписаної
  суми, яка зарезервована під трансляцію
  відповідного ролика.</span></span></span></font></font></font></p>
  <p style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><b>3.3.
  </b></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><b>Агент
  зобов'язаний:</b></span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">-
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">надавати
  Принципалу звіти в міру виконання
  Договору, але не рідше одного разу на
  місяць. До звіту Агента повинні бути
  прикладені необхідні докази витрат.
  В</span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">сі
  дані, що знаходяться в особистому
  кабінеті Принципала, а також звіти, що
  надсилатимуться на електронну пошту
  Принципала є достатніми доказами витрати
  коштів та надання послуг</span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">;</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">-
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">виконувати
  вказівки Принципала, що стосуються
  вчинених Агентом угод і інших зобов’язань,
  якщо ці вказівки не суперечать вимогам
  закону;</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">-
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">у
  разі звернення Принципала з причин,
  визначених п. 3.2. цього Договору, впродовж
  72 годин розглянути та повідомити про
  результати розгляду такого звернення
  шляхом надсилання листа на електронну
  пошту Принципала. </span></span></span></font></font></font>
  </p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">-
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">виконувати
  всі зобов’язання сумлінно.</span></span></span></font></font></font></p>
  <p style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><b>3.4.
  </b></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><b>Агент
  вправі:</b></span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">-
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">з
  метою виконання договору укласти
  субагентский договір з іншою особою,
  залишаючись відповідальним за дії
  субагента перед Принципалом, якщо
  сторони не домовляться про інше. Субагент
  не вправі укладати із третіми особами
  угоди від імені особи, що є Принципалом
  за агентським договором, за винятком
  випадків, прямо передбачених субагентским
  договором. Агент зобов'язаний погодити
  умови субагентского договору із
  Принципалом;</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">-
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">односторонньо
  розірвати даний Договір у випадках
  порушення Принципалом Правил розміщення
  контенту, цього Договору, законів
  Республіки Кіпр, підтримки дій Російської
  Федерації, заперечення агресії проти
  України.</span></span></span></font></font></font></p>
  <p align="center" style="line-height: 100%; margin-bottom: 0in; text-decoration: none">
  <br/>
  
  </p>
  <p align="center" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><b>4.
  </b></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><b>Дія
  непереборної сили</b></span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">4.1.
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Жодна
  із сторін не несе відповідальності
  перед іншою стороною за затримку або
  невиконання зобов'язань, обумовлені
  обставинами непереборної сили, що
  виникли незалежно від волі й бажання
  сторін і які не можна було передбачати
  або уникнути, включаючи оголошену або
  фактичну війну, громадські хвилювання,
  епідемії, блокаду, ембарго, а також
  землетрусу, повені, пожежі й інші стихійні
  лиха.</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">4.2.
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Сторона
  яка не може виконати своїх зобов'язань
  внаслідок дії непереборної сили
  зобов'язана негайно сповістити іншу
  сторону про зазначені обставини.</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">4.3.
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">У
  зв'язку з виниклими обставинами
  непереборної сили сторони повинні
  підписати протокол про припинення дії
  даного Договору або погодити спільні
  дії по подоланню несприятливих наслідків
  зазначених обставин.</span></span></span></font></font></font></p>
  <p align="center" style="line-height: 100%; margin-left: -0.59in; margin-bottom: 0in; text-decoration: none">
  <br/>
  
  </p>
  <p align="center" style="line-height: 100%; margin-left: -0.59in; margin-bottom: 0in">
  <font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><b>5.
  </b></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><b>Скасування
  розміщення користувацького контенту </b></span></span></font></font></font>
  </p>
  <p align="center" style="line-height: 100%; margin-left: -0.59in; margin-bottom: 0in">
  <font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><b>та
  повернення грошових коштів</b></span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">5.1.
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Якщо
  іншого не передбачено в Правилах або
  Договорі (якщо такий є) або іншому он-лайн
  інтерфейсі, що посилається на Умови
  розміщення контенту або цей Договір,
  будь-яка Сторона може в будь-який час
  скасувати будь-яке розміщення
  користувацького контенту до моменту
  розміщення. Принципал може скасувати
  розміщення користувацького контенту
  завчасного надавши </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Infocoin
  Limited</span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">
  письмове повідомлення, в тому числі, не
  обмежуючись, електронний лист, звернувшись
  до служби підтримки за посиланням
  </span></span></span></font></font></font><a href="https://ads.infocoin.online/support"><font color="#0563c1"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><u><span style="font-weight: normal">https://</span></u></span></font></font></font></a><a href="https://ads.infocoin.online/support"><font color="#0563c1"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><u><span style="font-weight: normal">business</span></u></span></font></font></font></a><a href="https://ads.infocoin.online/support"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">.infocoin.online/support</span></span></span></font></font></font></a><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">.</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">5.2.
  Розміщення користувацького контенту
  припиняється невдовзі після скасування.
  Будь-які інші скасовані розміщення
  користувацького контенту можуть
  публікуватися незважаючи на те, що було
  виконано всі дії для їх скасування,
  насамперед через брак часу для реакції
  з боку Агента, якщо такі оголошення
  опубліковуються і мають перегляди, а
  відповідно й виникають зобов’язання
  виплати з боку Агента, Принципал має
  сплатити на користь </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Infocoin
  Limited</span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">
  за такий опублікований користувацький
  контент. </span></span></span></font></font></font>
  </p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">5.3.
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">У
  разі прийняття рішення про повернення
  грошових коштів за заявою Принципала,
  відповідно до п.п.3.2. цього Договору,
  Агент надсилає на електронну пошту
  Принципала акт звіряння взаєморозрахунків.
  Зазначений акт вважається узгодженим
  Принципалом у разі: і) підписанням
  електронним цифровим підписом; іі)
  здійснення необхідних дій у особистому
  кабінеті ііі) надсиланням у відповідь
  підписаної скан-копії такого акту.</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">5.4.
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Після
  узгодження акту звіряння взаєморозрахунків,
  Агент шляхом перерахування грошових
  коштів на рахунок Принципала, з якого
  було здійснено відповідну оплату, 
  здійснює повернення грошових коштів
  Принципалу. Всі витрати, пов’язані з
  перерахуванням грошових коштів
  сплачуються за рахунок Принципала. </span></span></span></font></font></font>
  </p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">5.5.
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Повернення
  грошових коштів Агентом принципалу
  здійснюється впродовж 60 календарних
  днів. На час повернення грошових коштів
  впливає швидкість обробки транзакцій
  відповідними банками та/або платіжними
  системами, й у зв’язку з цим, строк
  повернення грошових коштів може бути
  збільшений.</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">5.6.
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Заявки
  на повернення грошових коштів під час
  трансляції ролику не приймаються.</span></span></span></font></font></font></p>
  <p align="center" style="line-height: 100%; margin-bottom: 0in; text-decoration: none">
  <br/>
  
  </p>
  <p align="center" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><b>6.</b></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><b>Заяви
  та гарантії</b></span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">6.1.
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Принципал
  заявляє та гарантує, що (i) вся інформація,
  надана Принципалом для </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Infocoin
  Limited</span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">,
  є повною, точною і оновленою; (ii) він має
  всі необхідні права, щоб надати дозвіл
  і цим надає </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Infocoin
  Limited</span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">
  і будь-яким Партнерам всі такі права,
  що необхідні для </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Infocoin
  Limited</span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">
  та для будь-якого Партнера (Партнерів)
  (в залежності від випадку) на використання,
  хостинг, розміщення в кеш-пам'яті,
  відстеження, зберігання, копіювання,
  зміну, розповсюдження, зміну формату,
  відтворення, оприлюднення, демонстрацію,
  передачу та поширення користувацького
  контенту Принципала (включаючи будь-які
  Контекстні об'єкти) (</span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">«</span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Використання
  Контенту</span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">»);
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">і
  (iii) Використання Контенту у відповідності
  до цього Договору, а також веб-сайтів
  та/або цільових сторінок, на які виходять
  канали передачі з користувацького
  контенту Принципала не повинно: (a)
  порушувати або заохочувати порушення
  будь-якого законодавства або чинних
  регуляторних або процесуальних норм;
  (б) порушувати будь-які права інтелектуальної
  власності будь-якої третьої особи або
  містити будь-який матеріал, який може
  завдати шкоди, бути образливим,
  непристойним, погрожуючим або таким,
  що принижує гідність.</span></span></span></font></font></font></p>
  <p align="center" style="line-height: 100%; margin-bottom: 0in; text-decoration: none">
  <br/>
  
  </p>
  <p align="center" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><b>7.
  </b></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><b>Конфіденційність</b></span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">7.1.
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Відповідно
  до цього Договору нижче на строк дії
  цього Договору та впродовж двох років
  після його припинення Сторони
  зобов'язуються не розкривати Конфіденційну
  інформацію іншої Сторони третім особам
  без попередньої письмової (включаючи
  переданої по електронній пошті) згоди
  за винятком випадків, передбачених цим
  Договором. Принципал несе виключну
  відповідальність за використання
  третіми особами будь-якої звітності
  Принципала в режимі он-лайн. </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">«</span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Конфіденційна
  Інформація</span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">»
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">включає
  в себе (i) паролі Принципала; (ii)
  користувацький контент (у тому числі
  Контекстні об'єкти) до моменту публікації;
  та (iv) будь-яку іншу Програмну інформацію
  або доступ до технології перед їх
  публічним оприлюдненням, що були надані
  з боку </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Infocoin
  Limited</span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">
  для Принципала і визначені на момент
  розголошення в письмовій формі як
  конфіденційного та/або службового
  характеру; (v) характерні риси Програми,
  які не є публічними і визначаються як
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">«</span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Бета</span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">»
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">або
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">«</span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Пробний
  контент</span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">».
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Ця
  Конфіденційна інформація не включає в
  себе інформацію, яка стала загальновідомою
  не через порушення з боку будь-якої
  Сторони або (а) яка була самостійно
  розроблена без доступу до Конфіденційної
  інформації іншої Сторони; (b) законно
  отримана від третьої особи або (с) повинна
  бути розголошена відповідно до закону
  або вимоги органу державної влади. </span></span></span></font></font></font>
  </p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">7.2.
  Infocoin </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">може
  надавати Конфіденційну інформацію
  Принципала: (i) будь-якій третій особі,
  що розмішує контент, або відповідному
  Принципалу в разі необхідності; або
  (ii) будь-якій холдинговій компанії
  Принципала або будь-якому дочірньому
  підприємству Принципала або будь-якому
  дочірньому підприємству холдингової
  компанії Принципала.</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">7.3.
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Принципал
  визнає й погоджується з тим, що будь-яка
  інформація, що стосується будь-якого
  рахунку, кредитної картки, виставляння
  та оплати рахунків, яку Принципал надає
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Infocoin
  Limited</span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">,
  може бути передана з боку </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Infocoin
  Limited</span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">
  компаніям, які працюють за дорученням
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Infocoin
  Limited</span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">,
  винятково для цілей здійснення перевірки
  залишку на рахунках, здійснення платежів
  на користь </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Infocoin
  Limited</span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">
  і/або для обслуговування рахунку
  Принципала.</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">7.4.
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Цей
  розділ договору не є вичерпним і відсилає
  до Політики Конфіденційності, якою
  керуємось ми, і ви приймаєте те, що ви
  маєте також нею керуватись у взаємодії
  з нами.</span></span></span></font></font></font></p>
  <p align="center" style="line-height: 100%; margin-bottom: 0in; text-decoration: none">
  <br/>
  
  </p>
  <p align="center" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><b>8.
  </b></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><b>Строк
  і припинення дії</b></span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">8.1.
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Перебіг
  строку дії цього Договору розпочинається
  з Дати підписання (в тому числі он-лайн
  шляхом виконання дій на сайті) і триває,
  поки не буде припинений Договір або
  поки не настане Дата закінчення строку
  договору. За загальним правилом договір
  триває 1 календарний рік. Принципал, за
  умови отримання попереднього погодження
  від </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Infocoin
  Limited</span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">
  електронною поштою, може подовжити дію
  цього Договору шляхом надання повідомлення
  електронною поштою, або шляхом виконання
  дій он-лайн на нашому сайті може продовжити
  строк дії даного договору на наступний
  строк у 1 календарний рік, або на такий
  інший період, який може бути погоджений
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Infocoin
  Limited</span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">
  в письмовій формі.</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">8.2.
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Ці
  Умови розглядаються як пропозиція
  укласти Договір у відповідності до
  законодавства Республіки Кіпр. Договір
  є укладеним в момент прийняття Умов в
  режимі он-лайн, і який розглядається як
  Дата Прийняття, якщо інша Дата Прийняття
  не визначена в Договорі про надання
  послуг (якщо такий є).</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">8.3.
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Цей
  Договір укладений в письмовій формі
  шляхом обміну електронними повідомленнями
  між Сторонами, за умови відсутності
  Договору про надання послуг, який
  укладено раніше. За запитом будь-якої
  Сторони, Сторони можуть підготувати
  паперову копію Договору. Для цього
  паперова копія буде датована Датою
  Прийняття, місцем вчинення буде вважатись
  місто за місцезнаходженням </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Infocoin
  Limited</span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">.</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">8.4.
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Для
  цілей застосовного законодавства про
  захист прав споживачів цей Договір, що
  є доступним в режими он-лайн перед
  укладанням Договору, розглядається як
  інформація про послуги, що надаються
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Infocoin
  Limited</span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">
  та/або Infocoin на відстані.</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">8.5.
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Цей
  Договір може бути розірваний з
  невідкладними наслідками з боку будь-якої
  Сторони шляхом надання письмового
  повідомлення (у тому числі без обмежень
  включаючи повідомлення електронною
  поштою) іншій Стороні.</span></span></span></font></font></font></p>
  <p align="center" style="line-height: 100%; margin-bottom: 0in; text-decoration: none">
  <br/>
  
  </p>
  <p align="center" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><b>9.
  </b></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><b>Наслідки
  припинення та анулювання розміщення
  контенту</b></span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">9.1.
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">У
  разі спливу строку дії або розірвання
  цього Договору: (i) у разі створення
  Принципалом нових кампаній або якщо
  він будь-яким іншим чином продовжує
  використовувати Програму, він надалі
  залишається зобов'язаним за зобов'язаннями,
  що передбачені цим Договором так само,
  коли б строк дії цього Договору не
  закінчився або коли б його не було б
  розірвано (у тому числі без обмежень
  включаючи зобов'язання щодо сплати
  нарахувань, які були понесені у зв'язку
  з Програмою); (ii) поки Принципал не скасує
  свої розміщення користувацького
  контенту, Принципал залишається надалі
  зобов'язаним за зобов'язаннями, що
  передбачені цим Договором, а також
  сплачувати нарахування, що були понесені
  у зв'язку з такими; (iii) Принципал надалі
  несе відповідальність за сплату будь-яких
  сум, що залишаються несплаченими станом
  на дату закінчення строку дії або
  розірвання; (iv) кожна Сторона на вимогу
  повертає іншій стороні Конфіденційну
  інформацію; а також (v) </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Infocoin
  Limited</span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">
  на свій власний розсуд може заблокувати
  або видалити будь-який рахунок Принципала
  в Програмі. </span></span></span></font></font></font>
  </p>
  <p align="center" style="line-height: 100%; margin-bottom: 0in; text-decoration: none">
  <br/>
  
  </p>
  <p align="center" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><b>10.
  </b></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><b>Відступлення</b></span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">10.1.
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Сторони
  мають право відступати або будь-яким
  іншим чином передавати свої права та/або
  зобов'язання відповідно до цього Договору
  лише з письмової згоди іншої Сторони
  (така згода не може бути відхилена або
  затримана без достатніх для цього
  підстав), за винятком того, що </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Infocoin
  Limited</span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">
  має право відступити свої права та/або
  зобов'язання відповідно до цього Договору
  третій особі. Підписанням цього договору
  Принципал погоджується на відступлення
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Infocoin
  Limited</span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">
  своїх права та/або зобов'язань, яке може
  виникнути. </span></span></span></font></font></font>
  </p>
  <p align="center" style="line-height: 100%; margin-bottom: 0in; text-decoration: none">
  <br/>
  
  </p>
  <p align="center" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><b>11.
  </b></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><b>Порядок
  вирішення спорів. Відповідальність
  сторін</b></span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">11.1.
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Усі
  суперечки або розбіжності, що виникають
  між сторонами за даним договором або у
  зв'язку з ним, вирішуються шляхом
  переговорів.</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">11.2.
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">У
  випадку неможливості вирішення
  розбіжностей шляхом переговорів між
  сторонами, суперечки підлягають розгляду
  у відповідному суді, згідно норм діючого
  законодавства.</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">11.3.
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">При
  порушенні умов даного Договору сторона,
  що порушила, зобов'язана відшкодувати
  іншій стороні заподіяні збитки, включаючи
  упущену вигоду.</span></span></span></font></font></font></p>
  <p align="center" style="line-height: 100%; margin-bottom: 0in; text-decoration: none">
  <br/>
  
  </p>
  <p align="center" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><b>12.
  </b></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><b>Інші
  положення</b></span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">12.1.
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Цей
  Договір може бути укладений по відношенню
  до кількох компаній з розміщення. По
  відношенню до однієї кампанії з розміщення
  Сторони можуть укласти декілька
  послідовних Договорів, включаючи як
  результат повне використання Бюджету
  за Договором.</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">12.2.
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Сторони
  погоджуються, що інформація з панелі
  управління або з облікового запису
  он-лайн системи Infocoin, у письмовому
  вигляді завірена </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Infocoin
  Limited</span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">
  та/або Infocoin, є належним доказом укладення
  або зміни умов Договору.</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">12.3.
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Надаючи
  персональні відомості в рамках цього
  Договору, Ви погоджуєтесь на їх обробку
  Infocoin у відповідності до Правил
  Конфіденційності, що знаходяться за
  адресою: </span></span></span></font></font></font><font color="#0000ff"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><u><span style="font-weight: normal">(<a href="https://www.infocoin.online/docs/">https://www.infocoin.online/docs/</a></span></u></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">).</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">12.4.
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">У
  цьому Договорі викладено всі умови,
  погоджені між Сторонами стосовно його
  предмета, і він заміщує собою всі
  попередні договори, заяви та будь-якого
  характеру домовленості між Сторонами
  щодо його предмета.</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">12.5.
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Правила
  в будь-який час можуть бути змінені
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Infocoin
  Limited</span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">
  в односторонньому порядку з метою
  відображення змін в тому, як Infocoin
  забезпечує загальну комерційну
  доцільність Програми. Жодне доповнення
  або зміна положень цього Договору не
  має зобов'язального характеру для Сторін
  до підтвердження у системі он-лайн або
  якщо їх не було оформлено письмовим
  документом, який був чітко погоджений
  сторонами в письмовій формі і в
  двосторонньому порядку (у тому числі
  без обмежень включаючи шляхом кліку
  мишкою та акцепту або обміну електронним
  повідомленнями).</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">12.6.
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Жодне
  нездійснення та жодна затримка у
  здійсненні будь-якого права або засобу
  правового захисту відповідно до цього
  Договору не вважатимуться відмовою від
  такого права або засобу правового
  захисту, а жодне разове або часткове
  здійснення будь-якого права або засобу
  правового захисту відповідно до цього
  Договору не позбавлятиме можливості
  будь-якого наступного здійснення права
  або засобу правового захисту або
  реалізації будь-якого іншого права або
  засобу правового захисту. Права та
  засоби правового захисту, передбачені
  цим Договором, є кумулятивними й не
  виключають можливості застосування
  будь-яких прав або засобів правового
  захисту, що передбачені законом.</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">12.7.
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Недійсність,
  незаконність або непридатність для
  звернення до виконання будь-якого
  положення цього Договору не тягне за
  собою недійсності або не впливає на
  подальшу юридичну силу будь-яких інших
  положень цього Договору.</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">12.8.
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Жодне
  положення цього Договору не тлумачиться
  як таке, що створює партнерство (просте
  товариство) або спільне підприємство
  будь-якого виду між Сторонами Договору
  або не передбачає виникнення між
  Сторонами агентських або представницьких
  відносин для досягнення будь-якої цілі
  будь-якого характеру. При цьому жодна
  зі Сторін не має права та повноважень
  пов'язувати іншу Сторону зобов'язаннями
  або вступати в договірні відносини від
  імені такої Сторони чи створювати
  зобов'язання перед третьою особою
  будь-яким чином та для будь-якої потреби.</span></span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">12.9.
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Якщо
  в цьому Договорі не обмовляється інше,
  всі повідомлення повинні направлятися
  на адреси, що зазначені у цьому Договорі
  (або в системі он-лайн), з копією для
  юридичного відділу шляхом (і) відправлення
  факсимільним зв'язком з підтвердженням
  одержання за умови надсилання копії
  поштовим відправленням першого класу
  або авіапоштою; (іі) відправлення
  кур'єрськими службами, причому такі
  повідомлення вважаються наданими в
  момент їх отримання; або (ііі) електронною
  поштою. </span></span></span></font></font></font>
  </p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">12.10.
  </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Агент
  є платником податку на прибуток
  підприємств згідно з нормами законодавства
  Республіки Кіпр.</span></span></span></font></font></font></p>
  <p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
  <br/>
  
  </p>
  <p align="center" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><b>13.
  </b></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><b>Юридична
  адреса й реквізити Агента</b></span></span></font></font></font></p>
  <p align="center" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
  <br/>
  
  </p>
  <p align="center" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><b>INFOCOIN
  LIMITED</b></span></span></font></font></font></p>
  <p align="center" style="line-height: 0.18in; margin-bottom: 0.11in"><font color="#19191b"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">Anexartisias
  &amp; Athinon, </span></span></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">NORA
  COURT, 2nd floor, Office 203, 3040, Limassol, Cyprus</span></span></span></font></font></font></p>
  <p align="center" style="line-height: 0.18in; margin-bottom: 0.11in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><span style="text-decoration: none"><span style="font-weight: normal">e-mail:
  info@infocoin-online.email</span></span></span></font></font></font></p>
  <p align="center" style="line-height: 0.18in; margin-bottom: 0.11in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Міхаліс
  Михайло</span></span></span></font></font></font></p>
  <p align="center" style="line-height: 0.18in; margin-bottom: 0.11in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="ru-RU"><span style="text-decoration: none"><span style="font-weight: normal">Директор.</span></span></span></font></font></font></p>
  <p style="line-height: 0.18in; margin-bottom: 0.11in"><br/>
  <br/>
  
  </p>
  </body>
  </html>`,
  de: `<!DOCTYPE html>
<html><head>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8">
<meta name="Generator" content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
\t{font-family:"Cambria Math";
\tpanose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
\t{font-family:Calibri;
\tpanose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
\t{font-family:Georgia;
\tpanose-1:2 4 5 2 5 4 5 2 3 3;}
@font-face
\t{font-family:inherit;
\tpanose-1:0 0 0 0 0 0 0 0 0 0;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
\t{margin-top:0in;
\tmargin-right:0in;
\tmargin-bottom:8.0pt;
\tmargin-left:0in;
\tline-height:107%;
\tfont-size:11.0pt;
\tfont-family:"Calibri",sans-serif;}
.MsoChpDefault
\t{font-family:"Calibri",sans-serif;}
.MsoPapDefault
\t{margin-bottom:8.0pt;
\tline-height:107%;}
 /* Page Definitions */
 @page WordSection1
\t{size:595.3pt 841.9pt;
\tmargin:56.75pt 42.5pt 78.0pt 70.9pt;}
div.WordSection1
\t{page:WordSection1;}
-->
</style>

</head>

<body lang="EN-US" link="#0563C1" vlink="#954F72" style="word-wrap:break-word">

<div class="WordSection1">

<p class="MsoNormal" align="center" style="margin-bottom:0in;text-align:center;
line-height:normal;"><b><span style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">&nbsp;</span></b></p>

<p class="MsoNormal" align="center" style="margin-bottom:0in;text-align:center;
line-height:normal;"><b><span lang="UK" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">ÖFFENTLICHER </span></b><b><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">AUF</span></b><b><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">TRAG
(</span></b><b><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">ANGEBOT</span></b><b><span lang="UK" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">)</span></b></p>

<p class="MsoNormal" align="center" style="margin-bottom:0in;text-align:center;
line-height:normal;"><b><span lang="DE" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">FÜR DIE</span></b><b><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
BENUTZER</span></b><b><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">-CONTENT-PLATZIERUNG</span></b></p>

<p class="MsoNormal" style="margin-bottom:0in;line-height:normal;"><b><span lang="UK" style="font-size:10.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">&nbsp;</span></b></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal;"><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">&nbsp;</span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal;"><span lang="DE" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">Dieser Auftrag</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
(unten definiert) wird durch die Annahme dieses Angebots, das alle wesentlichen
Bedingungen </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">des Auftrags</span><span lang="UK" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black"> enthält, durch Sie, </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Rechtsperson</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
oder natürliche Person, die: (i) diese Vereinbarung online </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">annimmt</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">;
oder (ii) als <b>„Auftraggeber“</b> identifiziert </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">ist </span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">und
</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">den Auftrag</span><span lang="UK" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black"> (im Folgenden – <b>„Kunde“ /
„Sie“ / „Auftraggeber“</b>) </span><span lang="DE" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">unterzeichnet, </span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">und
Infocoin Limited in der Person des Direktors Mykhailo</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black"> </span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Mikhalis,
handelnd </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">aufgrund der Satzung</span><span lang="UK" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black"> (im Folgenden – <b>„
Agent", "</b></span><b><span lang="DE" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">Firma</span></b><b><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">"</span></b><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">)
(im Folgenden zusammen - die <b>"Parteien"</b> oder jede einzeln -
eine <b>"Partei"</b>)</span><span lang="UK" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black"> </span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">abgeschlossen.
Diese</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">r</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black"> </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">Auftrag</span><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black"> regelt Ihre Teilnahme am Programm (unten
definiert).</span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal;"><b><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">&nbsp;</span></b></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal;"><b><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">In diesem Auftrag verwendete Begriffe:</span></b></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal;"><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">- <b>„Infocoin“</b> – eine unter dem Namen
Infocoin vereinte Anwendung und Website, die eine gemeinsame Funktionalität
haben und der Firma Infocoin Limited gehören;</span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal;"><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">- „Vertrauliche Informationen“ –
Informationen, die gemäß der Datenschutzrichtlinie unserer </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Firma</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
als solche definiert sind;</span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal;"><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">- <b>„Datenschutzrichtlinie“</b> </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">–</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
ein Dokument, das für alle Benutzer der Website und der Infocoin-Dienste
verbindlich ist und auf der Website unter folgendem Link veröffentlicht wird:
(https://www.infocoin.online/docs/). Abschnitt 7 </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">dieses
Auftrags</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black"> ist nicht erschöpfend und </span><span lang="DE" style="font-size:
12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">verweist</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
auf die vollständige Datenschutzrichtlinie, die </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">unsere</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">Zusammenwirkung mit ihnen</span><span lang="DE" style="font-size:
12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black"> </span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">regelt
und die </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">ohre Zusammenwirkung mit uns</span><span lang="UK" style="font-size:
12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black"> regeln </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">musst</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">;</span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal;"><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">- <b>„Hosting-Unternehmen“ </b>bezeichnet
das Hosting von </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">Benuther-Content</span><span lang="UK" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black"> und den entsprechenden Geldbetrag.
Ein Unternehmen – ein </span><span lang="DE" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">Hosting</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">;</span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal;"><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">- <b>„Regeln“</b> bezeichnet die unter
(https://www.infocoin.online/docs/) veröffentlichten Programmregeln,
einschließlich der unter (https://www.infocoin.online/docs/) veröffentlichten
Datenschutzrichtlinie;</span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal;"><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">- <b>„Infocoin-Programm“</b> bezeichnet
das Online-Content-</span><span lang="DE" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">Platzierung</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">-Programm.</span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal;"><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">&nbsp;</span></p>

<p class="MsoNormal" align="center" style="margin-bottom:0in;text-align:center;
line-height:normal;"><b><span lang="UK" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">1. Auftragsgegenstand</span></b></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal;"><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">1.1. Der </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Agent</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
verpflichtet sich, gegen eine </span><span lang="DE" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">Vergütung</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">, alle
erforderlichen Maßnahmen im Namen des Auftraggebers durchzuführen, um </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">das</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">Benutzer-Content </span><span lang="UK" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">des Auftraggebers in </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">eigenem</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
Namen, jedoch auf Kosten des Auftraggebers, über das Infocoin-System zu </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">platzieren</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">.</span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal;"><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">1.2. Der </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Agent</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
erwirbt Rechte und verpflichtet sich aus dem mit einem Dritten </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">im
Namen des Agenten und</span><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black"> auf Kosten des Auftraggebers abgeschlossenen
Vertrag.</span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal;"><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">1.3. Gemäß dem </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Auf</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">trag,
den der </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">Agent</span><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black"> mit einem Dritten im Namen und auf Kosten
des Auftraggebers schließt, gehen Rechte und Pflichten vom Auftraggeber aus.</span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal;"><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">1.4. Die Vergütung des Agenten kann in
Abhängigkeit von vielen Faktoren variieren. Die aktuelle Höhe der
Agentenvergütung ist im persönlichen </span><span lang="DE" style="font-size:
12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Kabinett</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
von Infocoin einsehbar und gilt nur zum Zeitpunkt der </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Auf</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">tragsunterzeichnung.
Die Vergütung des Agenten kann sich jederzeit ändern, Sie stimmen zu, dass Sie
über diese Vergütung ausreichend informiert sind, wenn sie zumindest </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">im</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
persönlichen </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">Kabinett</span><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black"> von Infocoin </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">angegeben</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
wird.</span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal;"><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">&nbsp;</span></p>

<p class="MsoNormal" align="center" style="margin-bottom:0in;text-align:center;
line-height:normal;"><b><span lang="UK" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">2. Розміщення і таргетування</span></b></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal;"><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">2.1. Infocoin Limited wird alle
Anstrengungen unternehmen, um </span><span lang="DE" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">das Benutzer-Content</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
des Auftraggebers gemäß den vom Auftraggeber bereitgestellten und ausgewählten
Hosting-Parametern zu </span><span lang="DE" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">platzieren</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">.</span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal;"><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">2.2. Infocoin Limited garantiert nicht:
(a) die Platzierung, der </span><span lang="DE" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">Position </span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">oder
die Geschwindigkeit, mit </span><span lang="DE" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">das Benuther-Content</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
gefunden </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">wird</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">, oder (b) die Anzahl (falls vorhanden) der Klicks pro Link und
die Anzahl der </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">Veröffentlichungen</span><span lang="UK" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">, </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Modifizierungen</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
oder Klicks in Bezug auf </span><span lang="DE" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">das</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black"> </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Content,
das </span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">auf der Website oder in der Infocoin-</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Wendung</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
oder Abschnitten dieser Website/</span><span lang="DE" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">Wendung veröffentlicht wird</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">.</span></p>

<p class="MsoNormal" align="center" style="margin-bottom:0in;text-align:center;
line-height:normal;"><span lang="UK" style="color:black"><br>
</span><b><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">3. Rechte und Pflichten der Parteien</span></b></p>

<p class="MsoNormal" style="margin-bottom:0in;line-height:normal;"><b><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">3.1.
Der </span></b><b><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">Auftraggeber</span></b><b><span lang="UK" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black"> ist verpflichtet:</span></b></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal;"><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">- den vom Auftraggeber für ein</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">e</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">Firma</span><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black"> zugeteilten Betrag </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">zu </span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">zahlen.
Die Zahlung erfolgt durch </span><span lang="DE" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">Handlungen</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
auf der Website im Infocoin-System und Überweisung von Geldern auf das
entsprechende Konto von Infocoin Limited. Diese Verpflichtungen des </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Agenten</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
gelten als vollständig erfüllt, nachdem der </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Agent</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
einen Bericht </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">für</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black"> den </span><span lang="DE" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">entsprechenden</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
Zeitraum oder einen Bericht über die Erfüllung von Verpflichtungen für einzelne
</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">von dem Agenten erfüllten Vereinbarungen</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
vorgelegt hat;</span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal;"><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">- ähnliche </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Auf</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">träge
mit anderen Personen nicht abzuschließen;</span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal;"><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">- bestehende Einwände gegen den </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">vorgelagenen</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
Bericht dem Agenten innerhalb von 5 (fünf) Tagen nach dessen </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Vorlegen</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">zu </span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">mitteilen. Werden keine </span><span lang="DE" style="font-size:
12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Einwände</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
innerhalb der gesetzten Frist </span><span lang="DE" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">mitteilt</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">, gilt
der Bericht als vom Auftraggeber angenommen.</span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal;"><b><span lang="DE" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">3.2. Der Auftraggeber ist berichtet auf:</span></b></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal;"><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">- </span><span lang="DE" style="font-size:
12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">die Anforderung</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">der</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black"> Rück</span><span lang="DE" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">zahl</span><span lang="UK" style="font-size:
12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">ung der für die </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Übertragung</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
des betreffenden Videos reservierten Gelder </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">vom</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
Support-Service über den Link https://business.infocoin.online/support vor
Beginn der </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">Übertragung</span><span lang="UK" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black"> von </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Benutzer-Content</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">;</span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal;"><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">- </span><span lang="DE" style="font-size:
12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">die Anforderung</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">der Zurückzahlung des</span><span lang="DE" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black"> </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">nicht
abgeschriebenen </span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">Restbetrag</span><span lang="DE" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">s</span><span lang="UK" style="font-size:
12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">, der der für die </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Übertragung</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
des betreffenden Videos reserviert </span><span lang="DE" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">wurde</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">,</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
vom</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black"> Support-Service über den Link
https://business.infocoin.online/support nach dem Ende der </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Übertragung</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
des </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">Videos</span><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black"> (nach 24 Stunden).</span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal;"><b><span lang="DE" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">3.3. Der Agent ist verpflichtet:</span></b></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal;"><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">- dem Auftraggeber über die </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Auf</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">tragserfüllung
Bericht </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">zu </span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">erstatten, jedoch mindestens einmal </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">pro</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
Monat. Dem Bericht des </span><span lang="DE" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">Agenten</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
müssen die erforderlichen Kostennachweise beigefügt werden. Alle Daten im
persönlichen </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">Kabinett</span><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black"> des Auftraggebers sowie die an die E-Mail
des Auftraggebers gesendeten Berichte sind ausreichende Beweise für die </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Kostenaufwände</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
und die Erbringung von Dienstleistungen;</span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal;"><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">- die Weisungen des Auftraggebers
bezüglich der vom </span><span lang="DE" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">Agenten</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
getroffenen Vereinbarungen und sonstigen Verpflichtungen auszuführen, sofern
diese Weisungen nicht den gesetzlichen Anforderungen widersprechen;</span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal;"><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">- im Falle einer </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Anfrage
</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">des Auftraggebers aus den in Ziffer 3.2 dieses </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Auftrags
</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">genannten Gründen &nbsp;</span><span lang="DE" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">diese Anfrage </span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">innerhalb
von 72 Stunden zu überprüfen </span><span lang="DE" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">und </span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">um die
Ergebnisse der Prüfung zu berichten, </span><span lang="DE" style="font-size:
12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">via</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
einen Brief an die E-Mail-Adresse des Auftraggebers.</span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal;"><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">- alle Verpflichtungen gewissenhaft </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">zu
</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">erfüllen.</span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal;"><b><span lang="DE" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">3.4. Der Agent ist berechtigt auf:</span></b></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal;"><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">- </span><span lang="DE" style="font-size:
12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">im Rahment der </span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Erfüllung
des </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">Auf</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">trages mit einer anderen Person einen Subagentenvertrag
abschließen und </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">damit </span><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">für die Handlungen des Subagenten
gegenüber dem Auftraggeber verantwortlich bleiben, sofern die Parteien nichts
anderes vereinbaren. Der </span><span lang="DE" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">Subagent</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black"> ist
außer in den im Sub</span><span lang="DE" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">agenten</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">vertrag
ausdrücklich vorgesehenen Fällen nicht berechtigt, im Namen des Auftraggebers
Verträge mit Dritten abzuschließen. Der </span><span lang="DE" style="font-size:
12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Agent</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
ist verpflichtet, die Bedingungen des </span><span lang="DE" style="font-size:
12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Subagenten</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">vertrages
mit dem Auftraggeber zu vereinbaren;</span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal;"><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">- dieser </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Auftrag
</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">einseitig zu kündigen, wenn der Auftraggeber Regeln </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">der</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
Veröffentlichung vom </span><span lang="DE" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">Content</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">,
diese</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">n</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black"> </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">Auftrag</span><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">, </span><span lang="DE" style="font-size:
12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">entsprechende</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
Gesetze der Republik Zypern</span><span lang="DE" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black"> bricht</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">, </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">die</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
Maßnahmen der Russischen Föderation</span><span lang="DE" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black"> unterstützt</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">,
</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">oder die</span><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black"> Aggression gegen die Ukraine </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">leugnet</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">.</span></p>

<p class="MsoNormal" align="center" style="margin-bottom:0in;text-align:center;
line-height:normal;"><b><span lang="UK" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">&nbsp;</span></b></p>

<p class="MsoNormal" align="center" style="margin-bottom:0in;text-align:center;
line-height:normal;"><span lang="UK" style="color:black"><br>
</span><b><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">4. </span></b><b><span lang="DE" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">Umstände</span></b><b><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
höherer Gewalt</span></b></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal;"><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">4.1. Keine Partei haftet gegenüber der
anderen Partei für Verzögerungen oder Nichterfüllung ihrer Verpflichtungen
aufgrund von Umständen höherer Gewalt, die außerhalb des Willens und Wollens
der Parteien entstanden sind und die nicht vorhersehbar oder vermeidbar waren,
einschließlich erklärter oder tatsächlicher Krieg, </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Bürgeru</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">nruhen,
Epidemien, Blockade, Embargo, sowie Erdbeben, Überschwemmungen, Brände und
andere Naturkatastrophen.</span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal;"><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">4.2. Die Partei, die ihren Verpflichtungen
aufgrund </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">von Umständen </span><span lang="UK" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">höherer Gewalt nicht </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">erfüllen</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
kann, ist verpflichtet, die andere Partei unverzüglich über die genannten
Umstände zu informieren.</span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal;"><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">4.3. </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">In
Zusammenhang mit</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black"> Umständen höherer Gewalt müssen die Parteien ein Protokoll über
die </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">Kündigung dieses Auftrags</span><span lang="UK" style="font-size:
12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black"> unterzeichnen oder
gemeinsame Maßnahmen vereinbaren, um die nachteiligen Folgen dieser Umstände zu
überwinden.</span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal;"><span lang="DE" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">&nbsp;</span></p>

<p class="MsoNormal" align="center" style="margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:-42.5pt;text-align:center;line-height:normal"><b><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">5.
</span></b><b><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">Widerruf</span></b><b><span lang="UK" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black"> der </span></b><b><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Benutzer-Content-Platzierung</span></b></p>

<p class="MsoNormal" align="center" style="margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:-42.5pt;text-align:center;line-height:normal"><b><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">und
Rückzahlungen</span></b></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">5.1. Sofern in den Regeln oder der Vereinbarung (falls vorhanden)
oder einer anderen Online-Schnittstelle, die auf die </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Content-Platzierung-</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Bedingungen
oder diese</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">n</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black"> </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">Auftrag</span><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black"> verweist, nichts anderes bestimmt ist,
kann jede Partei jederzeit </span><span lang="DE" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">jede</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black"> </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Platzierung</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
von </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">Benutzer-Content</span><span lang="UK" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black"> vor der </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Platzierung</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">widerrufen</span><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">. Der Auftraggeber kann die </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Platzierung</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
von </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">Benutzer-Content</span><span lang="DE" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black"> </span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">im
Voraus </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">widerrufen</span><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">, indem er Infocoin Limited schriftlich
benachrichtigt, einschließlich, aber nicht beschränkt auf E-Mail, </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">durch</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
den Support</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">-Dienst</span><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black"> unter
https://business.infocoin.online/support kontaktiert.</span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">5.2. </span><span lang="DE" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">Die Platzierung </span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">von </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Benutzer-Content</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">wird kurz nach dem </span><span lang="DE" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">Widerruf </span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">eingestellt.
Alle anderen </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">widerrufenen</span><span lang="UK" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black"> Postings von </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Benutzer-Content</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">können veröffentlicht werden, obwohl alle Maßnahmen ergriffen
wurden, um sie zu </span><span lang="DE" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">widerrufen</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">,
hauptsächlich aufgrund fehlender Zeit für den Agenten, um zu reagieren, wenn
solche Postings veröffentlicht werden und Aufrufe haben und dementsprechend von
nun an </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">Agentenz</span><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">ahlungsverpflichtungen nach sich ziehen</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">.</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">D</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">er Auftraggeber </span><span lang="DE" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">muss </span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Infocoin
Limited solche</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">s</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black"> </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">platzierten</span><span lang="DE" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black"> </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Benutzer-Content</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">bezahlen</span><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">.</span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">5.3. Im Falle einer Entscheidung zur </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Rückzahlung</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
auf Antrag des Auftraggebers gemäß Ziffer 3.2. dieses </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Auftrags</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
sendet der Agent an die E-Mail-Adresse des Auftraggebers ein Vergleichsprotokoll
der gegenseitigen Rechnungsstellung. Das angegebene </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Protokoll</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
gilt als vom </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">Agenten</span><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black"> vereinbart, wenn: i) </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">sie
</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">mit einer elektronischen digitalen Signatur unterzeichnet wird;
ii) erforderliche </span><span lang="DE" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">Handlungen</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black"> im
persönlichen </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">Kabinett vorgenommen wurden</span><span lang="UK" style="font-size:
12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black"> iii) </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">eine</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
unterschriebene Scan-Kopie diese</span><span lang="DE" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">s</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black"> </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Protokoll</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">rückgesendet wurde</span><span lang="UK" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">.</span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">5.4. Nach </span><span lang="DE" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">derAbstimmung</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black"> de</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">s</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
Vergleichsprotokolls </span><span lang="DE" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">zahlt</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black"> der
Agent durch Überweisung von Geldern auf das Konto des Auftraggebers, von dem
die entsprechende Zahlung </span><span lang="DE" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">vorübergewiesen</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
wurde, die Gelder an den Auftraggeber zurück. Alle Kosten im Zusammenhang mit
der Überweisung von Geldern gehen zu Lasten des Auftraggebers.</span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">5.5. Der </span><span lang="DE" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">Agent muss die Gelder</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black"> de</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">m</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
Auftraggeber innerhalb von 60 Kalendertagen</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
zurückzahlen</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">. Der Zeitpunkt der </span><span lang="DE" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">Rückzahlung</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
wird durch die Geschwindigkeit der Transaktions</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">verarbeitung</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
durch die entsprechenden Banken und/oder Zahlungssysteme beeinflusst, und in
dieser Hinsicht kann der Rück</span><span lang="DE" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">zahl</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">ungszeitraum
verlängert werden.</span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">5.6. Rüczahlungsanträge werden während der </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Übertragung</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
des Videos nicht akzeptiert.</span></p>

<p class="MsoNormal" align="center" style="margin-bottom:0in;text-align:center;
line-height:normal"><a name="_heading=h.gjdgxs"></a><b><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">&nbsp;</span></b></p>

<p class="MsoNormal" align="center" style="margin-bottom:0in;text-align:center;
line-height:normal"><b><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">6. Erklärungen und Garantien</span></b></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">6.1. Der Auftraggeber erklärt und garantiert, dass (i) alle
Informationen, die der Auftraggeber Infocoin Limited zur Verfügung gestellt
hat, vollständig, genau und aktuell sind; (ii) der Auftraggeber hat alle
notwendigen Rechte, um die Erlaubnis zu erteilen, und gewährt Infocoin Limited
und allen Partnern hiermit alle Rechte, die für Infocoin Limited und alle
Partner (je nach Fall) erforderlich sind, um </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">das
Benutzer-Content des Auftraggebers</span><span lang="UK" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black"> zu verwenden, zu hosten,
zwischenzuspeichern, zu verfolgen, zu speichern, </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">zu
kopieren</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">, zu modifizieren, zu verteilen, zu neuformatieren, zu
reproduzieren, zu veröffentlichen, </span><span lang="DE" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">zu wiedergeben</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">,
zu übertragen und zu </span><span lang="DE" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">ausbreiten</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black"> von </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Benutzer-Content</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
des Auftraggebers (einschließlich aller Kontextobjekte) ("</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Content-N</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">utzung");
und (iii) die Nutzung </span><span lang="DE" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">des Contents</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black"> gemäß
diese</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">s</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black"> </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">Auftrags</span><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black"> sowie der Websites und/oder Zielseiten,
zu denen die Übertragungskanäle </span><span lang="DE" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">für</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black"> </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">das
Benutzer-Content</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black"> des Auftraggebers führen, darf nicht: (a) gegen irgendwelche Gesetz</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">e</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
oder anwendbare Regulierungs- oder Verfahrensnormen verstoßen oder zu deren
Verletzung ermutigen; (b) geistige Eigentumsrechte Dritter verletzen oder
Material enthalten, das schädlich, beleidigend, obszön, bedrohlich oder </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">ehrenrührig</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
sein kann.</span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">6.1. Принципал заявляє та гарантує, що (i) вся інформація, надана
Принципалом для </span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">Infocoin
Limited<span style="color:black">, є повною, точною і оновленою; (ii) він має
всі необхідні права, щоб надати дозвіл і цим надає </span>Infocoin Limited<span style="color:black"> і будь-яким Партнерам всі такі права, що необхідні для </span>Infocoin
Limited<span style="color:black"> та для будь-якого Партнера (Партнерів) (в
залежності від випадку) на використання, хостинг, розміщення в кеш-пам'яті,
відстеження, зберігання, копіювання, зміну, розповсюдження, зміну формату,
відтворення, оприлюднення, демонстрацію, передачу та поширення користувацького
контенту Принципала (включаючи будь-які Контекстні об'єкти) («Використання
Контенту»); і (iii) Використання Контенту у відповідності до цього Договору, а
також веб-сайтів та/або цільових сторінок, на які виходять канали передачі з
користувацького контенту Принципала не повинно: (a) порушувати або заохочувати
порушення будь-якого законодавства або чинних регуляторних або процесуальних
норм; (б) порушувати будь-які права інтелектуальної власності будь-якої третьої
особи або містити будь-який матеріал, який може завдати шкоди, бути образливим,
непристойним, погрожуючим або таким, що принижує гідність.</span></span></p>

<p class="MsoNormal" align="center" style="margin-bottom:0in;text-align:center;
line-height:normal"><b><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">&nbsp;</span></b></p>

<p class="MsoNormal" align="center" style="margin-bottom:0in;text-align:center;
line-height:normal"><b><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">7. </span></b><b><span lang="DE" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">Geheimhaltung</span></b></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">7.1. </span><span lang="DE" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">Gemäss diesem Auftrag</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black"> unten
verpflichten sich die Parteien, während der Laufzeit dieses </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Auftrags</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
und für zwei Jahre nach ihrer Beendigung die vertraulichen Informationen der
anderen Partei nicht ohne vorherige schriftliche Zustimmung (einschließlich
E-Mail) an Dritte weiterzugeben, mit Ausnahme von die in diesem </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Auftrag</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
vorgesehenen Fälle. Der Auftraggeber ist allein verantwortlich für die </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Online-</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Verwendung
von Berichten des Auftraggebers durch Dritte. „Vertrauliche Informationen“
umfassen (i) Passwörter des Auftraggebers; (ii) Benutzer</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">-Content
</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">(einschließlich Kontextobjekte) vor seiner Veröffentlichung; und
(iv) alle anderen </span><span lang="DE" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">Programm</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">informationen
oder Zugang zu Technologie vor ihrer Veröffentlichung, die dem Auftraggeber von
Infocoin Limited zur Verfügung gestellt wurden und zum Zeitpunkt der
schriftlichen Offenlegung als vertraulich und/oder </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">inner</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">geschäftlich
bestimmt wurden; (v) Funktionen des Programms, die nicht öffentlich sind und
als „Beta“ oder „Testin</span><span lang="DE" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">content</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">“
definiert sind. Diese vertraulichen Informationen beinhalten keine
Informationen, die </span><span lang="DE" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">nicht wegen </span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">einer </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Verpflichtungsv</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">erletzung
durch eine Partei öffentlich bekannt geworden sind, oder (a) die unabhängig
ohne Zugang zu den vertraulichen Informationen der anderen Partei entwickelt
wurden; (b) rechtmäßig von einem Dritten erhalten wurden oder (c) gesetzlich
oder behördlich offengelegt werden müssen.</span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">7.2. Infocoin kann die vertraulichen Informationen des
Auftraggebers an: (i) jeden </span><span lang="DE" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">das Content platzierenden </span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Dritt</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">en</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
oder gegebenenfalls den jeweiligen </span><span lang="DE" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">Prinzipal</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">; oder
(ii) eine Holdinggesellschaft des Auftraggebers oder eine Tochtergesellschaft
des Auftraggebers oder eine Tochtergesellschaft der Holdinggesellschaft des
Auftraggebers</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black"> </span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">offenlegen.</span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">7.3. Der Auftraggeber </span><span lang="DE" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">an</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">erkennt
an und stimmt zu, dass alle Konto-, Kreditkarten-, Abrechnungs- und </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Rechnungzahl</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">ungsinformationen,
die der Auftraggeber Infocoin Limited zur Verfügung stellt, von Infocoin
Limited mit </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">Firmen</span><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">, die im Namen von Infocoin Limited
handeln, ausschließlich zum Zweck der Überprüfung von Kontoständen und
Zahlungen an Infocoin Limited und/oder </span><span lang="DE" style="font-size:
12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Führung</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
des Kontos des Auftraggebers</span><span lang="UK" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black"> </span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">geteilt
werden können.</span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">7.4. Dieser Abschnitt </span><span lang="DE" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">des Auftrags</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
ist nicht erschöpfend und </span><span lang="DE" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">verweist</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black"> auf
die Datenschutzrichtlinie, die für uns gilt, und Sie stimmen zu, dass Sie auch </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">während</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
Ihrer </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">Zusammenwirkung</span><span lang="UK" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black"> mit uns davon betroffen sein
müssen.</span></p>

<p class="MsoNormal" style="margin-bottom:0in;line-height:27.0pt;background:#F8F9FA"><span lang="DE" style="font-size:21.0pt;font-family:&quot;inherit&quot;,serif;color:#202124">&nbsp;</span></p>

<p class="MsoNormal" align="center" style="margin-bottom:0in;text-align:center;
line-height:normal"><b><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">8. Laufzeit und Kündigung</span></b></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span lang="UK"><br>
</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">8.1. Die Laufzeit </span><span lang="DE" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">dieses Auftrags</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
beginnt mit dem Datum der Unterzeichnung (einschließlich </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">O</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">nline</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">-Unterzeichnung</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
durch </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">Begehen von Handlungen</span><span lang="UK" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black"> auf der Website) und dauert
bis zur </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">Kündigung des Auftrags</span><span lang="UK" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black"> oder bis zum Ablaufdatum </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">des
Auftrags</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">. Die </span><span lang="DE" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">Auf</span><span lang="UK" style="font-size:
12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">tragsdauer beträgt in
der Regel 1 Kalenderjahr. Der Auftraggeber kann, vorbehaltlich der vorherigen
Zustimmung von Infocoin Limited per E-Mail, die Gültigkeit diese</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">s</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">Auftrags</span><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black"> verlängern, indem er eine </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">entsprechende
</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">Benachrichtigung per E-Mail übermittelt oder Online-</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Handlungen</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
auf unserer Website </span><span lang="DE" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">begeht, um </span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">die
Laufzeit dieses </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">Austrags</span><span lang="DE" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black"> </span><span lang="DE" style="font-size:
12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">für </span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">nächsten
Zeitraum von 1 Kalenderjahr oder für einen anderen Zeitraum, der von Infocoin
Limited schriftlich vereinbart werden kann</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">, zu </span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">verlänger</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">n</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">.</span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">8.2. Diese Bedingungen gelten als Angebot zum Abschluss eines </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Auftrags</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
gemäß der Gesetzgebung der Republik Zypern. Der </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Auf</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">trag
kommt zum Zeitpunkt der Annahme der Bedingungen online zustande </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">der</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
als Annahme</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">datum</span><span lang="DE" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black"> </span><span lang="DE" style="font-size:
12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">gilt</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">,
</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">falls</span><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black"> i</span><span lang="DE" style="font-size:
12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">n der</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">Vereinbarung</span><span lang="DE" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black"> </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">für</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
die Erbringung von Dienstleistungen (falls vorhanden) </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">k</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">ein
anderes Annahmedatum angegeben</span><span lang="DE" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black"> ist</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">.</span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">8.3. Dieser </span><span lang="DE" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">Auf</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">trag </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">ist</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
schriftlich </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">erstellt, </span><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">durch den Austausch elektronischer
Nachrichten zwischen den Parteien, sofern kein</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">e</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
zuvor abgeschlossene </span><span lang="DE" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">Vereinbarung</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black"> </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">für</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
die Erbringung von Dienstleistungen </span><span lang="DE" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">vorhanden ist</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">.
Auf Antrag einer </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">Auf</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">tragspartei können die </span><span lang="DE" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">Auf</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">tragsparteien
eine Papierkopie des </span><span lang="DE" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">Auftrags</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
erstellen. Zu diesem Zweck wird die Papierkopie mit dem Annahme</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">datum</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
datiert, </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">und </span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">der Ausführungsort ist die </span><span lang="DE" style="font-size:
12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Ort</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">,
in der sich Infocoin Limited befindet.</span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">8.4. Für die Zwecke der geltenden Verbraucherschutzgesetze gilt
diese</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">r</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black"> </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">Auftrag</span><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">, die vor Abschluss des </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Auftrags</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
online verfügbar ist, als Information über Dienstleistungen, die von Infocoin
Limited und/oder Infocoin aus der Ferne </span><span lang="DE" style="font-size:
12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">erbracht</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
werden.</span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">8.5. Diese</span><span lang="DE" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">r</span><span lang="DE" style="font-size:
12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black"> </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Auftrag</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
kann von jeder Partei durch schriftliche </span><span lang="DE" style="font-size:
12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Anmeldung</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
(einschließlich, aber nicht beschränkt auf E-Mail-Benachrichtigung) an die
andere Partei mit sofortiger Wirkung gekündigt werden.</span></p>

<p class="MsoNormal" align="center" style="margin-bottom:0in;text-align:center;
line-height:normal"><span lang="UK"><br>
</span><b><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">9. Folgen der Kündigung und Einstellung der </span></b><b><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Content-Platzierung</span></b></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">9.1. Im Falle des Ablaufs oder der Kündigung dieses </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Aufvertrags</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">:
(i) </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">wenn</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black"> der Auftraggeber neue Kampagnen erstellt oder das Programm auf
andere Weise weiter nutzt, bleibt er weiterhin an die in diesem </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Auftrag</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
vorgesehenen Verpflichtungen gebunden</span><span lang="DE" style="font-size:
12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">,</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black"> in
gleicher Weise, </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">es sei </span><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">die Laufzeit dieses </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Auftrags</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
noch nicht abgelaufen ist oder </span><span lang="DE" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">es sei</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black"> </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">der
Auftrag</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black"> </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">nicht </span><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">gekündigt wurde (einschließlich, aber
nicht beschränkt auf Verpflichtungen zur Zahlung von </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">mit
dem Programm gebungenen Zuschlagen</span><span lang="UK" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">); (ii) bis der Auftraggeber
seine </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">Platzierungen</span><span lang="UK" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black"> von Benutzer</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">-Content
widerruft</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">, bleibt der Auftraggeber weiterhin an die in diesem </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Auftrag</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
festgelegten Verpflichtungen gebunden und muss die damit verbundenen </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Zuschlagen</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
zahlen; (iii) der Auftraggeber haftet weiterhin für die Zahlung aller Beträge,
die zum Ablauf- oder Kündigungsdatum unbezahlt bleiben; (iv) jede Partei gibt
die vertraulichen Informationen auf Anfrage andere</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">r</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
Partei zurück; und (v) Infocoin Limited kann nach eigenem Ermessen </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">jedes
Konto</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black"> des Auftraggebers im Programm </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">sperren</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
oder löschen.</span></p>

<p class="MsoNormal" align="center" style="margin-bottom:0in;text-align:center;
line-height:normal"><span lang="UK"><br>
</span><b><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">10. </span></b><b><span lang="DE" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">Abtretung</span></b></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">10.1. Die Parteien </span><span lang="DE" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">sind berechtigt</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">,
ihre Rechte und/oder Pflichten </span><span lang="DE" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">gemäß</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black"> </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">diesem
Auftrag</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black"> nur mit schriftlicher Zustimmung der anderen Partei </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">abzutreten</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
oder auf andere Weise zu übertragen (eine solche Zustimmung kann nicht ohne
ausreichende Gründe abgelehnt oder verzögert werden), mit Ausnahme von Infocoin
Limited </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">ist berechtigt</span><span lang="UK" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">, seine Rechte und/oder
Pflichten </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">gemäß</span><span lang="DE" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black"> </span><span lang="DE" style="font-size:
12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">diesem Auftrag</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">an Dritte abzutreten. </span><span lang="DE" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">Durch</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
Unterzeichnung </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">dieses Auftrags</span><span lang="UK" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black"> stimmt der Auftraggeber der
Abtretung </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">von</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black"> eventuell entstehenden Rechte</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">n</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
und/oder Pflichten durch Infocoin Limited zu.</span></p>

<p class="MsoNormal" align="center" style="margin-bottom:0in;text-align:center;
line-height:normal"><b><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">&nbsp;</span></b></p>

<p class="MsoNormal" align="center" style="margin-bottom:0in;text-align:center;
line-height:normal"><b><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">11. Streitbeilegungsverfahren. Verantwortlichkeiten der Parteien</span></b></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal;"><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">11.1. Alle Streitigkeiten oder
Meinungsverschiedenheiten, die zwischen den Parteien aus oder im Zusammenhang
mit diesem </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">Auf</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">trag entstehen, werden durch Verhandlungen beigelegt.</span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal;"><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">11.2. Im Falle der Unmöglichkeit, Meinungsverschiedenheiten
durch Verhandlungen zwischen den Parteien zu lösen, werden die Streitigkeiten
vor dem zuständigen Gericht gemäß den Normen der geltenden Gesetzgebung
behandelt.</span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal;"><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">11.3. Im Falle eines Verstoßes gegen die
Bestimmungen </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">dieses Auftrags</span><span lang="UK" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black"> ist die </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">schuldige</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
Partei verpflichtet, der anderen Partei den verursachten Schaden,
einschließlich entgangenen Gewinns, zu ersetzen.</span></p>

<p class="MsoNormal" align="center" style="margin-bottom:0in;text-align:center;
line-height:normal;"><span lang="UK" style="color:black"><br>
</span><b><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">12. Sonstige Bestimmungen</span></b></p>

<p class="MsoNormal" style="margin-bottom:0in;line-height:normal;"><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">12.1.
Diese</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">r</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black"> </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">Auftrag</span><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black"> kann in Bezug auf mehrere Hosting-</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Firmen</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
abgeschlossen werden. In Bezug auf eine </span><span lang="DE" style="font-size:
12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Platzier</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">ungskampagne
können die Parteien mehrere aufeinanderfolgende </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Auf</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">träge
abschließen, einschließlich der vollständigen Nutzung des Budgets im Rahmen des
</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">Auf</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">trags.</span></p>

<p class="MsoNormal" style="margin-bottom:0in;line-height:normal;"><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">12.2.
Die Parteien vereinbaren, dass Informationen aus dem Control Panel oder aus dem
Online-Konto des Infocoin-Systems, die schriftlich von Infocoin Limited
und/oder Infocoin bestätigt wurden, ein angemessener Beweis für den Abschluss
oder die Änderung der </span><span lang="DE" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">Auf</span><span lang="UK" style="font-size:
12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">tragsbedingungen sind.</span></p>

<p class="MsoNormal" style="margin-bottom:0in;line-height:normal;"><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">12.3.
Indem Sie im Rahmen dieses </span><span lang="DE" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">Auftrags</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
personenbezogene Daten angeben, stimmen Sie deren Verarbeitung durch Infocoin
gemäß der Datenschutzrichtlinie zu, die Sie unter:
(https://www.infocoin.online/docs/) finden.</span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal;"><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">12.4. Diese</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">r</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">Auftrag</span><span lang="DE" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black"> </span><span lang="DE" style="font-size:
12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">setzt</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
alle zwischen den Parteien vereinbarten Bedingungen in Bezug auf ihren
Gegenstand fest und ersetzt alle vorherigen Vereinbarungen, Erklärungen und
jede Art von </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">Zustimmungen</span><span lang="UK" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black"> zwischen den Parteien in
Bezug auf </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">seinen</span><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black"> Gegenstand.</span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal;"><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">12.5. Die Regeln können jederzeit von
Infocoin Limited einseitig geändert werden, um Änderungen daran
widerzuspiegeln, wie Infocoin die allgemeine kommerzielle </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Zweckhaftigkeit</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
des Programms bereitstellt. Ergänzungen oder Änderungen an den Bestimmungen
dieses </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">Auftrags</span><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black"> sind für die Parteien </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">nicht
</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">bindend, bis sie im Online-System bestätigt wurden oder durch ein
schriftliches Dokument, das von den Parteien ausdrücklich schriftlich und
bilateral vereinbart wurde (einschließlich, aber nicht beschränkt auf per
Mausklick und Annahme oder elektronischer Nachrichtenübermittlung)</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">,
</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">ausgeführt wurden.</span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal;"><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">12.6. Keine </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Nichtausübung
</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">oder keine Verzögerung bei der Ausübung eines Rechts oder
Rechtsbehelfs im Rahmen diese</span><span lang="DE" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">s</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black"> </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Auftrags</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
gilt als Verzicht auf dieses Recht oder Rechtsbehelf, und keine einzelne oder
teilweise Ausübung eines Rechts oder Rechtsbehelfs im Rahmen diese</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">s</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">Auftrags</span><span lang="DE" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black"> </span><span lang="UK" style="font-size:
12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">verzichtet auf die
Möglichkeit einer späteren Ausübung eines Rechts oder Rechtsbehelfs oder die
Ausübung eines anderen Rechts oder Rechtsbehelfs. Die in </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">diesem
Auftrag</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black"> vorgesehenen Rechte und Rechtsbehelfe sind kumulativ und
schließen nicht die Möglichkeit aus, gesetzlich vorgesehene Rechte oder
Rechtsbehelfe anzuwenden.</span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal;"><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">12.7. Die Ungültigkeit, Rechtswidrigkeit
oder </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">Nichtanwendbarkeit</span><span lang="UK" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black"> einer Bestimmung diese</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">s</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">Auftrags</span><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black"> soll </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">andere</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
Bestimmungen </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">dieses Auftrags</span><span lang="UK" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black"> nicht </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">rechts</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">ungültig
machen oder beeinträchtigen.</span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal;"><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">12.8. Keine Bestimmung </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">dieses
Auftrags</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black"> darf so ausgelegt werden, dass sie eine Partnerschaft (offene
Handelsgesellschaft) oder ein </span><span lang="DE" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">gemeinsames Unternehmen</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
jeglicher Art zwischen den </span><span lang="DE" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">Auf</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">tragsparteien
gründet oder eine Agentur- oder Vertretungsbeziehung zwischen den Parteien zur
Erreichung eines beliebigen Zwecks begründet. Gleichzeitig hat keine der
Parteien das Recht und die Befugnis, die andere Partei mit Verpflichtungen zu
binden oder im Namen dieser Partei Vertragsbeziehungen einzugehen oder in
irgendeiner Weise und für jeden Bedarf Verpflichtungen gegenüber einem Dritten
zu begründen.</span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal;"><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">12.9. Sofern in diesem </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Auftrag</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
nichts anderes bestimmt ist, müssen alle </span><span lang="DE" style="font-size:
12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Anmeldungen</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
an die in diese</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">m</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black"> </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">Auftrag</span><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black"> (oder im Online-System) angegebenen
Adressen gesendet werden, mit einer Kopie an die Rechtsabteilung, durch (i)
Senden per Fax mit Empfangsbestätigung, </span><span lang="DE" style="font-size:
12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">vorbehaltlich Versand</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
eine</span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">r</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black"> Kopie per erstklassiger Post oder Luftpost; (ii) Versand per
Kurierdienst, und solche </span><span lang="DE" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">Anmeldungen</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
gelten zum Zeitpunkt ihres Empfangs als </span><span lang="DE" style="font-size:
12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">geliefert</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">;
oder (iii) per E-Mail.</span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal;"><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">12.10. Der </span><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Agent</span><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
ist ein Körperschaftsteuerzahler gemäß den Gesetzen der Republik Zypern.</span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal;"><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">12.10. Агент є платником податку на
прибуток підприємств згідно з нормами законодавства Республіки Кіпр.</span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal;"><span lang="UK" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif;color:black">&nbsp;</span></p>

<p class="MsoNormal" align="center" style="margin-bottom:0in;text-align:center;
line-height:normal;"><span lang="UK" style="color:black"><br>
</span><b><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">13. Juristische </span></b><b><span lang="DE" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">Anschrift</span></b><b><span lang="UK" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">
und </span></b><b><span lang="DE" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
color:black">Bankverbindung des </span></b><b><span lang="UK" style="font-size:
12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:black">Agenten</span></b></p>

<p class="MsoNormal" align="center" style="margin-bottom:0in;text-align:center;
line-height:normal;"><b><span lang="UK" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">&nbsp;</span></b></p>

<p class="MsoNormal" align="center" style="margin-bottom:0in;text-align:center;
line-height:normal;"><b><span lang="UK" style="font-size:12.0pt;
font-family:&quot;Times New Roman&quot;,serif;color:black">INFOCOIN LIMITED</span></b></p>

<p class="MsoNormal" align="center" style="text-align:center"><span lang="UK" style="font-size:12.0pt;line-height:107%;font-family:&quot;Times New Roman&quot;,serif;
color:#19191B">Anexartisias &amp; Athinon, </span><span lang="UK" style="font-size:12.0pt;line-height:107%;font-family:&quot;Times New Roman&quot;,serif">NORA
COURT, 2nd floor, Office 203, 3040, Limassol, Cyprus</span></p>

<p class="MsoNormal" align="center" style="text-align:center"><span lang="UK" style="font-size:12.0pt;line-height:107%;font-family:&quot;Times New Roman&quot;,serif">e-mail:
info@infocoin-online.email</span></p>

<p class="MsoNormal" align="center" style="text-align:center"><span lang="UK" style="font-size:12.0pt;line-height:107%;font-family:&quot;Times New Roman&quot;,serif">Mykhailo</span><span lang="UK" style="font-size:12.0pt;line-height:107%;font-family:&quot;Times New Roman&quot;,serif">
</span><span lang="UK" style="font-size:12.0pt;line-height:107%;font-family:&quot;Times New Roman&quot;,serif">Michalis</span></p>

<p class="MsoNormal" align="center" style="text-align:center"><span lang="UK" style="font-size:12.0pt;line-height:107%;font-family:&quot;Times New Roman&quot;,serif">Direktor.</span></p>

</div>




</body></html>`,
};
