import React from "react";
import {useFormContext} from "react-hook-form";

import {LoaderOverlay} from "../LoaderOverlay";


const FormLoader = () => {
    const {
        formState: {
            isSubmitting
        }
    } = useFormContext();

    return (
        <LoaderOverlay
          open={isSubmitting} />
    );
};


export {FormLoader};