import React, {useState, useRef, useCallback, useEffect} from "react";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import SlideCaptcha from "react-slide-captcha";
import "react-slide-captcha/dist/styles.css";

import {captchaActions} from "src/redux";

import {SuccessIcon} from "src/views/icons";

import {Overlay} from "../Overlay";
import {LoaderOverlay} from "../LoaderOverlay";

import "./index.scss";


const Puzzle = (props) => {
    const {
        onSuccess
    } = props;

    const dispatch = useDispatch();
    const {t} = useTranslation();

    const [isLoading, setLoading] = useState(true);
    const [isSuccess, setSuccess] = useState(false);
    const [isError, setError] = useState(false);
    const [id, setId] = useState(null);
    const [bgUrl, setBgUrl] = useState("");
    const [puzzleUrl, setPuzzleUrl] = useState("");
    const captchaRef = useRef(null);

    const getCaptcha = useCallback(async () => {
        setLoading(true);

        try {
            const data = await dispatch(captchaActions.getCaptcha());

            if(data) {
                const {
                    id,
                    bgUrl = "",
                    puzzleUrl = ""
                } = data;

                setId(id);
                setBgUrl(bgUrl);
                setPuzzleUrl(puzzleUrl);
            }
        }
        catch(err) {
            console.error(err);
        }

        setLoading(false);
    }, [dispatch]);

    const resetCaptcha = useCallback(async () => {
        await getCaptcha();

        if(captchaRef.current) {
            captchaRef.current.resetCaptcha();
        }
    }, [getCaptcha]);

    const handleRequest = useCallback(async (value) => {
        const valid = await dispatch(captchaActions.validateCaptcha(id, value));

        if(valid) {
            setSuccess(true);

            if(onSuccess) {
                onSuccess();
            }
        }
        else {
            setError(true);

            await resetCaptcha();
        }
    }, [dispatch, id, onSuccess, resetCaptcha]);

    useEffect(() => {
        getCaptcha().then(() => {
            //
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="puzzle">
            <SlideCaptcha
              ref={captchaRef}
              tipsStyle={{ fontSize: "18px" }}
              containerClassName="puzzle__captcha"
              displayType="static"
              isLoading={isLoading}
              tipsText={!isError ? t("Swipe puzzle to missing place") : "Try again"}
              bgUrl={bgUrl}
              puzzleUrl={puzzleUrl}
              onRequest={handleRequest} />

            <LoaderOverlay open={isLoading} />

            <Overlay open={isSuccess}>
                <SuccessIcon />
            </Overlay>
        </div>
    );
};


export {Puzzle};