import React from 'react';
import { Grid } from '@mui/material';

import logo from '../../../assets/images/Logo.svg';
import rightImg from '../../../assets/images/People.png';
import rightImg2 from '../../../assets/images/People2.png';

import { useTranslation } from 'react-i18next';
import { LogoLarge, SelectTranslation, SwitchTheme } from 'src/views/blocks';
import { ROUTES } from 'routing';
import './index.scss';

const AuthLayout = props => {
  const { className = '', children } = props;

  const { t } = useTranslation();

  return (
      <Grid width='100%' minHeight='100vh' backgroundColor='#f0f1f7'>
      <Grid
      className={`auth-layout ${className}`}
      container
      direction="column"
      wrap="nowrap"
    >
      <Grid item container justifyContent="space-between">
        <Grid item className="d-flex" alignItems="center">
          <img className="auth-logo" src={logo} alt="" />
        </Grid>
        <Grid item className="d-flex" alignItems="center">
          <SelectTranslation expanded auth />
        </Grid>
      </Grid>
      <Grid
        container
        wrap="nowrap"
        flex
        justifyContent="space-between"
        flexDirection="row"
        marginTop="90px"
        className="auth-content-container"
      >
        <Grid className="auth-content">{children}</Grid>
        <Grid className="auth-right-content">
          <Grid
            justifyContent="space-between"
            className="auth-right-content-row"
            marginLeft="20px"
          >
            <Grid className="auth-right-content-block">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="21.6"
                  viewBox="0 0 24 21.6"
                >
                  <g id="movie-line" transform="translate(-2 -3)">
                    <path
                      id="Контур_23"
                      data-name="Контур 23"
                      d="M2,4.192A1.2,1.2,0,0,1,3.19,3H24.81A1.191,1.191,0,0,1,26,4.192V23.408A1.2,1.2,0,0,1,24.81,24.6H3.19A1.192,1.192,0,0,1,2,23.408ZM4.4,5.4V22.2H23.6V5.4Zm7.946,4.1L18.2,13.4a.48.48,0,0,1,0,.8l-5.856,3.9a.48.48,0,0,1-.745-.4V9.9a.48.48,0,0,1,.746-.4Z"
                      transform="translate(0 0)"
                      fill="#9596a2"
                    />
                  </g>
                </svg>
              </div>
              <span>{t('Reliable and up-to date information.')}</span>
            </Grid>
            <Grid className="auth-right-content-block">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <g id="dashboard-line" transform="translate(-3 -3)">
                    <path
                      id="Контур_25"
                      data-name="Контур 25"
                      d="M16.333,27V13.667H27V27ZM3,16.333V3H13.667V16.333Zm8-2.667v-8H5.667v8ZM3,27V19H13.667v8Zm2.667-2.667H11V21.667H5.667Zm13.333,0h5.333v-8H19ZM16.333,3H27v8H16.333ZM19,5.667V8.333h5.333V5.667Z"
                      fill="#9596a2"
                    />
                  </g>
                </svg>
              </div>
              <span>{t('Independent choice of interests.')}</span>
            </Grid>
          </Grid>
          <Grid
            justifyContent="space-between"
            className="auth-right-content-row"
          >
            <Grid className="auth-right-content-block">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="20.571"
                  viewBox="0 0 24 20.571"
                >
                  <g id="wallet-3-line" transform="translate(-2 -3)">
                    <path
                      id="Контур_27"
                      data-name="Контур 27"
                      d="M24.857,7.571H26V19H24.857v3.429a1.143,1.143,0,0,1-1.143,1.143H3.143A1.143,1.143,0,0,1,2,22.429V4.143A1.143,1.143,0,0,1,3.143,3H23.714a1.143,1.143,0,0,1,1.143,1.143ZM22.571,19H15.714a5.714,5.714,0,0,1,0-11.429h6.857V5.286H4.286v16H22.571Zm1.143-2.286V9.857h-8a3.429,3.429,0,0,0,0,6.857Zm-8-4.571h3.429v2.286H15.714Z"
                      transform="translate(0 0)"
                      fill="#9596a2"
                    />
                  </g>
                </svg>
              </div>
              <span>
                {t(
                  'Easy and fast withdrawal of rewards for viewing information and advertisements from providers.',
                )}
              </span>
            </Grid>
            <Grid className="auth-right-content-block">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <g id="time-line" transform="translate(-2 -2)">
                    <path
                      id="Контур_29"
                      data-name="Контур 29"
                      d="M14,26A12,12,0,1,1,26,14,12,12,0,0,1,14,26Zm0-2.4A9.6,9.6,0,1,0,4.4,14,9.6,9.6,0,0,0,14,23.6ZM15.2,14H20v2.4H12.8V8h2.4Z"
                      fill="#9596a2"
                    />
                  </g>
                </svg>
              </div>
              <span>
                {t(
                  'Choose the time and duration of content viewing. No limit.',
                )}
              </span>
            </Grid>
          </Grid>
          <Grid justifyContent="center" marginTop="65px" className="d-flex">
            {window.location.pathname === ROUTES.registration ? (
              <img className="auth-right-img" src={rightImg2} alt="img" />
            ) : (
              <img className="auth-right-img" src={rightImg} alt="img" />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
      </Grid>
  );
};

export { AuthLayout };
