import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Table, TableCell, TableRow } from '@mui/material';
import { releaseData } from 'data/release-notes';

import { authActions } from 'src/store';
import { useCountriesApi, useProfile, useShowMessage } from 'src/hooks';

import Modal from '@components/Modal';
import { ArrowBackIcon, LogoutIcon, ReleaseIcon } from 'src/views/icons';
import { Button, PageHeader, PageLoader, Typography } from 'src/views/blocks';
import { DashboardLayout } from 'src/views/layouts';

import { PasswordSection, SessionList, UserForm, PhoneForm } from './blocks';

import './index.scss';
import NotificationBlock from './blocks/Notifications';
import VerificationSelect from './blocks/VerificationSelect';

const ProfilePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const showMessage = useShowMessage();
  const profile = useProfile();

  let { kyc_verified: kycVerified = false } = profile || {};

  const [isLoading, setLoading] = useState(true);
  const [modalReleaseNotes, setModalReleaseNotes] = useState(false);

  const [
    dataDesctiptionModalReleaseModal,
    setDataDesctiptionModalReleaseModal,
  ] = useState(null);

  const contentModalReleaseNotes = (
    <div className="table-content-release">
      {dataDesctiptionModalReleaseModal ? (
        <>
          <Button
            className="mb-3"
            leftAddons={
              <ArrowBackIcon size="mini" style={{ marginTop: '2px' }} />
            }
            bold
            onClick={() => setDataDesctiptionModalReleaseModal(null)}
          >
            {t('Back')}
          </Button>
          <Typography
            dangerouslySetInnerHTML={{
              __html: dataDesctiptionModalReleaseModal,
            }}
          />
        </>
      ) : (
        <Table
          sx={{
            borderRadius: '10px',
            backgroundColor: 'var(--player-background)',
          }}
        >
          {JSON.parse(releaseData).map((release, key) => {
            return (
              <TableRow
                key={key}
                onClick={() => {
                  setDataDesctiptionModalReleaseModal(
                    release.slice(release.lastIndexOf('|') + 1),
                  );
                }}
                sx={{
                  cursor: 'pointer',
                  transition: 'color 0.2s linear',
                  '&:hover': {
                    color: '#FF5F00',
                  },
                }}
              >
                <TableCell
                  style={{
                    borderBottom: 'none',
                  }}
                >
                  <Typography>
                    {release.slice(0, release.indexOf('|'))}
                  </Typography>
                </TableCell>
                <TableCell
                  style={{
                    borderBottom: 'none',
                  }}
                >
                  <Typography>
                    {release.slice(
                      release.indexOf('|') + 1,
                      release.lastIndexOf('|'),
                    )}
                  </Typography>
                </TableCell>
              </TableRow>
            );
          })}
        </Table>
      )}
    </div>
  );

  const onHideModalRealesNotes = () => {
    setModalReleaseNotes(!modalReleaseNotes);
    setDataDesctiptionModalReleaseModal(null);
  };

  const diffProfile = useCallback(
    data => {
      const diff = {};

      for (const field in data) {
        if (data[field] !== profile[field]) {
          diff[field] = data[field];
        }
      }

      return diff;
    },
    [profile],
  );

  const fetchData = useCallback(async () => {
    const res = await Promise.all([
      dispatch(authActions.fetchMe()),
    ]);

    for (const i in res) {
      showMessage(res[i]);
    }

    return res;
  }, [dispatch, showMessage]);

  const handleSubmit = useCallback(
    async data => {
      const saveData = diffProfile(data);

      if (Object.keys(saveData).length > 0) {
        const res = await dispatch(authActions.updateProfile(saveData));

        showMessage(res);
      }
      return true;
    },
    [dispatch, diffProfile, showMessage],
  );

  const handleLogout = useCallback(async () => {
    const res = await dispatch(authActions.logout());

    showMessage(res);
  }, [showMessage, dispatch]);

  useEffect(() => {
    setLoading(true);

    fetchData().then(res => {
      let success = true;

      for (const i in res) {
        if (res[i].status === 'ERROR') {
          success = false;
        }
      }

      if (success) {
        setLoading(false);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayout className="profile-page">
      <PageHeader variant="primary" title={t('Profile')} />

      {isLoading && <PageLoader />}

      {!isLoading && (
        <Grid container>
          <Grid
            item
            className="profile-page__left-cell pt-4 pb-4"
            xs={12}
            md={12}
            lg={6}
            xl={5}
          >
            <Typography className="mb-1" variant="h3">
              {t('Account information')}
            </Typography>

            <Typography className="mb-3" style={{ color: 'red' }} variant="h6">
              {t('* - required')}
            </Typography>

            <PhoneForm />
            <hr
              style={{
                background: 'var(--primary-color)',
                height: 2,
                marginTop: '1.3rem',
              }}
            />

            <UserForm
              data={profile}
              onSubmit={handleSubmit}
              fetchData={fetchData}
            />
          </Grid>

          <Grid
            item
            className="profile-page__right-cell pt-4 pb-4"
            xs={12}
            md={12}
            lg={6}
            xl={7}
          >
            <PasswordSection />
            <NotificationBlock t={t} />
            <VerificationSelect />
            <SessionList />
            <Grid container justifyContent="space-between">
              <Button
                className="mt-5 ms-2"
                leftAddons={<LogoutIcon size="small" />}
                bold
                onClick={handleLogout}
              >
                {t('Log out')}
              </Button>
              <Button
                className="mt-5 ms-2"
                leftAddons={<ReleaseIcon size="small" />}
                bold
                onClick={onHideModalRealesNotes}
              >
                <span className="ml-1">{t('Release notes')}</span>
              </Button>
              <Modal
                show={modalReleaseNotes}
                onHide={onHideModalRealesNotes}
                headerText={t('Release notes')}
                children={contentModalReleaseNotes}
                backgroundBody="#f0f1f7"
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </DashboardLayout>
  );
};

export { ProfilePage };
