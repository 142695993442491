import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogTitle, Grid } from '@mui/material';

import { useProfile, useShowMessage, useCountriesApi } from 'src/hooks';
import { useDispatch } from 'react-redux';
import {
  Button,
  Dialog,
  DialogContent,
  Form,
  IconButton,
  Typography,
} from '../../../../blocks';
import {
  CloseIcon,
  DoneIcon,
  WarningIcon,
  PencilIcon,
} from '../../../../icons';
import { PhoneInputComponent } from '../../../../inputs';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
  isValidPhoneNumber,
  getCountryCallingCode,
} from 'react-phone-number-input';
import { PhoneCodeForm } from '../PhoneCodeForm';
import { authActions } from '../../../../../store';
import { Spinner } from 'react-bootstrap';
import { getTempCodeLimit } from '@services/api/user';
export const PhoneSchema = () =>
  Yup.object().shape({
    phone: Yup.string().test('Validate Phone', 'Incorrect phone', value =>
      value ? isValidPhoneNumber(value) : false,
    ),
  });

const PhoneForm = () => {
  const { t } = useTranslation();
  const profile = useProfile();
  const showMessage = useShowMessage();
  const dispatch = useDispatch();
  const countries = useCountriesApi();

  const { phone, kyc_verified: kycVerified = false } = profile || {};

  const format = useCallback(value => {
    if (value[0] !== '+') {
      return `+${value}`;
    }
  }, []);

  const [isShowModal, setIsShowModal] = useState(false);

  const [isEdit, setIsEdit] = useState(!kycVerified);

  const [attempts, setAttemtps] = useState(null);

  const defaultValue = useMemo(() => {
    if (!phone && countries.length) {
      return `+${getCountryCallingCode(
        countries.find(c => c.name === profile?.country)?.iso_2,
      )}`;
    }

    if (phone) {
      return phone[0] !== '+' ? `+${phone}` : phone;
    }
  }, [profile.phone, countries, profile?.country]);

  const {
    setFieldValue,
    values,
    isValid,
    errors,
    touched,
    setFieldTouched,
    isSubmitting,
    handleSubmit,
  } = useFormik({
    initialValues: {
      phone: defaultValue,
    },
    validationSchema: PhoneSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const res = await dispatch(authActions.registerPhone(values.phone));

      if (res.status === 'OK') {
        setIsShowModal(true);
      }

      showMessage(res);
    },
  });

  useEffect(() => {
    if (countries.length) {
      setFieldValue('phone', defaultValue);
    }
  }, [countries.length, profile?.country]);

  const handleChange = useCallback(
    value => {
      setFieldValue('phone', value);
    },
    [setFieldValue],
  );

  const handleClose = useCallback(() => {
    setFieldValue('phone', format(phone));
    setIsEdit(false);
  }, [setFieldValue, profile.phone, format]);

  const handlePhoneConfirmSubmit = useCallback(
    async ({ code }) => {
      const res = await dispatch(authActions.confirmPhone(values.phone, code));

      showMessage(res);

      if (res.status === 'OK') {
        await dispatch(authActions.fetchMe());
        setIsShowModal(false);
        setIsEdit(false);
      }
    },
    [dispatch, showMessage, values.phone, setIsShowModal],
  );

  const getAttempts = useCallback(async () => {
    return await getTempCodeLimit().then(res => setAttemtps(res?.data?.limit));
  }, []);

  const resendTheCode = useCallback(async () => {
    const res = await dispatch(authActions.registerPhone(values.phone));

    await getAttempts();

    if (res.status === 'OK') {
      showMessage({
        message: 'Code sent successfully!',
        status: res.status,
      });
    } else {
      showMessage({
        message: 'Error',
        status: res.status,
      });
    }
  }, [dispatch, showMessage, values.phone]);

  useEffect(() => {
    if (!attempts) {
      getAttempts();
    }
  }, []);
  return (
    <React.Fragment>
      {!kycVerified && (
        <Typography className="mb-1" style={{ color: 'red' }} variant="h6">
          <WarningIcon size="smallmed" className="mr-2 mb-1 ml-1" />
          {t('Phone number verification required!')}
        </Typography>
      )}
      <Grid item>
        <PhoneInputComponent
          value={values.phone}
          onChange={handleChange}
          isValid={isValid}
          onBlur={() => setFieldTouched('phone')}
          touched={touched.phone}
          error={errors.phone}
          disabled={kycVerified && !isEdit}
          showDone={!kycVerified}
          rightAddons={
            kycVerified && (
              <React.Fragment>
                {!isEdit ? (
                  <React.Fragment>
                    {/*<IconButton*/}
                    {/*  size="small"*/}
                    {/*  color="secondary"*/}
                    {/*  icon={PencilIcon}*/}
                    {/*  onClick={() => setIsEdit(true)}*/}
                    {/*/>*/}
                    <IconButton
                      type="button"
                      size="mini"
                      color="white"
                      circle="green"
                      icon={DoneIcon}
                    />
                  </React.Fragment>
                ) : (
                  <IconButton
                    size="small"
                    icon={CloseIcon}
                    onClick={handleClose}
                  />
                )}
              </React.Fragment>
            )
          }
        />
      </Grid>
      {isEdit && isValid && (
        <Grid item mt={3}>
          <Button
            variant="primary"
            disabled={
              kycVerified
                ? phone[0] === '+'
                  ? phone === values.phone
                  : `+${phone}` === values.phone
                : false
            }
            onClick={handleSubmit}
            fullWidth
          >
            {isSubmitting ? (
              <Spinner animation="border" variant="light" size="sm" />
            ) : (
              t('Continue')
            )}
          </Button>
        </Grid>
      )}

      <Dialog open={isShowModal}>
        <DialogTitle>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item />

            <Grid item>
              <Typography
                className="typography-two-factor"
                variant="h2"
                align="center"
              >
                {t('Two-Factor Authentication')}
              </Typography>
            </Grid>

            <Grid item className="profile-phone-close">
              <IconButton
                size="large"
                icon={CloseIcon}
                onClick={() => setIsShowModal(false)}
              />
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <Form mode="onChange" onSubmit={handlePhoneConfirmSubmit}>
            <PhoneCodeForm onResendCode={resendTheCode} attempts={attempts} />
          </Form>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export { PhoneForm };
