import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Grid, Avatar } from '@mui/material';
import { Popper } from 'views/blocks/Popper';
import { useHistory } from 'react-router-dom';
import { ROUTES, PROFILE_MENU } from 'src/routing';
import { uiActions } from 'src/store';
import { useThemeProps, useProfile } from 'src/hooks';
import Reminder from '@components/Reminder';

import { IconButton, Typography } from 'src/views/blocks';
import { BurgerIcon, CloseIcon, LogoIcon } from 'src/views/icons';

import './index.scss';

const DashboardHeader = props => {
  const { theme } = useThemeProps(props);

  const dispatch = useDispatch();
  const profile = useProfile();
  const history = useHistory();

  const mockData = ['Your funds were successfully withdrawal at 23.04.2022'];

  const { isAuthenticated } = useSelector(state => state.auth);

  const { isSidebarExpanded: isExpanded } = useSelector(state => state.ui);

  const handleSidebarToggle = useCallback(() => {
    dispatch(uiActions.setSidebarExpanded(!isExpanded));
  }, [isExpanded, dispatch]);

  const pathProfile = PROFILE_MENU.length
    ? PROFILE_MENU.filter(item => item.label === 'Account')[0].path
    : '/profile';

  return (
    <Grid
      container
      className={`dashboard-header dashboard-header--${theme}`}
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item>
        <IconButton
          size="smallmed"
          icon={isExpanded ? CloseIcon : BurgerIcon}
          onClick={handleSidebarToggle}
        />
      </Grid>
      <Grid item>
        <Grid component={Link} container alignItems="center" to={ROUTES.home}>
          <Grid item>
            <LogoIcon size="medium" />
          </Grid>
        </Grid>
      </Grid>
      {isAuthenticated && profile && (
        <Grid item display="flex" alignItems="center">
          <Grid
            item
            className="cursor-pointer"
            onClick={() => history.push(pathProfile)}
          >
            <Popper
              component={Avatar}
              className="page-header__profile-icon"
              placement="bottom-end"
              // content={
              //     <ProfileMenu>
              //         {PROFILE_MENU.map((menuItem, index) => {
              //             return <ProfileMenuItem key={index} {...menuItem} />;
              //         })}
              //     </ProfileMenu>
              // }
              src={profile?.avatar || ''}
            >
              {(profile.name || profile.email || '').slice(0, 1)}
            </Popper>
          </Grid>
          <Grid item className="block-item">
            <Reminder title="" alerts={mockData} />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export { DashboardHeader };
