import React from 'react';

import { Icon } from 'src/views/blocks';

import './index.scss';

const AuthArrowBackIcon = props => {
  const { className = '', ...rest } = props;

  return (
    <Icon {...rest} className={`auth-arrow-back-icon ${className}`} mask />
  );
};

export { AuthArrowBackIcon };
