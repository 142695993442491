import {initialState} from "./state";
import {
    SET_THEME,
    SET_SIDEBAR_EXPANDED
} from "./action-types";


const reducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_THEME: {
            const {
                theme
            } = action.payload;

            return {
                ...state,
                theme
            };
        }

        case SET_SIDEBAR_EXPANDED: {
            const {
                isSidebarExpanded
            } = action.payload;

            return {
                ...state,
                isSidebarExpanded: !!isSidebarExpanded
            };
        }

        default:
            return state;
    }
};


export {reducer};