import React from "react";
import clsx from "clsx";

import {Typography} from "src/views/blocks";

import "./index.scss";


const RadioButton = (props) => {
    const {
        children,
        checked = false,
        color,
        // name,
        // value,
        ...rest
    } = props;

    const modifications = clsx({
        "radio-button--checked": checked
    });

    return (
        <label className={`radio-button ${modifications}`}>
            <span className="radio-button__circle" />

            <input
              {...rest}
              className="radio-button__input"
              type="radio" />

            <Typography
              className="radio-button__label"
              component="span"
              variant="body1"
              color={color}>
                {children}
            </Typography>
        </label>
    );
};


export {RadioButton};