import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { Grid } from '@mui/material';
import { countriesActions } from '@redux/actions/registration';
import { ROUTES } from 'src/routing';
import { useProfile, useShowMessage } from 'src/hooks';
import { authActions } from 'src/store';
import { AuthLayout } from 'src/views/layouts';
import { Typography } from 'src/views/blocks';
import { TermsModal } from 'views/blocks/TermsModal';
import { LoginForm } from './blocks';
import './index.scss';
import {
  getUserVerificationMethodAction,
  getVerificationsMethodAction,
  setVerificationMethodAction,
} from '../../../store/modules/auth/actions';
import { AgreementModal } from '../../blocks/AgreementModal';

const LoginPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const showMessages = useShowMessage();
  const profile = useProfile();

  const [showModalDocuments, setShowModal] = useState(false);
  const [showPublic, setShowPublic] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  const onToggleModalDocuments = useCallback(() => {
    setShowModal(!showModalDocuments);
  }, [showModalDocuments]);

  const handleSubmit = useCallback(
    async data => {
      const { email } = data;
      setLoading(true);
      const rE = await dispatch(authActions.fetchEmailStatus(email));
      if (rE.status === 'OK') {
        showMessages(rE);
        if (rE.activated) {
          const res = await dispatch(authActions.login(data));
          showMessages(res);
          if (res.status === 'OK') {
            try {
              const method = await dispatch(getUserVerificationMethodAction());
              if (!method) {
                const methods = await dispatch(getVerificationsMethodAction());
                dispatch(
                  setVerificationMethodAction({
                    views_verification_method_id: methods.find(
                      v => v.title === 'Face Unlock',
                    )?.id,
                  }),
                );
              }
              setLoading(true);
            } catch (e) {
              const methods = await dispatch(getVerificationsMethodAction());
              dispatch(
                setVerificationMethodAction({
                  views_verification_method_id: methods.find(
                    v => v.title === 'Face Unlock',
                  )?.id,
                }),
              );
            }
          }
          if (res.status === 'ERROR') {
            setSubmitError(res.message);
          }
        } else {
          dispatch({
            type: countriesActions.SET_REG_DATA,
            data: { email },
          });
          dispatch({
            type: countriesActions.SET_REG_STEP,
            data: 2,
          });

          await dispatch(authActions.fetchResendCodeRegister(email));
          showMessages({
            message:
              'Your account is not activated, activation is required for authorization!',
            status: 'ERROR',
          });
          history.push(ROUTES.registration);
        }
      } else {
        showMessages({
          message: 'Incorrect email or password',
          status: 'ERROR',
        });
        dispatch({
          type: countriesActions.SET_REG_DATA,
          data: data,
        });
        // history.push(ROUTES.registration);
      }
      setLoading(false);
    },
    [dispatch, showMessages, history],
  );

  return (
    <AuthLayout className="auth">
      {profile && <Redirect to={ROUTES.home} />}

      <Grid container paddingBottom="60px">
        <Grid item fontWeight="700">
          <Typography className="auth-form-title" color="black" variant="h2">
            {t('Welcome to INFOCOIN!')}
          </Typography>
        </Grid>
      </Grid>

      {loading && (
        <div className="spinner-container-login">
          <Spinner animation="border" variant="warning" />
        </div>
      )}

      {!loading && (
        <LoginForm
          setShowModal={setShowModal}
          setShowPublic={setShowPublic}
          error={submitError}
          onSubmit={handleSubmit}
        />
      )}
      <TermsModal
        open={showModalDocuments}
        lightTheme={true}
        onAccept={onToggleModalDocuments}
        onCancel={onToggleModalDocuments}
      />
      <AgreementModal
        open={showPublic}
        lightTheme={true}
        onAccept={() => setShowPublic(false)}
        onCancel={() => setShowPublic(false)}
      />
    </AuthLayout>
  );
};

export { LoginPage };
