import { useState, useEffect } from "react";

import { axiosInstance } from "src/store/helpers";


export const useCitiesApi = (id) => {
    const [cities, setCities] = useState([]);

    useEffect(() => {
        (async () => {
            if (!id) {
                setCities([]);

                return;
            }

            try {
                const res = await axiosInstance.get(`/auth/cities/${id}`, {
                    validateStatus: () => true
                });

                if (res.status === 200) {
                    const { data } = res;

                    setCities(data);
                }
                else {
                    setCities([]);
                }
            }
            catch (err) {
                console.error(err);

                setCities([]);
            }
        })();
    }, [id]);

    return cities;
};