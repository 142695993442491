import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Grid, Divider, Avatar } from '@mui/material';
import { Link } from 'react-router-dom';
import { Settings } from '@mui/icons-material';
import { Button } from 'src/views/blocks';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'routing';
import { PROFILE_MENU } from 'src/routing';
import { useThemeProps, useProfile } from 'src/hooks';

import Reminder from '@components/Reminder';

import { Typography } from '../Typography';
import { Popper } from '../Popper';

import './index.scss';

const mockData = ['Your funds were successfully withdrawal at 23.04.2022'];

const PageHeader = props => {
  const {
    variant,
    className = '',
    theme,
    title = '',
    titleProps: { className: titleClass = '', ...titleProps } = {},
    subtitle = '',
    subtitleProps: { className: subtitleClass = '', ...subtitleProps } = {},
    leftAddons,
  } = useThemeProps(props);

  const { t } = useTranslation();
  const profile = useProfile();

  const history = useHistory();
  const { isAuthenticated } = useSelector(state => state.auth);

  const modifications = clsx({
    [`page-header--variant-${variant}`]: !!variant,
  });

  const pathProfile = PROFILE_MENU.length
    ? PROFILE_MENU.filter(item => item.label === 'Account')[0].path
    : '/profile';

  return (
    <Grid
      className={`page-header ${className} ${modifications}`}
      container
      direction="column"
    >
      <Grid
        item
        className="container_headnav"
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={6} className="container_headnav-grid">
          <Grid container alignItems="center">
            {leftAddons && <Grid item>{leftAddons}</Grid>}

            <Grid item>
              <Typography
                component="div"
                variant="h1"
                bold
                {...titleProps}
                className={`page-header__title ${titleClass}`}
              >
                {title}
              </Typography>

              {subtitle && (
                <Typography
                  component="div"
                  variant="caption1"
                  {...subtitleProps}
                  className={`page-header__subtitle ${subtitleClass}`}
                >
                  {subtitle}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item alignSelf="center" className="container-infouser">
          {isAuthenticated ? (
            <Grid
              container
              justifyContent="flex-end"
              alignItems="center"
              className="container_headnavigate"
            >
              <Grid>
                {profile && !profile.isFilled && (
                  <>
                    <Grid item>
                      <div
                        className="container_wartext"
                        style={{ marginRight: '20px' }}
                      >
                        <Typography
                          variant="caption2"
                          align="right"
                          color="primary"
                        >
                          {t(
                            'Accrual of funds and their withdrawal are available only after filling in the contact information and confirming the phone number.',
                          )}
                        </Typography>
                      </div>
                    </Grid>
                  </>
                )}
              </Grid>
              {profile && (
                <Grid
                  container
                  justifyContent="flex-end"
                  alignItems="center"
                  width="fit-content"
                  wrap="nowrap"
                >
                  {profile && !profile.isFilled && (
                    <Grid item>
                      <Button
                        className="mr-3"
                        style={{
                          width: '162px',
                        }}
                        onClick={() => history.push(pathProfile)}
                        variant="primary"
                      >
                        {t('Go to Profile')}
                      </Button>
                    </Grid>
                  )}
                  {profile && (
                    <Grid item className="profile-container">
                      <Popper
                        component={Avatar}
                        placement="bottom-end"
                        // content={
                        //   <ProfileMenu>
                        //     {PROFILE_MENU.map((menuItem, index) => {
                        //       return <ProfileMenuItem key={index} {...menuItem} />;
                        //     })}
                        //   </ProfileMenu>
                        // }
                        src={profile.avatar || ''}
                      >
                        {(profile.name || profile.email || '').slice(0, 1)}
                      </Popper>
                    </Grid>
                  )}

                  {profile && profile.isFilled && (
                    <Grid item>
                      <div className="userInfo">
                        <h6 className="userName">{profile.name}</h6>
                        <span className="userPhone">
                          {profile.phone[0] !== '+'
                            ? '+' + profile.phone
                            : profile.phone}
                        </span>
                      </div>
                    </Grid>
                  )}

                  <Grid
                    item
                    className={!profile.isFilled ? 'block-item' : ''}
                    onClick={() => history.push(pathProfile)}
                  >
                    <div className="setting-container">
                      <Settings />
                    </div>
                  </Grid>

                  <Grid item className="block-item">
                    <Reminder title="" alerts={mockData} />
                  </Grid>
                </Grid>
              )}
            </Grid>
          ) : (
            <Grid
              container
              justifyContent="flex-end"
              alignItems="center"
              className="container_headnavigate"
              fontSize="22px"
            >
              <Grid>
                <Button component="span" variant="primary" size="medium">
                  <Link to={ROUTES.login}>{t('Sign In')}</Link>
                </Button>
              </Grid>
              {/* <Grid marginLeft="20px">
                <Link to={ROUTES.registration}>{t('Sign Up')}</Link>
              </Grid> */}
            </Grid>
          )}
        </Grid>
      </Grid>

      {variant === 'primary' && (
        <Grid item container>
          <Grid item flex={1}>
            <Divider
              style={{
                height: '2px',
                background: theme === 'dark' ? '#FF5F15' : '#FFFFFF',
              }}
              variant="fullWidth"
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export { PageHeader };
