import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from '@mui/material';

import { isoFormat } from 'src/utils';
import { getSessions } from '@services/api/user';
import { Typography } from 'src/views/blocks';

import './index.scss';

const SessionList = () => {
  const { t } = useTranslation();

  const [sessions, setSessions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await getSessions();

      if (res.status === 200) {
        setSessions(res.data);
      }
    };

    fetchData().catch(err => {
      console.error(err);
    });
  }, []);

  return (
    <div className="sessions-list mt-2">
      <Typography className="session-list__title" variant="h3">
        {t('Active sessions')}
      </Typography>
      <TableContainer className="session-list__container">
        <Table className="session-list__table">
          {sessions.map((session, index) => {
            const { client, ip, updated_at } = session;

            const showLength = 12;

            return (
              <TableRow key={index} container spacing={4}>
                <TableCell>
                  {client.length > showLength && (
                    <Tooltip title={client}>
                      <Typography>{client.slice(0, showLength)}...</Typography>
                    </Tooltip>
                  )}

                  {client.length <= showLength && (
                    <Typography>{client}</Typography>
                  )}
                </TableCell>

                <TableCell>
                  <Typography>{ip}</Typography>
                </TableCell>

                <TableCell>
                  <Typography>{isoFormat(updated_at, 'dd.MM.yyyy')}</Typography>
                </TableCell>
              </TableRow>
            );
          })}
        </Table>
      </TableContainer>
    </div>
  );
};

export { SessionList };
