import React, { Fragment, memo } from 'react';

import { withTranslation } from 'react-i18next';

import './HistoryItem.module.scss';

import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  IconButton,
} from '@mui/material';

import s from './HistoryItem.module.scss';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { Typography } from '../../views/blocks';

import moment from 'moment/min/moment-with-locales';

const HistoryItem = ({ item, setInfo, t }) => {
  return (
    <Grid container className={s.container}>
      <Grid item mb={2} className={s.day}>
        <Typography variant="h4" weight="semi-bold">
          {item[0].current_date === moment().format('DD MMMM YYYY')
            ? t('Today')
            : item[0].current_date}
        </Typography>
      </Grid>

      <Grid item width="100%">
        {item.map(({ video }) => {
          return (
            <Fragment>
              <Card className={s.card}>
                <CardActionArea
                  className={s.card__area}
                  onClick={() => setInfo(video)}
                >
                  <CardMedia
                    className={s.card__video}
                    component="video"
                    image={video?.filepath_480_p || video?.filepath_720_p}
                  />
                  <CardContent className={s.card__content}>
                    <Grid item width="100%">
                      <Grid item className={s.card__title}>
                        <Typography
                          variant="h5"
                          weight="normal-bold"
                          color="default"
                        >
                          {video?.title}
                        </Typography>
                      </Grid>
                      {video?.description && (
                        <Grid item mt={1} className={s.card__description}>
                          <Typography
                            variant="body2"
                            weight="normal-bold"
                            color="gray"
                          >
                            {video?.description}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                    <Grid item width="100%" className={s.card__company}>
                      <Typography
                        weight="normal-bold"
                        color="gray"
                        variant="body2"
                      >
                        {video?.company?.title}
                      </Typography>
                    </Grid>
                    <Grid item className={s.card__action}>
                      <IconButton>
                        <ArrowForwardIcon htmlColor="var(--color)" />
                      </IconButton>
                    </Grid>
                  </CardContent>
                </CardActionArea>
              </Card>
              <Divider className={s.divider} />
            </Fragment>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default withTranslation()(memo(HistoryItem));
