import { initialState } from './state';
import {
  SET_TOKEN,
  SET_SESSION,
  SET_USER_DATA,
  SET_ATTACHMENTS,
  SET_USER_VERIFICATION_METHOD,
  SET_VERIFICATION_METHODS,
  AUTH_LOGOUT,
} from './action-types';

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOKEN: {
      const { token } = action.payload;

      return {
        ...state,
        token,
        isAuthenticated: !!token,
        loaded: true,
      };
    }

    case SET_SESSION: {
      const { sessionId } = action.payload;

      return {
        ...state,
        session_id: sessionId,
      };
    }

    case SET_USER_DATA: {
      const { user } = action.payload;

      return {
        ...state,
        userData: user ? user : undefined,
        isProfileLoaded: !!user,
      };
    }

    case SET_ATTACHMENTS: {
      const { attachments = [] } = action.payload;

      return {
        ...state,
        attachments,
      };
    }

    case SET_VERIFICATION_METHODS: {
      return {
        ...state,
        verificationMethods: action.payload,
      };
    }
    case SET_USER_VERIFICATION_METHOD: {
      return {
        ...state,
        userVerificationMethod: action.payload,
      };
    }
    case AUTH_LOGOUT: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

export { reducer };
