import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';

import { Typography, LogoLarge } from 'src/views/blocks';

const NoItems = () => {
  const { t } = useTranslation();

  return (
    <Grid
      style={{ height: '100%' }}
      container
      direction="column"
      justifyContent="center"
    >
      <Grid item alignSelf="center">
        <LogoLarge className="mb-3" />
      </Grid>

      <Grid item>
        <Typography variant="h3" bold align="center">
          {t('While there is no video')}
        </Typography>
      </Grid>
    </Grid>
  );
};

export { NoItems };
