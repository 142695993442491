import {useMemo} from "react";
import {useLocation} from "react-router-dom";
import lodashSet from "lodash/set";


const useQuery = () => {
    const {search} = useLocation();

    return useMemo(() => {
        const [, queryString = ""] = /^\?(.*)$/.exec(search) || [];
        const params = new URLSearchParams(queryString);

        const res = {};

        params.forEach((value, key) => {
            lodashSet(res, key, value);
        });

        return res;
    }, [search]);
};


export {useQuery};