import {NODE_ENV} from "src/config";


const validatePassword = (value) => {
    if(typeof value === "string") {
        let hasError = false;

        if(value.length < 8) {
            hasError = true;
        }

        if(NODE_ENV !== "development") {
            hasError = [
                /[A-Z]/,
                /[a-z]/,
                /\d/,
                /[$&+,:;=?@#|'<>.^*()%!{}-]/
            ].reduce((hasError, reg) => {
                return hasError || !reg.test(value);
            }, hasError);
        }

        if(hasError) {
            return "Invalid password";
        }
    }

    return undefined;
};


export {validatePassword};