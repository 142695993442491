export const EN = `
<!DOCTYPE html>
<html>
<head>
\t<meta http-equiv="content-type" content="text/html; charset=utf-8"/>
\t<title></title>
\t<meta name="generator" content="LibreOffice 7.4.2.3 (Linux)"/>
\t<meta name="author" content="user"/>
\t<meta name="created" content="2022-05-11T11:37:00"/>
\t<meta name="changed" content="00:00:00"/>
\t<meta name="GrammarlyDocumentId" content="35b77ade2070f91f094eb5c56980120db369e0d3be0965099f9e45ea4677c1f2"/>
\t<style type="text/css">
\t\t@page { size: 8.27in 11.69in; margin-left: 0.98in; margin-right: 0.59in; margin-top: 0.59in; margin-bottom: 0.59in }
\t\t@page p { line-height: 115%; text-align: left; orphans: 2; widows: 2; margin-bottom: 0.1in; direction: ltr; background: transparent }
\t\t@page a:link { color: #0563c1; text-decoration: underline }
\t</style>
</head>
<body lang="en-US" link="#0563c1" vlink="#800000" dir="ltr"><p align="center" style="line-height: 108%; margin-bottom: 0.11in">
<br/>
<br/>

</p>
<p align="center" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>TERMS
OF USE</b></font></font></p>
<p align="center" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">(version
from 05</font></font><sup><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">th</font></font></sup><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
of January 2023)</font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">PLEASE
READ THE TERMS AND CONDITIONS CAREFULLY</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Welcome
to Infocoin.online! This Terms of Use defines the terms and
conditions for accessing and using websites, mobile versions of
websites, mobile applications, and other Internet portals owned,
operated, operating under the INFOCOIN trademark, or accessed by
Infocoin Limited (as defined below) from time to time, that belongs
to (a) Infocoin Limited electronic resources, including, but not
limited to: the Internet version and the version optimized for mobile
devices, sites identified by the Uniform Resource Locator
&quot;Infocoin.online&quot;, mobile and other applications of the
Infocoin Limited project (hereinafter – the Infoсoin Platform, the
Platform) This document is a legally binding agreement between you as
a user (users) of the Platform (hereinafter - You, Your, or User) and
the Infocoin Limited. legal entity, entering into an agreement and
defined in accordance with paragraph 2.1 below (hereinafter – we,
our, or Infocoin Limited). </font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>1.
Application and acceptance of the terms</b></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1.1
The Terms contained herein govern your access to and use of the
Platform and services, software, and products (using the Platform
that include the platforms specified in paragraph 7.1 of the Terms)
of Infocoin Limited (such services, software, and products are
hereinafter collectively referred to as the Services), along with the
Privacy Policy (as defined in paragraph 3.3 below) and the
Advertising Product Policy, as well as other rules and Policies that
Infocoin Limited and/or its affiliates may post from time to time.
This document and other similar rules and Policies are hereinafter
collectively referred to as the &quot;Terms.&quot; You agree to
accept and be bound by the Terms by using or accessing the Site and
the Services. Please do not use or access the Services and the Site
if you do not accept all of the Terms.</font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1.2
You may not use or access the Services or the Site, and may not agree
to the Terms if (a) You are not of age to enter into an agreement
with Infocoin Limited or (b) you are prohibited from using any of the
Services under the laws of any country/region, including the
country/region of your residence or from which you use or access the
Services and the Site. </font></font>
</p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1.3
Infocoin Limited may change any of the Terms anytime by posting the
relevant amended and supplemented Terms on the Site. By using or
accessing the Services or the Site, you signify your acceptance of
the amended and updated Terms.</font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1.4
If Infocoin Limited has published or provided a translation English
version of the version of the Terms, you agree that the translation
is provided for convenience only and that English version the edition
governs your use of and access to the Services or the Site.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1.5
In some cases, you must enter into a separate agreement (or
agreements), either online or offline, with Infocoin Limited or
another affiliate of Infocoin Limited, for the provision of any
Services (or part of the Services) (hereinafter - the Additional
Agreement&quot;). If the provisions of the Terms and Additional
Agreements either do not correspond or contradict each other,
priority is given to the Additional Agreements only in part
regulating the provision of the relevant Services (or part of the
Services) provided under such Additional Agreements. </font></font>
</p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1.6
The Terms may not be modified or supplemented in any way different
from writing by an authorized officer of Infocoin Limited, followed
by posting on the Site.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1.7
When using or accessing the Infocoin Platform and the Services, you
consent to the processing of personal data in accordance with the
terms of the EU Regulation 2016/679 - General Data Protection
Regulation (hereinafter - GDPR). The Privacy Policy
(</font></font><a href="https://www.infocoin.online/docs"><font color="#0563c1"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>https://www.infocoin.online/docs</u></font></font></font></a><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">)
directly lists the information we collect, the reasons we collect it,
how we use it, and the ways in which we may disclose it.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1.8
At the time of registration, in order to comply with the User
Agreement and Privacy Policy, you must be at least 16 years old.</font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>2.
Provision of Services and Legal Information</b></font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">2.1.
You are entering into an agreement with Infocoin Limited, company
incorporated in the Republic of Cyprus (registration number: HE
433674). Since some or part of the Services may be supported and
provided by affiliates of Infocoin Limited, Infocoin Limited may
outsource certain Services to its affiliates.</font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">2.2.
You must register as a User of the Platform to access and use some
Services. In the future, Infocoin Limited reserves the right, without
prior notice, to restrict the access or use of certain Services,
parts of the Service, or any function of the Site for the User or
make subject to other conditions that Infocoin Limited can enter at
will.</font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">2.3
The Services (or any part of the Service provided) may vary by region
and country. Infocoin Limited does not warrant or represent that a
particular Service, a feature or function of a Service, or a similar
and enhanced Service, will be available to Users or a particular
User. Infocoin Limited may, in its sole discretion, limit, cancel or
create different levels of access, use, and functionality of any
Services (or any part of the Service provided) to other users.</font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">2.4
In these Terms, &quot;Paid Users&quot; means Users who order services
for claiming goods and/or services and all other persons carrying out
commercial activities on the Site. The minimum price for one second
of content placement is 0.0012423 Euros. Infocoin Limited may create,
modify, improve, impose conditions on, suspend or cancel the
provision of any Service (or any part of the Service) without prior
notice, except in the case of the provision of a paid Service, when
such changes will not materially adversely affect the ability of Paid
Users to use such a Service.</font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">2.5
Some Services (or part of them) may be provided by affiliates of
Infocoin Limited on behalf of Infocoin Limited.</font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">2.6
The Services are provided on an &quot;as is&quot; basis. Infocoin
Limited does not provide any guarantees regarding the error-free and
uninterrupted operation of the Site or Services, the compliance of
the Site or Services with the specific goals and expectations of the
User.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>3.
Terms for all users</b></font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">3.1
As a condition of Your access to and the Platform and Services use,
You agree to comply with all applicable laws and regulations when
using and accessing the Site and Services.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">3.2
You acknowledge that (a) You will not copy, reproduce, upload,
republish, sell, distribute or resell any Services or any
information, text, images, graphics, videos, sounds, directories,
files, databases or listings, etc. available on the Site (Site
Content), and (b) you will not copy, reproduce, download, collect or
otherwise use the Site Content for the purposes of running a business
that competes with that of Infocoin Limited, or in any way to
commercially use the Content of the Site. A systematic search of the
Site Content on the Platform to create or compile, directly or
indirectly, a collection, accumulation, databases, directories
(manually, using robots, spyware, or automatic devices) without the
written permission of Infocoin Limited is forbidden. Use of any
content or data on the Platform for any purpose not expressly
permitted in the Terms is prohibited.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">3.3
You must read the following documents that regulate the protection
and use of User's personal information, which is at the disposal of
Infocoin Limited and our affiliates: </font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">\t</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">a)
to all Users who use or access the Site associated with Infocoin
Limited – Infocoin Limited Privacy Policy; </font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">b)
for all Users who use or access the Site associated with Infocoin
Limited – Infocoin Limited User Agreement; </font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">3.4
Infocoin Limited may allow Users to access content, products, and
services offered by third parties through hyperlinks (in the form of
text links, banners, channels, etc.), APIs, or otherwise leading to
third-party websites. You are cautioned to read the terms of use of
such sites and/or the Privacy Policy before using the Platform. You
acknowledge that Infocoin Limited does not control such third-party
sites, does not review such sites, and is not responsible or liable
to anyone for such sites or any content, products, or services
provided on or using such sites. </font></font>
</p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">3.5
You confirm that you will not carry out any actions that may lead to
a violation of the integrity of computer systems and networks of
Infocoin Limited and/or any Users and will not have unauthorized
access to such computer systems and/or networks.</font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">3.6
By uploading or displaying any information, content or data
(hereinafter collectively - User Content) on the Platform or by
providing any User Content to Infocoin Limited or its
representatives, to the extent permitted by applicable law, you grant
Infocoin Limited display, transmit, distribute, reproduce, publish,
duplicate, adapt, modify, translate, create derivative works from,
and in any other way use any or all User Content in any form, media,
and technology, whether known or not yet known in any way and for any
purpose that may be beneficial to Infocoin Limited, the operation of
the Platform, the provision of any Services and/or the User's
business. You represent and affirm Infocoin Limited that you have all
the rights and authority to grant such permission and User Content
and use of such User Content (including derivative works of
intellectual activity) Infocoin Limited and/or its affiliates based
on such permission is free from any restrictions and does not violate
the Rights of third parties (as this term is defined in paragraph 5.4
of the Terms). To the maximum extent permitted by law, you waive your
right to enforce your intellectual property rights in User Content
for Infocoin Limited and/or its affiliates, successors, or
sub-licensees in connection with the use of such User Content due to
the provision of the Services. Information protected under the laws
on personal data protection will be used and stored only following
these laws requirements.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>3</b></font></font><sup><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>1</b></font></font></sup><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>.
Conditions for Participants viewing User Content</b></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">3</font></font><sup><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1</font></font></sup><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">.1
Participants who will register as Viewers, have the opportunity to
receive reward through the Infocoin Platform, as a result of viewing
the User Content.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">3</font></font><sup><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1</font></font></sup><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">.2
Participants who registers on the Infocoin Platform must review the
selected content in order to reward through and take the necessary
steps to confirm the review and verification of the person. </font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">3</font></font><sup><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1</font></font></sup><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">.3
It is not possible for Viewers to view content from more than one
electronic device at the same time.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">3</font></font><sup><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1</font></font></sup><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">.4
In case of detection in the User content of signs provided by p. 5.5
of this Agreement, the Viewer must apply to the site support service
at </font></font><a href="https://view.infocoin.online/help"><font color="#0563c1"><font face="Times New Roman, serif"><u>https://view.infocoin.online/help</u></font></font></a><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><a name="_heading=h.1fob9te"></a>
<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">3</font></font><sup><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1</font></font></sup><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">.5 Each
Viewer has the right to register only one unique account. Infocoin
Limited prohibits the creation of more than one account by the
Viewer. If one Viewer creates several accounts, Infocoin Limited has
the right to delete such accounts, guided by the terms of clause 6.2.
of this Agreement.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">3</font></font><sup><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1</font></font></sup><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">.6
With regard to other conditions, rights and obligations not specified
in this Section, Viewers are governed by this User Agreement,
applicable international law, applicable laws and regulations in
their respective jurisdictions.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>4.
Member Accounts</b></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.1
A User must be registered on the Platform to access or use certain
Services (a Registered User hereinafter also referred to as a
&quot;Member&quot;). Except for authorized explicitly by Infocoin
Limited, one User can register only one user account. Infocoin
Limited has the right to cancel or delete the User's account if
Infocoin Limited has grounds to believe that the User has
simultaneously registered or controls two or more accounts. In the
future, Infocoin Limited may reject the User's registration request
for any reason.</font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.2
When registering on the Platform, Infocoin Limited  must assign an
account and create a Member identification number and a password (the
registered User must choose the password upon registration; usually
e-mail address used as Member identification number).</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.3
The combination of Member ID and password is unique for each account.
Each Member is solely responsible for maintaining the confidentiality
and security of its Member ID and password for all uses and
activities under your account (whether the Member authorizes such
uses or activities or not). A Member may not share, transfer, or
assign the use of its Member ID or password to any other person, even
within the Member's company (if applicable). The Member commits to
immediately notify Infocoin Limited that they have grounds to believe
that there is unauthorized use of their password or account, or any
other violation of the account's security.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.4
The Member confirms that any use of the Site and Services, as well as
activities that are carried out under your account (including,
without limitation, posting any information about the company and
products, clicking the buttons to confirm consent to any Additional
Agreements or rules, subscribing to or paying for any services,
sending e-mails using an e-mail account or sending SMS, or social
networks), will be regarded as authorized by the Member.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.5
Member acknowledges that transferring his account to others or
allowing multiple users outside of your company (if applicable) to
use his account (collectively, &quot;multiple use&quot;) may cause
irreparable harm to Infocoin Limited or other Users of the Platform.
The Member undertakes to reimburse Infocoin Limited, its affiliates,
management, employees, agents, and representatives for any loss or
damage (including but not limited to lost revenue) incurred due to
multiple uses of his account. The Member also confirms that in the
event of multiple uses of his account, or violation through the fault
of the Member of the safety of his account data, Infocoin Limited
shall not be liable for any loss or damage incurred due to such
breach and shall have the right to suspend or terminate the Member's
account without the Member's consent.</font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.6
One of the Services provided to all Members on the Site is to ensure
that Members' accounts are protected from fraud, loss of account
control against the will of Members, and the commission of illegal
actions using accounts that violate these Terms and Members' rights,
including, but not limited to, seizure account to create inaccurate
traffic on the Platform, making transactions against the will of the
Members, etc. (hereinafter referred to as the &quot;Antifraud
Service&quot;). As part of the Antifraud Service, we are obliged to
periodically monitor the activities performed with the Member's
account to identify potential threats to the security of the Member's
account on the Platform.</font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.7
In case we detect potential threats to the account within the
framework of the Antifraud Service, we undertake to:</font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.7.1
Limit the transactions function of making (purchases) on the Platform
for the Member's account immediately (within 24 hours), as soon as we
become aware of the potential threat. This restriction will not
affect transactions (purchases) made before the restriction was
introduced. To resolve the issue of contesting and canceling such
transactions (purchases), if they were made by intruders, the Member
must contact us;</font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.7.2
Contact the Member and take reasonable steps to ensure that their
account has not been taken over by intruders or malicious software.
As part of the Antifraud Service, our specialists may, based on
specific circumstances, enter into electronic correspondence with the
Member, contact them using the contact details provided by the Member
during registration (and also during subsequent use of the account),
and/or request that the person who controls the account record,
confirmed that they are a Member by providing a scan/photo of any
official identity document;</font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.7.3
Consider the possibility of removing restrictions on transactions
(purchases) for the Member's account based on the results of the
measures outlined in paragraph 4.7.2 of the Terms;</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.8
We are committed to maintaining the confidentiality of all
information received from the Member as a result of the measures
specified in paragraph 4.7.2 of the Terms, the very fact of limiting
the function of making transactions (purchases) for the Member's
account, as well as the methodology for monitoring within the
framework of the Antifraud Service and the results of such
monitoring.</font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.9
We are committed to responding promptly to law enforcement requests
regarding a Member, their content, and their account. </font></font>
</p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.10
The Member undertakes to assist us in carrying out the activities
specified in paragraph 4.7.2 of the Terms, including providing
complete, reliable, and up-to-date information requested from them as
part of the Antifraud Service, under our instructions.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.11
In case of failure to provide the information requested following
paragraph 4.7.2 of the Terms, within 30 days from the date of the
request, the Member's account may be deleted or completely blocked.
In case of providing us with false information in response to our
requests, the account may be deleted or completely blocked at any
time at our discretion. If false information is provided, we will
regard this as a circumstance indicating a possible illegal takeover
of the account, which may be the basis for taking the measures
established by the Terms and contacting the competent law enforcement
agencies.</font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.12
We make every reasonable effort to maintain the cyber security of
accounts. However, we do not guarantee that, as a result of the
provision of the Antifraud Service, third parties will not be able to
illegally use the account, including that they will not be able to
cause harm to the Member. In this regard, the Member must be vigilant
when working with the account, refrain from entering the password on
other people's devices, use the account in public places, save the
password on unprotected resources, and perform other actions that
create a security risk. To avoid doubt, all limitations of liability
outlined in paragraph 8 of these Terms shall apply in full to the
Antifraud Service.</font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>5.
Obligations of Members</b></font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.1
Each Paid User represents, warrants, and represents that (a) they
have full authority to agree to the Terms, authorize and perform
their obligations under this Agreement, (b) it uses and accesses the
Site and Services for business purposes only, and (c) for Paid Users
that are legal entities, the address provided at registration is the
main location of the business. For the purposes of this provision, a
branch or subsidiary office may not be considered a separate entity,
and your main location will be treated as the main office.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.2
The Paid User is required to provide information, company, business,
or product/service data as part of the registering process on the
Platform or using and accessing any Services or account. Each Paid
User represents, warrants, and guarantees that (a) such information
and data, whether provided during the registration process or use of
the Site or Services, is true, accurate, current, and complete, and
(b) you will maintain and promptly update all information and data to
keep it true, accurate, current and complete.</font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.3
By becoming a Member, you agree to add your contact information to
our database, authorize </font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Infocoin
Limited </font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">and
our affiliates, and otherwise use your personal information following
the Privacy Policy.</font></font></font></p>
<p style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.4
Each Member represents, guarantees and acknowledges that (a) they are
solely responsible for obtaining all necessary third party
permissions with respect to any User Content that they submit, upload
or display; (b) any User Content they submit, upload or display does
not infringe the copyrights, patents, trademarks, trade names, trade
secrets or any other personal or proprietary rights of any third
party (&quot;Third Party Rights&quot;); (c) they have the right and
authority to sell, buy, trade, distribute or export or offer to sell,
trade, distribute or export the products or services described in the
User Content and such sale, purchase, trade, distribution or export
or offer does not violate any Third Party Rights; (d) You and Your
affiliates are not subject to any trade restrictions, sanctions or
other legal restrictions imposed by any country, international
organization or jurisdiction, nor have you engaged in the online sale
of counterfeit or pirated products; (e) if the User Content contains
images of individuals other than you, then you acknowledge that you
have obtained the consent of such persons to use their images in
accordance with the terms of this User Agreement. If such persons are
minors under applicable law, you acknowledge that you have obtained
the consent of their legal representatives to use their image under
the terms of this User Agreement, as required by applicable law; (f)
if the User Content contains an image of you, then you acknowledge
that you consent to the use of your image following the terms of this
User Agreement.</font></font></font></p>
<p style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.5
Each Member represents, guarantees, and confirms that the User
Content that they submit, upload, or display must be:</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">(a)
true, accurate, complete, and lawful;</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">(b)
Not be false, misleading, or deceptive;</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">(c)
Does not contain information that is defamatory, calumnious,
threatening or harassing, obscene, inappropriate, offensive,
aggressive, explicitly sexual, or harmful to minors;</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">(d)
Does not contain information that is discriminatory or promotes
discrimination based on race, gender, religion, national origin,
disability, sexual orientation, or age;</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">e)
Does not violate any applicable laws and regulations (including,
without limitation, those governing export control, consumer
protection, copyright protection, unfair competition, excessive price
gouging, or false advertising) or promotes any activities that may
violate any applicable laws and rules;</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">(f)
Does not contain direct or indirect links to any other websites that
contain any content that may violate these Terms.</font></font></font></p>
<p style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<br/>

</p>
<p style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.6
Each Member also represents, warrants, and confirms that they
undertake:</font></font></font></p>
<p style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<br/>

</p>
<p style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">a)
operate on the Site following any applicable laws and regulations;</font></font></font></p>
<p style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">b)
Conduct their business transactions in good faith;</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">c)
Operate following the Terms and any applicable Additional Agreements;</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">d)
Not to use the Site Services to defraud any person or entity
(including, without limitation, selling stolen items, using stolen
credit/debit cards);</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">e)
Not to impersonate another person or organization, not to
misrepresent oneself or one's affiliation with any person or
organization;</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">f)
Not engage in spamming or phishing activities;</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">g)
Prevent any other illegal activity (including, without limitation,
those that would constitute a criminal offense, give rise to civil
liability, etc.) or encourage any illegal activity;</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">h)
Not try to copy, reproduce, exploit or expropriate various corporate
directories, databases, and listings owned by </font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Infocoin
Limited</font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">;</font></font></font></p>
<p style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">i)
Not distribute any computer viruses or other destructive devices or
codes that damage, interfere with, intercept or expropriate any
software or hardware systems, data, or personal information;</font></font></font></p>
<p style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">j)
Not engage in any scheme that may undermine the integrity of the
data, systems, or networks used by </font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Infocoin
Limited</font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
and/or any user of the Platform or gain unauthorized access to such
data, systems, and networks;</font></font></font></p>
<p style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">k)
Not engage in any activities that could force </font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Infocoin
Limited</font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
and its affiliates to bear any responsibility.</font></font></font></p>
<p style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.7
The Member may not use the Site, Services, and account to engage in
activities similar or identical to the business of the e-claim site
</font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Infocoin
Limited</font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">.&nbsp;</font></font></font></p>
<p style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.8
The Member agrees to provide all necessary information, data, and
confirmation, and provide all the required assistance and cooperation
necessary to provide </font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Infocoin
Limited</font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
services for evaluating a violation of the Terms and/or handling a
complaint by any Member and/or the Anti-Fraud Service. If the Member
fails to comply with these requirements, resulting in a delay,
suspension, or cancellation of the Service, </font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Infocoin
Limited</font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
is not obligated to extend the term of the relevant Service, nor be
liable for any loss or damage arising from or related to such delay,
suspension or cancellation. </font></font></font>
</p>
<p style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.9
Member acknowledges and agrees that </font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Infocoin
Limited</font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
reserves the right but is not obligated to actively monitor or
exercise any editorial control over the content of any communications
or data or information (including User Content) created, obtained, or
made available through the Services or the Site. </font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Infocoin
Limited</font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
does not endorse, review, or otherwise certify the scope of any
video, content, or other data or information (including User Content)
created, submitted, posted, or otherwise made by any Member. Each
Member is responsible for the content of their messages and may be
held legally responsible or liable for the content of their comments
or other data or information.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.10
The Paid User is aware of and agrees that the Site and Services (as a
Paid User) may be used by the organization (including sole
proprietors) and their representatives, or an individual, for the
purpose of conducting business.</font></font></font></p>
<p style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.11
Member acknowledges and agrees that each Member's sole responsibility
is to review the applicable laws and regulations in their respective
jurisdiction to ensure that use of and access to the Site and
Services is following them.</font></font></font></p>
<p style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.12
The Member hereby confirms that they had read this User Agreement and
agrees (and/or received the appropriate written consent of his legal
representatives (if applicable) and </font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Infocoin
Limited</font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
has the right to require documentary evidence of this consent) that
</font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Infocoin
Limited</font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
may contact you by mail, e-mail, telephone, send SMS, e-mail
messages, push notifications, as well as using other means of
communication, including, but not limited to, social networks
(including but not limited to LinkedIn, Facebook), instant messengers
(including Viber, Whatsapp, Telegram), for purposes including, but
not limited to, marketing, advertising, opinion research, promotion
of the Platform and Services. The Member hereby also agrees that
</font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Infocoin
Limited</font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">,
for the purposes of sending advertising, information, service, and
other messages to Infocoin Limited, has the right to involve third
parties, including, but not limited to, various companies providing
relevant services in the sphere of advertising and information,
service and other messages, mobile operators, instant messengers,
different social networks, platforms, other companies providing
similar services.</font></font></font></p>
<p style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<br/>

</p>
<p style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.13
Each Member represents, guarantees and agrees that</font></font></font></p>
<p style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">(a)
He and his affiliates comply with applicable laws and regulations
(including, but not limited to, applicable laws and regulations
regarding product safety, intellectual property rights, data privacy,
consumer protection, fair competition, pricing, appropriate
advertising, labor, environmental environment, health and safety,
anti-corruption legislation and legislation on combating money
laundering and financing of terrorism);</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.14
Each Member of the Platform, the internet and mobile-optimized
version, the sites identified by the Uniform Resource Locator
Infocoin.online and the corresponding mobile application of the
</font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Infocoin
Limited</font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">,
also represents, warrants, and agrees with the fact that:</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">(a)
he and his affiliates are not subject to any trade restrictions,
sanctions, or other legal restrictions imposed or proposed by any
country, international organization, or jurisdiction (&quot;Relevant
Entities&quot;);</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">(b)
Neither he nor any of his parent companies or affiliates (if
applicable), directly or indirectly, enter into transactions or
provide any funds, goods, or services to Relevant Entities; And</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">(c)
he agree to comply at all times with all applicable export control
laws and regulations and sanctions relating to goods, services,
software, and technology when using the Services, including sanctions
resolutions, laws and regulations adopted and enforced by the United
Nations Security Council, the People's Republic of China, the United
States of America and any other country.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">If
at any time you fail to comply with any of the above requirements,
you should&nbsp;</font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>immediately&nbsp;</u></font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">stop
using the Services and the Site.&nbsp;</font></font></font></p>
<p style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.15.
If </font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Infocoin
Limited</font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">.
reasonably believes that any of your actions and/or omissions
violates or threatens to violate any applicable laws and regulations,
</font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Infocoin
Limited</font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
may, in its sole discretion, at any time, take such action as it
deems necessary in light of the relevant circumstances, including,
but not limited to, terminating all or some of the Services to you,
as well as certain features of the Site, closing the relevant
postings and closing your account, while reserving all the rights
that it may have about any actions or behavior of its participants
that do not comply with the requirements of the law.</font></font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>6.
Violations of Members</b></font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">6.1
Infocoin Limited</font></font><font><font face="Times New Roman, serif">
</font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
reserves the right, in its sole discretion, to remove, modify, or
reject any User Content (in whole or in part) that you submit,
upload, or display on the Site that we reasonably believe to be
illegal, in violation of the Terms, or that Infocoin Limited</font></font><font><font face="Times New Roman, serif">
</font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
may call upon or affiliates to liability or violates the Rights of
Third Parties or may harm the interests of our Members, third parties
or Infocoin Limited or its affiliates or which is in any way deemed
inappropriate, at the sole discretion of Infocoin Limited.  </font></font>
</p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">6.2
If any Member violates any of the Terms or if Infocoin Limited</font></font><font><font face="Times New Roman, serif">
</font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
has reasonable grounds to believe that any Member is in breach of any
of the Terms, then Infocoin Limited</font></font><font><font face="Times New Roman, serif">
</font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">has
the right to apply such response measures that Infocoin Limited</font></font><font><font face="Times New Roman, serif">
</font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
deems appropriate, including, without limitation: (a) to suspend or
terminate the Member's account and all and any accounts associated
with such an  account as Infocoin Limited</font></font><font><font face="Times New Roman, serif">
</font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">determine
at own discretion. At the same time, Infocoin Limited</font></font><font><font face="Times New Roman, serif">
</font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
will not be liable for loss or damage arising out of or in connection
with such suspension or deletion; (b) to limit, suspend or terminate
your subscription to any Service, access to any Service, or current
or future use of any Service; (c) to remove posted information or
other User Content that the Member has submitted, posted or displays,
or impose limits on the amount of information that the Member may
publish or display, or the amount of other User Content that the
Member may publish or display; (d) to impose other restrictions on
the Member's use of any features or functions of any Service as
Infocoin Limited</font></font><font><font face="Times New Roman, serif">
</font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
deems appropriate at its own discretion; and (e) to use any other
remedy, retaliation or penalty as Infocoin Limited</font></font><font><font face="Times New Roman, serif">
</font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
considers to be appropriate at its sole discretion. </font></font>
</p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">6.3
Without limiting the generality of the provisions of the Terms, a
Member will be deemed to be in breach of the Terms in any of the
following circumstances:</font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">a)
upon a complaint or claim from any third party, if Infocoin Limited
has reasonable grounds to believe that such Member intends to or does
not fulfill its contract with a third party, including, without
limitation, the failure by the Member, who is a supplier of goods or
services through the Platform and Services, of any goods or services
ordered by such third party after receipt purchase price, or, in
supplying the goods, such Member has breached the terms and
conditions specified in the contract entered into with that third
party;</font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">b)
Infocoin Limited has reasonable grounds to suspect that such Member
has used stolen credit cards or other false or misleading information
in any transaction with the counterparty;</font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">c)
Infocoin Limited has reasonable grounds to suspect that any
information provided by the Member is not relevant or complete or is
false, inaccurate, or misleading;</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">d)
Infocoin Limited believes that the Member's actions may lead to
losses or financial liability of the organization Infocoin Limited or
affiliates or any other Users.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">6.4
Infocoin Limited reserves the right to cooperate fully with
government or regulatory  agencies, law enforcement, private
detectives and/or affected third parties to investigate any suspected
criminal or civil wrong. In addition, to the extent permitted by
applicable law, Infocoin Limited may disclose Member's contact
information about a Member's identity and/or information about their
account(s), transactions, or actions taken on or through the Site, at
the request of a government, regulatory or law enforcement agency, or
an aggrieved third party, or as a result of subpoena or other court
cases. Infocoin Limited shall not be liable for damages or
consequences arising from such disclosure, and the Member undertakes
not to take any action and from bringing a claim against Infocoin
Limitedbecause of this disclosure.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">6.5
Infocoin Limited may at any time, in its sole discretion, impose
restrictions, suspend or terminate this Member's use of any of the
Services or the Site, without liability to the Member, if Infocoin
Limited received notice that the Member is in breach of any agreement
or obligation with any Infocoin Limited affiliate, and such violation
results in or there is a reasonable suspicion of involvement in
illegal or fraudulent activities. Infocoin Limited reserves the right
but is not obligated to investigate such violation or seek
confirmation from the Member. </font></font>
</p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">6.6
Each Member guarantees to reimburse Infocoin Limited, affiliates,
directors, employees, agents, and representatives, as well as to
protect from possible losses, damages, claims, and liabilities
(including legal costs in full) that may arise from the submission,
publication or display of any User Content, due to for using or
accessing the Platform or Services, or for violating the Terms or any
Additional Agreements.</font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">6.7
Each Member agrees that Infocoin Limited is not and shall not be
liable to them or anyone for any User Content or other data
transmitted through the Site using the Services, including
fraudulent, misleading, deceptive, inaccurate, defamatory, or illegal
data, and that the risk of damage from such User Content and other
data rests entirely with each Member. Infocoin Limited reserves the
right, at its own expense, to assume the exclusive defense and
control of any matter subject to indemnification by a Member, in
which case Members shall cooperate with Infocoin Limited agreeing to
use all available remedies.</font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>7.
Transactions between Buyers and Sellers</b></font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">7.1
Through the Website, Infocoin Limited provides electronic web
platforms for exchanging information between advertisers and other
Members. Infocoin Limited  additionally provides an electronic web
platform for conducting Members' transactions of placing, agreeing,
completing transactions, managing and fulfilling orders for the
provision of advertising services on the Internet through the Site,
an adapted version for mobile phones and applications, following the
Advertising Services Agreement. However, Infocoin Limited does not
represent sellers or buyers for specific transactions for any
Service. Infocoin Limited does not control and is not responsible for
the quality, safety, legality, or availability of the products or
services advertised on the Site or the ability of sellers to complete
a sale or the ability of buyers to complete a purchase. The Services
include all of the Site's functionality that you may use and the
direction and provision of personalized marketing and promotional
information. </font></font>
</p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">7.2
Users are hereby notified that there may be dangerous clicks on
advertising links and messages posted for the purpose of phishing or
other selfish purposes from Paid Users who have provided false
information about themselves. Infocoin Limited uses several methods
to verify the accuracy of certain information from Paid Users who
provide it to us when registering to pay for Services on the Site.
However, due to the difficulty of identifying Users over the
Internet, Infocoin Limited cannot and does not confirm the alleged
identity of each user (including, without limitation, Paid Users). We
encourage you to use a variety of means and common sense to assess
whom you are dealing with.</font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">7.3
Users using or accessing the Platform or Services bear the risk of
clicking on any links and entering into any sales transactions and
other transactions in connection with the use of the Platform. Buyers
and sellers using or accessing the Site or the Services accept the
entire risk of liability or any damages in connection with clicks and
subsequent activities relating to products or services that are the
subject of transactions outside of Infocoin Limited. Examples of such
risks include, but are not limited to: non-delivery of products and
services, fraudulent schemes, poor product quality, non-compliance
with specifications, defective or dangerous products, illegal
products, delay or failure to deliver or pay, miscalculations of
costs, breach of warranty, breach of contract, transport incidents,
the risks that the production, import, export, distribution, offer,
display, purchase, sale and/or use of products or services may
infringe or may be claimed to infringe the rights of third parties,
and the risk that the User may bear the costs of defense or other any
costs in connection with the violation of the rights of third
parties, or with any claims on the part of any Party entitled to
protection, or compensation in connection with the violation of their
rights, as a result of the presentation of claims or claims and
lawsuits of such third parties persons. Such risks also include
claims from consumers, other buyers, end-users of products, or other
third parties who claim to have been harmed by your use of
third-party Platform or Services by clicking on sponsored links. All
preceding risks are referred to as &quot;Third Party Risks.&quot;
Infocoin Limited shall not be liable for any loss, claim, liability,
expense, injury, inconvenience, business interruption, or cost of any
kind that may arise out of or in connection with any Third Party
risks. </font></font>
</p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">7.4
Users on the Site are solely responsible for determining and
enforcing the terms and conditions of transactions entered into on
Third Party Platform through or as a result of using the Site or the
Services, including, without limitation, terms regarding payment,
returns, warranties, shipping, insurance, fees, taxes, titles,
licenses, fines, permits, processing, transportation and storage,
subject to any additional obligations stipulated by any concluded
agreement. </font></font>
</p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">7.5
The User undertakes to provide all information and data required by
Infocoin Limited, since Your transactions are conducted on, through,
or due to the use of the Site or Services. Infocoin Limited has the
right to suspend or delete the account of any User if the User fails
to provide the necessary information and data. At the same time,
Infocoin Limited will not be liable for loss or damage arising out of
or in connection with such suspension or deletion. </font></font>
</p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">7.6
In the event of a dispute by any User, such Users undertake to
release Infocoin Limited (and our agents, affiliates, directors,
officers, and employees) from all claims, requests, actions,
procedures, costs, expenses, and damages (including, without
limitation, any substantial, special, incidental or consequential
damages) arising out of or in connection with such dispute or
transaction.</font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>8.
Limitation of Liability</b></font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">8.1
TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE SERVICES PROVIDED BY
Infocoin Limited ON OR THROUGH THE SITE IS PROVIDED &quot;AS IS,&quot;
&quot;AS AVAILABLE,&quot; AND &quot;WITH ALL FAULTS&quot; AND
Infocoin Limited DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED,
INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF CONDITION, QUALITY,
DURABILITY, PERFORMANCE, ACCURACY, RELIABILITY, MERCHANTABILITY, AND
APPROPRIATENESS FOR A PARTICULAR PURPOSE. ALL SUCH WARRANTIES,
REPRESENTATIONS, CONDITIONS, LIABILITIES ARE HEREBY EXCLUDED. </font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">8.2
TO THE MAXIMUM EXTENT PERMITTED BY LAW, Infocoin Limited MAKES NO
WARRANTIES OF ANY KIND ABOUT THE RELIABILITY, ACCURACY, CORRECTNESS,
RELIABILITY, QUALITY, STABILITY, COMPLETENESS, OR CURRENTNESS OF ANY
INFORMATION PROVIDED ON OR THROUGH THE SITE; Infocoin Limited DOES
NOT GUARANTEE THAT THE MANUFACTURE, IMPORT, EXPORT, DISTRIBUTION,
OFFER, DISPLAY, PURCHASE, SALES AND/OR USE OF THE PRODUCTS OR
SERVICES OFFERED OR DISPLAYED ON THE SITE DO NOT INFRINGE ANY RIGHTS
OF ANY THIRD PARTIES. Infocoin Limited MAKES NO STATEMENTS OR
WARRANTIES OF ANY KIND CONCERNING ANY PRODUCT OR SERVICE OFFERED OR
PRESENTED ON THE SITE.  </font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"> 
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">8.3
Any data uploaded or otherwise obtained through the Site or Services
is created at the discretion of each User. Each User is solely
responsible for any damage to their computer system or Infocoin
Limited computer system(s) and any loss of data that may result from
the download of any such data. No information or advice, whether oral
or written, received by any User from Infocoin Limited or through or
from the Site do not create any warranties not expressly stated here.</font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">8.4
The Site may promote User's services or products provided by
independent third parties. No warranties or representations are made
for such services or products. Infocoin Limited and affiliates are
not responsible for any of such services or products.</font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">8.5
Each User agrees to indemnify Infocoin Limited, affiliates,
directors, officers, and employees for any and all damages, and also
to indemnify said persons from claims, liability (including legal
costs in full), which may arise from such User's use or access to the
Site or the Services (including, but not limited to providing,
posting or displaying such User's information or User Content on the
Platform) or from your breach of any of the Terms, including
representations and warranties. Each User hereby agrees to reimburse
Infocoin Limited, affiliates, directors, officers, and employees any
and all losses, claims, liability (including legal fees on a fully
indemnified basis) that may result from the User's breach of
representations and warranties made by the User to Infocoin Limited,
including but not limited to those outlined in paragraph 5 of this
Agreement.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">8.6
Each User hereby agrees to compensate Infocoin Limited, affiliates,
directors, officers, and employees any and all losses, claims,
liability (including legal fees in full) that may arise, directly or
indirectly, as a result of any claims asserted by claimants of Third
Party Rights or other third parties related to the services,
products, User Content or other information offered or displayed on
the Site. Each User hereby agrees that Infocoin Limited shall not be
accountable or liable to you for any data posted by others, including
defamatory or illegal data, and that the risk of loss from such data
lies entirely with each User. Infocoin Limited reserves the right, at
its own cost, to assume the exclusive defense and control of any
matter subject to indemnification by you, in which case you shall
cooperate with Infocoin Limited in asserting any remedies available.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">8.7
Infocoin Limited shall not be liable for any direct, indirect,
punitive, incidental, or consequential loss or damage of any kind
(including but not limited to loss of profit or savings, business
interruption, loss of information) incurred as a result of a
contract, negligence, breach of law, in equity or otherwise, or any
other damages arising out of any of the following: </font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">a)
use or inability to use the Platform or Services;</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">b)
Any defect in data, information, or services acquired or received
from the User or any other third party through the Site or the
Services;</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">c)
Violation of the rights of third parties or claims or requirements
for the production, import, export, distribution, offer, display,
purchase, sale and/or use of the User's products or services
advertised or displayed on the Site or through the provision of the
Services that may violate or may be claimed as violating the rights
of third parties; or claims of any party related to the protection of
rights,</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">d)
Unauthorized access of third parties to the data or personal
information of any User;</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">e)
Statements or actions of any User of the Platform or Services;</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">f)
Other actions related to the Platform or Services arising, including
due to negligence.</font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">8.8
Notwithstanding any of the preceding, the aggregate liability of
Infocoin Limited, our employees, agents, affiliates, representatives,
or persons acting on our behalf to each User for all claims arising
from the use of or access to the Site or Services during any calendar
year, shall be limited to the greater of (a) the User's payment to
Infocoin Limited for using or accessing the Site or Services during a
calendar month and (b) the maximum amount permitted by applicable
law. The preceding provision does not preclude the User from
requiring evidence of actual damages. All claims arising in
connection with the use of the Site or the Services must be brought
within six (6) months from the date the cause of action arose, or a
longer period if required under the applicable law governing these
Terms.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">8.9
The limitations and disclaimers of liability to You under these Terms
apply to the maximum extent permitted by law and apply whether or not
the Infocoin Limited User is warned or should have known of the
possibility of such damages.</font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>9.
Force majeure</b></font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">9.1
Under no circumstances Infocoin Limited shall not be liable and
obligated to pay compensation of any nature for any damage resulting
from the unavailability, inconvenience or failure of the services,
systems, the Platform or the delay or failure to provide the Services
for the following reasons: (a) system outage for maintenance; (b) the
impossibility of data transmission as a result of failures in the
operation of communication terminals or telecommunications equipment;
(c) systems failure and inability to perform functions due to force
majeure, including, but not limited to, typhoons, earthquakes,
tsunamis, floods, power outages, fires, storms, wars, political
unrest, strikes, labor shortages, or materials, riots, insurrections,
civil unrest, acts of terrorism, explosions, natural disasters,
governmental actions, acts of national or foreign courts or
tribunals, default by third parties; or (d) suspension or delay of
the Services or system failures due to causes beyond control of
Infocoin Limited, such as hacker or cyber attacks, technical
adjustments or malfunctions of telecommunications equipment, website
updates, failures of third parties, or any suspension or interruption
of logistic or business operations (including, but not limited to,
delays or interruptions in the resumption of work or operations by
order of any governmental authority), in the event of a national or
regional spread of an epidemic or pandemic.</font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>10.
Intellectual Property Rights</b></font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">10.1
Infocoin Limited is the sole owner or legal licensee of all rights
and interests in the Site and the Site's Content. The Site and Site
Content represent trade secrets and other intellectual property
rights protected under international copyright and other laws. All
titles, proprietary and intellectual property rights in and to the
Site and Site content shall remain with Infocoin Limited, affiliates,
or licensors, if any. All rights asserted under the Terms or Infocoin
Limited are hereby reserved. </font></font>
</p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">10.2
&quot;Infocoin&quot;, &quot;INFOCOIN&quot; and related icons and
logos are registered trademarks or service marks of Infocoin Limited 
or its affiliates in different jurisdictions and are protected under
applicable copyright, trademark, and other proprietary laws.
Unauthorized copying, modification, use, or publication of these
marks is strictly prohibited. </font></font>
</p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">10.3
Infocoin Limited may involve an independent third party to provide
the Platform or Services (for example, providers for authentication
and service verification). You may not use any trademarks, service
marks, or logos of such independent third parties without the prior
written consent of such third parties.</font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">10.4
To the fullest extent permitted by applicable law, all right, title,
and interest in all derivative works of intellectual activity created
by Infocoin Limited and/or its affiliates using User Content under
the Terms are owned by Infocoin Limited and may be freely transferred
to Infocoin Limited  (including, under a license agreement) to any
third party or its affiliates.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>11.
Notices</b></font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">11.1
All legal notices or claims to Infocoin Limited must be made in
writing and sent to Infocoin Limited  in person, by courier, or by
registered mail to the Director and address: Anexartisias &amp;
Athinon, NORA COURT, 2nd floor, Office 203, 3040 Limassol, Cyprus.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><a name="_heading=h.gjdgxs"></a>
<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">To
the attention of the Legal Department. All legal notices or requests
to the address of Infocoin Limited must be made in writing and sent
to Infocoin Limited in person, by courier or registered mail to the
address: Anexartisias &amp; Athinon, NORA COURT, 2nd floor, Office
203, 3040 Limassol, Cyprus. The notifications must be valid at the
time of receipt of Infocoin Limited in any of the above ways. </font></font>
</p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">11.2
All legal notices or claims against the User shall be valid if either
delivered personally, by courier, registered mail, facsimile or
e-mail to the last known address, fax or e-mail specified by the User
to Infocoin Limited, or by posting such notices or demands on the
Site, in a publicly available section, without payment. The User's
notice is recognized as received by such User if and when:</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">a)
Infocoin Limited can demonstrate that such notice or demand, whether
in physical or electronic form, has been sent to such User, or</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">b)
immediately after Infocoin Limited places such notice on the Site on
publicly available sections without payment.</font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">11.3
You agree that all agreements, notices, requirements, information,
and other communications that Infocoin Limited sends you
electronically satisfy the law regulations, and such notices shall be
deemed to have been given in writing.</font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>12.
General provisions</b></font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.1
Subject to any Additional Agreements, the Terms constitute the entire
agreement between You and Infocoin Limited and govern Your use of the
Platform and Services, superseding any prior written or oral
agreements regarding the contents of this document.</font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.2
Infocoin Limited and you are independent parties, and none of the
following relationships: agency, partnership, joint venture,
employee-employer, or franchisor-franchisee is implied or created
under the Terms.</font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.3
If any provision of the Terms is held to be invalid or unenforceable,
that provision shall be removed, and the remaining provisions shall
remain in effect and be enforced.</font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.4
Headings are used for reference purposes only and in no way define,
limit, construe or describe the content of the respective clause.</font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.5
Opt-out by Infocoin Limited exercise any right or omission for any
breach by You under the Terms does not constitute a waiver of that
right and does not constitute a waiver of right of Infocoin Limited 
to act accordingly in the event of subsequent or similar violations.</font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.6
Infocoin Limited  has the right to assign the Terms (including all of
our rights, titles, benefits, interests, and obligations in the
Terms) to any person or entity (including any affiliate of Infocoin
Limited.). In general or in part, you may not assign the Terms to any
person or entity.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.7
THESE TERMS AND CONDITIONS (AGREEMENT) ARE GOVERNED BY THE APPLICABLE
LAW, IF YOU CONCLUDE THE AGREEMENT, IN ACCORDANCE WITH PARAGRAPH 2.1.
THE PARTIES TO THIS AGREEMENT ACKNOWLEDGE THAT THE COURTS OF THE
REPUBLIC OF CYPRUS HAVE EXCLUSIVE COMPETENCE RELATING TO THE
SETTLEMENT OF DISPUTES BETWEEN US AS SUBJECTS OF THE AGREEMENT.</font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.8.
If you have any comments regarding the Services we provide to you,
you may contact the company office in writing, either by e-mail
</font></font><a href="mailto:help@infocoin-online.email"><font color="#0563c1"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>help@infocoin.online</u></font></font></font></a><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
or by filling out the special feedback form on the Site with any such
feedback or questions.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><a name="_heading=h.30j0zll"></a>
<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.9
The fact of deleting your account means the termination of these
Terms, concluded between you and us, unilaterally out of court.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
</body>
</html>
`;
