import React from "react";

import {Icon} from "src/views/blocks";
import "./index.scss";


const LogoDarkIcon = (props) => {
    const {
        className = "",
        ...rest
    } = props;

    return (
        <Icon
            {...rest}
            className={`logo-icon-dark ${className}`}
            background />
    );
};


export {LogoDarkIcon};