import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { NODE_ENV } from 'src/config';
import { ROUTES } from 'src/routing';

import { PrivateLayout } from './layouts';
import {
  CategoryPage,
  CompanyPage,
  ForgotPasswordPage,
  HomePage,
  LoginPage,
  PlaylistCategoryPage,
  ProfilePage,
  RegisterPage,
  SupportPage,
} from './pages';
import Partners from './pages/Partners';

import WalletPage from './pages/WalletPage';
import HistoryPage from './pages/HistoryPage';
import DeleteHelpCrunchParent from './pages/Partners/components/DeleteHelpCrunchParent';

const AppRouter = () => {
  const location = useLocation();

  return (
    <Switch location={location}>
      <Route exact path={ROUTES.login}>
        <LoginPage />
      </Route>

      <Route exact path={ROUTES.registration}>
        <RegisterPage />
      </Route>

      <Route exact path={ROUTES.forgot}>
        <ForgotPasswordPage />
      </Route>

      <Route exact path={ROUTES.home}>
        <HomePage />
      </Route>

      <Route exact path={ROUTES.hph}>
        <PrivateLayout>
          <DeleteHelpCrunchParent>
            <Partners withoutInterface name="ad_google" />
          </DeleteHelpCrunchParent>
        </PrivateLayout>
      </Route>

      <Route exact path={ROUTES.partners}>
        <Partners name="ad_google" />
      </Route>

      <Route exact path={ROUTES.playlistCategory}>
        <PrivateLayout>
          <PlaylistCategoryPage />
        </PrivateLayout>
      </Route>

      <Route exact path={ROUTES.profile}>
        <PrivateLayout>
          <ProfilePage />
        </PrivateLayout>
      </Route>

      <Route exact path={ROUTES.company}>
        <PrivateLayout>
          <CompanyPage />
        </PrivateLayout>
      </Route>

      <Route exact path={ROUTES.history}>
        <PrivateLayout>
          <HistoryPage />
        </PrivateLayout>
      </Route>

      <Route exact path={ROUTES.categories}>
        <PrivateLayout>
          <CategoryPage />
        </PrivateLayout>
      </Route>

      <Route path={ROUTES.wallet}>
        <PrivateLayout>
          <WalletPage />
        </PrivateLayout>
      </Route>

      <Route exact path={ROUTES.support}>
        <PrivateLayout>
          <SupportPage />
        </PrivateLayout>
      </Route>

      {NODE_ENV === 'development' && <Route exact path={ROUTES.test}></Route>}

      <Redirect exact to={ROUTES.login} />
    </Switch>
  );
};

export default AppRouter;
