import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { DashboardLayout } from 'src/views/layouts';
import { PageHeader, PageLoader } from 'src/views/blocks';
import { useProfile } from '../../../hooks';

import { connect } from 'react-redux';

import moment from 'moment/min/moment-with-locales';

import Balance from './blocks/Balance';
import CardBlock from './blocks/CardBlock';
import TransactionTable from './blocks/TransactionTable';

import './index.scss';
import { initialWalletAction } from 'store/modules/wallet/actions';

const WalletPage = ({ initialWalletAction, wallet }) => {
  const { loading } = wallet;

  const { country } = useProfile();

  const { t, i18n } = useTranslation();

  moment.locale(i18n.language);

  useEffect(() => {
    const initial = async () => await initialWalletAction(country, wallet);

    initial();
  }, []);

  return (
    <DashboardLayout>
      <PageHeader className="mb-5" variant="primary" title={t('Activity')} />

      {loading ? (
        <PageLoader />
      ) : (
        <Grid
          container
          justifyContent="space-between"
          className="wallet-content"
        >
          <Grid item flexDirection="column" className="wallet-content__left">
            <Balance />
            <CardBlock />
          </Grid>

          <Grid item className="wallet-content__right">
            <TransactionTable />
          </Grid>
        </Grid>
      )}
    </DashboardLayout>
  );
};

const mapStateToProps = ({ wallet }) => ({
  wallet,
});

export default connect(mapStateToProps, { initialWalletAction })(
  memo(WalletPage),
);
