import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import ReactSelect from 'react-select';

import { Typography } from 'src/views/blocks';
import { ClearIndicator, Option, SingleValue } from './blocks';
import './index.scss';

const Select = props => {
  const {
    renderOption: RenderOption,
    className = '',
    isSearchable = true,
    isDisabled = false,
    isRequired,
    isClearable = false,
    options: propsOptions = [],
    translate = false,
    error = false,
    onFocus,
    helperText,
    label,
    placeholder = '',
    name,
    value,
    onChange,
  } = props;

  const { t } = useTranslation();

  const options = useMemo(() => {
    if (translate) {
      return propsOptions.map(option => {
        const { label, ...rest } = option;

        return {
          label: t(label),
          ...rest,
        };
      });
    }

    return propsOptions;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, translate, JSON.stringify(propsOptions)]);

  const modifications = clsx({
    'select--error': error,
  });

  const handleChange = useCallback(
    option => {
      if (onChange) {
        onChange(option?.value || '');
      }
    },
    [onChange],
  );

  return (
    <div className={`select ${className} ${modifications}`}>
      {label && (
        <div
          className={`field-label ${isRequired ? 'field-label--required' : ''}`}
        >
          {t(label)}
        </div>
      )}

      <ReactSelect
        components={{
          IndicatorSeparator: null,
          ClearIndicator: props => {
            return <ClearIndicator {...props} />;
          },
          ...(RenderOption
            ? {
                Option: props => {
                  return <Option {...props} component={RenderOption} />;
                },
                SingleValue: props => {
                  return <SingleValue {...props} component={RenderOption} />;
                },
              }
            : {}),
        }}
        className="react-select"
        classNamePrefix="react-select"
        options={options.map(option => {
          const { label } = option;

          if (translate && label) {
            return {
              ...option,
              label: t(label),
            };
          }

          return option;
        })}
        name={name}
        isSearchable={isSearchable}
        isDisabled={isDisabled}
        placeholder={placeholder}
        onFocus={onFocus}
        value={options.find(option => option.value === value) || value}
        onChange={handleChange}
        isClearable={isClearable}
      />

      {error && helperText && (
        <Typography className="select__helper-text" variant="caption2">
          {helperText}
        </Typography>
      )}
    </div>
  );
};

export { Select };
